import Axios from "axios";
import api from "../../api";

const baseUrl = "/api/chartDocument";

export const getChartDocuments = () => {
  return api.get(baseUrl);
};

export const viewChartDocument = (id) => {
  return api.get(`${baseUrl}/${id}`);
};

export const addChartDocument = (postData) => {
  return api.post(baseUrl, postData);
};

export const updateChartDocument = (updateId, putData) => {
  return api.put(`${baseUrl}/${updateId}`, putData);
};

export const deleteChartDocument = (deleteId) => {
  return api.delete(`${baseUrl}/${deleteId}`);
};

export const getPresignedUrl = (id, type) => {
  return api.post(`${baseUrl}/presignedUrl`, { id, type });
};

export const uploadFile = (url, file, cb) => {
  const controller = new AbortController();
  return Axios.put(url, file.fileObject, {
    headers: {
      "Content-Type": file.type,
    },
    onUploadProgress: (e) => {
      cb(file, controller, {
        uploaded: e.loaded,
        total: e.total,
        progress: Math.floor((e.loaded / e.total) * 100),
      });
    },
    signal: controller.signal,
  });
};

export const downloadFile = (key, type, name) => {
  return api.post(`${baseUrl}/downloadFile`, { key, type, name });
};