import { createSlice } from '@reduxjs/toolkit';
import {
  getExclusiveDocumentApi,
  updateExclusiveChartApi,
  createExclusionChartApi,
  deleteExclusiveApi,
} from './exclusionService';

const initialState = {
  exclusionDocumentList: [],
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
}

export const exclusionDocumentSlice = createSlice({
  name: 'exclusion',
  initialState,
  reducers: {
    // add your non-async reducers here
    logout: state => {
      state.exclusionDocumentList = [];
      state.loading = 'idle';
      state.currentRequestId = undefined;
      state.error = null;
    }
  },

  extraReducers: (builder) => {
    // extraReducers handles asynchronous requests, which is our main focus.
    // GET

    builder
      .addCase(getExclusiveDocumentApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getExclusiveDocumentApi.fulfilled, (state, action) => {

        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.exclusionDocumentList.length = 0;
          state.exclusionDocumentList.push(...action.payload);
          state.currentRequestId = undefined;
        }
      })
      .addCase(getExclusiveDocumentApi.rejected, (state, action) => {

        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })
    // POSt
    builder
      .addCase(createExclusionChartApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(createExclusionChartApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.exclusionDocumentList.unshift(action.payload);
          state.currentRequestId = undefined;
        }
      })

      .addCase(createExclusionChartApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })
    // PUT

    builder
      .addCase(updateExclusiveChartApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(updateExclusiveChartApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          const updateItem = action.payload;

          const index = state.exclusionDocumentList.findIndex((item) => item.id === updateItem.id);
          if (index > -1) {
            state.exclusionDocumentList[index] = updateItem;
          }
          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })
      .addCase(updateExclusiveChartApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

    // Delete 

    builder
      .addCase(deleteExclusiveApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteExclusiveApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          const deleteId = action.payload; // Adjust based on the actual shape of your payload
          if (deleteId) {
            const index = state.exclusionDocumentList.findIndex((item) => item.id === action.payload);
            if (index > -1) {
              state.exclusionDocumentList.splice(index, 1);
            }
          }
          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })

      .addCase(deleteExclusiveApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

  }
})


export const { logout } = exclusionDocumentSlice.actions;
export default exclusionDocumentSlice.reducer;