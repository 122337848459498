import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/loading/loadingSlice';
import { Col, Form, Input, Row, Select } from 'antd';
import { runes } from 'runes2';
import { axiosErrorHandling, isEmptyObject, isObject, trimObjValues } from '../../utils/utility';
import { toast } from 'react-toastify';
import { createProviderApi } from '../../redux/provider/providerService';
import ModalBox from '../../components/ModalBox';

const CreateProviderUser = ({
  createStatus,
  setCreateStatus,
  providerList
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [passwordStrength, setPasswordStrength] = useState({
    lowercase: false,
    uppercase: false,
    specialChar: false,
    number: false,
    length: false,
  });
  const [nameValidator, setNameValidator] = useState({
    specialChar: false,
  });

  const userId = useSelector((state) => state.userDetails.userId);
  const project = useSelector((state) => state.userDetails.project);


  const handlePasswordChange = (value) => {
    // Password validation checks
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /[0-9]/;

    setPasswordStrength({
      lowercase: lowercaseRegex.test(value),
      uppercase: uppercaseRegex.test(value),
      specialChar: specialCharRegex.test(value),
      number: numberRegex.test(value),
      length: value.length >= 8,
    });
  };

  const validatePassword = (_, value) => {
    if (
      passwordStrength.lowercase &&
      passwordStrength.uppercase &&
      passwordStrength.specialChar &&
      passwordStrength.number &&
      passwordStrength.length
    ) {
      return Promise.resolve();
    }
    return Promise.reject(
      'Password must be 8 characters or more, include at least one lowercase character, one uppercase character, one special character, and one number.'
    );
  };

  const handleNameChange = (value) => {
    // Name validation checks
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>_+=/;`~[\]\\']/;


    setNameValidator({
      specialChar: !specialCharRegex.test(value),
    });
  };

  const validateName = (_, value) => {
    if (
      nameValidator.specialChar
    ) {
      return Promise.resolve();
    }
    return Promise.reject(
      'Special characters are not allowed in user names.'
    );
  };


  const getContent = () => {
    const filterOption = (input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
      <Row gutter={16}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onSubmitCreateUser}
            autoComplete="off"
          >
            <Form.Item
              className="mb-2"
              label="Name"
              name="name"
              rules={[
                { required: true, message: 'Please enter the name!' },
                { validator: validateName },
              ]}
            >
              <Input
                count={{
                  show: true,
                  max: 25,
                  strategy: (txt) => runes(txt).length,
                  exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(''),
                }}
                onChange={(e) => handleNameChange(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              className="mb-2"
              label="Email"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'This is not a valid email!',
                },
                { required: true, message: 'Please enter the email!' },
                { whitespace: true },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="mb-2"
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please enter the password!' },
                { validator: validatePassword },
              ]}
            >
              <Input.Password
                onChange={(e) => handlePasswordChange(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              className="mb-2"
              label="Access"
              name="access"
              rules={[
                { required: true, message: 'Please select the user access!' },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select user access"
                optionFilterProp="children"
                filterOption={filterOption}
                options={[
                  { value: 'VIEW', label: 'VIEW' },
                  { value: 'DOWNLOAD', label: 'DOWNLOAD' },

                ]}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  };

  const onSubmitCreateUser = async () => {
    try {
      const values = await form.validateFields();
      const items = { ...values };

      // Check if the rest of the form data is an object
      if (!isObject(items)) {
        return toast.warning('This is not object data!');
      }

      // Check if form data is empty
      if (isEmptyObject(items)) {
        return toast.warning('Empty object cannot accept!');
      }

      // Trim values
      trimObjValues(items);

      // Check if name already exists
      const nameResult = providerList.filter(
        (item) => item.name.toLowerCase() === items.name.toLowerCase()
      );
      if (nameResult.length > 0) {
        return toast.warning('The name already exists!');
      }

      // Check if email already exists
      const emailResult = providerList.filter(
        (item) => item.email.toLowerCase() === items.email.toLowerCase()
      );
      if (emailResult.length > 0) {
        return toast.warning('The email already exists!');
      }

      // Proceed with user creation
      setCreateStatus(false);
      dispatch(setLoading(true));
      items.isProvider = false;
      items.status = "ACTIVE";
      items.project = project;
      items.createdBy = userId;
      await dispatch(createProviderApi(items)).unwrap();
      dispatch(setLoading(false));
      toast.success('Successfully created a user!');
    } catch (error) {
      dispatch(setLoading(false));
      return axiosErrorHandling(error);
    }
  };

  return (
    <>
      {createStatus && (
        <ModalBox
          open={createStatus}
          title="Create User"
          width="xs"
          content={getContent()}
          onSubmitTitle="Save"
          onSubmit={onSubmitCreateUser}
          onCancelTitle="Cancel"
          onCancel={() => {
            setCreateStatus(false);
          }}
        />
      )}
    </>
  );
};

export default CreateProviderUser;
