/* eslint-disable no-useless-escape */
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import dayjs from 'dayjs';
import { SHA256 } from 'crypto-js';
import { automateEquipmentData, States } from '../assets/DispCodes';
import LOGO from "../assets/Logo_WWS.png";
import ExcelJS from 'exceljs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axios from 'axios';

dayjs.extend(utc);
dayjs.extend(timezone);
const dateFormat = 'MM/DD/YYYY';

export const filterLowercase = (dataList, objectKey, searchItem) => {
  const result = dataList.filter((item) => {
    return item[objectKey]
      .toString()
      .toLowerCase()
      .match(searchItem.toString().toLowerCase());
  });
  if (result.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const filterLowercaseReturnId = (
  dataList,
  objectKey,
  searchItem,
  returnPropertyName
) => {
  const result = dataList.filter((item) => {
    return item[objectKey]
      .toString()
      .toLowerCase()
      .match(searchItem.toString().toLowerCase());
  });
  if (result.length > 0) {
    const data = result[0][returnPropertyName];
    return data;
  } else {
    return null;
  }
};

export const filterLowercaseReturnData = (dataList, objectKey, searchItem) => {
  const result = dataList.filter((item) => {
    return item[objectKey]
      .toString()
      .toLowerCase()
      .match(searchItem.toString().toLowerCase());
  });
  if (result.length > 0) {
    const data = result[0];
    return data;
  } else {
    return null;
  }
};

export const axiosErrorHandling = (error) => {
  if (error.response) {
    const { data } = error.response;
    return toast.error(data);
  } else if (error.request) {
    return toast.error(error.request);
  } else if (error.message) {
    return toast.error(error.message);
  } else {
    return toast.error(error);
  }
};

export const axiosSubmitErrorHandling = (error) => {
  if (error.response) {
    const { data } = error.response;
    return toast.warning(data);
  } else if (error.request) {
    return toast.warning(error.request);
  } else if (error.message) {
    return toast.warning(error.message);
  } else {
    return toast.warning(error);
  }
};

export const isObject = (o) => {
  return o !== null && typeof o === 'object' && Array.isArray(o) === false;
};

export const isEmptyObject = (objectName) => {
  if (objectName) return Object.keys(objectName).length === 0;
};

export const unChangedObj = (inputValue) => {
  const checkIsObject = isObject(inputValue);

  if (checkIsObject) {
    return JSON.parse(JSON.stringify(inputValue));
  }

  return inputValue;
};

export const trimObjValues = (obj) => {
  if (obj) {
    return Object.keys(obj).forEach(
      (k) => (obj[k] = typeof obj[k] == 'string' ? obj[k].trim() : obj[k])
    );
  }
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const isHereNegativeValueInNumberField = (inputValue) => {
  if (inputValue) {
    const formatNumber = inputValue ? parseInt(inputValue) : inputValue;
    return formatNumber < 0 ? true : false;
  }
  return null;
};

export const isAnySpecialCharacters = (inputValue) =>
  !new RegExp('^[a-zA-Z0-9 ]*$').test(inputValue); // allowed space and not allowed special characters
export const isInvalidNumber = (inputValue) =>
  !new RegExp('^[0-9]+$').test(inputValue);

export const isInvalidWebsiteUrl = (inputValue) =>
  !new RegExp(
    '(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}.[a-zA-Z0-9]{2,}.[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})?'
  ).test(inputValue);

/* sort ascending order */
export const sortArrayOfObjectsByAscendingOrder = (arrayList, keyName) => {
  if (Array.isArray(arrayList) && arrayList.length > 0 && keyName) {
    const cloneArray = [...arrayList];
    cloneArray.sort((a, b) => a[`${keyName}`].localeCompare(b[`${keyName}`]));
    return cloneArray;
  }
  return null;
};

/* sort ascending order */
export const sortObjectKeyByAscendingOrder = (inputobj) => {
  if (!_.isEmpty(inputobj)) {
    const cloneObject = { ...inputobj };
    const ordered = Object.keys(cloneObject)
      .sort()
      .reduce((obj, key) => {
        obj[key] = cloneObject[key];
        return obj;
      }, {});
    return ordered;
  }
  return null;
};

export const compareTwoObjects = (previousObject, currentObject) => {
  if (!isObject(previousObject))
    return toast.warning('Previous object is not a type of object');

  if (!isObject(currentObject))
    return toast.warning('Current object is not a type of object');

  if (_.isEmpty(previousObject))
    return toast.warning('previous object is empty');

  if (_.isEmpty(currentObject)) return toast.warning('Current object is empty');

  const result = _.isEqual(previousObject, currentObject);
  return result;
};

export const removeDuplicatesInArrayOfObjects = (arrayOfObjects, keyName) => {
  if (Array.isArray(arrayOfObjects) && arrayOfObjects.length && keyName) {
    const unique = arrayOfObjects.filter((obj, index) => {
      return (
        index === arrayOfObjects.findIndex((o) => obj[keyName] === o[keyName])
      );
    });

    return unique;
  }
};

export const checkEveryObjectValueIsEmptyInArray = (arrayOfObjectsList) => {
  if (Array.isArray(arrayOfObjectsList) && arrayOfObjectsList.length) {
    const checkIsEmptyValueInObject = arrayOfObjectsList.map((object) =>
      Object.values(object).every((val) => Boolean(val))
    );
    const result = checkIsEmptyValueInObject.every((v) => v === false);
    return result;
  }
  return false;
};

export const navigateWebsiteUrl = (websiteUrl) => {
  if (websiteUrl) {
    const checkHttp = websiteUrl.includes('http://');
    const checkHttps = websiteUrl.includes('https://');

    let url = '';

    if (checkHttp) url = websiteUrl.replace('http://', '');
    if (checkHttps) url = websiteUrl.replace('https://', '');
    if (!url) url = websiteUrl;

    window.open(`//${url}`, '_blank');
  }
};

export const getStateName = (stateList, stateId) => {
  return new Promise((resolve, reject) => {
    if (Array.isArray(stateList) && stateList.length > 0 && stateId) {
      const filterState = _.filter(stateList, { id: stateId });
      const stateName = filterState.length > 0 ? filterState[0].name : '';

      stateName ? resolve(stateName) : reject('');
    } else {
      reject('');
    }
  });
};

export const getUserName = (userList, userId) => {
  return new Promise((resolve, reject) => {
    if (Array.isArray(userList) && userList.length > 0 && userId) {
      const filterUser = _.filter(userList, { id: userId });
      const userName = filterUser.length > 0 ? filterUser[0].name : '';

      userName ? resolve(userName) : reject('');
    } else {
      reject('');
    }
  });
};

export const formatSelectedFiles = (files) => {
  if (files.length > 0) {
    return files.map((f) => {
      return {
        fileObject: f,
        folderPath: f.webkitRelativePath.replace(`/${f.name}`, ''),
        fullPath: f.webkitRelativePath,
        lastModified: f.lastModified,
        lastModifiedDate: f.lastModifiedDate,
        name: f.name,
        size: f.size,
        type: f.type,
        webkitRelativePath: f.webkitRelativePath,
      };
    });
  }

  return [];
};

export const DateToJSDate = (excelDate, timeZone) => {
  // Excel date format starts from December 31, 1899
  const excelStartDate = dayjs('1899-12-31T00:00:00Z').utc();

  // Convert Excel date to the correct number of days
  const jsDate = excelStartDate.add(excelDate - 1, 'day');

  // Convert to the specified time zone and format as MM/DD/YYYY
  const formattedDate = jsDate.tz(timeZone.value, true).format('MM/DD/YYYY');

  return formattedDate;
};

const stateMapping = States.reduce((acc, { value, label }) => {
  acc[value] = label;
  return acc;
}, {});

export const exportToExcel = (extractionData, chartData, fileName) => {

  const headers = Object.values(chartData);

  const partiallyMaskedKeys = {
    "orderingPhysicianNPI": 4,    // Show last 4 characters
    "patientPhoneNumber": 4,
    "patientMailId": 3,
    "patientPrimaryInsurance": 3,
    "primaryInsuranceGroupNumber": 3,
    "secondaryInsuranceNumber": 4,
    "secondaryInsuranceGroupNumber": 3,


  };

  const maskString = (str, visibleCount, maskChar = 'X') => {
    if (str.length <= visibleCount) return str;
    const maskedPart = str.slice(0, -visibleCount).replace(/./g, maskChar);
    const visiblePart = str.slice(-visibleCount);
    return maskedPart + visiblePart;
  };


  const data = extractionData.map((entry) => {
    const rowData = Object.keys(chartData).map((key) => {
      let value = entry[key];
      if (extractionData.length === 1 && partiallyMaskedKeys[key] && typeof value === 'string') {
        return maskString(value, partiallyMaskedKeys[key]);
      }

      if (value === "U/A") {
        return "UNAVAILABLE";
      }
      if (
        key === "receivedOn" ||
        key === 'dob' ||
        key === 'subscriberDob' ||
        key === 'babyDueDate' ||
        key === 'verifyCompletedOn' ||
        key === 'coverageStartDate' ||
        key === 'coverageEndDate' ||
        key === 'callingAdded' ||
        key === 'prescriptionReceivedOn' ||
        key === 'lmnOrderDate' ||
        key === 'lmnStartDate' ||
        key === 'lmnEndDate' ||
        key === 'validationDate' ||
        key === 'initialSubsequentDate'
      ) {
        return entry[key] === "U/A" || entry[key] === "N/A" ? entry[key] : formatDate(entry[key]);
      } else if (key === 'internalFtpDate' || key === "orderDate" || key === "deliveredOn" || key === 'insuranceCardReceivedOn') {
        return entry[key] === "U/A" || entry[key] === "N/A" ? entry[key] : subFormatDate(entry[key]);
      } else if (
        key === 'innDedCalendar' ||
        key === 'innDedMetAmount' ||
        key === 'innDedBalanceAmount' ||
        key === 'innOopCalendar' ||
        key === 'innOopMetAmount' ||
        key === 'oonDedCalendar' ||
        key === 'oonDedMetAmount' ||
        key === 'oonOopCalendar' ||
        key === 'ooDedBalanceAmount' ||
        key === 'oonOopMet'
      ) {
        return value === undefined || value === null || value === '' || value === 'N/A' ? 'N/A' : `$${value}`;
      } else if (
        key === 'innCoins' ||
        key === 'oonCoins'
      ) {
        return value === undefined || value === null || value === '' || value === 'N/A' ? 'N/A' : `${value}%`;
      } else if (key === "vfRequestedEquipmentModel") {
        return entry.vfRequestedEquipmentModelDetails.length > 0
          ? entry.vfRequestedEquipmentModelDetails.length === 1
            ? entry.vfRequestedEquipmentModelDetails[0].vfRequestedEquipmentModel
            : entry.vfRequestedEquipmentModelDetails.map(item => item.vfRequestedEquipmentModel).join(', ')
          : 'N/A';
      } else if (key === "hcpcsCodes") {
        return entry.vfRequestedEquipmentModelDetails.length > 0
          ? entry.vfRequestedEquipmentModelDetails.length === 1
            ? entry.vfRequestedEquipmentModelDetails[0].hcpcsCodes
            : entry.vfRequestedEquipmentModelDetails.map(item => item.hcpcsCodes).join(', ')
          : 'N/A';
      }

      else if (key === "equipmentName") {
        return entry.vfRequestedEquipmentModelDetails.length > 0
          ? entry.vfRequestedEquipmentModelDetails.length === 1
            ? entry.vfRequestedEquipmentModelDetails[0].equipmentName
            : entry.vfRequestedEquipmentModelDetails.map(item => item.equipmentName).join(', ')
          : 'N/A';
      } else if (key === "orderingPhysicianNPI") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysicianNPI
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysicianNPI).join(', ')
          : 'N/A';
      } else if (key === "orderingPhysician") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysician
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysician).join(', ')
          : 'N/A';
      } else if (key === "orderingPhysicianAddress") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysicianAddress
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysicianAddress).join(', ')
          : 'N/A';
      } else if (key === "orderingPhysicianPhoneNumber") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysicianPhoneNumber
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysicianPhoneNumber).join(', ')
          : 'N/A';
      } else if (key === "orderingPhysicianFaxNumber") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysicianFaxNumber
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysicianFaxNumber).join(', ')
          : 'N/A';
      } else if (key === "orderingPhysicianSpeciality") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysicianSpeciality
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysicianSpeciality).join(', ')
          : 'N/A';
      } else if (key === "hcpcsFormPrescription") {
        return entry.prescriptionModelDetails.length > 0
          ? entry.prescriptionModelDetails.length === 1
            ? entry.prescriptionModelDetails[0].hcpcsFormPrescription || 'N/A'
            : entry.prescriptionModelDetails.map(item => item.hcpcsFormPrescription || 'N/A').join(', ')
          : 'N/A';
      } else if (key === "equipmentModelFromPrescription") {
        return entry.prescriptionModelDetails.length > 0
          ? entry.prescriptionModelDetails.length === 1
            ? entry.prescriptionModelDetails[0].equipmentModelFormPrescription || 'N/A'
            : entry.prescriptionModelDetails.map(item => item.equipmentModelFormPrescription || 'N/A').join(', ')
          : 'N/A';
      }

      else if (key === "patientPhoneNumber") {
        return entry.phoneMailEquipmentModels.length > 0
          ? entry.phoneMailEquipmentModels.map(item =>
            extractionData.length === 1
              ? maskString(item.phoneNumber, partiallyMaskedKeys[key])
              : item.phoneNumber
          ).join(', ')
          : 'N/A';
      }

      else if (key === "patientMailId") {
        return entry.phoneMailEquipmentModels.length > 0
          ? entry.phoneMailEquipmentModels.map(item =>
            extractionData.length === 1
              ? maskString(item.mailId, partiallyMaskedKeys[key])
              : item.mailId
          ).join(', ')
          : 'N/A';
      }

      else if (key === "DxCodesModelDetails") {
        return entry.DxCodesModelDetails.length > 0
          ? entry.DxCodesModelDetails.length === 1
            ? entry.DxCodesModelDetails[0].dxCodes
            : entry.DxCodesModelDetails.map(item => item.dxCodes).join(', ')
          : 'N/A';
      }

      else if (key === "primaryInsuranceState" || key === "secondaryInsuranceState") {
        return stateMapping[entry[key]] || 'N/A';
      } else if (key === "project") {
        return entry['facilityName'] ? entry['facilityName'] : entry[key] && entry[key].name ? entry[key].name : 'N/A';
      }
      else if (key === "startTime") {
        return value === undefined || value === null || value === '' || value === 'N/A' ? 'N/A' : value;
      } else if (key === "endTime") {
        return value === undefined || value === null || value === '' || value === 'N/A' ? 'N/A' : value;
      } else if (key === "ach") {
        const startTime = entry['startTime'] ? (entry['startTime']) : '';
        const endTime = entry['endTime'] ? (entry['endTime']) : '';
        return startTime && endTime ? convertCalculateDuration(startTime, endTime) : 'N/A';
      } else {
        return entry[key] || 'N/A';
      }
    });
    return rowData;
  });

  const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

  return XLSX.writeFile(workbook, fileName + '.xlsx');
};
// export const reportRepositoryExportToExcel = (extractionData, chartData, fileName) => {
//   // Remove the 'key' and 'no' properties from each data object
//   const dataWithoutKeyNo = extractionData.map(({ key, no, ...rest }) => rest);

//   const ws = XLSX.utils.json_to_sheet(dataWithoutKeyNo);

//   // Customize header
//   const range = XLSX.utils.decode_range(ws['!ref']);
//   for (let C = range.s.c; C <= range.e.c; ++C) {
//     const address = XLSX.utils.encode_col(C) + "1";
//     if (!ws[address]) continue;
//     ws[address].s = { font: { bold: true }, alignment: { horizontal: "center" } };
//   }

//   // Adding total row
//   const totalRow = [{ name: 'Total # of Patients Verified', count: dataWithoutKeyNo.reduce((acc, item) => acc + item.count, 0) }];
//   XLSX.utils.sheet_add_json(ws, totalRow, { skipHeader: true, origin: -1 });

//   const wb = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(wb, ws, "Verification Summary");

//   // Generate Excel file and trigger download
//   XLSX.writeFile(wb, `${fileName}.xlsx`);
// }

export const reportMonthlyVerificationSummaryRepositoryExportToExcel = async (extractionData, fileName, filteredChartData) => {

  const workbook = new ExcelJS.Workbook();

  // First worksheet
  const worksheet1 = workbook.addWorksheet('Summary');

  // Define columns for the first worksheet
  worksheet1.columns = [
    { header: 'Verification Summary', key: 'name', width: 45 },
    { header: '', key: 'count', width: 15 }
  ];

  // Add header styling for the first worksheet
  const headerRow1 = worksheet1.getRow(1);
  headerRow1.font = { bold: true };
  headerRow1.alignment = { horizontal: 'center' };
  headerRow1.eachCell((cell, colNumber) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF375623' } // Green background color
    };
    cell.font = { color: { argb: 'FFFFFFFF' }, bold: true }; // White bold text
    if (colNumber === 1) {
      cell.value = 'Verification Summary';
    }
  });

  // Add data rows for the first worksheet
  extractionData.forEach((item, index) => {
    const row = worksheet1.addRow({
      name: item.name ?? 'u/a',
      count: item.count ?? 'u/a'
    });
    row.alignment = { horizontal: 'center' };
    if (index % 2 === 0) {
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF2F2F2' } // Light grey background for alternating rows
      };
    }
  });

  // Add total row for the first worksheet
  const totalCount1 = extractionData.reduce((acc, item) => acc + (item.count ?? 0), 0);
  const totalRow1 = worksheet1.addRow({ name: 'Total # of Patients Verified', count: totalCount1 });
  totalRow1.eachCell((cell, colNumber) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF375623' } // Green background color
    };
    cell.font = { color: { argb: 'FFFFFFFF' }, bold: true }; // White bold text
  });

  // Add border to all cells in the first worksheet
  worksheet1.eachRow({ includeEmpty: true }, function (row) {
    row.eachCell({ includeEmpty: true }, function (cell) {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });

  // Second worksheet
  const worksheet2 = workbook.addWorksheet('Verification');

  // Define columns for the second worksheet
  worksheet2.columns = [
    { header: 'Received On', key: 'receivedOn', width: 30 },
    { header: 'Patient Name', key: 'patientName', width: 30 },
    { header: 'DOB', key: 'dob', width: 30 },
    { header: 'Address', key: 'address', width: 30 },
    { header: 'City', key: 'city', width: 30 },
    { header: 'State', key: 'state', width: 30 },
    { header: 'Zip', key: 'zipCode', width: 30 },
    { header: 'Home #', key: 'patientPhoneNumber', width: 30 },
    { header: 'Mail ID', key: 'patientMailId', width: 30 },
    { header: 'Primary Insurance', key: 'patientPrimaryInsurance', width: 30 },
    { header: 'Primary Insurance State', key: 'primaryInsuranceState', width: 30 },
    { header: 'Primary Plan', key: 'primaryInsurancePlan', width: 30 },
    { header: 'Primary Policy Number', key: 'policyPatientPrimaryInsurance', width: 30 },
    { header: 'Primary Group ID', key: 'primaryInsuranceGroupNumber', width: 30 },
    { header: 'Provider', key: 'providerProjectName', width: 30 },
    { header: 'Procedure Codes', key: 'procedureCodes', width: 30 },
    { header: 'VF Date', key: 'verifyCompletedOn', width: 30 },
    { header: 'Calender Month', key: 'calendarMonth', width: 30 },
    { header: 'Coverage Start Date', key: 'coverageStartDate', width: 30 },
    { header: 'Coverage End Date', key: 'coverageEndDate', width: 30 },
    { header: 'Network Status', key: 'networkStatus', width: 30 },
    { header: '"INN Beni\'s for DME"', key: 'innBenefits', width: 30 },
    { header: 'INN Ded Actual Amt', key: 'innDedActualAmt', width: 30 },
    { header: 'INN Ded Met Amt', key: 'innDedMetAmt', width: 30 },
    { header: 'INN Ded Bal', key: 'innDedBal', width: 30 },
    { header: 'INN Coins', key: 'innCoins', width: 30 },
    { header: 'INN OOP Actual Amt', key: 'innOopActualAmt', width: 30 },
    { header: 'INN OOP Used Amt', key: 'innOopUsedAmt', width: 30 },
    { header: 'OON Beni\'s for DME', key: 'onnBenefits', width: 30 },
    { header: 'OON Ded Actual Amt', key: 'oonDedActualAmt', width: 30 },
    { header: 'OON Ded Met Amt', key: 'oonDedMetAmt', width: 30 },
    { header: 'OON Ded Bal', key: 'oonDedBal', width: 30 },
    { header: 'OON Coins', key: 'oonCoins', width: 30 },
    { header: 'OON OOP Actual Amt', key: 'oonOopCalendar', width: 30 },
    { header: 'OON OOP Used Amt', key: 'oonOopUsedAmt', width: 30 },
    { header: 'Auth required', key: 'authRequired', width: 30 },
    { header: 'VF Category', key: 'vfCategory', width: 30 },
    { header: 'VF Comments', key: 'vfComments', width: 30 }
  ];

  // Add header styling for the second worksheet
  const headerRow2 = worksheet2.getRow(1);
  headerRow2.font = { bold: true };
  headerRow2.alignment = { horizontal: 'center' };
  headerRow2.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF375623' } // Green background color
    };
    cell.font = { color: { argb: 'FFFFFFFF' }, bold: true }; // White bold text
  });

  // Add data rows for the second worksheet
  filteredChartData.forEach((item, index) => {
    const hcpcsCodes = item?.vfRequestedEquipmentModelDetails?.map(equipmentModelDetails => equipmentModelDetails.hcpcsCodes).join(',') || 'u/a';
    const mailID = item.phoneMailEquipmentModels.map(phoneMailEquipmentModels => phoneMailEquipmentModels.mailId).join(',') || 'u/a';
    const phoneNumber = item.phoneMailEquipmentModels.map(phoneMailEquipmentModels => phoneMailEquipmentModels.phoneNumber).join(',') || 'u/a';
    const row = worksheet2.addRow({
      receivedOn: formatDate(item.receivedOn) ?? 'u/a',
      patientName: item.patientName ?? 'u/a',
      dob: formatDate(item.dob) ?? 'u/a',
      address: item.address ?? 'u/a',
      city: item.city ?? 'u/a',
      state: stateMapping[item.state] ?? 'u/a', // Convert state short form to full name
      zipCode: item.zipCode ?? 'u/a',
      patientPhoneNumber: phoneNumber,
      patientMailId: mailID,
      patientPrimaryInsurance: item.patientPrimaryInsurance ?? 'u/a',
      primaryInsuranceState: stateMapping[item.primaryInsuranceState] ?? 'u/a',
      primaryInsurancePlan: item.primaryInsurancePlan ?? 'u/a',
      policyPatientPrimaryInsurance: item.patientPrimaryInsurance ?? 'u/a',
      primaryInsuranceGroupNumber: item.primaryInsuranceGroupNumber ?? 'u/a',
      providerProjectName: item.project?.name ?? 'u/a',
      procedureCodes: hcpcsCodes,
      verifyCompletedOn: formatDate(item.verifyCompletedOn) ?? 'u/a',
      calendarMonth: item.calendarMonth ?? 'u/a',
      coverageStartDate: formatDate(item.coverageStartDate) ?? 'u/a',
      coverageEndDate: formatDate(item.coverageEndDate) ?? 'u/a',
      networkStatus: item.networkStatus ?? 'u/a',
      innBenefits: item.innBenefits ?? 'u/a',
      innDedActualAmt: item.innDedCalendar ?? 'u/a',
      innDedMetAmt: item.innDedMetAmount ?? 'u/a',
      innDedBal: item.innDedBalanceAmount ?? 'u/a',
      innCoins: item.innCoins ?? 'u/a',
      innOopActualAmt: item.innOopCalendar ?? 'u/a',
      innOopUsedAmt: item.innOopMetAmount ?? 'u/a',
      onnBenefits: item.onnBenefits ?? 'u/a',
      oonDedActualAmt: item.oonDedCalendar ?? 'u/a',
      oonDedMetAmt: item.oonDedMetAmount ?? 'u/a',
      oonDedBal: item.ooDedBalanceAmount ?? 'u/a',
      oonCoins: item.oonCoins ?? 'u/a',
      oonOopCalendar: item.oonOopCalendar ?? 'u/a',
      oonOopUsedAmt: item.oonOopMet ?? 'u/a',
      authRequired: item.authRequired ?? 'u/a',
      vfCategory: item.vfCategory ?? 'u/a',
      vfComments: item.vfComments ?? 'u/a'
    });
    row.alignment = { horizontal: 'center' };
    if (index % 2 === 0) {
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF2F2F2' } // Light grey background for alternating rows
      };
    }
  });

  // Write the Excel file to a buffer
  const buffer = await workbook.xlsx.writeBuffer();

  // Save the buffer as an Excel file
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}.xlsx`);
};

export const reportVerificationWeeklySummaryRepositoryExportToExcel = async (extractionData, fileName, filteredChartData, chartList) => {
  const workbook = new ExcelJS.Workbook();

  // First worksheet
  const worksheet1 = workbook.addWorksheet('Summary');

  // Define columns for the first worksheet
  worksheet1.columns = [
    { header: 'Verification Summary', key: 'name', width: 45 },
    { header: '', key: 'count', width: 15 }
  ];

  // Add header styling for the first worksheet
  const headerRow1 = worksheet1.getRow(1);
  headerRow1.font = { bold: true };
  headerRow1.alignment = { horizontal: 'center' };
  headerRow1.eachCell((cell, colNumber) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF92D050' } // Green background color
    };
    cell.font = { color: { argb: 'FFFFFFFF' }, bold: true }; // White bold text
    if (colNumber === 1) {
      cell.value = 'Verification Summary';
    }
  });

  // Add data rows for the first worksheet
  extractionData.forEach((item, index) => {
    const row = worksheet1.addRow({
      name: item.name ?? 'u/a',
      count: item.count ?? 'u/a'
    });
    row.alignment = { horizontal: 'center' };
    if (index % 2 === 0) {
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF2F2F2' } // Light grey background for alternating rows
      };
    }
  });

  // Add total row for the first worksheet
  const totalCount1 = extractionData.reduce((acc, item) => acc + (item.count ?? 0), 0);
  const totalRow1 = worksheet1.addRow({ name: 'Total No.of Patients Received', count: totalCount1 });
  totalRow1.eachCell((cell, colNumber) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF92D050' } // Green background color
    };
    cell.font = { color: { argb: 'FFFFFFFF' }, bold: true }; // White bold text
  });

  // Add border to all cells in the first worksheet
  worksheet1.eachRow({ includeEmpty: true }, function (row) {
    row.eachCell({ includeEmpty: true }, function (cell) {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });

  // Second Sheet No.of patient Vf In-process
  const workSheet2 = workbook.addWorksheet('No.of Patients VF In-Process');

  // Define columns for the second worksheet
  workSheet2.columns = [
    { header: 'Received On', key: 'receivedOn', width: 30 },
    { header: 'Patient Name', key: 'patientName', width: 30 },
    { header: 'DOB', key: 'dob', width: 30 },
    { header: 'Address', key: 'address', width: 30 },
    { header: 'City', key: 'city', width: 30 },
    { header: 'State', key: 'state', width: 30 },
    { header: 'Zip', key: 'zipCode', width: 30 },
    { header: 'Home #', key: 'patientPhoneNumber', width: 30 },
    { header: 'Mail ID', key: 'patientMailId', width: 30 },
    { header: 'Primary Insurance', key: 'patientPrimaryInsurance', width: 30 },
    { header: 'Primary Insurance State', key: 'primaryInsuranceState', width: 30 },
    { header: 'Primary Plan', key: 'primaryInsurancePlan', width: 30 },
    { header: 'Primary Policy Number', key: 'policyPatientPrimaryInsurance', width: 30 },
    { header: 'Primary Group ID', key: 'primaryInsuranceGroupNumber', width: 30 },
    { header: 'Secondary Insurance', key: 'secondaryInsurance', width: 30 },
    { header: 'Secondary Insurance State', key: 'secondaryInsuranceState', width: 30 },
    { header: 'Secondary Plan', key: 'secondaryInsurancePlan', width: 30 },
    { header: 'Secondary Policy Number', key: 'secondaryInsuranceNumber', width: 30 },
    { header: 'Secondary Group ID', key: 'secondaryInsuranceGroupId', width: 30 },
    { header: 'Baby DOB/Due Date', key: 'babyDueDate', width: 30 },
    { header: 'Dx Codes', key: 'DxCodesModelDetails', width: 30 },
    { header: 'Procedure Codes', key: 'procedureCodes', width: 30 },
    { header: 'Model', key: 'vfModels', width: 30 },
    { header: 'VF Date', key: 'verifyCompletedOn', width: 30 },
    { header: 'Calender Month', key: 'calendarMonth', width: 30 },
    { header: 'Coverage Start Date', key: 'coverageStartDate', width: 30 },
    { header: 'Coverage End Date', key: 'coverageEndDate', width: 30 },
    { header: 'Network Status', key: 'networkStatus', width: 30 },
    { header: '"INN Beni\'s for DME"', key: 'innBenefits', width: 30 },
    { header: 'INN Ded Actual Amt', key: 'innDedActualAmt', width: 30 },
    { header: 'INN Ded Met Amt', key: 'innDedMetAmt', width: 30 },
    { header: 'INN Ded Bal', key: 'innDedBal', width: 30 },
    { header: 'INN Coins', key: 'innCoins', width: 30 },
    { header: 'INN OOP Actual Amt', key: 'innOopActualAmt', width: 30 },
    { header: 'INN OOP Used Amt', key: 'innOopUsedAmt', width: 30 },
    { header: 'OON Beni\'s for DME', key: 'onnBenefits', width: 30 },
    { header: 'OON Ded Actual Amt', key: 'oonDedActualAmt', width: 30 },
    { header: 'OON Ded Met Amt', key: 'oonDedMetAmt', width: 30 },
    { header: 'OON Ded Bal', key: 'oonDedBal', width: 30 },
    { header: 'OON Coins', key: 'oonCoins', width: 30 },
    { header: 'OON OOP Actual Amt', key: 'oonOopCalendar', width: 30 },
    { header: 'OON OOP Used Amt', key: 'oonOopUsedAmt', width: 30 },
    { header: 'Auth required', key: 'authRequired', width: 30 },
    { header: 'Category', key: 'category', width: 30 },
    { header: '"S&SReceived Breast Pump in Last 6 Months?"', key: 'ssReceived', width: 30 },
    { header: 'Physician Order', key: 'physcianOrder', width: 30 },
    { header: 'VF Comments', key: 'vfComments', width: 30 }
  ];

  const headerRow2 = workSheet2.getRow(1);
  headerRow2.font = { bold: true };
  headerRow2.alignment = { horizontal: 'center' };
  headerRow2.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF92D050' } // Green background color
    };
    cell.font = { color: { argb: 'FFFFFFFF' }, bold: true }; // White bold text
  });

  let VfInProcess = filteredChartData.filter((item) => item.vfCategory === "VF In-Process");

  // Add data rows for the second worksheet
  VfInProcess.forEach((item, index) => {

    const dxCodes = item.DxCodesModelDetails.map(dxDetail => dxDetail.dxCodes).join(',') || 'u/a';
    const hcpcsCodes = item?.vfRequestedEquipmentModelDetails?.map(equipmentModelDetails => equipmentModelDetails.hcpcsCodes).join(',') || 'u/a';
    const physicainOrder = item.orderingPhysicianModelDetails.map(orderingPhysicianDetail => orderingPhysicianDetail.orderingPhysician).join(',') || 'u/a';
    const vfModels = item.vfRequestedEquipmentModelDetails.map(vfRequestedEquipmentModelDetails => vfRequestedEquipmentModelDetails.equipmentName).join(',') || 'u/a';
    const mailID = item.phoneMailEquipmentModels.map(phoneMailEquipmentModels => phoneMailEquipmentModels.mailId).join(',') || 'u/a';
    const phoneNumber = item.phoneMailEquipmentModels.map(phoneMailEquipmentModels => phoneMailEquipmentModels.phoneNumber).join(',') || 'u/a';
    const row = workSheet2.addRow({

      receivedOn: formatDate(item.receivedOn) ?? 'u/a',
      patientName: item.patientName ?? 'u/a',
      dob: formatDate(item.dob) ?? 'u/a',
      address: item.address ?? 'u/a',
      city: item.city ?? 'u/a',
      state: stateMapping[item.state] ?? 'u/a',
      zipCode: item.zipCode ?? 'u/a',
      patientPhoneNumber: phoneNumber,
      patientMailId: mailID,
      patientPrimaryInsurance: item.patientPrimaryInsurance ?? 'u/a',
      primaryInsuranceState: stateMapping[item.primaryInsuranceState] ?? 'u/a',
      primaryInsurancePlan: item.primaryInsurancePlan ?? 'u/a',
      policyPatientPrimaryInsurance: item.patientPrimaryInsurance ?? 'u/a',
      primaryInsuranceGroupNumber: item.primaryInsuranceGroupNumber ?? 'u/a',
      secondaryInsurance: item.secondaryInsurance ?? 'u/a',
      secondaryInsuranceState: stateMapping[item.secondaryInsuranceState] ?? 'u/a',
      secondaryInsurancePlan: item.secondaryInsurancePlan ?? 'u/a',
      secondaryInsuranceNumber: item.secondaryInsuranceNumber ?? 'u/a',
      secondaryInsuranceGroupId: item.secondaryInsuranceGroupNumber ?? 'u/a',
      babyDueDate: formatDate(item.babyDueDate) ?? 'u/a',
      DxCodesModelDetails: dxCodes, // Comma-separated dxCodes
      procedureCodes: hcpcsCodes,
      vfModels: vfModels,
      hcpcsCodes: item.hcpcsCodes ?? 'u/a',
      vfTAT: item.vfTAT ?? 'u/a',
      calendarMonth: item.calendarMonth ?? 'u/a',
      coverageStartDate: formatDate(item.coverageStartDate) ?? 'u/a',
      coverageEndDate: formatDate(item.coverageEndDate) ?? 'u/a',
      networkStatus: item.networkStatus ?? 'u/a',
      innBenefits: item.innBenefits ?? 'u/a',
      innDedActualAmt: item.innDedCalendar ?? 'u/a',
      innDedMetAmt: item.innDedMetAmount ?? 'u/a',
      innDedBal: item.innDedBalanceAmount ?? 'u/a',
      innCoins: item.innCoins ?? 'u/a',
      innOopActualAmt: item.innOopCalendar ?? 'u/a',
      innOopUsedAmt: item.innOopMetAmount ?? 'u/a',
      onnBenefits: item.onnBenefits ?? 'u/a',
      oonDedActualAmt: item.oonDedCalendar ?? 'u/a',
      oonDedMetAmt: item.oonDedMetAmount ?? 'u/a',
      oonDedBal: item.ooDedBalanceAmount ?? 'u/a',
      oonCoins: item.oonCoins ?? 'u/a',
      oonOopCalendar: item.oonOopCalendar ?? 'u/a',
      oonOopUsedAmt: item.oonOopMet ?? 'u/a',
      authRequired: item.authRequired ?? 'u/a',
      category: item.vfCategory ?? 'u/a',
      ssReceived: item.ssReceived ?? 'u/a',
      physcianOrder: physicainOrder,
      vfComments: item.vfComments ?? 'u/a'
    });

    row.alignment = { horizontal: 'center' };
    if (index % 2 === 0) {
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF2F2F2' } // Light grey background for alternating rows
      };
    }
  });

  // Third Sheet No.of patient Delivered
  const worksheet3 = workbook.addWorksheet('No.of Patients Delivered');

  // Define columns for the third worksheet
  worksheet3.columns = [
    { header: 'Received On', key: 'receivedOn', width: 30 },
    { header: 'Patient Name', key: 'patientName', width: 30 },
    { header: 'DOB', key: 'dob', width: 30 },
    { header: 'Address', key: 'address', width: 30 },
    { header: 'City', key: 'city', width: 30 },
    { header: 'State', key: 'state', width: 30 },
    { header: 'Zip', key: 'zipCode', width: 30 },
    { header: 'Home #', key: 'patientPhoneNumber', width: 30 },
    { header: 'Mail ID', key: 'patientMailId', width: 30 },
    { header: 'Primary Insurance', key: 'patientPrimaryInsurance', width: 30 },
    { header: 'Primary Insurance State', key: 'primaryInsuranceState', width: 30 },
    { header: 'Primary Plan', key: 'primaryInsurancePlan', width: 30 },
    { header: 'Primary Policy Number', key: 'policyPatientPrimaryInsurance', width: 30 },
    { header: 'Primary Group ID', key: 'primaryInsuranceGroupNumber', width: 30 },
    { header: 'Secondary Insurance', key: 'secondaryInsurance', width: 30 },
    { header: 'Secondary Insurance State', key: 'secondaryInsuranceState', width: 30 },
    { header: 'Secondary Plan', key: 'secondaryInsurancePlan', width: 30 },
    { header: 'Secondary Policy Number', key: 'secondaryInsuranceNumber', width: 30 },
    { header: 'Secondary Group ID', key: 'secondaryInsuranceGroupId', width: 30 },
    { header: 'Baby DOB/Due Date', key: 'babyDueDate', width: 30 },
    { header: 'Dx Codes', key: 'DxCodesModelDetails', width: 30 },
    { header: 'Procedure Codes', key: 'procedureCodes', width: 30 },
    { header: 'Model', key: 'vfModels', width: 30 },
    { header: 'VF Date', key: 'verifyCompletedOn', width: 30 },
    { header: 'Calender Month', key: 'calendarMonth', width: 30 },
    { header: 'Coverage Start Date', key: 'coverageStartDate', width: 30 },
    { header: 'Coverage End Date', key: 'coverageEndDate', width: 30 },
    { header: 'Network Status', key: 'networkStatus', width: 30 },
    { header: '"INN Beni\'s for DME"', key: 'innBenefits', width: 30 },
    { header: 'INN Ded Actual Amt', key: 'innDedActualAmt', width: 30 },
    { header: 'INN Ded Met Amt', key: 'innDedMetAmt', width: 30 },
    { header: 'INN Ded Bal', key: 'innDedBal', width: 30 },
    { header: 'INN Coins', key: 'innCoins', width: 30 },
    { header: 'INN OOP Actual Amt', key: 'innOopActualAmt', width: 30 },
    { header: 'INN OOP Used Amt', key: 'innOopUsedAmt', width: 30 },
    { header: 'OON Beni\'s for DME', key: 'onnBenefits', width: 30 },
    { header: 'OON Ded Actual Amt', key: 'oonDedActualAmt', width: 30 },
    { header: 'OON Ded Met Amt', key: 'oonDedMetAmt', width: 30 },
    { header: 'OON Ded Bal', key: 'oonDedBal', width: 30 },
    { header: 'OON Coins', key: 'oonCoins', width: 30 },
    { header: 'OON OOP Actual Amt', key: 'oonOopCalendar', width: 30 },
    { header: 'OON OOP Used Amt', key: 'oonOopUsedAmt', width: 30 },
    { header: 'Auth required', key: 'authRequired', width: 30 },
    { header: 'Category', key: 'category', width: 30 },
    { header: '"S&SReceived Breast Pump in Last 6 Months?"', key: 'breastPump', width: 30 },
    { header: 'Physician Order', key: 'physicainOrder', width: 30 },
    { header: 'VF Comments', key: 'vfComments', width: 30 },
    { header: 'Delivered On', key: 'deliveredOn', width: 30 },
  ];

  const headerRow3 = worksheet3.getRow(1);
  headerRow3.font = { bold: true };
  headerRow3.alignment = { horizontal: 'center' };
  headerRow3.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF92D050' } // Green background color
    };
    cell.font = { color: { argb: 'FFFFFFFF' }, bold: true }; // White bold text
  });

  let patientDelivered = chartList.filter(item => item.vfCategory !== "VF In-Process" && item.vfCategory !== "Missing Demo/Insurance Info");

  // Add data rows for the third worksheet
  patientDelivered.forEach((item, index) => {
    const dxCodes = item.DxCodesModelDetails.map(dxDetail => dxDetail.dxCodes).join(',') || 'u/a';
    const hcpcsCodes = item?.vfRequestedEquipmentModelDetails?.map(equipmentModelDetails => equipmentModelDetails.hcpcsCodes).join(',') || 'u/a';
    const physicainOrder = item.orderingPhysicianModelDetails.map(orderingPhysicianDetail => orderingPhysicianDetail.orderingPhysician).join(',') || 'u/a';
    const vfModels = item.vfRequestedEquipmentModelDetails.map(vfRequestedEquipmentModelDetails => vfRequestedEquipmentModelDetails.equipmentName).join(',') || 'u/a';
    const mailID = item.phoneMailEquipmentModels.map(phoneMailEquipmentModels => phoneMailEquipmentModels.mailId).join(',') || 'u/a';
    const phoneNumber = item.phoneMailEquipmentModels.map(phoneMailEquipmentModels => phoneMailEquipmentModels.phoneNumber).join(',') || 'u/a';
    const row = worksheet3.addRow({
      receivedOn: formatDate(item.receivedOn) ?? 'u/a',
      patientName: item.patientName ?? 'u/a',
      dob: formatDate(item.dob) ?? 'u/a',
      address: item.address ?? 'u/a',
      city: item.city ?? 'u/a',
      state: stateMapping[item.state] ?? 'u/a',
      zipCode: item.zipCode ?? 'u/a',
      patientPhoneNumber: phoneNumber,
      patientMailId: mailID,
      patientPrimaryInsurance: item.patientPrimaryInsurance ?? 'u/a',
      primaryInsuranceState: stateMapping[item.primaryInsuranceState] ?? 'u/a',
      primaryInsurancePlan: item.primaryInsurancePlan ?? 'u/a',
      policyPatientPrimaryInsurance: item.patientPrimaryInsurance ?? 'u/a',
      primaryInsuranceGroupNumber: item.primaryInsuranceGroupNumber ?? 'u/a',
      secondaryInsurance: item.secondaryInsurance ?? 'u/a',
      secondaryInsuranceState: stateMapping[item.secondaryInsuranceState] ?? 'u/a',
      secondaryInsurancePlan: item.secondaryInsurancePlan ?? 'u/a',
      secondaryInsuranceNumber: item.secondaryInsuranceNumber ?? 'u/a',
      secondaryInsuranceGroupId: item.secondaryInsuranceGroupNumber ?? 'u/a',
      babyDueDate: formatDate(item.babyDueDate) ?? 'u/a',
      DxCodesModelDetails: dxCodes, // Comma-separated dxCodes
      procedureCodes: hcpcsCodes,
      vfModels: vfModels,
      vfTAT: item.vfTAT ?? 'u/a',
      calendarMonth: item.calendarMonth ?? 'u/a',
      coverageStartDate: formatDate(item.coverageStartDate) ?? 'u/a',
      coverageEndDate: formatDate(item.coverageEndDate) ?? 'u/a',
      networkStatus: item.networkStatus ?? 'u/a',
      innBenefits: item.innBenefits ?? 'u/a',
      innDedActualAmt: item.innDedCalendar ?? 'u/a',
      innDedMetAmt: item.innDedMetAmount ?? 'u/a',
      innDedBal: item.innDedBalanceAmount ?? 'u/a',
      innCoins: item.innCoins ?? 'u/a',
      innOopActualAmt: item.innOopCalendar ?? 'u/a',
      innOopUsedAmt: item.innOopMetAmount ?? 'u/a',
      onnBenefits: item.onnBenefits ?? 'u/a',
      oonDedActualAmt: item.oonDedCalendar ?? 'u/a',
      oonDedMetAmt: item.oonDedMetAmount ?? 'u/a',
      oonDedBal: item.ooDedBalanceAmount ?? 'u/a',
      oonCoins: item.oonCoins ?? 'u/a',
      oonOopCalendar: item.oonOopCalendar ?? 'u/a',
      oonOopUsedAmt: item.oonOopMet ?? 'u/a',
      authRequired: item.authRequired ?? 'u/a',
      category: item.vfCategory ?? 'u/a',
      breastPump: item.ssReceived ?? 'u/a',
      vfComments: item.vfComments ?? 'u/a',
      physicainOrder: physicainOrder,
      deliveredOn: item.deliveredOn ?? 'u/a'
    });
    row.alignment = { horizontal: 'center' };

    if (index % 2 === 0) {
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF2F2F2' } // Light grey background for alternating rows
      };
    }
  });

  const buffer = await workbook.xlsx.writeBuffer();
  // Save the buffer as an Excel file
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}.xlsx`);
}

export const reportDayToDayRepositoryExportExcel = async (extractionData, fileName) => {
  const workbook = new ExcelJS.Workbook();
  const workSheet2 = workbook.addWorksheet('Verification');

  // Define columns for the second worksheet
  workSheet2.columns = [
    { header: 'ORIGINAL SCAN DATE', key: 'originalScanDate', width: 30 },
    { header: 'SUBSEQUENT VF DATE', key: 'subsequentScanDate', width: 30 },
    { header: 'MONTH', key: 'month', width: 30 },
    { header: 'PATIENT NAME', key: 'patientName', width: 30 },
    { header: 'DOB', key: 'dob', width: 30 },
    { header: 'PRIMARY INSURANCE #', key: 'primaryInsuranceNumber', width: 30 },
    { header: 'GENDER', key: 'gender', width: 30 },
    { header: 'ADDRESS', key: 'address', width: 30 },
    { header: 'CITY', key: 'city', width: 30 },
    { header: 'STATE', key: 'state', width: 30 },
    { header: 'ZIP', key: 'zipCode', width: 30 },
    { header: 'HOME #', key: 'homeNumber', width: 30 },
    { header: 'MAIL ID', key: 'mailId', width: 30 },
    { header: 'PRIMARY INSURANCE', key: 'primaryInsurance', width: 30 },
    { header: 'PRIMARY INSURANCE STATE', key: 'primaryInsuranceState', width: 30 },
    { header: 'PRIMARY INSURANCE PLAN', key: 'primaryInsurancePlan', width: 30 },
    { header: 'PRIMARY INSURANCE GROUP #', key: 'primaryInsuranceGroupNumber', width: 30 },
    { header: 'SECONDARY INSURANCE', key: 'secondaryInsurance', width: 30 },
    { header: 'SECONDARY INSURANCE STATE', key: 'secondaryInsuranceState', width: 30 },
    { header: 'SECONDARY INSURANCE PLAN', key: 'secondaryInsurancePlan', width: 30 },
    { header: 'SECONDARY INSURANCE #', key: 'secondaryInsuranceNumber', width: 30 },
    { header: 'SECONDARY INSURANCE GROUP #', key: 'secondaryInsuranceGroupNumber', width: 30 },
    { header: 'BABY DUE DATE', key: 'babyDueDate', width: 30 },
    { header: 'DX CODES', key: 'dxCodes', width: 30 },
    { header: 'HCPCS CODES', key: 'hcpcsCodes', width: 30 },
    { header: 'MODEL', key: 'model', width: 30 },
    { header: 'ORDERING PHYSICIAN', key: 'orderingPhysician', width: 30 },
    { header: 'VF DATE', key: 'verifyCompletedOn', width: 30 },
    { header: 'CALENDAR MONTH', key: 'calendarMonth', width: 30 },
    { header: 'COVERAGE START DATE', key: 'coverageStartDate', width: 30 },
    { header: 'COVERAGE END DATE', key: 'coverageEndDate', width: 30 },
    { header: 'NETWORK STATUS', key: 'networkStatus', width: 30 },
    { header: 'INN BENEFIT\'S FOR DME', key: 'innBenefits', width: 30 },
    { header: 'INN DED CALENDAR/ACTUAL AMT', key: 'innDedActualAmt', width: 30 },
    { header: 'INN DED MET AMT', key: 'innDedMetAmt', width: 30 },
    { header: 'INN DED BAL AMT', key: 'innDedBalAmt', width: 30 },
    { header: 'INN COINS', key: 'innCoins', width: 30 },
    { header: 'INN OOP ACTUAL AMT', key: 'innOopActualAmt', width: 30 },
    { header: 'INN OOP USED AMT', key: 'innOopUsedAmt', width: 30 },
    { header: 'OON BENEFITS FOR DME', key: 'onnBenefits', width: 30 },
    { header: 'OON DED ACTUAL AMT', key: 'oonDedActualAmt', width: 30 },
    { header: 'OON DED MET AMT', key: 'oonDedMetAmt', width: 30 },
    { header: 'OON DED BAL AMT', key: 'oonDedBalAmt', width: 30 },
    { header: 'OON COINS', key: 'oonCoins', width: 30 },
    { header: 'OON OOP ACTUAL AMT', key: 'oonOopCalendar', width: 30 },
    { header: 'OON OOP USED AMT', key: 'oonOopUsedAmt', width: 30 },
    { header: 'AUTH REQUIRED', key: 'authRequired', width: 30 },
    { header: 'CATEGORY', key: 'category', width: 30 },
    { header: '"S&S RECEIVED BP IN LAST 6 MONTHS?"', key: 'receivedBpInLast6Months', width: 30 },
    { header: 'Prescription Availability', key: 'consultationStatus', width: 30 },
    { header: 'VF COMMENTS', key: 'vfComments', width: 30 },
    { header: 'SUBSEQUENT VF DATE', key: 'subsequentVfDate', width: 30 }
  ];


  const headerRow2 = workSheet2.getRow(1);
  headerRow2.font = { bold: true };
  headerRow2.alignment = { horizontal: 'center' };
  headerRow2.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF92D050' } // Green background color
    };
    cell.font = { color: { argb: 'FFFFFFFF' }, bold: true }; // White bold text
  });

  // Add data rows for the second worksheet

  extractionData.forEach((item, index) => {
    const dxCodes = item.DxCodesModelDetails.map(dxDetail => dxDetail.dxCodes).join(',') || 'u/a';
    const vfModels = item.vfRequestedEquipmentModelDetails.map(vfRequestedModel => vfRequestedModel.equipmentName).join(',') || 'u/a';
    const hcpcsCodes = item?.vfRequestedEquipmentModelDetails?.map(equipmentModelDetails => equipmentModelDetails.hcpcsCodes).join(',') || 'u/a';
    const physicainOrder = item.orderingPhysicianModelDetails.map(orderingPhysicianDetail => orderingPhysicianDetail.orderingPhysician).join(',') || 'u/a';
    const row = workSheet2.addRow({
      originalScanDate: formatDate(item.originalScanDate) ?? 'u/a',
      subsequentScanDate: formatDate(item.subsequentScanDate) ?? 'u/a',
      month: item.receivedOn ? dayjs(item.receivedOn).format("MMMM'YY") : 'u/a',
      patientName: item.patientName ?? 'u/a',
      dob: formatDate(item.dob) ?? 'u/a',
      primaryInsuranceNumber: item.patientPrimaryInsurance ?? 'u/a',
      gender: item.gender ?? 'u/a',
      address: item.address ?? 'u/a',
      city: item.city ?? 'u/a',
      state: stateMapping[item.state] ?? 'u/a',
      zipCode: item.zipCode ?? 'u/a',
      homeNumber: item.patientPhoneNumber ?? 'u/a',
      mailId: item.patientMailId ?? 'u/a',
      patientPrimaryInsurance: item.patientPrimaryInsurance ?? 'u/a',
      primaryInsurance: item.primaryInsurance ?? 'u/a',
      primaryInsuranceState: stateMapping[item.primaryInsuranceState] ?? 'u/a',
      primaryInsurancePlan: item.primaryInsurancePlan ?? 'u/a',
      primaryInsuranceGroupNumber: item.primaryInsuranceGroupNumber ?? 'u/a',
      secondaryInsurance: item.secondaryInsurance ?? 'u/a',
      secondaryInsuranceState: stateMapping[item.secondaryInsuranceState] ?? 'u/a',
      secondaryInsurancePlan: item.secondaryInsurancePlan ?? 'u/a',
      secondaryInsuranceNumber: item.secondaryInsuranceNumber ?? 'u/a',
      secondaryInsuranceGroupNumber: item.secondaryInsuranceGroupNumber ?? 'u/a',
      babyDueDate: formatDate(item.babyDueDate) ?? 'u/a',
      dxCodes: dxCodes, // Comma-separated dxCodes
      hcpcsCodes: hcpcsCodes,
      model: vfModels,
      orderingPhysician: physicainOrder,
      verifyCompletedOn: formatDate(item.verifyCompletedOn) ?? 'u/a',
      calendarMonth: item.calendarMonth ?? 'u/a',
      coverageStartDate: formatDate(item.coverageStartDate) ?? 'u/a',
      coverageEndDate: formatDate(item.coverageEndDate) ?? 'u/a',
      networkStatus: item.networkStatus ?? 'u/a',
      innBenefits: item.innBenefits ?? 'u/a',
      innDedActualAmt: item.innDedCalendar ?? 'u/a',
      innDedMetAmt: item.innDedMetAmount ?? 'u/a',
      innDedBalAmt: item.innDedBalanceAmount ?? 'u/a',
      innCoins: item.innCoins ?? 'u/a',
      innOopActualAmt: item.innOopCalendar ?? 'u/a',
      innOopUsedAmt: item.innOopMetAmount ?? 'u/a',
      onnBenefits: item.onnBenefits ?? 'u/a',
      oonDedActualAmt: item.oonDedCalendar ?? 'u/a',
      oonDedMetAmt: item.oonDedMetAmount ?? 'u/a',
      oonDedBalAmt: item.oonDedBalanceAmount ?? 'u/a',
      oonCoins: item.oonCoins ?? 'u/a',
      oonOopCalendar: item.oonOopCalendar ?? 'u/a',
      oonOopUsedAmt: item.oonOopMet ?? 'u/a',
      authRequired: item.authRequired ?? 'u/a',
      category: item.vfCategory ?? 'u/a',
      receivedBpInLast6Months: item.ssReceived ?? 'u/a',
      consultationStatus: item.consultationStatus ?? 'u/a',
      vfComments: item.vfComments ?? 'u/a',
      subsequentVfDate: formatDate(item.subsequentVfDate) ?? 'u/a'
    });
    row.alignment = { horizontal: 'center' };

    if (index % 2 === 0) {
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF2F2F2' } // Light grey background for alternating rows
      };
    }
  });

  const buffer = await workbook.xlsx.writeBuffer();

  // Save the buffer as an Excel file
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}.xlsx`);

}
export const reportVfFormat = async (extractionData, fileName) => {
  const workbook = new ExcelJS.Workbook();
  const workSheet2 = workbook.addWorksheet('Verification');

  // Define columns for the second worksheet
  workSheet2.columns = [
    { header: 'ORIGINAL SCAN DATE', key: 'originalScanDate', width: 30 },
    { header: 'SUBSEQUENT VF DATE', key: 'subsequentScanDate', width: 30 },
    { header: 'MONTH', key: 'month', width: 30 },
    { header: 'PATIENT NAME', key: 'patientName', width: 30 },
    { header: 'DOB', key: 'dob', width: 30 },
    { header: 'PRIMARY INSURANCE #', key: 'primaryInsuranceNumber', width: 30 },
    { header: 'GENDER', key: 'gender', width: 30 },
    { header: 'ADDRESS', key: 'address', width: 30 },
    { header: 'CITY', key: 'city', width: 30 },
    { header: 'STATE', key: 'state', width: 30 },
    { header: 'ZIP', key: 'zipCode', width: 30 },
    { header: 'HOME #', key: 'homeNumber', width: 30 },
    { header: 'MAIL ID', key: 'mailId', width: 30 },
    { header: 'PRIMARY INSURANCE', key: 'patientPrimaryInsurance', width: 30 },
    { header: 'PRIMARY INSURANCE STATE', key: 'primaryInsuranceState', width: 30 },
    { header: 'PRIMARY INSURANCE PLAN', key: 'primaryInsurancePlan', width: 30 },
    { header: 'PRIMARY INSURANCE GROUP #', key: 'primaryInsuranceGroupNumber', width: 30 },
    { header: 'SECONDARY INSURANCE', key: 'secondaryInsurance', width: 30 },
    { header: 'SECONDARY INSURANCE STATE', key: 'secondaryInsuranceState', width: 30 },
    { header: 'SECONDARY INSURANCE PLAN', key: 'secondaryInsurancePlan', width: 30 },
    { header: 'SECONDARY INSURANCE #', key: 'secondaryInsuranceNumber', width: 30 },
    { header: 'SECONDARY INSURANCE GROUP #', key: 'secondaryInsuranceGroupNumber', width: 30 },
    { header: 'BABY DUE DATE', key: 'babyDueDate', width: 30 },
    { header: 'DX CODES', key: 'dxCodes', width: 30 },
    { header: 'HCPCS CODES', key: 'hcpcsCodes', width: 30 },
    { header: 'MODEL', key: 'model', width: 30 },
    { header: 'ORDERING PHYSICIAN', key: 'orderingPhysician', width: 30 },
    { header: 'VF DATE', key: 'verifyCompletedOn', width: 30 },
    { header: 'CALENDAR MONTH', key: 'calendarMonth', width: 30 },
    { header: 'COVERAGE START DATE', key: 'coverageStartDate', width: 30 },
    { header: 'COVERAGE END DATE', key: 'coverageEndDate', width: 30 },
    { header: 'NETWORK STATUS', key: 'networkStatus', width: 30 },
    { header: 'INN BENEFIT\'S FOR DME', key: 'innBenefits', width: 30 },
    { header: 'INN DED CALENDAR/ACTUAL AMT', key: 'innDedActualAmt', width: 30 },
    { header: 'INN DED MET AMT', key: 'innDedMetAmt', width: 30 },
    { header: 'INN DED BAL AMT', key: 'innDedBalAmt', width: 30 },
    { header: 'INN COINS', key: 'innCoins', width: 30 },
    { header: 'INN OOP ACTUAL AMT', key: 'innOopActualAmt', width: 30 },
    { header: 'INN OOP USED AMT', key: 'innOopUsedAmt', width: 30 },
    { header: 'OON BENEFITS FOR DME', key: 'onnBenefits', width: 30 },
    { header: 'OON DED ACTUAL AMT', key: 'oonDedActualAmt', width: 30 },
    { header: 'OON DED MET AMT', key: 'oonDedMetAmt', width: 30 },
    { header: 'OON DED BAL AMT', key: 'oonDedBalAmt', width: 30 },
    { header: 'OON COINS', key: 'oonCoins', width: 30 },
    { header: 'OON OOP ACTUAL AMT', key: 'oonOopCalendar', width: 30 },
    { header: 'OON OOP USED AMT', key: 'oonOopUsedAmt', width: 30 },
    { header: 'AUTH REQUIRED', key: 'authRequired', width: 30 },
    { header: 'CATEGORY', key: 'category', width: 30 },
    { header: '"S&S RECEIVED BP IN LAST 6 MONTHS?"', key: 'receivedBpInLast6Months', width: 30 },
    { header: 'Prescription Availability', key: 'consultationStatus', width: 30 },
    { header: 'VF COMMENTS', key: 'vfComments', width: 30 },
    { header: 'SUBSEQUENT VF DATE', key: 'subsequentVfDate', width: 30 }
  ];



  const headerRow2 = workSheet2.getRow(1);
  headerRow2.font = { bold: true };
  headerRow2.alignment = { horizontal: 'center' };
  headerRow2.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFFFFF' } // White background color
    };
    cell.font = { color: { argb: 'FF000000' }, bold: true }; // Dark black text color, bold font
  });


  // Add data rows for the second worksheet

  extractionData.forEach((item, index) => {
    const dxCodes = item.DxCodesModelDetails.map(dxDetail => dxDetail.dxCodes).join(',') || 'u/a';
    const vfModels = item.vfRequestedEquipmentModelDetails.map(euqipmentModelDetails => euqipmentModelDetails.equipmentName).join(',') || 'u/a';
    const physicainOrder = item.orderingPhysicianModelDetails.map(orderingPhysicianDetail => orderingPhysicianDetail.orderingPhysician).join(',') || 'u/a';

    const row = workSheet2.addRow({
      originalScanDate: formatDate(item.originalScanDate) ?? 'u/a',
      subsequentScanDate: formatDate(item.subsequentScanDate) ?? 'u/a',
      month: item.receivedOn ? dayjs(item.receivedOn).format("MMMM'YY") : 'u/a',
      patientName: item.patientName ?? 'u/a',
      dob: formatDate(item.dob) ?? 'u/a',
      primaryInsuranceNumber: item.primaryInsuranceNumber ?? 'u/a',
      gender: item.gender ?? 'u/a',
      address: item.address ?? 'u/a',
      city: item.city ?? 'u/a',
      state: stateMapping[item.state] ?? 'u/a',
      zipCode: item.zipCode ?? 'u/a',
      homeNumber: item.homeNumber ?? 'u/a',
      mailId: item.mailId ?? 'u/a',
      patientPrimaryInsurance: item.patientPrimaryInsurance ?? 'u/a',
      primaryInsuranceState: stateMapping[item.primaryInsuranceState] ?? 'u/a',
      primaryInsurancePlan: item.primaryInsurancePlan ?? 'u/a',
      primaryInsuranceGroupNumber: item.primaryInsuranceGroupNumber ?? 'u/a',
      secondaryInsurance: item.secondaryInsurance ?? 'u/a',
      secondaryInsuranceState: stateMapping[item.secondaryInsuranceState] ?? 'u/a',
      secondaryInsurancePlan: item.secondaryInsurancePlan ?? 'u/a',
      secondaryInsuranceNumber: item.secondaryInsuranceNumber ?? 'u/a',
      secondaryInsuranceGroupNumber: item.secondaryInsuranceGroupNumber ?? 'u/a',
      babyDueDate: formatDate(item.babyDueDate) ?? 'u/a',
      dxCodes: dxCodes, // Comma-separated dxCodes
      hcpcsCodes: item.hcpcsCodes ?? 'u/a',
      model: vfModels,
      orderingPhysician: physicainOrder,
      verifyCompletedOn: formatDate(item.verifyCompletedOn) ?? 'u/a',
      calendarMonth: item.calendarMonth ?? 'u/a',
      coverageStartDate: formatDate(item.coverageStartDate) ?? 'u/a',
      coverageEndDate: formatDate(item.coverageEndDate) ?? 'u/a',
      networkStatus: item.networkStatus ?? 'u/a',
      innBenefits: item.innBenefits ?? 'u/a',
      innDedActualAmt: item.innDedCalendar ?? 'u/a',
      innDedMetAmt: item.innDedMetAmount ?? 'u/a',
      innDedBalAmt: item.innDedBalanceAmount ?? 'u/a',
      innCoins: item.innCoins ?? 'u/a',
      innOopActualAmt: item.innOopCalendar ?? 'u/a',
      innOopUsedAmt: item.innOopMetAmount ?? 'u/a',
      onnBenefits: item.onnBenefits ?? 'u/a',
      oonDedActualAmt: item.oonDedCalendar ?? 'u/a',
      oonDedMetAmt: item.oonDedMetAmount ?? 'u/a',
      oonDedBalAmt: item.oonDedBalanceAmount ?? 'u/a',
      oonCoins: item.oonCoins ?? 'u/a',
      oonOopCalendar: item.oonOopCalendar ?? 'u/a',
      oonOopUsedAmt: item.oonOopMet ?? 'u/a',
      authRequired: item.authRequired ?? 'u/a',
      category: item.category ?? 'u/a',
      receivedBpInLast6Months: item.ssReceived ?? 'u/a',
      consultationStatus: item.consultationStatus ?? 'u/a',
      vfComments: item.vfComments ?? 'u/a',
      subsequentVfDate: formatDate(item.subsequentVfDate) ?? 'u/a'
    });
    row.alignment = { horizontal: 'center' };

    if (index % 2 === 0) {
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF2F2F2' } // Light grey background for alternating rows
      };
    }
  });

  const buffer = await workbook.xlsx.writeBuffer();

  // Save the buffer as an Excel file
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}.xlsx`);

}
export const eAndBRejectionSummary = async (extractionData, fileName) => {
  const workbook = new ExcelJS.Workbook();

  // First worksheet
  const worksheet1 = workbook.addWorksheet('BOB_E&B Rejection Summary');

  // Define columns for the first worksheet
  worksheet1.columns = [
    { header: 'Facility', key: 'facility', width: 30 },
    { header: 'Month', key: 'month', width: 30 },
    { header: 'Total Received', key: 'totalReceived', width: 30 },
    { header: 'Total # Of Cases Denied', key: 'totalCasesDenied', width: 30 },
    { header: 'C&C - WWS', key: 'ccWws', width: 30 },
    { header: 'Member', key: 'member', width: 30 },
    { header: 'N/A', key: 'na', width: 30 },
    { header: 'Provider Office', key: 'providerOffice', width: 30 },
    { header: 'WWS', key: 'wws', width: 30 },
    { header: 'WWS/Provider', key: 'wwsProvider', width: 30 }
  ];

  const addedFacilities = new Set();
  const invalidMonths = ['U/A', 'u/a', 'N/A', 'n/a', 'UNAVAILABLE'];
  const invalidSSReceived = ['U/A', 'u/a', 'N/A', 'n/a'];

  extractionData.forEach((item) => {
    const facility = item?.project?.name ?? 'u/a';
    const month = dayjs(item.receivedOn).format('MMMM YYYY'); // Convert receivedOn to 'Month YYYY' format


    if (!invalidMonths.includes(month)) {
      const facilityMonthKey = `${facility}-${month}`;

      if (!addedFacilities.has(facilityMonthKey)) {
        // Filter active charts for the current facility and month, excluding invalid month values
        const activeCharts = extractionData.filter(chart =>
          chart.isActive &&
          chart.project?.name === facility &&
          dayjs(chart.receivedOn).format('MMMM YYYY') === month &&
          !invalidMonths.includes(dayjs(chart.receivedOn).format('MMMM YYYY'))
        );

        const totalReceived = activeCharts.length;

        // Filter charts with specified vfCategory, ssReceived, or isActive === false
        const deniedCategories = ["VF Denied_Closed", "Missing Demo/Insurance Info", "VF Closed", "VF Held"];
        const deniedCharts = activeCharts.filter(chart =>
          !(deniedCategories.includes(chart.vfCategory) ||
            (chart.ssReceived && !invalidSSReceived.includes(chart.ssReceived)) ||
            chart.isActive === false)
        );



        const totalCasesDenied = deniedCharts.length;

        const row = worksheet1.addRow({
          facility: facility,
          month: month,
          totalReceived: totalReceived,
          totalCasesDenied: totalCasesDenied,
          ccWws: item.ccWws ?? 'u/a',
          member: item.member ?? 'u/a',
          na: item.na ?? 'u/a',
          providerOffice: item.providerOffice ?? 'u/a',
          wws: item.wws ?? 'u/a',
          wwsProvider: item.wwsProvider ?? 'u/a'
        });

        row.alignment = { horizontal: 'center' };
        if (extractionData.indexOf(item) % 2 === 0) {
          row.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF2F2F2' } // Light grey background for alternating rows
          };
        }

        addedFacilities.add(facilityMonthKey);
      }
    }
  });

  // Add header styling for the first worksheet
  const headerRow1 = worksheet1.getRow(1);
  headerRow1.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // White text
  headerRow1.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF5B95F9' } // Blue background color
    };
  });

  // Second worksheet
  const worksheet2 = workbook.addWorksheet('BOB_E&B Rejection Patient Info');

  // Define columns for the second worksheet
  worksheet2.columns = [
    { header: 'Facility', key: 'facility', width: 30 },
    { header: 'Month', key: 'month', width: 30 },
    { header: 'Patient Name', key: 'patientName', width: 30 },
    { header: 'Insurance', key: 'insurance', width: 30 },
    { header: 'Total Approved, PA Pending & Moved to PA', key: 'totalApproved', width: 30 },
    { header: 'VF In-Process', key: 'vfInProcess', width: 30 },
    { header: 'Total # Of Cases Denied', key: 'totalCasesDenied', width: 30 },
    { header: 'Count Of Rejection', key: 'countOfRejection', width: 30 },
    { header: 'VF Category Of Rejection', key: 'vfCategoryOfRejection', width: 30 },
    { header: 'QMS Category Of Rejection', key: 'qmsCategoryOfRejection', width: 30 },
    { header: 'AR', key: 'ar', width: 30 }
  ];

  extractionData.forEach((item, index) => {
    const row = worksheet2.addRow({
      facility: item.facility ?? 'u/a',
      month: item.calendarMonth ?? 'u/a',
      patientName: item.patientName ?? 'u/a',
      insurance: item.insurance ?? 'u/a',
      totalApproved: item.totalApproved ?? 'u/a',
      vfInProcess: item.vfInProcess ?? 'u/a',
      totalCasesDenied: item.totalCasesDenied ?? 'u/a',
      providerOffice: item.providerOffice ?? 'u/a',
      countOfRejection: item.countOfRejection ?? 'u/a',
      vfCategoryOfRejection: item.vfCategoryOfRejection ?? 'u/a',
      qmsCategoryOfRejection: item.qmsCategoryOfRejection ?? 'u/a',
      ar: item.ar ?? 'u/a'
    });

    row.alignment = { horizontal: 'center' };
    if (index % 2 === 0) {
      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF2F2F2' } // Light grey background for alternating rows
      };
    }
  });

  // Add header styling for the second worksheet
  const headerRow2 = worksheet2.getRow(1);
  headerRow2.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // White text
  headerRow2.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF5B95F9' } // Blue background color
    };
  });

  const buffer = await workbook.xlsx.writeBuffer();
  // Save the buffer as an Excel file
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}.xlsx`);
};


export const reportMonthlyVerificationSummaryRepositoryExportToPDF = async (extractionData, fileName, filteredChartData) => {
  const doc = new jsPDF('p', 'mm', 'a4');

  // Title and Metadata
  doc.setFontSize(16);
  doc.text('Monthly Verification Summary Report', 105, 10, { align: 'center' });

  // Summary Section
  doc.setFontSize(12);
  doc.text('Verification Summary', 10, 20);

  const summaryColumns = [
    { header: 'Verification Summary', dataKey: 'name' },
    { header: '', dataKey: 'count' }
  ];
  const summaryRows = extractionData.map((item) => ({
    name: item.name ?? 'u/a',
    count: item.count ?? 'u/a'
  }));

  doc.autoTable({
    startY: 25,
    head: [summaryColumns.map(col => col.header)],
    body: summaryRows,
    theme: 'striped',
    styles: {
      cellPadding: 2,
      fontSize: 10,
      halign: 'center',
    },
    headStyles: {
      fillColor: [55, 86, 35],
      textColor: [255, 255, 255],
    }
  });

  // Total row
  const totalCount1 = extractionData.reduce((acc, item) => acc + (item.count ?? 0), 0);
  doc.autoTable({
    startY: doc.lastAutoTable.finalY + 5,
    head: [['Total # of Patients Verified', totalCount1]],
    theme: 'striped',
    styles: {
      cellPadding: 2,
      fontSize: 10,
      halign: 'center',
    },
    headStyles: {
      fillColor: [55, 86, 35],
      textColor: [255, 255, 255],
    }
  });

  // Verification Section
  doc.addPage();
  doc.setFontSize(12);
  doc.text('Verification Details', 10, 10);

  const verificationColumns = [
    { header: 'Received On', dataKey: 'receivedOn' },
    { header: 'Patient Name', dataKey: 'patientName' },
    { header: 'DOB', dataKey: 'dob' },
    { header: 'Address', dataKey: 'address' },
    { header: 'City', dataKey: 'city' },
    { header: 'State', dataKey: 'state' },
    { header: 'Zip', dataKey: 'zipCode' },
    { header: 'Home #', dataKey: 'patientPhoneNumber' },
    { header: 'Mail ID', dataKey: 'patientMailId' },
    { header: 'Primary Insurance', dataKey: 'patientPrimaryInsurance' },
    { header: 'Primary Insurance State', dataKey: 'primaryInsuranceState' },
    { header: 'Primary Plan', dataKey: 'primaryInsurancePlan' },
    { header: 'Primary Policy Number', dataKey: 'policyPatientPrimaryInsurance' },
    { header: 'Primary Group ID', dataKey: 'primaryInsuranceGroupNumber' },
    { header: 'Provider', dataKey: 'providerProjectName' },
    { header: 'Procedure Codes', dataKey: 'procedureCodes' },
    { header: 'VF Date', dataKey: 'verifyCompletedOn' },
    { header: 'Calender Month', dataKey: 'calendarMonth' },
    { header: 'Coverage Start Date', dataKey: 'coverageStartDate' },
    { header: 'Coverage End Date', dataKey: 'coverageEndDate' },
    { header: 'Network Status', dataKey: 'networkStatus' },
    { header: '"INN Beni\'s for DME"', dataKey: 'innBenefits' },
    { header: 'INN Ded Actual Amt', dataKey: 'innDedActualAmt' },
    { header: 'INN Ded Met Amt', dataKey: 'innDedMetAmt' },
    { header: 'INN Ded Bal', dataKey: 'innDedBal' },
    { header: 'INN Coins', dataKey: 'innCoins' },
    { header: 'INN OOP Actual Amt', dataKey: 'innOopActualAmt' },
    { header: 'INN OOP Used Amt', dataKey: 'innOopUsedAmt' },
    { header: 'OON Beni\'s for DME', dataKey: 'onnBenefits' },
    { header: 'OON Ded Actual Amt', dataKey: 'oonDedActualAmt' },
    { header: 'OON Ded Met Amt', dataKey: 'oonDedMetAmt' },
    { header: 'OON Ded Bal', dataKey: 'oonDedBal' },
    { header: 'OON Coins', dataKey: 'oonCoins' },
    { header: 'OON OOP Actual Amt', dataKey: 'oonOopCalendar' },
    { header: 'OON OOP Used Amt', dataKey: 'oonOopUsedAmt' },
    { header: 'Auth required', dataKey: 'authRequired' },
    { header: 'VF Category', dataKey: 'vfCategory' },
    { header: 'VF Comments', dataKey: 'vfComments' }
  ];
  const verificationRows = filteredChartData.map((item) => ({
    receivedOn: formatDate(item.receivedOn) ?? 'u/a',
    patientName: item.patientName ?? 'u/a',
    dob: formatDate(item.dob) ?? 'u/a',
    address: item.address ?? 'u/a',
    city: item.city ?? 'u/a',
    state: stateMapping[item.state] ?? 'u/a', // Convert state short form to full name
    zipCode: item.zipCode ?? 'u/a',
    patientPhoneNumber: item.phoneMailEquipmentModels.map(phoneMailEquipmentModels => phoneMailEquipmentModels.phoneNumber).join(',') || 'u/a',
    patientMailId: item.phoneMailEquipmentModels.map(phoneMailEquipmentModels => phoneMailEquipmentModels.mailId).join(',') || 'u/a',
    patientPrimaryInsurance: item.patientPrimaryInsurance ?? 'u/a',
    primaryInsuranceState: stateMapping[item.primaryInsuranceState] ?? 'u/a',
    primaryInsurancePlan: item.primaryInsurancePlan ?? 'u/a',
    policyPatientPrimaryInsurance: item.patientPrimaryInsurance ?? 'u/a',
    primaryInsuranceGroupNumber: item.primaryInsuranceGroupNumber ?? 'u/a',
    providerProjectName: item.project?.name ?? 'u/a',
    procedureCodes: item?.vfRequestedEquipmentModelDetails?.map(equipmentModelDetails => equipmentModelDetails.hcpcsCodes).join(',') || 'u/a',
    verifyCompletedOn: formatDate(item.verifyCompletedOn) ?? 'u/a',
    calendarMonth: item.calendarMonth ?? 'u/a',
    coverageStartDate: formatDate(item.coverageStartDate) ?? 'u/a',
    coverageEndDate: formatDate(item.coverageEndDate) ?? 'u/a',
    networkStatus: item.networkStatus ?? 'u/a',
    innBenefits: item.innBenefits ?? 'u/a',
    innDedActualAmt: item.innDedCalendar ?? 'u/a',
    innDedMetAmt: item.innDedMetAmount ?? 'u/a',
    innDedBal: item.innDedBalanceAmount ?? 'u/a',
    innCoins: item.innCoins ?? 'u/a',
    innOopActualAmt: item.innOopCalendar ?? 'u/a',
    innOopUsedAmt: item.innOopMetAmount ?? 'u/a',
    onnBenefits: item.onnBenefits ?? 'u/a',
    oonDedActualAmt: item.oonDedCalendar ?? 'u/a',
    oonDedMetAmt: item.oonDedMetAmount ?? 'u/a',
    oonDedBal: item.ooDedBalanceAmount ?? 'u/a',
    oonCoins: item.oonCoins ?? 'u/a',
    oonOopCalendar: item.oonOopCalendar ?? 'u/a',
    oonOopUsedAmt: item.oonOopMet ?? 'u/a',
    authRequired: item.authRequired ?? 'u/a',
    vfCategory: item.vfCategory ?? 'u/a',
    vfComments: item.vfComments ?? 'u/a'
  }));

  doc.autoTable({
    startY: 15,
    head: [verificationColumns.map(col => col.header)],
    body: verificationRows,
    theme: 'striped',
    styles: {
      cellPadding: 2,
      fontSize: 10,
      halign: 'center',
    },
    headStyles: {
      fillColor: [55, 86, 35],
      textColor: [255, 255, 255],
    }
  });

  doc.save(`${fileName}.pdf`);
};

// Function to format date as dd-mm-yyyy
const formatDate = (dateString) => {
  if (dateString === "U/A" || dateString === "N/A") {
    return dateString;
  } else if (dateString === null) {
    return "U/A"
  }

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return 'N/A';
  }
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const generatePDF = (extractionData, chartData, fileName) => {
  const doc = new jsPDF({
    orientation: 'landscape', // Set orientation to landscape
  });

  // Extract column headers from chartData
  const columns = Object.entries(chartData).map(([key, value]) => ({
    header: value,
    dataKey: key,
  }));
  // Extract rows from data
  const partiallyMaskedKeys = {
    "orderingPhysicianNPI": 4,    // Show last 4 characters
    "patientPhoneNumber": 4,
    "patientMailId": 3,
    "patientPrimaryInsurance": 3,
    "primaryInsuranceGroupNumber": 3,
    "secondaryInsuranceNumber": 4,
    "secondaryInsuranceGroupNumber": 3,


  };

  const maskString = (str, visibleCount, maskChar = 'X') => {
    if (str.length <= visibleCount) return str;
    const maskedPart = str.slice(0, -visibleCount).replace(/./g, maskChar);
    const visiblePart = str.slice(-visibleCount);
    return maskedPart + visiblePart;
  };

  const data = extractionData.map((entry) => {

    const rowData = Object.keys(chartData).map((key) => {
      let value = entry[key];
      if (extractionData.length === 1 && partiallyMaskedKeys[key] && typeof value === 'string') {
        return maskString(value, partiallyMaskedKeys[key]);
      }

      if (value === "U/A") {
        return "UNAVAILABLE";
      }
      if (
        key === "receivedOn" ||
        key === 'dob' ||
        key === 'subscriberDob' ||
        key === 'babyDueDate' ||
        key === 'verifyCompletedOn' ||
        key === 'coverageStartDate' ||
        key === 'coverageEndDate' ||
        key === 'subsequentScanDate' ||
        key === 'callingAdded' ||
        key === 'prescriptionReceivedOn' ||
        key === 'lmnOrderDate' ||
        key === 'lmnStartDate' ||
        key === 'lmnEndDate' ||
        key === 'validationDate' ||
        key === 'initialSubsequentDate'
      ) {
        return entry[key] === "U/A" || entry[key] === "N/A" ? entry[key] : formatDate(entry[key]);
      } else if (key === 'internalFtpDate' || key === "orderDate" || key === "deliveredOn" || key === 'insuranceCardReceivedOn') {
        return entry[key] === "U/A" || entry[key] === "N/A" ? entry[key] : subFormatDate(entry[key]);
      } else if (
        key === 'innDedCalendar' ||
        key === 'innDedMetAmount' ||
        key === 'innDedBalanceAmount' ||
        key === 'innOopCalendar' ||
        key === 'innOopMetAmount' ||
        key === 'oonDedCalendar' ||
        key === 'oonDedMetAmount' ||
        key === 'oonOopCalendar' ||
        key === 'ooDedBalanceAmount' ||
        key === 'oonOopMet'
      ) {
        return value === undefined || value === null || value === '' || value === 'N/A' ? 'N/A' : `$${value}`;
      } else if (
        key === 'innCoins' ||
        key === 'oonCoins'
      ) {
        return value === undefined || value === null || value === '' || value === 'N/A' ? 'N/A' : `${value}%`;
      }
      else if (key === "vfRequestedEquipmentModel") {
        return entry.vfRequestedEquipmentModelDetails.length > 0
          ? entry.vfRequestedEquipmentModelDetails.length === 1
            ? entry.vfRequestedEquipmentModelDetails[0].vfRequestedEquipmentModel
            : entry.vfRequestedEquipmentModelDetails.map(item => item.vfRequestedEquipmentModel).join(', ')
          : 'N/A';
      } else if (key === "hcpcsCodes") {
        return entry.vfRequestedEquipmentModelDetails.length > 0
          ? entry.vfRequestedEquipmentModelDetails.length === 1
            ? entry.vfRequestedEquipmentModelDetails[0].hcpcsCodes
            : entry.vfRequestedEquipmentModelDetails.map(item => item.hcpcsCodes).join(', ')
          : 'N/A';
      } else if (key === "equipmentName") {
        return entry.vfRequestedEquipmentModelDetails.length > 0
          ? entry.vfRequestedEquipmentModelDetails.length === 1
            ? entry.vfRequestedEquipmentModelDetails[0].equipmentName
            : entry.vfRequestedEquipmentModelDetails.map(item => item.equipmentName).join(', ')
          : 'N/A';
      }
      else if (key === "orderingPhysicianNPI") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysicianNPI
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysicianNPI).join(', ')
          : 'N/A';
      }
      else if (key === "orderingPhysician") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysician
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysician).join(', ')
          : 'N/A';
      }
      else if (key === "orderingPhysicianAddress") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysicianAddress
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysicianAddress).join(', ')
          : 'N/A';
      }
      else if (key === "orderingPhysicianPhoneNumber") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysicianPhoneNumber
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysicianPhoneNumber).join(', ')
          : 'N/A';
      }
      else if (key === "orderingPhysicianFaxNumber") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysicianFaxNumber
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysicianFaxNumber).join(', ')
          : 'N/A';
      }
      else if (key === "orderingPhysicianSpeciality") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysicianSpeciality
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysicianSpeciality).join(', ')
          : 'N/A';
      }
      else if (key === "hcpcsFormPrescription") {
        return entry.prescriptionModelDetails.length > 0
          ? entry.prescriptionModelDetails.length === 1
            ? entry.prescriptionModelDetails[0].hcpcsFormPrescription
            : entry.prescriptionModelDetails.map(item => item.hcpcsFormPrescription).join(', ')
          : 'N/A';
      }
      else if (key === "equipmentModelFormPrescription") {
        return entry.prescriptionModelDetails.length > 0
          ? entry.prescriptionModelDetails.length === 1
            ? entry.prescriptionModelDetails[0].equipmentModelFormPrescription
            : entry.prescriptionModelDetails.map(item => item.equipmentModelFormPrescription).join(', ')
          : 'N/A';
      }
      else if (key === "patientPhoneNumber") {
        return entry.phoneMailEquipmentModels.length > 0
          ? entry.phoneMailEquipmentModels.map(item =>
            extractionData.length === 1
              ? maskString(item.phoneNumber, partiallyMaskedKeys[key])
              : item.phoneNumber
          ).join(', ')
          : 'N/A';
      }
      else if (key === "patientMailId") {
        return entry.phoneMailEquipmentModels.length > 0
          ? entry.phoneMailEquipmentModels.map(item =>
            extractionData.length === 1
              ? maskString(item.mailId, partiallyMaskedKeys[key])
              : item.mailId
          ).join(', ')
          : 'N/A';
      }

      else if (key === "DxCodesModelDetails") {
        return entry.DxCodesModelDetails.length > 0
          ? entry.DxCodesModelDetails.length === 1
            ? entry.DxCodesModelDetails[0].dxCodes
            : entry.DxCodesModelDetails.map(item => item.dxCodes).join(', ')
          : 'N/A';
      }
      else if (key === "primaryInsuranceState" || key === "secondaryInsuranceState") {
        return stateMapping[entry[key]] || 'N/A';
      } else if (key === "project") {
        return entry['facilityName'] ? entry['facilityName'] : entry[key] && entry[key].name ? entry[key].name : 'N/A';
      } else if (key === "startTime") {
        return value === undefined || value === null || value === '' || value === 'N/A' ? 'N/A' : value;
      } else if (key === "endTime") {
        return value === undefined || value === null || value === '' || value === 'N/A' ? 'N/A' : value;
      } else if (key === "ach") {
        const startTime = entry['startTime'] ? (entry['startTime']) : '';
        const endTime = entry['endTime'] ? (entry['endTime']) : '';
        return startTime && endTime ? convertCalculateDuration(startTime, endTime) : 'N/A';
      }
      else {
        return entry[key] || 'N/A';
      }
    });

    return rowData;
  });

  // Set custom file name
  fileName = fileName || 'chart_details.pdf';

  // Add table to the document
  doc.autoTable({
    theme: 'grid',
    head: [columns.map((column) => column.header)],
    body: data,
    horizontalPageBreak: true,
    horizontalPageBreakRepeat: 0,
    styles: {
      minCellHeight: 15,
      fontSize: 7,
      margin: 1,
    },
  });

  // Add watermark image to all pages
  const watermarkImageUrl = LOGO; // Path to your watermark image
  const img = new Image();
  img.src = watermarkImageUrl;

  img.onload = () => {
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const imgWidth = 20; // Adjust the size as needed
      const imgHeight = 15; // Adjust the size as needed
      const xPosition = pageWidth - imgWidth - 10; // 10 units from the right edge
      const yPosition = pageHeight - imgHeight - 10; // 10 units from the bottom edge

      doc.setGState(new doc.GState({ opacity: 0.3 }));
      doc.addImage(img, 'PNG', xPosition, yPosition, imgWidth, imgHeight, undefined, 'NONE');
      doc.setGState(new doc.GState({ opacity: 1.0 }));
    }
    // Save the PDF after the watermark is added
    doc.save(fileName);
  };
};

export const convertObjectToArray = (data) => {
  return Object.keys(data).map(key => {
    return {
      key,
      ...data[key]
    };
  });
};

export const addNumberToObjectArray = (data) => {
  for (let i = 0; i < data.length; i++) {
    data[i].no = i + 1;
  }
  return data;
};

export const addNumberToObjectArrayToDownload = (data) => {
  return data.map((item, index) => {
    // Create a shallow copy of the item and add the 'no' property
    return { ...item, no: index + 1 };
  });
};

export const customFindById = (objects, id, batchNo) => {
  for (let i = 0; i < objects?.length; i++) {
    if (objects[i]?.name?.id === id && objects[i]?.batchNo === batchNo) {
      return objects[i];
    }
  }
  return null;
};

export const checkChartsCompletion = (data) => {
  if (data) {
    const noOfChartsAssigned = data?.noOfChartsAssigned;
    const sessions = data?.logData;

    let totalCompletedCharts = 0;
    for (const session of sessions) {
      totalCompletedCharts += session?.noOfChartsCompleted?.length;
    }
    return totalCompletedCharts === noOfChartsAssigned;
  }
  return true
};

export const convertCoinValue = (value) => {
  const floatValue = parseFloat(value);

  if (isNaN(floatValue)) {
    return null;
  }

  return Math.round(floatValue * 100);
};

export const customFindByAdmin = (objects, id) => {
  for (let i = 0; i < objects.length; i++) {
    if (objects[i]?.name?.id === id) {
      return objects[i];
    }
  }
  return null;
};

export const addNoToObjects = (array) => {
  let items = [...array];
  for (let i = 0; i < items.length; i++) {
    let item = { ...items[i] };
    item['no'] = i + 1;
    items[i] = item;
  }
  return items;
};

export const reportsToExcel = (updatedLogs, headerData, fileName) => {


  // Extracting headers from logData
  const headers = Object.values(headerData);

  // Extracting data based on keys from logData
  const data = updatedLogs.map((entry) => {
    const rowData = Object.keys(headerData).map((key) => {
      if (key === 'logData') {
        return entry.logData
          .map((log, index) => {
            const loggedInFormatted = dayjs(log.loggedIn).format(
              'MM-DD-YY hh:mm:ss A'
            );
            const loggedOutFormatted = dayjs(log.loggedOut).format(
              'MM-DD-YY hh:mm:ss A'
            );
            return `Session: ${index + 1
              }\nLogged In: ${loggedInFormatted}\nLogged Out: ${loggedOutFormatted}\nCharts Completed: ${log.noOfChartsCompleted.length
              }\nReason: ${log.reason === undefined ? 'Batch Completed' : log.reason
              }\n`;
          })
          .join('\n');
      }
      else if (key === 'auditScore') {
        const auditScore =
          entry[key] !== undefined
            ? parseFloat(
              ((entry[key] / (entry.noOfChartsAssigned * 2)) * 100).toFixed(2)
            )
            : '';
        return auditScore !== '' ? `${auditScore}%` : '';
      }
      else if (key === 'completionStatus') {
        if (entry.userType === "VERIFICATION") {
          return entry.completedVerification
            ? 'Verification Completed'
            : 'Verification Not Completed';
        } else if (entry.userType === "AUDIT") {
          return entry.completedAudit
            ? 'Audit Completed'
            : 'Audit Not Completed';
        } else {
          return '';
        }
      } else if (key === 'sessionData') {
        return entry.sessionData
          .map((session, index) => {
            const loggedInFormatted = dayjs(session.loggedIn).format(
              'MM-DD-YY hh:mm:ss A'
            );
            const loggedOutFormatted = session.loggedOut
              ? dayjs(session.loggedOut).format('MM-DD-YY hh:mm:ss A')
              : 'Not logged out';
            const actionsPerformed = session.actionPerformed.join(', ');
            return `Session: ${index + 1
              }\nLogged In: ${loggedInFormatted}\nLogged Out: ${loggedOutFormatted}\nActions Performed: ${actionsPerformed}`;
          })
          .join('\n');
      } else if (
        typeof entry[key] === 'object' &&
        entry[key] !== null &&
        'name' in entry[key]
      ) {
        return entry[key].name || '';
      }
      else if (key === "vfRequestedEquipmentModel") {
        return entry.vfRequestedEquipmentModelDetails.length > 0
          ? entry.vfRequestedEquipmentModelDetails.length === 1
            ? entry.vfRequestedEquipmentModelDetails[0].vfRequestedEquipmentModel
            : entry.vfRequestedEquipmentModelDetails.map(item => item.vfRequestedEquipmentModel).join(', ')
          : 'N/A';
      } else if (key === "hcpcsCodes") {
        return entry.vfRequestedEquipmentModelDetails.length > 0
          ? entry.vfRequestedEquipmentModelDetails.length === 1
            ? entry.vfRequestedEquipmentModelDetails[0].hcpcsCodes
            : entry.vfRequestedEquipmentModelDetails.map(item => item.hcpcsCodes).join(', ')
          : 'N/A';
      } else if (key === "equipmentName") {
        return entry.vfRequestedEquipmentModelDetails.length > 0
          ? entry.vfRequestedEquipmentModelDetails.length === 1
            ? entry.vfRequestedEquipmentModelDetails[0].equipmentName
            : entry.vfRequestedEquipmentModelDetails.map(item => item.equipmentName).join(', ')
          : 'N/A';
      } else if (key === "orderingPhysicianNPI") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysicianNPI
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysicianNPI).join(', ')
          : 'N/A';
      } else if (key === "orderingPhysician") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysician
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysician).join(', ')
          : 'N/A';
      } else if (key === "orderingPhysicianAddress") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysicianAddress
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysicianAddress).join(', ')
          : 'N/A';
      } else if (key === "orderingPhysicianPhoneNumber") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysicianPhoneNumber
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysicianPhoneNumber).join(', ')
          : 'N/A';
      } else if (key === "orderingPhysicianFaxNumber") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysicianFaxNumber
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysicianFaxNumber).join(', ')
          : 'N/A';
      } else if (key === "orderingPhysicianSpeciality") {
        return entry.orderingPhysicianModelDetails.length > 0
          ? entry.orderingPhysicianModelDetails.length === 1
            ? entry.orderingPhysicianModelDetails[0].orderingPhysicianSpeciality
            : entry.orderingPhysicianModelDetails.map(item => item.orderingPhysicianSpeciality).join(', ')
          : 'N/A';
      }
      else if (key === "patientPhoneNumber") {
        return entry.phoneMailEquipmentModels.length > 0
          ? entry.phoneMailEquipmentModels.length === 1
            ? entry.phoneMailEquipmentModels[0].phoneNumber
            : entry.phoneMailEquipmentModels.map(item => item.phoneNumber).join(', ')
          : 'N/A';

      }
      else if (key === "patientMailId") {
        return entry.phoneMailEquipmentModels.length > 0
          ? entry.phoneMailEquipmentModels.length === 1
            ? entry.phoneMailEquipmentModels[0].mailId
            : entry.phoneMailEquipmentModels.map(item => item.mailId).join(', ')
          : 'N/A';
      }

      else {
        return entry[key] === undefined ? '' : entry[key];
      }
    });
    return rowData;
  });

  // Creating worksheet with headers and data
  const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

  // Creating workbook and appending worksheet
  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

  // Writing workbook to file
  XLSX.writeFile(workbook, fileName + '.xlsx');
};

export const reportsToPDF = (updatedLogs, headerData, fileName) => {
  const doc = new jsPDF({
    orientation: 'landscape', // Set orientation to landscape
  });

  // Extract column headers from chartData
  const columns = Object.entries(headerData).map(([key, value]) => ({
    header: value,
    dataKey: key,
  }));

  // Extract rows from data
  const data = updatedLogs.map((entry) => {
    const rowData = Object.keys(headerData).map((key) => {
      if (key === 'logData') {
        return entry.logData
          .map((log, index) => {
            const loggedInFormatted = dayjs(log.loggedIn).format(
              'MM-DD-YY hh:mm:ss A'
            );
            const loggedOutFormatted = dayjs(log.loggedOut).format(
              'MM-DD-YY hh:mm:ss A'
            );
            return `Session: ${index + 1
              }\nLogged In: ${loggedInFormatted}\nLogged Out: ${loggedOutFormatted}\nCharts Completed: ${log.noOfChartsCompleted.length
              }\nReason: ${log.reason === undefined ? 'Batch Completed' : log.reason
              }\n`;
          })
          .join('\n');
      } else if (key === 'auditScore') {
        const auditScore =
          entry[key] !== undefined
            ? parseFloat(
              ((entry[key] / (entry.noOfChartsAssigned * 2)) * 100).toFixed(2)
            )
            : '';
        return auditScore !== '' ? `${auditScore}%` : '';
      } else if (key === 'completionStatus') {
        if (entry.userType === "VERIFICATION") {
          return entry.completedVerification
            ? 'Verification Completed'
            : 'Verification Not Completed';
        } else if (entry.userType === "AUDIT") {
          return entry.completedAudit
            ? 'Audit Completed'
            : 'Audit Not Completed';
        } else {
          return '';
        }
      } else if (
        typeof entry[key] === 'object' &&
        entry[key] !== null &&
        'name' in entry[key]
      ) {
        return entry[key].name || '';
      } else if (key === 'sessionData') {
        return entry.sessionData
          .map((session, index) => {
            const loggedInFormatted = dayjs(session.loggedIn).format(
              'MM-DD-YY hh:mm:ss A'
            );
            const loggedOutFormatted = session.loggedOut
              ? dayjs(session.loggedOut).format('MM-DD-YY hh:mm:ss A')
              : 'Not logged out';
            const actionsPerformed = session.actionPerformed.join(', ');
            return `Session: ${index + 1
              }\nLogged In: ${loggedInFormatted}\nLogged Out: ${loggedOutFormatted}\nActions Performed: ${actionsPerformed}`;
          })
          .join('\n');
      } else {
        return entry[key] === undefined ? '' : entry[key];
      }
    });
    return rowData;
  });

  // Set custom file name
  fileName = fileName || 'log_details.pdf';

  // Add table to the document

  doc.autoTable({
    theme: 'grid',
    head: [columns.map((column) => column.header)],
    body: data,
    horizontalPageBreak: true,
    horizontalPageBreakRepeat: 0,
    styles: {
      minCellHeight: 15,
      fontSize: 7,
      margin: 1,
    },
  });
  // Add watermark image to all pages
  const watermarkImageUrl = LOGO; // Path to your watermark image
  const img = new Image();
  img.src = watermarkImageUrl;

  img.onload = () => {
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const imgWidth = 20; // Adjust the size as needed
      const imgHeight = 15; // Adjust the size as needed
      const xPosition = pageWidth - imgWidth - 10; // 10 units from the right edge
      const yPosition = pageHeight - imgHeight - 10; // 10 units from the bottom edge

      doc.setGState(new doc.GState({ opacity: 0.3 }));
      doc.addImage(img, 'PNG', xPosition, yPosition, imgWidth, imgHeight, undefined, 'NONE');
      doc.setGState(new doc.GState({ opacity: 1.0 }));
    }

    // Save the PDF after the watermark is added
    doc.save(fileName);
  };
};

export const excelTimeTo24Hrs = (excelTime) => {
  // Excel time format fraction represents fraction of 24 hours
  // 1 day = 24 hours = 1
  const totalHours = excelTime * 24;

  // Split totalHours into hours and minutes
  const hours = Math.floor(totalHours);
  const minutes = Math.round((totalHours - hours) * 60);

  // Format hours and minutes
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  // Return time in 24-hour format
  return `${formattedHours}:${formattedMinutes}`;
};

export const calculateDuration = (startTime, endTime) => {
  // Calculate duration in fraction of a day
  const duration = endTime - startTime;

  // Convert duration to hours and minutes
  const totalHours = Math.floor(duration * 24);
  const totalMinutes = Math.round((duration * 24 * 60) % 60);

  // Return duration in hh:mm format
  return `${totalHours}:${String(totalMinutes).padStart(2, '0')}`;
};

export const convertCalculateDuration = (startTime, endTime) => {
  // Helper function to convert "hh:mm" to fraction of a day
  const timeToFraction = (timeStr) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours / 24 + minutes / 1440;
  };

  // Convert start and end times to fractions of a day
  const startFraction = timeToFraction(startTime);
  const endFraction = timeToFraction(endTime);

  // Calculate duration in fraction of a day
  const duration = endFraction - startFraction;

  // Convert duration to hours and minutes
  const totalHours = Math.floor(duration * 24);
  const totalMinutes = Math.round((duration * 24 * 60) % 60);

  // Return duration in hh:mm format
  return `${totalHours}:${String(totalMinutes).padStart(2, '0')}`;
};

export const assignChartsToAuditors = (charts, users) => {
  const assignments = {}; // To store the assigned charts for each user
  users.forEach((user) => (assignments[user] = []));

  // Function to find the next available user who didn't AUDIT the chart
  function findNextAvailableUser(chart, startIdx) {
    for (let i = 0; i < users.length; i++) {
      const idx = (startIdx + i) % users.length;
      if (chart.verifyBy.id !== users[idx]) {
        return idx;
      }
    }
    return -1; // In case no suitable user is found, which shouldn't happen
  }

  // Sort charts to ensure equal distribution
  charts.sort((a, b) => a.pmDocId.localeCompare(b.pmDocId));

  let userIndex = 0;
  charts.forEach((chart) => {
    let nextUserIndex = findNextAvailableUser(chart, userIndex);
    if (nextUserIndex !== -1) {
      assignments[users[nextUserIndex]].push(chart);
      userIndex = nextUserIndex + 1;
    }
  });

  return assignments;
};

// export const generateChartUniqueId = (algorithmObject) => {
//   // Extract all fields from algorithmObject and ensure fullName is treated as a string
//   const fullName = String(algorithmObject.fullName || '').toUpperCase().trim();
//   const dob = algorithmObject.dob || '';
//   const gender = (algorithmObject.gender || '').toUpperCase();
//   const zipCode = algorithmObject.zipCode || '';
//   const phoneNumber = algorithmObject.phoneNumber || '';
//   const email = (algorithmObject.email || '').toUpperCase();
//   let ssn = '';

//   // Normalize and concatenate fields
//   if (algorithmObject.ssn !== undefined && algorithmObject.ssn !== null) {
//     const ssnStr = String(algorithmObject.ssn).toLowerCase();
//     if (ssnStr === "unavailable" || ssnStr === "n/a" || ssnStr === "u/a") {
//       ssn = '';
//     } else {
//       ssn = ssnStr;
//     }
//   }

//   const uniqueString = `${fullName}|${dob}|${gender}|${zipCode}|${phoneNumber}|${email}|${ssn}`;

//   // Hash the concatenated string to generate chartUniqueId using SHA-256 from crypto-js
//   const hash = SHA256(uniqueString).toString();

//   // Truncate the hash to desired length (e.g., first 12 characters)
//   const truncatedHash = hash.substring(0, 12); // Adjust the length as needed

//   return truncatedHash;
// };


export const generateChartUniqueId = (algorithmObject) => {
  // Extract all fields from algorithmObject and ensure fullName is treated as a string
  const fullName = String(algorithmObject.fullName || '').toUpperCase().trim();
  const dob = algorithmObject.dob || '';
  const gender = (algorithmObject.gender || '').toUpperCase();
  const zipCode = algorithmObject.zipCode || '';
  const phoneNumber = algorithmObject.phoneNumber || '';
  const email = (algorithmObject.email || '').toUpperCase();
  let ssn = '';

  // Normalize and concatenate fields
  if (algorithmObject.ssn !== undefined && algorithmObject.ssn !== null) {
    const ssnStr = String(algorithmObject.ssn).toLowerCase();
    if (ssnStr === "unavailable" || ssnStr === "n/a" || ssnStr === "u/a") {
      ssn = '';
    } else {
      ssn = ssnStr;
    }
  }

  const uniqueString = `${fullName}|${dob}|${gender}|${zipCode}|${phoneNumber}|${email}|${ssn}`;

  // Hash the concatenated string to generate chartUniqueId using SHA-256 from crypto-js
  const hash = SHA256(uniqueString).toString();

  // Extract a 5-digit number from the hash (e.g., the last 5 digits)
  const numericHash = parseInt(hash.substring(hash.length - 5), 16);
  const randomFiveDigits = (numericHash % 100000).toString().padStart(5, '0');

  // Concatenate prefix 'WWS', truncated hash, and the 5-digit number
  const chartUniqueId = `WWS${randomFiveDigits}`;

  return chartUniqueId;
};


export const validateHeaders = (file, requiredHeaders) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];

        const missingHeaders = requiredHeaders.filter(header => !headers.includes(header));

        if (missingHeaders.length) {
          reject(`Document missing required headers: ${missingHeaders.join(', ')}`);
        } else {
          resolve('Headers are valid');
        }
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = (error) => reject(error);

    reader.readAsArrayBuffer(file);
  });
};

export const parseDates = (dateString) => {
  if (dateString?.includes(",")) {
    return dateString?.split(',').map(date => dayjs(date.trim(), dateFormat));
  }
  return dateString
};

export const formattedDate = (orderDate) => {
  if (orderDate && !orderDate.includes(',')) {
    return dayjs(orderDate);
  } else {
    return parseDates(orderDate);
  }
};

export const checkMatchingCodes = (hcpcsCodes) => {
  const matchedEquipment = automateEquipmentData
    .filter(item => item.codes.some(code => hcpcsCodes.includes(code)))
    .map(item => item.equipment);

  return matchedEquipment;
}

export const subFormatDate = (date) => {
  if (date === null) {
    return "U/A"
  }
  // Check if the date is in a comma-separated format
  if (typeof date === 'string' && date.includes(',')) {
    return date.split(',').map(d => dayjs(d.trim()).format('MM/DD/YYYY')).join(',');
  }

  // Check if the date is a valid date and format it
  if (dayjs(date).isValid()) {
    return dayjs(date).format('MM/DD/YYYY');
  }
  // Return the date as is if it's not a valid date (e.g., "U/A" or "N/A")
  return date;
};

export const formatDatesForSave = (datesArray) => {
  return datesArray
    .filter(date => date !== null)
    .map(date => dayjs(date).format('MM/DD/YYYY'))
    .join(',');
};

export const convertToCommaSeparated = (dateString, timeZone) => {
  // Check if dateString is a string and contains line breaks
  if (typeof dateString === 'string' && dateString.match(/\r?\n/)) {
    // Ensure the input string is trimmed and split by line breaks
    const dateArray = dateString.trim().split(/\r?\n/);

    // Process each date in the array
    const formattedDates = dateArray.map((dateStr) => {
      // Parse the dateStr with multiple formats to accommodate various date formats
      const jsDate = dayjs(dateStr, ['MM/DD/YYYY', 'M/D/YYYY']);
      if (jsDate.isValid()) {
        // Convert to the specified time zone and format as MM/DD/YYYY
        return jsDate.tz(timeZone.value, true).format('MM/DD/YYYY');
      } else {
        console.warn(`Invalid date format encountered: ${dateStr}`);
        return dateStr; // Return the original string if not in a valid date format
      }
    });

    // Join the formatted dates into a comma-separated string
    return formattedDates.join(', ');
  }
  if (typeof dateString === 'string' && dateString.match(/\,/)) {
    // Ensure the input string is trimmed and split by comma
    const dateArray = dateString.trim().split(/\,/);

    // Process each date in the array
    const formattedDates = dateArray.map((dateStr) => {
      // Parse the dateStr with multiple formats to accommodate various date formats
      const jsDate = dayjs(dateStr, ['MM/DD/YYYY', 'M/D/YYYY']);
      if (jsDate.isValid()) {
        // Convert to the specified time zone and format as MM/DD/YYYY
        return jsDate.tz(timeZone.value, true).format('MM/DD/YYYY');
      } else {
        console.warn(`Invalid date format encountered: ${dateStr}`);
        return dateStr; // Return the original string if not in a valid date format
      }
    });

    // Join the formatted dates into a comma-separated string
    return formattedDates;
  }
  // Process a single date string (not split by line breaks)
  const result = DateToJSDate(dateString, timeZone);
  return result;
};

export const getIpAddress = async () => {
  try {
    const { data } = await axios.get('https://api.ipify.org?format=json');
    return data.ip;
  } catch (error) {
    console.error("Could not fetch IP address:", error);
    return 'Unavailable';
  }
};

export const extractChartsOptions = [
  {
    label: <span >Patient Details</span>,
    title: 'patient',
    options: [
      {
        label: "Facility Name",
        value: 'project',
      },
      {
        label: "Patient Received On",
        value: 'receivedOn',
      },
      {
        label: "UUID",
        value: 'chartId',
      },
      {
        label: "Patient Name",
        value: 'patientName',
      },
      {
        label: "DOB",
        value: 'dob',
      },
      {
        label: "Age",
        value: 'age',
      },
      {
        label: "Primary Insurance #",
        value: 'patientPrimaryInsurance',
      },
      {
        label: "SSN #",
        value: 'ssnNumber',
      },
      {
        label: "Gender",
        value: 'gender',
      },
      {
        label: "Relationship",
        value: 'relationship',
      },
      {
        label: "Subscriber Name",
        value: 'subscriberName',
      },
      {
        label: "Subscriber DOB",
        value: 'subscriberDob',
      },
      {
        label: "Marital Status",
        value: 'maritalStatus',
      },
      {
        label: "Address Received in PRF/LMN",
        value: 'receivedAddress',
      },
      {
        label: "Address",
        value: 'address',
      },
      {
        label: "City",
        value: 'city',
      },
      {
        label: "State",
        value: 'state',
      },
      {
        label: "Zip",
        value: 'zipCode',
      },
      {
        label: "Home/Phone #",
        value: 'patientPhoneNumber',
      },
      {
        label: "Mail ID",
        value: 'patientMailId',
      },
    ],
  },
  {
    label: <span>Insurance Information</span>,
    title: 'insurance',
    options: [
      {
        label: "Portal Availability",
        value: 'portalAvailability',
      },
      {
        label: "Insurance Card Received On",
        value: 'insuranceCardReceivedOn',
      },
      {
        label: "Insurance Card",
        value: 'insuranceCard',
      },
      {
        label: "Multiplan",
        value: 'multiplan',
      },
      {
        label: "IC Payer ID",
        value: 'icPayerID',
      },
      {
        label: "Primary Insurance",
        value: 'primaryInsurance',
      },
      {
        label: "Primary Insurance State",
        value: 'primaryInsuranceState',
      },
      {
        label: "Primary Insurance Plan",
        value: 'primaryInsurancePlan',
      },
      {
        label: "Primary Insurance Group #",
        value: 'primaryInsuranceGroupNumber',
      },
      {
        label: "Secondary Insurance",
        value: 'secondaryInsurance',
      },
      {
        label: "Secondary Insurance State",
        value: 'secondaryInsuranceState',
      },
      {
        label: "Secondary Insurance Plan",
        value: 'secondaryInsurancePlan',
      },
      {
        label: "Secondary Insurance #",
        value: 'secondaryInsuranceNumber',
      },
      {
        label: "Secondary Insurance Group #",
        value: 'secondaryInsuranceGroupNumber',
      },
    ],
  },
  {
    label: <span>Order Summary</span>,
    title: 'product',
    options: [
      {
        label: "VF Requested Equipment Model",
        value: 'vfRequestedEquipmentModel',
      },
      {
        label: "HCPCS Codes",
        value: 'hcpcsCodes',
      },
      {
        label: "Equipment Name",
        value: 'equipmentName',
      },
      {
        label: "Baby Due Date",
        value: 'babyDueDate',
      },
      {
        label: "Weeks Due",
        value: 'weeksDue',
      },
      {
        label: "Order Date",
        value: 'orderDate',
      },
      {
        label: "Prescription Received On",
        value: "prescriptionReceivedOn",
      },
      {
        label: "DX Codes",
        value: 'DxCodesModelDetails',
      },
      {
        label: "Ordering Physician",
        value: 'orderingPhysician',
      },
      {
        label: "Ordering Physician NPI",
        value: 'orderingPhysicianNPI',
      },
      {
        label: "Ordering Physician Address",
        value: 'orderingPhysicianAddress',
      },
      {
        label: "Ordering Physician Phn #",
        value: 'orderingPhysicianPhoneNumber',
      },
      {
        label: "Ordering Physician Fax #",
        value: 'orderingPhysicianFaxNumber',
      },
      {
        label: "Ordering Physician Speciality",
        value: 'orderingPhysicianSpeciality',
      },
      {
        label: "PCP",
        value: 'pcp',
      },
      {
        label: "PCP NPI #",
        value: 'pcpNpiNumber',
      },
      {
        label: "PCP Address",
        value: 'pcpAddress',
      },
      {
        label: "PCP Phn #",
        value: 'pcpPhoneNumber',
      },
      {
        label: "PCP Fax #",
        value: 'pcpFaxNumber',
      },
      {
        label: "PCP Speciality",
        value: 'pcpSpeciality',
      },
      {
        label: "HCPCS From Prescription",
        value: 'hcpcsFromPrescription',
      },
      {
        label: "Equipment Model From Prescription",
        value: 'equipmentModelFromPrescription',
      },
      {
        label: "VF TAT",
        value: 'vfTAT',
      },
      {
        label: "VF Date",
        value: 'verifyCompletedOn',
      },
    ],
  },
  {
    label: <span>LMN Validation</span>,
    title: 'lmn',
    options: [
      {
        label: "LMN Received On",
        value: "lmnReceviedOn",
      },
      {
        label: "LMN Order Date",
        value: 'lmnOrderDate',
      },
      {
        label: "Prescribing Physician",
        value: 'prescribingPhysician',
      },
      {
        label: "NPI Number",
        value: 'npiNumber',
      },
      {
        label: "NPI Verification Method",
        value: 'npiVerificationMethod',
      },
      {
        label: "Physician Signature",
        value: 'physicianSignature',
      },
      {
        label: "Physician Phone",
        value: 'physicianPhone',
      },
      {
        label: "Physician Fax",
        value: 'physicianFax',
      },
      {
        label: "HCPCS Code",
        value: 'hcpcsCode',
      },
      {
        label: "Item/Equipment Description",
        value: 'itemEquipmentDescription',
      },
      {
        label: "Quantity",
        value: 'quantity',
      },
      {
        label: "Diagnosis Code(s)",
        value: 'diagnosisCode',
      },
      {
        label: "Start Date",
        value: 'lmnStartDate',
      },
      {
        label: "End Date",
        value: 'lmnEndDate',
      },
      {
        label: "Order Type",
        value: 'orderType',
      },
      {
        label: "Face-to-Face Notes",
        value: 'faceToFaceNotes',
      },
      {
        label: "Progress Notes/Medical Records",
        value: 'progressNotesMedicalRecords',
      },
      {
        label: "Patient Medical History",
        value: 'patientMedicalHistory',
      },
      {
        label: "Sizing",
        value: 'sizing',
      },
      {
        label: "Customization",
        value: 'customization',
      },
      {
        label: "Accessories/Supplies",
        value: 'accessoriesSupplies',
      },
      {
        label: "HIPAA Compliant",
        value: 'hipaaCompliant',
      },
      {
        label: "Medicare/Medicaid Compliant",
        value: 'medicareMedicaidCompliant',
      },
      {
        label: "State Regulations Compliant",
        value: 'stateRegulationsCompliant',
      },
      {
        label: "Additional Clinical Info Included",
        value: 'additionalClinicalInfoIncluded',
      },
      {
        label: "Resolvable",
        value: 'resolvable',
      },
      {
        label: "Additional Notes",
        value: 'additionalNotes',
      },
      {
        label: "Validation Status",
        value: 'validationStatus',
      },
      {
        label: "Reason for Denial/Pending",
        value: 'reasonForDenialPending',
      },
      {
        label: "Validation Date",
        value: 'validationDate',
      },
    ],
  },
  {
    label: <span>Coverage Summary</span>,
    title: 'coverage',
    options: [
      {
        label: "Calendar Month",
        value: 'calendarMonth',
      },
      {
        label: "Coverage Start Date",
        value: 'coverageStartDate',
      },
      {
        label: "Coverage End Date",
        value: 'coverageEndDate',
      },
      {
        label: "Network Status",
        value: 'networkStatus',
      },
      {
        label: "INN Benefit's For DME",
        value: 'innBenefits',
      },
      {
        label: "INN Ded Calendar/Actual Amt",
        value: 'innDedCalendar',
      },
      {
        label: "INN Ded Met Amt",
        value: 'innDedMetAmount',
      },
      {
        label: "INN Ded Bal Amt",
        value: 'innDedBalanceAmount',
      },
      {
        label: "INN Coins",
        value: 'innCoins',
      },
      {
        label: "INN OOP Calendar/Actual Amt",
        value: 'innOopCalendar',
      },
      {
        label: "INN OOP Met Amt",
        value: 'innOopMetAmount',
      },
      {
        label: "OON Benefits For DME",
        value: 'onnBenefits',
      },
      {
        label: "OON Ded Calendar/Actual Amt",
        value: 'oonDedCalendar',
      },
      {
        label: "OON Ded Met Amt",
        value: 'oonDedMetAmount',
      },
      {
        label: "OON Ded Bal Amt",
        value: 'ooDedBalanceAmount',
      },
      {
        label: "OON Coins",
        value: 'oonCoins',
      },
      {
        label: "OON OOP Calendar/Actual Amt",
        value: 'oonOopCalendar',
      },
      {
        label: "OON OOP Met Amt",
        value: 'oonOopMet',
      },
      {
        label: "Auth Required",
        value: 'authRequired',
      },
      {
        label: "E&B Status",
        value: 'vfCategory',
      },
      {
        label: "S&S Received Equipment In the Last 6 Months?",
        value: 'ssReceived',
      },
      {
        label: "Prescription Availability",
        value: 'consultationStatus',
      },
      {
        label: "VF Comments",
        value: 'vfComments',
      },
      {
        label: "Subsequent Scan Date",
        value: 'subsequentScanDate',
      },
      {
        label: "Subsequent Quarter To Verify",
        value: 'subsequentQuarter',
      },
      {
        label: "Transaction ID/SSA",
        value: 'transaction',
      },
      {
        label: "Calling Assigned Reason",
        value: 'callingAssigned',
      },
      {
        label: "Calling Added On",
        value: 'callingAdded',
      },
      {
        label: "Delivery Status",
        value: 'deliveryStatus',
      },
    ],
  },
  {
    label: <span>Caller Space</span>,
    title: 'caller',
    options: [
      {
        label: "Insurance Phn #",
        value: 'insurancePhoneNumber',
      },
      {
        label: "PCB",
        value: 'pcb',
      },
      {
        label: "Calling Comments",
        value: 'callingComments',
      },
      {
        label: "Call Ref #",
        value: 'callRefNumber',
      },
      {
        label: "Calling Payer ID",
        value: 'callingPayerId',
      },
      {
        label: "Call Mode",
        value: 'callMode',
      },
      {
        label: "Operator",
        value: 'operator',
      },
      {
        label: "Start Time",
        value: 'startTime',
      },
      {
        label: "End Time",
        value: 'endTime',
      },
      {
        label: "ACH",
        value: 'ach',
      },
      {
        label: "Call Disposition Code",
        value: 'callDispositionCode',
      },
      {
        label: "CR Disposition Code",
        value: 'crDispositionCode',
      },
    ],
  }
];

export const extractSpecificChart = [
  {
    label: "Patient Details",
    value: 'patientDetails',
  },
  {
    label: "Insurance Information",
    value: 'InsuranceInformation',
  },
  {
    label: "Order Summary",
    value: 'orderSummary',
  },
  {
    label: "LMN Validation",
    value: 'lmnValidation',
  },
  {
    label: "Coverage Summary",
    value: 'coverageSummary',
  },
  {
    label: "Caller Space",
    value: 'callerSpace',
  },
]

export const dataGroupMapping = {
  "patientDetails": [
    "project", "internalFtpDate", "receivedOn", "initialSubsequentDate", "chartId", "patientName", "dob", "age",
    "patientPrimaryInsurance", "ssnNumber", "gender", "relationship", "subscriberName",
    "subscriberDob", "maritalStatus", "receivedAddress", "address", "city", "state", "zipCode",
    "patientPhoneNumber", "patientMailId"
  ],
  "InsuranceInformation": [
    "portalAvailability", "insuranceCardReceivedOn", "insuranceCard", "multiplan",
    "icPayerID", "primaryInsurance", "primaryInsuranceState", "primaryInsurancePlan", "primaryInsuranceGroupNumber",
    "secondaryInsurance", "secondaryInsuranceState", "secondaryInsurancePlan", "secondaryInsuranceNumber", "secondaryInsuranceGroupNumber"
  ],
  "orderSummary": [
    "vfRequestedEquipmentModel", "hcpcsCodes", "equipmentName", "babyDueDate", "weeksDue", "orderDate",
    "prescriptionClassification",
    "DxCodesModelDetails", "orderingPhysician", "orderingPhysicianNPI", "orderingPhysicianAddress", "orderingPhysicianPhoneNumber",
    "orderingPhysicianFaxNumber", "orderingPhysicianSpeciality", "pcp", "pcpNpiNumber", "pcpAddress", "pcpPhoneNumber", "pcpFaxNumber",
    "pcpSpeciality", "vfTAT", "verifyCompletedOn", "hcpcsFormPrescription", "equipmentModelFromPrescription", "prescriptionReceivedOn"
  ],
  "lmnValidation":
    [
      "prescriptionReceivedOn", "lmnOrderDate", "prescribingPhysician", "npiNumber", "npiVerificationMethod", "physicianSignature", "physicianPhone", "physicianFax", "hcpcsCode",
      "itemEquipmentDescription", "quantity", "diagnosisCode", "lmnStartDate", "lmnEndDate", "orderType", "faceToFaceNotes", "progressNotesMedicalRecords", "patientMedicalHistory",
      "sizing", "customization", "accessoriesSupplies", "hipaaCompliant", "medicareMedicaidCompliant", "stateRegulationsCompliant", "additionalClinicalInfoIncluded", "resolvable",
      "additionalNotes", "validationStatus", "reasonForDenialPending", "validationDate"
    ]
  ,
  "coverageSummary": [
    "calendarMonth", "coverageStartDate", "coverageEndDate", "networkStatus", "innBenefits", "innDedCalendar", "innDedMetAmount", "innDedBalanceAmount", "innCoins", "innOopCalendar",
    "innOopMetAmount", "onnBenefits", "oonDedCalendar", "oonDedMetAmount", "ooDedBalanceAmount", "oonCoins", "oonOopCalendar", "oonOopMet", "authRequired", "vfCategory", "ssReceived",
    "consultationStatus", "vfComments", "subsequentScanDate", "subsequentQuarter", "transaction", "callingAssigned", "callingAdded", "deliveryStatus", "deliveredOn"
  ],
  "callerSpace": [
    "insurancePhoneNumber", "pcb", "callingComments", "callRefNumber", "callingPayerId", "callMode", "operator", "startTime", "endTime", "ach", "callDispositionCode", "crDispositionCode"
  ]
  // Add other data groups as necessary
};

export const allProjectAccessChecker = (userProject, projectList) => {
  return new Promise((resolve, reject) => {
    // Check if input arrays are valid
    if (!Array.isArray(userProject) || !userProject.length) {
      return reject(new Error("Invalid userProject array"));
    }

    if (!Array.isArray(projectList) || !projectList.length) {
      return reject(new Error("Invalid projectList array"));
    }

    // Map projectList to an array of IDs and remove duplicates from both arrays
    const projectListIds = projectList.map((project) => project.id);
    const uniqueUserProject = [...new Set(userProject)];
    const uniqueProjectListIds = [...new Set(projectListIds)];

    // Check if arrays have the same length and elements
    const checkLength = uniqueUserProject.length === uniqueProjectListIds.length;
    const checkUserProject = uniqueUserProject.every(id => uniqueProjectListIds.includes(id));
    const checkProjectListIds = uniqueProjectListIds.every(id => uniqueUserProject.includes(id));

    const arraysMatch = checkLength && checkUserProject && checkProjectListIds;

    resolve(arraysMatch); // Resolve the promise with the result
  });
};
