import React, { memo } from 'react'

const SearchBar = ({
  searchQuery,
  setSearchQuery,
  setSearchTerm
}) => {

  const handleChange = (event) => {
    const targetValue = event.target.value;
    setSearchQuery(targetValue);
    setSearchTerm(targetValue)
  }

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const trimmedText = pastedText.trim();
    setSearchQuery(trimmedText);
    setSearchTerm(trimmedText)
  }

  return (
    <div className="w-full px-5 py-5">
      <div className="relative mt-1">
        <button
          className="block w-7 h-7 text-center text-xl leading-0 absolute top-2 left-2 text-gray-400 focus:outline-none cursor-default"
          onClick={(e) => e.preventDefault()}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </button>
        <input
          type="text"
          className="w-full pl-9 pr-10 py-2 border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-blue-500 focus:shadow-md"
          value={searchQuery}
          onChange={handleChange}
          onPaste={handlePaste}
          placeholder="Search..."
        />
        {
          searchQuery && (
            <button
              className="block w-7 h-7 text-center text-xl leading-0 absolute top-2 right-2 text-gray-400 focus:outline-none"
              onClick={() => {
                setSearchQuery('');
                setSearchTerm('')
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 50 50">
                <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
              </svg>
            </button>
          )
        }
      </div>
    </div>
  )
}

export default memo(SearchBar);
