/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useState } from 'react';
import { Col, Row, DatePicker, Form, Select, Input, TimePicker, Button, Spin } from "antd";
import { ClockCircleOutlined, } from "@ant-design/icons";
import dayjs from "dayjs";
import { toast } from 'react-toastify';
import {
 addNoToObjects, isEmptyObject, isObject, exportToExcel, trimObjValues,
 generatePDF,
 addNumberToObjectArray,
} from "../../../utils/utility.js";
import { scheduleSendApi } from '../../../redux/scheduleSend/scheduleSendService';
import { useDispatch } from 'react-redux';

const CreateScheduleSend = ({
 setIsCollapseOpen,
 isCollapseOpen,
 handleRangePickerChange,
 disabledDate,
 setSelectedFrequency,
 selectedFrequency,
 frequencyOptions,
 filterChart,
 setTakeReport
}) => {
 // eslint-disable-next-line no-unused-vars
 const [warningVisible, setWarningVisible] = useState(false);
 const [selectedFormat, setSelectedFormat] = useState(null);
 const selectRef = useRef(null);
 const [loadings, setLoadings] = useState(false);
 const [form] = Form.useForm();
 const { RangePicker } = DatePicker;
 const dispatch = useDispatch();
 const [selectFieldVisible, setSelectFieldVisible] = useState(false);

 const handleChange = (value) => {
  setSelectedFormat(value); // Update the selectedFormat state
  if (value === 'pdf') {
   setWarningVisible(true);
  } else {
   setWarningVisible(false);
  }
 };

 const onSubmitExportReport = useCallback(async () => {
  try {
   let values = await form.validateFields();
   if (values.format !== undefined && (selectedFormat !== null && selectedFormat !== undefined)) {
    values = { ...values, format: selectedFormat }
   }
   else {
    return toast.warning("Please Select The Format");
   }
   const items = { ...values };

   const { "Select Date": selectedDates, fileName } = values;

   if (selectedDates && selectedDates.length === 2) {
    const [startDate, endDate] = selectedDates;
    const formattedStartDate = dayjs(startDate).startOf("day");
    const formattedEndDate = dayjs(endDate).endOf("day");

    const filtered = filterChart.filter((item) => {
     const itemDate = dayjs(item.createdAt).startOf("day");
     return (
      (itemDate.isAfter(formattedStartDate) &&
       itemDate.isBefore(formattedEndDate)) ||
      itemDate.isSame(formattedStartDate) ||
      itemDate.isSame(formattedEndDate)
     );
    });

    // setFilteredData(filtered);
    if (!isObject(items)) {
     return toast.warning("This is not object data!");
    }

    /* check is empty object */
    if (isEmptyObject(items)) {
     return toast.warning("Empty object cannot accept!");
    }

    /* trim values */
    trimObjValues(items);
    if (filtered.length === 0 || filtered.length === filtered) {
     return toast.error("On selected date no data found!");
    }
    const chartData = {
     /* Patient Details */
     no: "SL.No",
     project: "Facility Name",
     internalFtpDate: "Internal FTP Date",
     receivedOn: "Patient Received On",
     initialSubsequentDate: "Subsequent Scan Date",
     chartId: "UUID",
     patientName: "Patient Name",
     dob: "DOB",
     age: "Age",
     patientPrimaryInsurance: "Primary Insurance #",
     ssnNumber: "SSN #",
     gender: "Gender",
     relationship: "Relationship",
     subscriberName: "Subscriber Name",
     subscriberDob: "Subscriber DOB",
     maritalStatus: "Marital Status",
     receivedAddress: "Address Received in PRF/LMN",
     address: "Address",
     city: "City",
     state: "State",
     zipCode: "Zip",
     patientPhoneNumber: "Home/Phone #",
     patientMailId: "Mail ID",

     /* Insurance Information */

     portalAvailability: "Portal Availability",
     insuranceCardReceivedOn: "Insurance Card Received On",
     insuranceCard: "Insurance Card",
     multiplan: "Multiplan",
     icPayerID: "IC Payer ID",
     primaryInsurance: "Primary Insurance",
     primaryInsuranceState: "Primary Insurance State",
     primaryInsurancePlan: "Primary Insurance Plan",
     primaryInsuranceGroupNumber: "Primary Insurance Group #",
     secondaryInsurance: "Secondary Insurance",
     secondaryInsuranceState: "Secondary Insurance State",
     secondaryInsurancePlan: "Secondary Insurance Plan",
     secondaryInsuranceNumber: "Secondary Insurance #",
     secondaryInsuranceGroupNumber: "Secondary Insurance Group #",

     /* Order Summary */

     vfRequestedEquipmentModel: "VF Requested Equipment Model",
     hcpcsCodes: "HCPCS Codes",
     equipmentName: "Equipment Name",
     babyDueDate: "Baby Due Date",
     weeksDue: "Weeks Due",
     orderDate: "Order Date",
     DxCodesModelDetails: "DX Codes",
     orderingPhysician: "Ordering Physician",
     orderingPhysicianNPI: "Ordering Physician NPI",
     orderingPhysicianAddress: "Ordering Physician Address",
     orderingPhysicianPhoneNumber: "Ordering Physician Phn #",
     orderingPhysicianFaxNumber: "Ordering Physician Fax #",
     orderingPhysicianSpeciality: "Ordering Physician Speciality",
     pcp: "PCP",
     pcpNpiNumber: "PCP NPI #",
     pcpAddress: "PCP Address",
     pcpPhoneNumber: "PCP Phn #",
     pcpFaxNumber: "PCP Fax #",
     pcpSpeciality: "PCP Speciality",
     vfTAT: "VF TAT",
     verifyCompletedOn: "VF Date",
     hcpcsFormPrescription: "HCPCS Form Prescription ",
     equipmentModelFromPrescription: "Equipment Model From Prescription",
     prescriptionReceivedOn: "Prescription Received On",


     /*LMN Validation*/
     lmnReceviedOn: "LMN Received On",
     lmnOrderDate: "LMN Order Date",
     prescribingPhysician: "Prescribing Physician",
     npiNumber: "NPI Number",
     npiVerificationMethod: "NPI Verification Method",
     physicianSignature: "Physician Signature",
     physicianPhone: "Physician Phone",
     physicianFax: "Physician Fax",
     hcpcsCode: "HCPCS Code",
     itemEquipmentDescription: "Item/Equipment Description",
     quantity: "Quantity",
     diagnosisCode: "Diagnosis Code(s)",
     lmnStartDate: "Start Date",
     lmnEndDate: "End Date",
     orderType: "Order Type",
     faceToFaceNotes: "Face-to-Face Notes",
     progressNotesMedicalRecords: "Progress Notes/Medical Records",
     patientMedicalHistory: "Patient Medical History",
     sizing: "Sizing",
     customization: "Customization",
     accessoriesSupplies: "Accessories/Supplies",
     hipaaCompliant: "HIPAA Compliant",
     medicareMedicaidCompliant: "Medicare/Medicaid Compliant",
     stateRegulationsCompliant: "State Regulations Compliant",
     additionalClinicalInfoIncluded: "Additional Clinical Info Included",
     resolvable: "Resolvable",
     additionalNotes: "Additional Notes",
     validationStatus: "Validation Status",
     reasonForDenialPending: "Reason for Denial/Pending",
     validationDate: "Validation Date",

     /* Coverage Summary  */
     calendarMonth: "Calendar Month",
     coverageStartDate: "Coverage Start Date",
     coverageEndDate: "Coverage End Date",
     networkStatus: "Network Status",
     innBenefits: "INN Benefit's For DME",
     innDedCalendar: "INN Ded Calendar/Actual Amt",
     innDedMetAmount: "INN Ded Met Amt",
     innDedBalanceAmount: "INN Ded Bal Amt",
     innCoins: "INN Coins",
     innOopCalendar: "INN OOP Calendar/Actual Amt",
     innOopMetAmount: "INN OOP Met Amt",
     onnBenefits: "OON Benefits For DME",
     oonDedCalendar: "OON Ded Calendar/Actual Amt",
     oonDedMetAmount: "OON Ded Met Amt",
     ooDedBalanceAmount: "OON Ded Bal Amt",
     oonCoins: "OON Coins",
     oonOopCalendar: "OON OOP Calendar/Actual Amt",
     oonOopMet: "OON OOP Met Amt",
     authRequired: "Auth Required",
     vfCategory: "E&B Status",
     ssReceived: "S&S Received Equipment In the Last 6 Months?",
     consultationStatus: "Prescription Availability",
     vfComments: "VF Comments",
     subsequentScanDate: 'Subsequent VF Date',
     subsequentQuarter: "Subsequent Quarter To Verify",
     transaction: "Transaction ID/SSA",
     callingAssigned: "Calling Assigned Reason",
     callingAdded: "Calling Added On",
     deliveryStatus: "Delivery Status",
     deliveredOn: "Delivered On",

     /* Calling Details  */
     insurancePhoneNumber: "Insurance Phn #",
     pcb: "PCB",
     callingComments: "Calling Comments",
     callRefNumber: "Call Ref #",
     callingPayerId: "Calling Payer ID",
     callMode: "Call Mode",
     operator: "Operator",
     startTime: "Start Time",
     endTime: "End Time",
     ach: "ACH",
     callDispositionCode: "Call Disposition Code",
     crDispositionCode: "CR Disposition Code"
    };
    addNumberToObjectArray(chartData);

    const updatedLogs = addNoToObjects(filtered);
    function camelToNormal(text) {
     return (
      text
       // Replace any numeric text with #
       .replace("Number", " #")
       // Insert a space before all caps
       .replace(/([A-Z])/g, " $1")
       // Capitalize the first character of the resulting string
       .replace(/^./, function (str) {
        return str.toUpperCase();
       })
       // Replace 'Pcp' with 'PCP'
       .replace(/\bPcp\b/g, "PCP")
       .replace(/\bVf\b/g, "VF")
       .replace(/\bss\b/g, "SS")
       .replace(/\bDob\b/g, "DOB")
       .replace(/\bOnn\b/g, "ONN")
       .replace(/\bOon\b/g, "OON")
     );
    }

    let headerData = {};
    let excludeHeaderData = [
     "batchNo",
     "pmDocId",
     "isValidChart",
     "isActive",
     "vfDate",
     "id",
     "updateCount",
     "updatedAt",
     "orderDate",
     "no",
     "auditBy",
     "verifyBy",
     "chartNo",
    ];
    updatedLogs.forEach((item) => {
     Object.keys(item).forEach((key) => {
      let formattedKeyName = null;
      let keyName = key;
      if (!excludeHeaderData.includes(keyName)) {
       if (keyName === "vfTAT") {
        formattedKeyName = "VF TAT";
       } else if (keyName === "receivedOn") {
        formattedKeyName = "Patient Received On";
       } else if (keyName === "patientAcc") {
        formattedKeyName = "Patient Acc #";
       } else if (keyName === "ssnNumber") {
        formattedKeyName = "SSN #";
       } else if (keyName === "icPayerID") {
        formattedKeyName = "IC Payer ID";
       } else if (keyName === "hcpcsCodes") {
        formattedKeyName = "HCPCS Codes";
       } else if (keyName === "ssReceived") {
        formattedKeyName = "SS Received";
       } else {
        // Default case using camelToNormal
        formattedKeyName = camelToNormal(keyName);
       }

       if (!headerData[keyName]) {
        headerData[keyName] = formattedKeyName;
       }
      }
     });
    });

    // if (items.format === "excel") {
    //   const selectedData = Object.fromEntries(values.extractSelectedField.map(field => [field, chartData[field]]));
    //   exportToExcel(filtered, selectedData, fileName)
    // } else if (items.format === "pdf") {
    //   const selectedData = Object.fromEntries(values.extractSelectedField.map(field => [field, chartData[field]]));
    //   generatePDF(filtered, selectedData, fileName);
    // }

    if (items.exportAs === "all" && items.format === "excel") {
     exportToExcel(filtered, chartData, fileName);
    } else if (items.exportAs === "all" && items.format === "pdf") {
     generatePDF(filtered, chartData, fileName);
    } else if (items.exportAs === "allAndFields" && items.format === "excel") {
     const selectedData = Object.fromEntries(values.extractSelectedField.map(field => [field, chartData[field]]));
     exportToExcel(filtered, selectedData, fileName);
    } else if (items.exportAs === "allAndFields" && items.format === "pdf") {
     const selectedData = Object.fromEntries(values.extractSelectedField.map(field => [field, chartData[field]]));
     generatePDF(filtered, selectedData, fileName);
    }
    setLoadings(() => false)
    setTakeReport(() => false);
    setSelectedFormat(() => null)
    setTakeReport(false);
    setWarningVisible(() => false)
    form.resetFields();
   }
  } catch (error) {
   toast.error(error);
  }
 }, [filterChart, form, selectedFormat, setTakeReport]);

 const onScheduleSendReport = useCallback(async () => {
  try {
   let values = await form.validateFields();
   if (values.format !== undefined && (selectedFormat !== null && selectedFormat !== undefined)) {
    values = { ...values, format: selectedFormat }
   }
   else {
    return toast.warning("Please Select The Format");
   }

   const currentTime = dayjs();
   const notifyDateTime = dayjs(values.notifyTime, 'HH:mm:ss'); // Parse notifyTime as HH:mm:ss format

   if (currentTime.isAfter(notifyDateTime)) {
    return toast.warning("Notify time has already passed!");
   }

   const { "Select Date": selectedDates,
    fileName,
    format,
    to,
    notifyDate,
    notifyTime,
    frequency } = values;

   if (selectedDates && selectedDates.length === 2) {
    const [startDate, endDate] = selectedDates;
    const rangeStartDate = dayjs(startDate).startOf("day");
    const rangeEndDate = dayjs(endDate).endOf("day");
    const filtered = filterChart.filter((item) => {
     const itemDate = dayjs(item.createdAt).startOf("day");
     return (
      (itemDate.isAfter(rangeStartDate) &&
       itemDate.isBefore(rangeEndDate)) ||
      itemDate.isSame(rangeStartDate) ||
      itemDate.isSame(rangeEndDate)
     );
    });
    if (filtered.length === 0) {
     toast.warning("No data found on selected date range!");
     return; // Exit the function if no data is found
    }

    const items = {}
    items.rangeStartDate = rangeStartDate
    items.rangeEndDate = rangeEndDate
    items.to = to
    items.format = format
    items.fileName = fileName
    items.notifyDateStart = notifyDate[0]
    items.notifyDateEnd = notifyDate[1]
    items.notifyTime = notifyTime
    items.frequency = frequency
    items.isCompleted = false

    setLoadings(() => true)
    await dispatch(scheduleSendApi(items)).unwrap();
    toast.success("Reports Scheduled Successfully")
    setLoadings(() => false)
    setTakeReport(() => false);
    setSelectedFormat(() => null)
    setIsCollapseOpen(() => false)
    setWarningVisible(() => false)
    form.resetFields();
   }

  } catch (error) {
   toast.error(error);
   setLoadings(false)
  }
 }, [dispatch, filterChart, form, selectedFormat, setIsCollapseOpen, setTakeReport]);
 const disabledDatesTime = (current) => {
  const currentTime = dayjs();
  if (current && current.isSame(currentTime, 'day')) {
   return {
    disabledHours: () => Array.from({ length: 24 }, (_, i) => i).slice(0, currentTime.hour()),
    disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i).slice(0, currentTime.minute()),
    // disabledSeconds: () => Array.from({ length: 60 }, (_, i) => i).slice(0, currentTime.second()),
   };
  }
  return {};
 };
 const handleBatchAndChart = (value) => {
  if (value === "all") {
   setSelectFieldVisible(false);
  } else if (value === "allAndFields") {
   setSelectFieldVisible(true);
  }
 };


 const onSearch = (value) => {
  // console.log('search:', value);
 };
 const filterOption = (input, option) =>
  option?.label?.props?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

 const filterOption1 = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

 return (
  <>
   <Row gutter={16}>
    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
     <Form
      layout="vertical"
      form={form}
      onFinish={onSubmitExportReport}
      autoComplete="off"
     >
      {/* 
            <Form.Item
              className="mb-2"
              label="Choose Format"
              name="format"
              rules={[{ required: true, message: "Please select the format!" }]}
            >
              <Select
                showSearch
                placeholder="Select export as"
                optionFilterProp="children"
                value={selectedFormat}
                onChange={handleChange}
                options={[
                  { value: "excel", label: "Excel" },
                  { value: "pdf", label: "PDF" }
                ]}
              />
              {selectedFormat && (
                <small className="mt-10">
                  Current selected format: <span className="font-bold">{selectedFormat === "excel" ? "Excel" : "PDF"}</span>
                </small>
              )}
              {warningVisible && (
                <Alert
                  size="small"
                  className="mt-1 text-xs"
                  message="Note: Selecting PDF will cause the field to break."
                  type="warning"
                  showIcon
                />
              )}
            </Form.Item> */}
      {/* {selectFieldVisible && <Form.Item
              className="mb-2"
              label="Extract Selected Field"
              name="extractSelectedField"
            >
              <Select
                showSearch
                allowClear
                mode="multiple"
                placeholder="Select fields to export"
                optionFilterProp="children"
                // onChange={handleFieldsChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={[
                  {
                    label: <span>Patient Details</span>,
                    title: 'patient',
                    options: [
                      {
                        label: "Facility",
                        value: 'project',
                      },
                      {
                        label: "Patient Received On",
                        value: 'receivedOn',
                      },
                      {
                        label: "Patient Acc #",
                        value: 'chartId',
                      },
                      {
                        label: "Patient Name",
                        value: 'patientName',
                      },
                      {
                        label: "DOB",
                        value: 'dob',
                      },
                      {
                        label: "Age",
                        value: 'age',
                      },
                      {
                        label: "Primary Insurance #",
                        value: 'patientPrimaryInsurance',
                      },
                      {
                        label: "SSN #",
                        value: 'ssnNumber',
                      },
                      {
                        label: "Gender",
                        value: 'gender',
                      },
                      {
                        label: "Relationship",
                        value: 'relationship',
                      },
                      {
                        label: "Subscriber Name",
                        value: 'subscriberName',
                      },
                      {
                        label: "Subscriber DOB",
                        value: 'subscriberDob',
                      },
                      {
                        label: "Marital Status",
                        value: 'maritalStatus',
                      },
                      {
                        label: "Address Received in PRF/LMN",
                        value: 'receivedAddress',
                      },
                      {
                        label: "Address",
                        value: 'address',
                      },
                      {
                        label: "City",
                        value: 'city',
                      },
                      {
                        label: "State",
                        value: 'state',
                      },
                      {
                        label: "Zip",
                        value: 'zipCode',
                      },
                      {
                        label: "Home/Phone #",
                        value: 'patientPhoneNumber',
                      },
                      {
                        label: "Mail ID",
                        value: 'patientMailId',
                      }
                    ],
                  },
                  {
                    label: <span>Insurance Information</span>,
                    title: 'insurance',
                    options: [
                      {
                        label: "Portal Availability",
                        value: 'portalAvailability',
                      },
                      {
                        label: "Insurance Card Received On",
                        value: 'insuranceCardReceivedOn',
                      },
                      {
                        label: "Insurance Card",
                        value: 'insuranceCard',
                      },
                      {
                        label: "Multiplan",
                        value: 'multiplan',
                      },
                      {
                        label: "IC Payer ID",
                        value: 'icPayerID',
                      },
                      {
                        label: "Primary Insurance",
                        value: 'primaryInsurance',
                      },
                      {
                        label: "Primary Insurance State",
                        value: 'primaryInsuranceState',
                      },
                      {
                        label: "Primary Insurance Plan",
                        value: 'primaryInsurancePlan',
                      },
                      {
                        label: "Primary Insurance Group #",
                        value: 'primaryInsuranceGroupNumber',
                      },
                      {
                        label: "Secondary Insurance",
                        value: 'secondaryInsurance',
                      },
                      {
                        label: "Secondary Insurance State",
                        value: 'secondaryInsuranceState',
                      },
                      {
                        label: "Secondary Insurance Plan",
                        value: 'secondaryInsurancePlan',
                      },
                      {
                        label: "Secondary Insurance #",
                        value: 'secondaryInsuranceNumber',
                      },
                      {
                        label: "Secondary Insurance Group #",
                        value: 'secondaryInsuranceGroupNumber',
                      },
                    ],
                  },
                  {
                    label: <span>Order Summary</span>,
                    title: 'product',
                    options: [
                      {
                        label: "VF Requested Equipment Model",
                        value: 'vfRequestedEquipmentModel',
                      },
                      {
                        label: "HCPCS Codes",
                        value: 'hcpcsCodes',
                      },
                      {
                        label: "Equipment Name",
                        value: 'equipmentName',
                      },
                      {
                        label: "Baby Due Date",
                        value: 'babyDueDate',
                      },
                      {
                        label: "Weeks Due",
                        value: 'weeksDue',
                      },
                      {
                        label: "Order Date",
                        value: 'orderDate',
                      },
                      {
                        label: "DX Codes",
                        value: 'DxCodesModelDetails',
                      },
                      {
                        label: "Ordering Physician",
                        value: 'orderingPhysician',
                      },
                      {
                        label: "Ordering Physician NPI",
                        value: 'orderingPhysicianNPI',
                      },
                      {
                        label: "Ordering Physician Address",
                        value: 'orderingPhysicianAddress',
                      },
                      {
                        label: "Ordering Physician Phn #",
                        value: 'orderingPhysicianPhoneNumber',
                      },
                      {
                        label: "Ordering Physician Fax #",
                        value: 'orderingPhysicianFaxNumber',
                      },
                      {
                        label: "Ordering Physician Speciality",
                        value: 'orderingPhysicianSpeciality',
                      },
                      {
                        label: "PCP",
                        value: 'pcp',
                      },
                      {
                        label: "PCP NPI #",
                        value: 'pcpNpiNumber',
                      },
                      {
                        label: "PCP Address",
                        value: 'pcpAddress',
                      },
                      {
                        label: "PCP Phn #",
                        value: 'pcpPhoneNumber',
                      },
                      {
                        label: "PCP Fax #",
                        value: 'pcpFaxNumber',
                      },
                      {
                        label: "PCP Speciality",
                        value: 'pcpSpeciality',
                      },
                      {
                        label: "HCPCS From Prescription",
                        value: 'hcpcsFromPrescription',
                      },
                      {
                        label: "Equipment Model From Prescription",
                        value: 'equipmentModelFromPrescription',
                      },
                      {
                        label: "VF TAT",
                        value: 'vfTAT',
                      }
                    ],
                  },
                  {
                    label: <span>LMN Validation</span>,
                    title: 'lmn',
                    options: [
                      {
                        label: "LMN Order Date",
                        value: 'lmnOrderDate',
                      },
                      {
                        label: "Prescribing Physician",
                        value: 'prescribingPhysician',
                      },
                      {
                        label: "NPI Number",
                        value: 'npiNumber',
                      },
                      {
                        label: "NPI Verification Method",
                        value: 'npiVerificationMethod',
                      },
                      {
                        label: "Physician Signature",
                        value: 'physicianSignature',
                      },
                      {
                        label: "Physician Phone",
                        value: 'physicianPhone',
                      },
                      {
                        label: "Physician Fax",
                        value: 'physicianFax',
                      },
                      {
                        label: "HCPCS Code",
                        value: 'hcpcsCode',
                      },
                      {
                        label: "Item/Equipment Description",
                        value: 'itemEquipmentDescription',
                      },
                      {
                        label: "Quantity",
                        value: 'quantity',
                      },
                      {
                        label: "Diagnosis Code(s)",
                        value: 'diagnosisCode',
                      },
                      {
                        label: "Start Date",
                        value: 'lmnStartDate',
                      },
                      {
                        label: "End Date",
                        value: 'lmnEndDate',
                      },
                      {
                        label: "Order Type",
                        value: 'orderType',
                      },
                      {
                        label: "Face-to-Face Notes",
                        value: 'faceToFaceNotes',
                      },
                      {
                        label: "Progress Notes/Medical Records",
                        value: 'progressNotesMedicalRecords',
                      },
                      {
                        label: "Patient Medical History",
                        value: 'patientMedicalHistory',
                      },
                      {
                        label: "Sizing",
                        value: 'sizing',
                      },
                      {
                        label: "Customization",
                        value: 'customization',
                      },
                      {
                        label: "Accessories/Supplies",
                        value: 'accessoriesSupplies',
                      },
                      {
                        label: "HIPAA Compliant",
                        value: 'hipaaCompliant',
                      },
                      {
                        label: "Medicare/Medicaid Compliant",
                        value: 'medicareMedicaidCompliant',
                      },
                      {
                        label: "State Regulations Compliant",
                        value: 'stateRegulationsCompliant',
                      },
                      {
                        label: "Additional Notes",
                        value: 'additionalNotes',
                      },
                      {
                        label: "Validation Status",
                        value: 'validationStatus',
                      },
                      {
                        label: "Reason for Denial/Pending",
                        value: 'reasonForDenialPending',
                      },
                      {
                        label: "Validation Date",
                        value: 'validationDate',
                      }
                    ],
                  },
                  {
                    label: <span>Coverage Summary</span>,
                    title: 'coverage',
                    options: [
                      {
                        label: "Calendar Month",
                        value: 'calendarMonth',
                      },
                      {
                        label: "Coverage Start Date",
                        value: 'coverageStartDate',
                      },
                      {
                        label: "Coverage End Date",
                        value: 'coverageEndDate',
                      },
                      {
                        label: "Network Status",
                        value: 'networkStatus',
                      },
                      {
                        label: "INN Benefit's For DME",
                        value: 'innBenefits',
                      },
                      {
                        label: "INN Ded Calendar/Actual Amt",
                        value: 'innDedCalendar',
                      },
                      {
                        label: "INN Ded Met Amt",
                        value: 'innDedMetAmount',
                      },
                      {
                        label: "INN Ded Bal Amt",
                        value: 'innDedBalanceAmount',
                      },
                      {
                        label: "INN Coins",
                        value: 'innCoins',
                      },
                      {
                        label: "INN OOP Calendar/Actual Amt",
                        value: 'innOopCalendar',
                      },
                      {
                        label: "INN OOP Met Amt",
                        value: 'innOopMetAmount',
                      },
                      {
                        label: "OON Benefits For DME",
                        value: 'onnBenefits',
                      },
                      {
                        label: "OON Ded Calendar/Actual Amt",
                        value: 'oonDedCalendar',
                      },
                      {
                        label: "OON Ded Met Amt",
                        value: 'oonDedMetAmount',
                      },
                      {
                        label: "OON Ded Bal Amt",
                        value: 'ooDedBalanceAmount',
                      },
                      {
                        label: "OON Coins",
                        value: 'oonCoins',
                      },
                      {
                        label: "OON OOP Calendar/Actual Amt",
                        value: 'oonOopCalendar',
                      },
                      {
                        label: "OON OOP Met Amt",
                        value: 'oonOopMet',
                      },
                      {
                        label: "Auth Required",
                        value: 'authRequired',
                      },
                      {
                        label: "E&B Status",
                        value: 'vfCategory',
                      },
                      {
                        label: "S&S Received Equipment In the Last 6 Months?",
                        value: 'ssReceived',
                      },
                      {
                        label: "Consultation Status",
                        value: 'consultationStatus',
                      },
                      {
                        label: "VF Comments",
                        value: 'vfComments',
                      },
                      {
                        label: "Subsequent Scan Date",
                        value: 'subsequentScanDate',
                      },
                      {
                        label: "Subsequent Quarter To Verify",
                        value: 'subsequentQuarter',
                      },
                      {
                        label: "Transaction ID/SSA",
                        value: 'transaction',
                      },
                      {
                        label: "Calling Assigned Reason",
                        value: 'callingAssigned',
                      },
                      {
                        label: "Calling Added On",
                        value: 'callingAdded',
                      },
                      {
                        label: "Delivery Status",
                        value: 'deliveryStatus',
                      }
                    ],
                  },
                  {
                    label: <span>Caller Space</span>,
                    title: 'caller',
                    options: [
                      {
                        label: "Insurance Phn #",
                        value: 'insurancePhoneNumber',
                      },
                      {
                        label: "PCB",
                        value: 'pcb',
                      },
                      {
                        label: "Calling Comments",
                        value: 'callingComments',
                      },
                      {
                        label: "Call Ref #",
                        value: 'callRefNumber',
                      },
                      {
                        label: "Calling Payer ID",
                        value: 'callingPayerId',
                      },
                      {
                        label: "Call Mode",
                        value: 'callMode',
                      },
                      {
                        label: "Operator",
                        value: 'operator',
                      },
                      {
                        label: "Start Time",
                        value: 'startTime',
                      },
                      {
                        label: "End Time",
                        value: 'endTime',
                      },
                      {
                        label: "ACH",
                        value: 'ach',
                      },
                      {
                        label: "Call Disposition Code",
                        value: 'callDispositionCode',
                      },
                      {
                        label: "CR Disposition Code",
                        value: 'crDispositionCode',
                      },
                    ],
                  }
                ]}
              />
            </Form.Item>} */}
      {/* <Form.Item
              className="mb-2"
              label="File Name"
              name="fileName"
              rules={[{ required: true, message: "File name required for download" }]}
            >
              <Input placeholder="Enter Filename here" />
            </Form.Item> */}
      <Form.Item
       className="mb-2 p-0 justify-start"
       label="Notify Date"
       name="notifyDate"
       rules={[{ required: true, message: "Please select the schedule send mail date and time!" }]}
      >
       <RangePicker
        allowClear={false}
        onChange={handleRangePickerChange}
        disabledDate={disabledDate}
        disabledTime={disabledDatesTime}
       />
      </Form.Item>
      <Form.Item
       className="mb-2 p-0 justify-start"
       label="Notify Time"
       name="notifyTime"
       rules={[
        { required: true, message: "Please select the schedule send mail date and time!" },
        ({ getFieldValue }) => ({
         validator(_, value) {
          const selectedTime = dayjs(value, 'hh:mm A');

          const currentTime = dayjs().startOf('minute'); // Start of the current minute
          if (selectedTime && selectedTime.isBefore(currentTime)) {
           return Promise.reject(new Error('Selected time is in the past'));
          }
          return Promise.resolve();
         }
        })
       ]}
      >
       <TimePicker
        className="w-full"
        format="h:mm A"
        use12Hours
        hideSecond
        disabledTime={disabledDatesTime}
       />
      </Form.Item>
      <Form.Item
       className="mb-2 p-0 justify-start"
       label="Frequency"
       name="frequency"
       rules={[{ required: true, message: "Please select the schedule send mail date and time!" }]}
      >
       <Select
        allowClear
        className="w-full"
        value={selectedFrequency}
        onChange={(value) => setSelectedFrequency(value)}
        options={frequencyOptions}
        ref={selectRef}
       />
      </Form.Item>

      <Form.Item
       className="mb-2 p-0"
       label="Send To"
       name="to"
       rules={[
        { required: true, message: "Please enter the receiver email address!" },
        { type: 'email', message: 'Please enter a valid email address' }
       ]}
      >
       <Input placeholder="Receiver Email Address" className="w-full" />
      </Form.Item>
      {/* <Collapse
              className="w-full p-0 mt-3"
              size="small"
              onChange={(key) => setIsCollapseOpen(key.length > 0)}
              expandIconPosition="right"
            >
              <Collapse.Panel
                key="1"
                header="Schedule Send"
                className="p-0 text-start"
              >
                {isCollapseOpen && (
                  <>
                    <Form.Item
                      className="mb-2 p-0 justify-start"
                      label="Notify Date"
                      name="notifyDate"
                      rules={[{ required: true, message: "Please select the schedule send mail date and time!" }]}
                    >
                      <RangePicker
                        allowClear={false}
                        onChange={handleRangePickerChange}
                        disabledDate={disabledDate}
                        disabledTime={disabledDatesTime}
                      />
                    </Form.Item>
                    <Form.Item
                      className="mb-2 p-0 justify-start"
                      label="Notify Time"
                      name="notifyTime"
                      rules={[
                        { required: true, message: "Please select the schedule send mail date and time!" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const selectedTime = dayjs(value, 'hh:mm A');

                            const currentTime = dayjs().startOf('minute'); // Start of the current minute
                            if (selectedTime && selectedTime.isBefore(currentTime)) {
                              return Promise.reject(new Error('Selected time is in the past'));
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}
                    >
                      <TimePicker
                        className="w-full"
                        format="h:mm A"
                        use12Hours
                        hideSecond
                        disabledTime={disabledDatesTime}
                      />
                    </Form.Item>
                    <Form.Item
                      className="mb-2 p-0 justify-start"
                      label="Frequency"
                      name="frequency"
                      rules={[{ required: true, message: "Please select the schedule send mail date and time!" }]}
                    >
                      <Select
                        allowClear
                        className="w-full"
                        value={selectedFrequency}
                        onChange={(value) => setSelectedFrequency(value)}
                        options={frequencyOptions}
                        ref={selectRef}
                      />
                    </Form.Item>

                    <Form.Item
                      className="mb-2 p-0"
                      label="Send To"
                      name="to"
                      rules={[
                        { required: true, message: "Please enter the receiver email address!" },
                        { type: 'email', message: 'Please enter a valid email address' }
                      ]}
                    >
                      <Input placeholder="Receiver Email Address" className="w-full" />
                    </Form.Item>
                  </>
                )}
              </Collapse.Panel>
            </Collapse> */}

      {/* Render different buttons based on isCollapseOpen */}
      <Form.Item>
       <div className="grid grid-cols-3 grid-rows-1 gap-3 mt-4 ">
        <div className="text-start">

         <Button
          key="cancel"
          type="primary"
          danger

          onClick={() => {
           setTakeReport(false);
           setSelectedFormat(() => null);
           setIsCollapseOpen(() => false)
           form.resetFields();
          }}
         >
          Cancel
         </Button>
        </div>

        <div className="col-span-2 text-end ">
         {isCollapseOpen ? <Button
          key="export"
          type="primary"
          icon={<ClockCircleOutlined />}
          iconposition={"end"}
          onClick={onScheduleSendReport}

         >
          Schedule Send
         </Button> : <Button

          key="export"
          type="primary"
          onClick={onSubmitExportReport}

         >
          Export
         </Button>
         }

        </div>
       </div>
      </Form.Item>
     </Form>
    </Col>
   </Row>
   <Spin spinning={loadings} fullscreen />
  </>
 );
};

export default CreateScheduleSend;
