import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeTab: "1",
  isPatientDetailsSaved: false,
  isInsuranceDetailsSaved: false,
  isProductDetailsSaved: false,
  isCoverageDetailsSaved: false,
  isCallingDetailsSaved: false,
  callerSpaceToggle: false,
  chartDetailView: { selected: false, data: {} }, // Initial state for chart detail
};

// export const chartViewSlice = createSlice({
//   name: 'chartView',
//   initialState,
//   reducers: {
//     setActiveTab: (state, action) => {
//       state.activeTab = action.payload;
//     },
//     setCallerSpaceToggle: (state, action) => {
//       state.callerSpaceToggle = action.payload;
//     },
//     setChartDetailView: (state, action) => {
//       state.chartDetailView = {
//         selected: action.payload.selected,
//         data: action.payload.data,
//       };
//     },
//     resetChartViewState: (state) => {
//       // Reset all states to initial when closing modal
//       return initialState;
//     },
//     setPatientDetailsSaved: (state, action) => {
//       state.isPatientDetailsSaved = action.payload;
//     },
//     setInsuranceDetailsSaved: (state, action) => {
//       state.isInsuranceDetailsSaved = action.payload;
//     },
//     setProductDetailsSaved: (state, action) => {
//       state.isProductDetailsSaved = action.payload;
//     },
//     setCoverageDetailsSaved: (state, action) => {
//       state.isCoverageDetailsSaved = action.payload;
//     },
//     setCallingDetailsSaved: (state, action) => {
//       state.isCallingDetailsSaved = action.payload;
//     },
//     // Add more actions for other saved states
//   },
// });

export const chartViewManagementSlice = createSlice({
  name: 'chartViewManagement',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setCallerSpaceToggle: (state, action) => {
      state.callerSpaceToggle = action.payload;
    },
    setChartDetailView: (state, action) => {
      state.chartDetailView = {
        selected: action.payload.selected,
        data: action.payload.data,
      };
    },
    resetChartViewState: (state) => {
      // Reset all states to initial when closing modal
      return initialState;
    },
    setPatientDetailsSaved: (state, action) => {
      state.isPatientDetailsSaved = action.payload;
    },
    setInsuranceDetailsSaved: (state, action) => {
      state.isInsuranceDetailsSaved = action.payload;
    },
    setProductDetailsSaved: (state, action) => {
      state.isProductDetailsSaved = action.payload;
    },
    setCoverageDetailsSaved: (state, action) => {
      state.isCoverageDetailsSaved = action.payload;
    },
    setCallingDetailsSaved: (state, action) => {
      state.isCallingDetailsSaved = action.payload;
    },
    // Add more actions for other saved states
  },
});

export const {
  setActiveTab,
  setCallerSpaceToggle,
  setChartDetailView,
  resetChartViewState,
  setPatientDetailsSaved,
  setInsuranceDetailsSaved,
  setProductDetailsSaved,
  setCoverageDetailsSaved,
  setCallingDetailsSaved,
} = chartViewManagementSlice.actions;

export default chartViewManagementSlice.reducer;
