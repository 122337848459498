import React from 'react';
import { Button, Form, Input } from 'antd';
import { Link } from "react-router-dom";

const SignUp = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <div className='w-full h-[100vh] flex justify-center items-center bg-slate-100'>
      <div className='w-[90vw] flex items-center bg-white rounded-xl shadow-xl'>
        <div className='xl:w-3/5 lg:w-3/5 md:w-3/5 sm:w-3/5 xs:w-0 xl:flex lg:flex md:flex sm:flex xs:hidden'>
          <div className='w-full h-full flex justify-center items-center'>
            <img src={require("../../assets/Wonder-Worth-Solutions-Logo.png")} alt='logo' />
          </div>
        </div>
        <div className='flex xl:w-2/5 lg:w-2/5 md:w-2/5 sm:w-2/5 xs:w-full p-5 bg-gray-100 xs:bg-white rounded-xl'>
          <Form
            className='w-full'
            name="basic"
            layout='vertical'
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              className='mb-2'
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Please enter the email!', },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              className='mb-2'
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please enter the password!', },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              className='mb-2'
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                { required: true, message: 'Please enter the confirm password!', },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              className='mb-2'
            >
              <Button
                className='w-full bg-blue-400 mt-2 font-medium'
                type="primary"
                htmlType="submit"
              >
                Sign Up
              </Button>
            </Form.Item>
            <Form.Item className='mb-2'>
              <Link
                className='text-blue-logo font-medium hover:underline float-right'
                to='/'
              >
                Sign In
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div >
  )
}

export default SignUp;