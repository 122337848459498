import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form, Select, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { setLoading } from '../../redux/loading/loadingSlice';
import { axiosErrorHandling, isEmptyObject, isObject, trimObjValues } from '../../utils/utility';
import { toast } from 'react-toastify';
import { setUserDetails } from '../../redux/userDetails/userDetailsSlice';
import { getAssignedBatchesNo } from '../../api/chart/chart';
import { FaArrowLeft } from 'react-icons/fa';
import { trackLogIn } from '../../api/userLog/userLog';


const BatchConfirmPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [batchNoList, setBatchNoList] = useState([]);
  const [form] = Form.useForm();
  const userId = useSelector((state) => state.userDetails.userId);
  const project = useSelector((state) => state.userDetails.project);
  const role = useSelector((state) => state.userDetails.role);

  useEffect(() => {
    try {
      dispatch(setLoading(true));
      const fetchAssignedBatch = async () => {
        if (role !== null && role === "VERIFICATION") {
          const { data } = await getAssignedBatchesNo(userId, project, role);
          if (Array.isArray(data) && data.length > 0) {
            const arrayOfData = [];
            for (let item of data) {
              let createObj = {};
              createObj.value = item;
              createObj.label = item;
              arrayOfData.push(createObj);
            }

            if (arrayOfData.length > 0) {
              /* sort ascending order */
              arrayOfData.sort((a, b) => a.label.localeCompare(b.label));
              setBatchNoList(arrayOfData);
            }
            dispatch(setLoading(false));
          }
        }
        if (role !== null && role === "AUDIT") {
          const { data } = await getAssignedBatchesNo(userId, project, role);
          if (Array.isArray(data) && data.length > 0) {
            const arrayOfData = [];
            for (let item of data) {
              let createObj = {};
              createObj.value = item;
              createObj.label = item;
              arrayOfData.push(createObj);
            }

            if (arrayOfData.length > 0) {
              /* sort ascending order */
              arrayOfData.sort((a, b) => a.label.localeCompare(b.label));
              setBatchNoList(arrayOfData);
            }
            dispatch(setLoading(false));
          }
        }
        if (role !== null && role === "VERIFICATION CALLER") {
          const { data } = await getAssignedBatchesNo(userId, project, role);
          if (Array.isArray(data) && data.length > 0) {
            const arrayOfData = [];
            for (let item of data) {
              let createObj = {};
              createObj.value = item;
              createObj.label = item;
              arrayOfData.push(createObj);
            }

            if (arrayOfData.length > 0) {
              /* sort ascending order */
              arrayOfData.sort((a, b) => a.label.localeCompare(b.label));
              setBatchNoList(arrayOfData);
            }
            dispatch(setLoading(false));
          }
        }
        if (role !== null && role === "AUDIT CALLER") {
          const { data } = await getAssignedBatchesNo(userId, project, role);
          if (Array.isArray(data) && data.length > 0) {
            const arrayOfData = [];
            for (let item of data) {
              let createObj = {};
              createObj.value = item;
              createObj.label = item;
              arrayOfData.push(createObj);
            }

            if (arrayOfData.length > 0) {
              /* sort ascending order */
              arrayOfData.sort((a, b) => a.label.localeCompare(b.label));
              setBatchNoList(arrayOfData);
            }
            dispatch(setLoading(false));
          }
        }
      }
      fetchAssignedBatch();
    } catch (error) {
      dispatch(setLoading(false));
    }
    finally {
      dispatch(setLoading(false)); // Ensure loading is stopped after the fetch attempt
    }
  }, [dispatch, project, role, userId]);

  const onSearch = (value) => {
    // console.log('search:', value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


  const onSubmitBatch = async () => {
    try {
      const values = await form.validateFields();
      const items = { ...values };

      /* check is object */
      if (!isObject(items)) {
        return toast.warning('This is not object data!');
      }

      /* check is empty object */
      if (isEmptyObject(items)) {
        return toast.warning('Empty object cannot accept!');
      }

      /* trim values */
      trimObjValues(items);
      dispatch(setLoading(true));
      dispatch(setUserDetails({
        userId: userId, role: role, project: project, batchNo: values.selectedBatch
      }));
      await trackLogIn(userId, role);
      dispatch(setLoading(false));
      navigate("/chart");
    } catch (error) {
      dispatch(setLoading(false));
      return axiosErrorHandling(error);
    }
  };

  const handleBack = () => {
    navigate("/users/")
  };

  return (
    <div className="relative">
      <button
        onClick={handleBack}
        className="absolute text-blue-600 hover:text-blue-800"
      >
        <FaArrowLeft />
      </button>
      <div className="flex items-center justify-center">
        <div className="w-full max-w-md p-4">
          <Row gutter={16}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form
                layout='vertical'
                form={form}
                onFinish={onSubmitBatch}
                autoComplete="off"
              >
                <Form.Item
                  className='mb-2'
                  label="Select Data Group"
                  name="selectedBatch"
                  rules={[{ required: true, message: 'Please select data group!' }]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Select a Data Group"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    filterOption={filterOption}
                    options={batchNoList}
                  />
                </Form.Item>

                <Form.Item className="mb-2">
                  <Button
                    className="w-fit bg-blue-400 mt-2 font-medium float-right"
                    type="primary"
                    htmlType="submit"
                  >
                    Start
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </div>

  )
}

export default BatchConfirmPage;
