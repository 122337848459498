import React from 'react'
import { Layout } from 'antd';
const { Footer } = Layout;

const AntFooter = () => {
  return (
    <Footer
      className='bottom-0 sticky'
      style={{
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 'bold'
      }}
    >
      © {new Date().getFullYear()} : MYWWS Business Manager | Version V1.0.0

    </Footer>
  )
}

export default AntFooter