/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Row, Modal } from 'antd';
import dayjs from 'dayjs';
import { Button, Form, Input, Select, DatePicker } from 'antd';
import { toast } from 'react-toastify';
import {
 axiosSubmitErrorHandling,
 getIpAddress,
 isEmptyObject,
 isObject,
 trimObjValues,
} from '../../utils/utility.js';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/loading/loadingSlice.js';
import { updateChartApi } from '../../redux/chart/chartService.js';
import _ from 'lodash';

const { TextArea } = Input;
const dateFormat = 'MM/DD/YYYY';

const LmnValidationDetails = ({
 chartDetail,
 setActiveSubTab,
 onNextTab,
 defaultSize,
 formRef,
 charts,
 setCharts,
 setChartDetailView,
 documentViewer
}) => {
 const dispatch = useDispatch();
 const [form] = Form.useForm();
 const [showLmnStartField, setShowLmnStartField] = useState(false);
 const [lmnStartDate, setLmnStartDate] = useState(null);
 const [showLmnEndField, setShowLmnEndField] = useState(false);
 const [lmnEndDate, setLmnEndDate] = useState(null);
 // const [showLmnOrderDateField, setShowLmnOrderDateField] = useState(false);
 const [lmnOrderDate, setLmnOrderDate] = useState(null);
 const [enabledEdit, setEnabledEdit] = useState(false)
 const role = useSelector((state) => state.userDetails.role);
 const userId = useSelector((state) => state.userDetails.userId);
 const [showLmnReceivedDateField, setShowLmnReceivedDateField] = useState(true);
 const [lmnReceivedDate, setLmnReceivedDate] = useState(null);
 const EditAccess = ((enabledEdit && role === "CODING")) ||
  (role === "PROVIDER" || role === "USER" || role === "VERIFICATION" || role === "AUDIT" || role === "VERIFICATION CALLER" || role === "AUDIT CALLER" || role === "GUEST") ? false : true;

 const colProps = documentViewer
  ? { xl: 24, lg: 12, md: 12, sm: 12, xs: 12 }
  : { xl: 8, lg: 8, md: 8, sm: 12, xs: 24 }; // Adjust based on your preferred layout

 useEffect(() => {
  let lmnInitialValue = {
   /* Patient Detail Initial Values */

   prescribingPhysician: chartDetail?.prescribingPhysician,
   npiNumber: chartDetail?.npiNumber,
   npiVerificationMethod: chartDetail?.npiVerificationMethod,
   physicianSignature: chartDetail?.physicianSignature,
   physicianPhone: chartDetail?.physicianPhone,
   physicianFax: chartDetail?.physicianFax,
   hcpcsCode: chartDetail?.hcpcsCode,
   itemEquipmentDescription: chartDetail?.itemEquipmentDescription,
   quantity: chartDetail?.quantity,
   diagnosisCode: chartDetail?.diagnosisCode,
   orderType: chartDetail?.orderType,
   faceToFaceNotes: chartDetail?.faceToFaceNotes,
   progressNotesMedicalRecords: chartDetail?.progressNotesMedicalRecords,
   patientMedicalHistory: chartDetail?.patientMedicalHistory,
   sizing: chartDetail?.sizing,
   customization: chartDetail?.customization,
   accessoriesSupplies: chartDetail?.accessoriesSupplies,
   hipaaCompliant: chartDetail?.hipaaCompliant,
   medicareMedicaidCompliant: chartDetail?.medicareMedicaidCompliant,
   stateRegulationsCompliant: chartDetail?.stateRegulationsCompliant,
   additionalClinicalInfoIncluded: chartDetail?.additionalClinicalInfoIncluded,
   resolvable: chartDetail?.resolvable,
   additionalNotes: chartDetail?.additionalNotes,
   validationStatus: chartDetail?.validationStatus,
   reasonForDenialPending: chartDetail?.reasonForDenialPending,
   validationDate: chartDetail?.validationDate ? dayjs(chartDetail?.validationDate) : '',
   lmnOrderDate: chartDetail?.lmnOrderDate ? dayjs(chartDetail.lmnOrderDate) : null,

  };

  if (chartDetail.lmnValidationCompleted) {
   setEnabledEdit(true)
  }

  if (chartDetail?.lmnReceviedOn && chartDetail?.lmnReceviedOn !== "Invalid Date" && chartDetail?.lmnReceviedOn !== "U/A" && chartDetail?.lmnReceviedOn !== "N/A") {
   setShowLmnReceivedDateField(false);
   setLmnReceivedDate(null);
  }

  if (chartDetail?.lmnReceviedOn && chartDetail?.lmnReceviedOn !== "Invalid Date" && chartDetail?.lmnReceviedOn !== "U/A" && chartDetail?.lmnReceviedOn !== "N/A") {
   // Ensure chartDetail.insuranceCardReceivedOn is split into an array and parsed correctly
   const putLmnDate = chartDetail?.lmnReceviedOn.split(',').map(date => dayjs(date, dateFormat));
   setLmnReceivedDate(putLmnDate);
   form.setFieldsValue({
    lmnReceviedOn: putLmnDate,
   });

   setShowLmnReceivedDateField(true);
  }


  if (chartDetail?.lmnStartDate && chartDetail?.lmnStartDate !== "Invalid Date" && chartDetail?.lmnStartDate !== "U/A" && chartDetail?.lmnStartDate !== "N/A") {
   const putLmnStartDate = chartDetail?.lmnStartDate ? dayjs(chartDetail.lmnStartDate) : ''
   setLmnStartDate(putLmnStartDate);
   setShowLmnStartField(true);
  } else {
   setShowLmnStartField(false);
   setLmnStartDate(null);
  }

  if (chartDetail?.lmnEndDate && chartDetail?.lmnEndDate !== "Invalid Date" && chartDetail?.lmnEndDate !== "U/A" && chartDetail?.lmnEndDate !== "N/A") {
   const putLmnEndDate = chartDetail?.lmnEndDate ? dayjs(chartDetail.lmnEndDate) : ''
   setLmnEndDate(putLmnEndDate);
   setShowLmnEndField(true);
  } else {
   setShowLmnEndField(false);
   setLmnEndDate(null);
  }

  // if (chartDetail?.lmnOrderDate && chartDetail?.lmnOrderDate !== "Invalid Date" && chartDetail?.lmnOrderDate !== "U/A" && chartDetail?.lmnOrderDate !== "N/A") {
  //   const putLmnOrderDate = chartDetail?.lmnOrderDate ? dayjs(chartDetail.lmnOrderDate) : ''
  //   setLmnOrderDate(putLmnOrderDate)
  //   setShowLmnOrderDateField(false);
  // } else {
  //   setShowLmnOrderDateField(false);
  //   setLmnOrderDate(null);
  // }

  form.setFieldsValue(lmnInitialValue);
 }, [chartDetail, form]);

 const onFinishFailed = (errorInfo) => {
  // console.log('Failed:', errorInfo);
 };

 const onChange = (value) => {
  // console.log(`selected ${value}`);
 };

 const onSearch = (value) => {
 };

 const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


 const onChangeDatePicker = (date, dateString) => {
  // console.log(date, dateString);
 };

 const onChangeLmnStartDatePicker = (date, dateString) => {
  // console.log(date, dateString);
  setLmnStartDate(dayjs(date).format("MM/DD/YYYY"));
 };

 const handleRemoveLmnStart = () => {
  setShowLmnStartField(false);
  setLmnStartDate(null);
  form.setFieldsValue({ lmnStartDate: "" });
 };

 const handleAddLmnStart = () => {
  setLmnStartDate(null);
  setShowLmnStartField(true);
 };


 const onChangeLmnEndDatePicker = (date, dateString) => {
  // console.log(date, dateString);
  setLmnEndDate(dayjs(date).format("MM/DD/YYYY"));
 };

 const handleRemoveLmnEnd = () => {
  setShowLmnEndField(false);
  setLmnEndDate(null);
  form.setFieldsValue({ lmnEndDate: "" });
 };

 const handleAddLmnEnd = () => {
  setLmnEndDate(null);
  setShowLmnEndField(true);
 };

 const onChangeLmnOrderDatePicker = (date, dateString) => {
  // console.log(date, dateString);
  setLmnOrderDate(dayjs(date).format("MM/DD/YYYY"));
 };

 const formatPhoneNumber = (value) => {
  if (value === 'U/A') {
   return value; // No formatting needed for "U/A" and "N/A"
  }

  // Remove all non-numeric characters
  const cleaned = value.replace(/\D/g, '');

  // Limit the length to 10 digits
  const limited = cleaned.slice(0, 10);

  // Format the phone number according to (999) 999-9999 pattern
  const match = limited.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

  if (match) {
   const [, areaCode, centralOfficeCode, lineNumber] = match;
   if (lineNumber) {
    return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
   } else if (centralOfficeCode) {
    return `(${areaCode}) ${centralOfficeCode}`;
   } else if (areaCode) {
    return `(${areaCode}`;
   }
  }
  return value;
 };

 //Save Patient Details
 const onFinish = async (values) => {
  // console.log('Success:', values);
  try {

   const values = await form.validateFields();
   const items = {
    ...values
   };

   if (lmnReceivedDate === "Invalid Date") {
    items.lmnReceviedOn = "U/A"
   }

   if (!showLmnReceivedDateField && !lmnReceivedDate) {
    items.lmnReceviedOn = "N/A"
   }

   if (lmnReceivedDate && lmnReceivedDate !== "Invalid Date") {
    items.lmnReceviedOn = lmnReceivedDate
   }

   if (lmnStartDate === "Invalid Date") {
    items.lmnStartDate = "U/A"
   }

   if (!showLmnStartField && !lmnStartDate) {
    items.lmnStartDate = "N/A"
   }

   if (lmnStartDate && lmnStartDate !== "Invalid Date") {
    items.lmnStartDate = lmnStartDate
   }

   if (lmnEndDate === "Invalid Date") {
    items.lmnEndDate = "U/A"
   }

   if (!showLmnEndField && !lmnEndDate) {
    items.lmnEndDate = "N/A"
   }

   if (lmnOrderDate && lmnOrderDate !== "Invalid Date") {
    items.lmnOrderDate = lmnOrderDate
   }

   if (lmnOrderDate === "Invalid Date") {
    items.lmnOrderDate = "U/A"
   }

   // if (!showLmnOrderDateField && !lmnOrderDate) {
   //  items.lmnOrderDate = "N/A"
   // }

   if (lmnOrderDate && lmnOrderDate !== "Invalid Date") {
    items.lmnOrderDate = lmnOrderDate
   }

   items.userRole = role;
   items.userId = userId;
   const ipAddress = await getIpAddress();
   items.ipAddress = ipAddress;

   let LMNdates = lmnReceivedDate
   if (Array.isArray(LMNdates)) {
    LMNdates = LMNdates
     ?.filter(date => date !== null) // Filter out null values
     ?.map(date => dayjs(date).format('MM/DD/YYYY')) // Format each date
     ?.join(','); // Join into a single string
   }
   items.lmnReceviedOn = LMNdates

   /* check is object */
   if (!isObject(items)) {
    return toast.warning('This is not object data!');
   }

   /* check is empty object */
   if (isEmptyObject(items)) {
    return toast.warning('Empty object cannot accept!');
   }

   /* trim values */
   trimObjValues(items);

   dispatch(setLoading(true));
   const updateId = chartDetail.id;
   const res = await dispatch(updateChartApi({ updateId, items })).unwrap();
   const rowIndex = _.findIndex(charts, { id: updateId });

   if (rowIndex > -1) {
    let updatedCharts = [...charts];
    let updatedItem = { ...updatedCharts[rowIndex], ...res };
    updatedCharts[rowIndex] = updatedItem;
    setCharts(updatedCharts);
   }
   if (typeof setChartDetailView === 'function') {
    setChartDetailView((prevView) => ({
     ...prevView,  // Keep the rest of the view intact
     data: {
      ...prevView.data, // Merge the current data
      ...res,  // Override with updated response from the API
     },
     selected: true,  // Ensure the chart is still selected
    }));
   }

   toast.success('LMN Validation details updated successfully!');
   dispatch(setLoading(false));
  } catch (error) {
   dispatch(setLoading(false));
   return axiosSubmitErrorHandling(error);
  }
 };

 //Save Patient Details

 const onSignOffValidation = async (values) => {
  Modal.confirm({
   title: 'Complete Validation',
   content: 'Are you sure you want to complete validation? Once submitted, it cannot be edited! If you agree, Conform; otherwise, Cancel.',
   okText: 'Conform',
   cancelText: 'Cancel',
   onOk: async () => {
    try {
     const validatedValues = await form.validateFields();
     const items = {
      ...validatedValues,
     };

     if (lmnStartDate === "Invalid Date") {
      items.lmnStartDate = "U/A";
     }

     if (!showLmnStartField && !lmnStartDate) {
      items.lmnStartDate = "N/A";
     }

     if (lmnStartDate && lmnStartDate !== "Invalid Date") {
      items.lmnStartDate = lmnStartDate;
     }

     if (lmnEndDate === "Invalid Date") {
      items.lmnEndDate = "U/A";
     }

     if (!showLmnEndField && !lmnEndDate) {
      items.lmnEndDate = "N/A";
     }

     if (lmnEndDate && lmnEndDate !== "Invalid Date") {
      items.lmnEndDate = lmnEndDate;
     }

     items.userRole = role;
     /* check is object */
     if (!isObject(items)) {
      return toast.warning('This is not object data!');
     }

     /* check is empty object */
     if (isEmptyObject(items)) {
      return toast.warning('Empty object cannot accept!');
     }
     let LMNdates = lmnReceivedDate
     if (Array.isArray(LMNdates)) {
      LMNdates = LMNdates
       .filter(date => date !== null) // Filter out null values
       .map(date => dayjs(date).format('MM/DD/YYYY')) // Format each date
       .join(','); // Join into a single string
     }
     items.lmnReceviedOn = LMNdates

     /* trim values */
     trimObjValues(items);

     dispatch(setLoading(true));
     const updateId = chartDetail.id;
     items.lmnValidatedBy = userId;
     items.lmnValidationCompletedOn = dayjs().format("MM/DD/YYYY");
     items.lmnValidationCompleted = true;
     delete items.lmnReceviedOn

     const res = await dispatch(updateChartApi({ updateId, items })).unwrap();
     if (res.lmnValidationCompleted) {
      setEnabledEdit(true);
     }
     toast.success('LMN Validation details updated successfully!');
     dispatch(setLoading(false));
    } catch (error) {
     dispatch(setLoading(false));
     return axiosSubmitErrorHandling(error);
    }
   },
  });
 };

 // Function to disable dates beyond the current date
 const disabledDate = (current) => {
  // Get the current date and year
  const currentDate = dayjs();
  const currentYear = currentDate.year();

  // Disable dates after the current year or after the current date
  return current && (dayjs(current).year() > currentYear || dayjs(current).isAfter(currentDate, 'day'));
 };

 const onChangeLmnReceivedDate = (date, dateString) => {
  // console.log(date, dateString);
  // setLmnReceivedDate(dayjs(date).format("MM/DD/YYYY"));
  setLmnReceivedDate(date);
 };

 const handleRemoveLmnDate = () => {
  setShowLmnReceivedDateField(false);
  setLmnReceivedDate(null);
  form.setFieldsValue({ lmnReceviedOn: "" });
 };

 const handleAddLmnDate = () => {
  setLmnReceivedDate(null);
  setShowLmnReceivedDateField(true);
 };


 return (
  <Form
   ref={formRef}
   layout="vertical"
   form={form}
   onFinish={onFinish}
   onFinishFailed={onFinishFailed}
   autoComplete="off"
   size={defaultSize === "dataCapture" ? "small" : ""}
  >
   <Row gutter={16}>
    <Col
     {...colProps}
    // xl={8} lg={8} md={8} sm={12} xs={24}
    >
     {showLmnReceivedDateField ? (<Form.Item
      className="mb-2"
      label="LMN Received On"
      name="lmnReceviedOn"
     >
      <DatePicker
       multiple
       className="w-full"
       // defaultValue={lmnReceivedDate}
       value={lmnReceivedDate?.length ? lmnReceivedDate?.map(date => dayjs(date, dateFormat)) : null}
       // value={lmnReceivedDate ? dayjs(lmnReceivedDate) : null}
       onChange={onChangeLmnReceivedDate}
       format={dateFormat}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
      <Button
       type="link"
       onClick={handleRemoveLmnDate}
       disabled={!EditAccess}
      >Remove LMN Received On Date</Button>
     </Form.Item>) :
      <Button
       type="link"
       onClick={handleAddLmnDate}
       disabled={!EditAccess}
      >Add LMN Received On Date</Button>
     }
     <Form.Item
      className="mb-2"
      label="LMN Order Date"
      name="lmnOrderDate"
      rules={[
       { required: true, message: 'Please enter the LMN Order Date!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <DatePicker
       className="w-full"
       defaultValue={lmnOrderDate ? dayjs(lmnOrderDate) : null}
       onChange={onChangeLmnOrderDatePicker}
       format={dateFormat}
       disabledDate={disabledDate}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>

     <Form.Item
      className="mb-2"
      label="Prescribing Physician"
      name="prescribingPhysician"
      rules={[
       {
        validator: (_, value) => {
         if (value) {
          if (/^[A-Za-z/./-\s]*$/.test(value) || value === 'U/A' || value === 'N/A') {
           return Promise.resolve();
          } else {
           return Promise.reject('Prescribing Physician must be Alphabets, "U/A", or "N/A".');
          }
         }
         return Promise.resolve(); // Allow empty input if not required
        },
       },
      ]}
     >
      <Input
       className="cursor-pointer"
       type="text"
       onInput={(e) => {
        const inputValue = e.target.value.toUpperCase(); // Convert to uppercase
        // Allow only alphabets and spaces, and "U/A" or "N/A"
        if (/^[A-Za-z/./-\s]*$/.test(inputValue) || inputValue === 'U/A' || inputValue === 'N/A') {
         e.target.value = inputValue; // Set the uppercase value
        } else {
         // Remove invalid characters and keep uppercase
         e.target.value = inputValue.replace(/[^A-Z\\/\s]/g, '');
        }
       }}
       readOnly={!EditAccess}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="NPI Number"
      name="npiNumber"
      rules={[
       {
        validator: (_, value) => {
         if (value) {
          if (/^\d{10}$/.test(value) || value === 'U/A') {
           return Promise.resolve();
          } else {
           return Promise.reject('NPI Number must be a 10-digit number, "U/A".');
          }
         }
         return Promise.resolve(); // Allow empty input if not required
        },
       },
      ]}
     >
      <Input
       type="text"
       onInput={(e) => {
        const inputValue = e.target.value.toUpperCase();;
        // Allow only 10-digit numbers, "U/A", or "N/A"
        if (/^[A-Za-z\s]*$/.test(inputValue) || inputValue === 'U/A') {
         // Allow digits only up to 10 characters
         if (inputValue.length <= 10 || inputValue === 'U/A') {
          e.target.value = inputValue;
         } else {
          e.target.value = inputValue.slice(0, 10);
         }
        } else {
         // Remove invalid characters
         e.target.value = inputValue.replace(/^[A-Za-z\s]*$/g, '').slice(0, 10);
        }
       }}
       readOnly={!EditAccess}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="NPI Verification Method"
      name="npiVerificationMethod"
      rules={[
       { required: true, message: 'Please enter the NPI Verification Method!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Select a NPI Verification Method"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        { value: "NPI Registry", label: "NPI Registry" },
        { value: "U/A", label: "U/A" }
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Physician Signature"
      name="physicianSignature"
      rules={[
       { required: true, message: 'Please enter the Physician Signature!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Select Physician Signature"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        {
         value: "Available", label: "Available"
        },
        {
         value: "U/A", label: "U/A"
        }
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Physician Phone"
      name="physicianPhone"
      rules={[
       {
        validator: (_, value) => {
         // Check if value is empty
         if (!value) {
          return Promise.reject('Please enter the Physician Phone!');
         }

         // Check if value is "U/A" or a valid phone number
         if (value === 'U/A' || /^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
          return Promise.resolve();
         }

         return Promise.reject('Please enter a valid phone number or "U/A".');
        }
       }
      ]}
     >
      <Input
       className="cursor-pointer"
       type="text"
       maxLength={14} // Maximum length for formatted phone number (including parentheses, space, and hyphen)
       onInput={(e) => {
        let inputValue = e.target.value.toUpperCase(); // Convert input to uppercase
        e.target.value = formatPhoneNumber(inputValue);
       }}
       onKeyPress={(e) => {
        // Allow only numbers, control keys, and the letters U, A, and slash
        if (!/[0-9UuAaAN\\/]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
         e.preventDefault();
        }
       }}
       readOnly={!EditAccess}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Physician Fax"
      name="physicianFax"
      rules={[
       {
        validator: (_, value) => {
         // Check if value is empty
         if (!value) {
          return Promise.reject('Please enter the Physician Fax!');
         }

         // Check if value is "U/A"  or a valid phone number
         if (value === 'U/A' || /^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
          return Promise.resolve();
         }

         return Promise.reject('Please enter a valid fax number or "U/A".');
        }
       }
      ]}
     >
      <Input
       className="cursor-pointer"
       type="text"
       maxLength={14} // Maximum length for formatted phone number (including parentheses, space, and hyphen)
       onInput={(e) => {
        let inputValue = e.target.value.toUpperCase(); // Convert input to uppercase
        e.target.value = formatPhoneNumber(inputValue);
       }}
       onKeyPress={(e) => {
        // Allow only numbers, control keys, and the letters U, A, and slash
        if (!/[0-9UuAaAN\\/]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
         e.preventDefault();
        }
       }}
       readOnly={!EditAccess}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="HCPCS Code"
      name="hcpcsCode"
      rules={[
       {
        validator: (_, value) => {
         if (value) {
          // Check if value is "U/A" or "N/A" or a valid HCPCS code
          if (/^(U\/A|[A-Z]\d{4,5})(, [A-Z]\d{4,5})*$/.test(value)) {
           return Promise.resolve();
          }
          return Promise.reject('HCPCS Code must be a valid alphanumeric code or "U/A".');
         }
         return Promise.resolve(); // Allow empty input if not required
        },
       },
      ]}
     >
      <TextArea
       className="cursor-pointer"
       type="text"
       onInput={(e) => {
        let inputValue = e.target.value.toUpperCase(); // Convert to uppercase
        // Replace invalid characters and ensure proper format
        e.target.value = inputValue
         .replace(/[^A-Z0-9,\\/ ]/g, '') // Remove invalid characters except comma and space
         .replace(/(?<=,)\s*/g, ' '); // Ensure a single space after a comma
       }}
       readOnly={!EditAccess}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Item/Equipment Description"
      name="itemEquipmentDescription"
      rules={[
       {
        required: true,
        message: 'Please enter the Item/Equipment Description!',
       },
       {
        validator: (_, value) => {
         if (value) {
          // Check if value is "U/A" or "N/A" or contains valid characters and formats
          if (/^(U\/A|N\/A|[A-Za-z0-9\\/,/(/)\-\s]*$)/.test(value)) {
           return Promise.resolve();
          }
          return Promise.reject('Item/Equipment Description must contain valid characters or be "U/A".');
         }
         return Promise.resolve(); // Allow empty input if not required
        },
       },
      ]}
     >
      <TextArea
       className="cursor-pointer"
       type="text"
       onInput={(e) => {
        let inputValue = e.target.value.toUpperCase(); // Convert to uppercase
        // Allow only valid characters: alphabets, numbers, commas, slashes, and spaces
        e.target.value = inputValue.replace(/[^A-Z0-9,\s\-\\//(/)]/g, '');
       }}
       readOnly={!EditAccess}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Quantity"
      name="quantity"
      rules={[
       {
        required: true,
        message: 'Please enter a valid quantity or "U/A".',
       },
       {
        validator: (_, value) => {
         if (value) {
          // Check if value is a valid format (numbers, ., ,, |, or "U/A")
          if (/^(\d+([.,|]\d+)*|U\/A)$/.test(value)) {
           return Promise.resolve();
          }
          return Promise.reject('Quantity must be a valid number format or "U/A".');
         }
         return Promise.resolve(); // Allow empty input if not required
        },
       },
      ]}
     >
      <Input
       className="cursor-pointer"
       type="text"
       onInput={(e) => {
        let inputValue = e.target.value.toUpperCase(); // Convert to uppercase
        // Allow digits, "U/A", and . , |
        e.target.value = inputValue.replace(/[^0-9U\\/A.,|]/g, '');
       }}
       readOnly={!EditAccess}
      />
     </Form.Item>

    </Col>
    <Col
     {...colProps}
    // xl={8} lg={8} md={8} sm={12} xs={24}
    >
     <Form.Item
      className="mb-2"
      label="Diagnosis Code(s)"
      name="diagnosisCode"
      rules={[
       { required: true, message: 'Please enter the Diagnosis Code(s)!' },
       {
        validator: (_, value) => {
         if (value) {
          // Check if value is "U/A" or "N/A" or contains valid characters and formats
          if (/^(U\/A|N\/A|[A-Za-z0-9\\/, /.\\/(\\/)s]*$)/.test(value)) {
           return Promise.resolve();
          }
          return Promise.reject('Diagnosis Code(s) must contain valid characters or be "U/A".');
         }
         return Promise.resolve(); // Allow empty input if not required
        },
       },
      ]}
     >
      <TextArea
       className="cursor-pointer"
       type="text"
       onInput={(e) => {
        let inputValue = e.target.value.toUpperCase(); // Convert to uppercase
        // Allow only valid characters: alphabets, numbers, commas, slashes, and spaces
        e.target.value = inputValue.replace(/[^A-Z0-9,\s\\/./(\\/)]/g, '');
       }}
       readOnly={!EditAccess}
      />
     </Form.Item>
     {showLmnStartField ?
      (<Form.Item
       className="mb-2"
       label="Start Date"
       name="lmnStartDate"
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      >
       <DatePicker
        className="w-full"
        defaultValue={lmnStartDate ? dayjs(lmnStartDate) : null}
        onChange={onChangeLmnStartDatePicker}
        format={dateFormat}
        disabledDate={disabledDate}
        style={!EditAccess ? { pointerEvents: "none" } : {}}
       />
       <Button
        type="link"
        onClick={handleRemoveLmnStart}
        disabled={!EditAccess}
       >Remove Start Date</Button>
      </Form.Item>)
      : (
       <Button
        type="link"
        onClick={handleAddLmnStart}
        disabled={!EditAccess}
       >Add Start Date</Button>
      )}
     {showLmnEndField ?
      (<Form.Item
       className="mb-2"
       label="End Date"
       name="lmnEndDate"
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      >
       <DatePicker
        className="w-full"
        defaultValue={lmnEndDate ? dayjs(lmnEndDate) : null}
        onChange={onChangeLmnEndDatePicker}
        format={dateFormat}
        style={!EditAccess ? { pointerEvents: "none" } : {}}
       />
       <Button
        type="link"
        onClick={handleRemoveLmnEnd}
        disabled={!EditAccess}
       >Remove End Date</Button>
      </Form.Item>)
      : (
       <Button
        type="link"
        onClick={handleAddLmnEnd}
        disabled={!EditAccess}
       >Add End Date</Button>
      )
     }
     <Form.Item
      className="mb-2"
      label="Order Type"
      name="orderType"
      rules={[
       { required: true, message: 'Please enter the Order Type!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Select Order Type"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        { value: "Rental", label: "Rental" },
        { value: "Purchase", label: "Purchase" },
        { value: "U/A", label: "U/A" }
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Face-to-Face Notes"
      name="faceToFaceNotes"
      rules={[
       { required: true, message: 'Please enter the Face-to-Face Notes!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Select Face-to-Face Notes"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        { value: "YES", label: "YES" },
        { value: "U/A", label: "U/A" }
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Progress Notes/Medical Records"
      name="progressNotesMedicalRecords"
      rules={[
       { required: true, message: 'Please enter the Progress Notes/Medical Records!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Select Progress Notes/Medical Records"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        { value: "YES", label: "YES" },
        { value: "U/A", label: "U/A" }
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Patient Medical History"
      name="patientMedicalHistory"
      rules={[
       { required: true, message: 'Please enter the Patient Medical History!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Select Patient Medical History"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        { value: "YES", label: "YES" },
        { value: "U/A", label: "U/A" }
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Sizing"
      name="sizing"
      rules={[
       { required: true, message: 'Please enter the Sizing!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Select Sizing"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        { value: "YES", label: "YES" },
        { value: "U/A", label: "U/A" }
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Customization"
      name="customization"
      rules={[
       { required: true, message: 'Please enter the Customization!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Select Customization"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        { value: "YES", label: "YES" },
        { value: "U/A", label: "U/A" }
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Accessories/Supplies"
      name="accessoriesSupplies"
      rules={[
       { required: true, message: 'Please enter the Accessories/Supplies!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Select Accessories/Supplies"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        { value: "YES", label: "YES" },
        { value: "U/A", label: "U/A" }
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>
    </Col>
    <Col
     {...colProps}
    // xl={8} lg={8} md={8} sm={12} xs={24}
    >
     <Form.Item
      className="mb-2"
      label="HIPAA Compliant"
      name="hipaaCompliant"
      rules={[
       { required: true, message: 'Please enter the HIPAA Compliant!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Select HIPAA Compliant"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        { value: "YES", label: "YES" },
        { value: "NO", label: "NO" },
        { value: "U/A", label: "U/A" }
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Medicare/Medicaid Compliant"
      name="medicareMedicaidCompliant"
      rules={[
       { required: true, message: 'Please enter the Medicare/Medicaid Compliant!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Select Medicare/Medicaid Compliant"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        { value: "YES", label: "YES" },
        { value: "NO", label: "NO" },
        { value: "U/A", label: "U/A" }
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="State Regulations Compliant"
      name="stateRegulationsCompliant"
      rules={[
       { required: true, message: 'Please enter the State Regulations Compliant!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Select State Regulations Compliant"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        { value: "YES", label: "YES" },
        { value: "NO", label: "NO" },
        { value: "U/A", label: "U/A" }
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Additional Clinical Info Included"
      name="additionalClinicalInfoIncluded"
      rules={[
       { required: true, message: 'Please enter the Additional Clinical Info Included!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Additional Clinical Info Included"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        { value: "YES", label: "YES" },
        { value: "NO", label: "NO" },
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>

     <Form.Item
      className="mb-2"
      label="Resolvable"
      name="resolvable"
      rules={[
       { required: true, message: 'Please enter the resolvable!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Resolvable"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        { value: "YES", label: "YES" },
        { value: "NO", label: "NO" },
        { value: "N/A", label: "N/A" }
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>

     <Form.Item
      className="mb-2"
      label="Additional Notes"
      name="additionalNotes"
      rules={[
       { required: true, message: 'Please enter the Additional Notes!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Select Additional Notes"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        { value: "YES", label: "YES" },
        { value: "U/A", label: "U/A" }
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Validation Status"
      name="validationStatus"
      rules={[
       { required: true, message: 'Please enter the Validation Status!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <Select
       showSearch
       allowClear
       placeholder="Select Validation Status"
       optionFilterProp="children"
       onChange={onChange}
       onSearch={onSearch}
       filterOption={filterOption}
       options={[
        {
         value: "Valid", label: "Valid"
        },
        { value: "Invalid", label: "Invalid" }
       ]}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Reason for Denial/Pending"
      name="reasonForDenialPending"
      rules={[
       { required: true, message: 'Please enter the Reason for Denial/Pending!' },
      ]}
     >
      <TextArea
       className="cursor-pointer"
       type="text"
       readOnly={!EditAccess}
      />
     </Form.Item>
     <Form.Item
      className="mb-2"
      label="Validation Date"
      name="validationDate"
      rules={[
       { required: true, message: 'Please enter the Validation Date!' },
      ]}
      style={!EditAccess ? { pointerEvents: "none" } : {}}
     >
      <DatePicker
       className="w-full"
       onChange={onChangeDatePicker}
       format={dateFormat}
       style={!EditAccess ? { pointerEvents: "none" } : {}}
      />
     </Form.Item>
    </Col>
   </Row>
   <Row gutter={16}>
    <Col
     // {...colProps}
     xl={12} lg={12} md={12} sm={12} xs={12}
    >
     <Form.Item className="mb-2">
      {/* <Button className="w-fit mt-2 font-medium">Cancel</Button> */}

     </Form.Item>
    </Col>
    <Col
     // {...colProps}
     xl={12} lg={12} md={12} sm={12} xs={12}
    >
     <Form.Item className="mb-2">
      {((EditAccess) && (role !== "SUPER ADMIN") && (role !== "ADMIN")) && <Button
       className="w-fit bg-blue-400 mt-2 font-medium float-right"
       type="primary"
       onClick={onSignOffValidation}
       disabled={!EditAccess}
      >
       Complete Validation
      </Button>}
      {EditAccess && <Button
       className="w-fit mt-2 font-medium float-right mr-2"
       htmlType="submit"
       disabled={!EditAccess}
      >
       Save Validation
      </Button>}
     </Form.Item>
    </Col>
   </Row>
  </Form >
 )
}

export default LmnValidationDetails;