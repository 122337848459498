import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

const Selector = ({ placeholder, options, setUserChange, monthWidth }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (options.length > 0) {
      setLoading(false);
    }
  }, [options]);

  const onChange = (value) => {
    setUserChange(placeholder === "Called By" ? "calledBy" : placeholder, value); // Pass the filter name and selected value to the parent
    setSelectedValue(value);
  };

  const onSearch = (value) => {
    // console.log('search:', value);
  };
  const stateFilter = (input, option) => {
    const inputValue = input.trim().toLowerCase();
    const optionValue = option?.value.toLowerCase();
    const optionLabel = option?.label.toLowerCase();

    return (
      (inputValue.length === 2 && optionValue === inputValue) ||
      (inputValue.length > 2 && optionLabel.includes(inputValue))
    );
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase());


  return (
    <div>
      <Select
        mode="multiple"
        loading={loading}
        showSearch
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        filterOption={placeholder === "State" ? stateFilter : filterOption}
        options={options}
        value={selectedValue}
        maxTagCount={1}
        maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
        style={{
          width:
            placeholder === 'Month'
              ? monthWidth
              : placeholder === 'Status'
                ? monthWidth
                : placeholder === 'State'
                  ? monthWidth
                  : placeholder === 'Product'
                    ? monthWidth
                    : placeholder === 'Insurance'
                      ? monthWidth
                      : placeholder === 'Export'
                        ? monthWidth
                        : placeholder === "Year"
                          ? monthWidth
                          : placeholder === "CallingCompletedOn" ?
                            monthWidth
                            : placeholder === "Called By" ?
                              monthWidth
                              : "",
          height: '100%',
          maxWidth: '100%',
        }}
        allowClear={true}
        autoClearSearchValue
        placement="topRight"
        popupMatchSelectWidth={true}
        size="small"
      />
    </div>
  );
};

export default Selector;
