import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { Empty, Spin } from 'antd';

const PiChart = ({ monthData, chartType, modalOpen, piModalOpen }) => {
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (monthData.length > 0) {
      setLoading(false);
    }
  }, [monthData]);

  const options = {
    // title: userChange ? userChange : "All Data",
    titleTextStyle: {
      color: 'Black',
      fontSize: 13,
      // bold: true,
      fontName: 'Monospace',
    },
    is3D: true,
    sliceVisibilityThreshold: 0,
    backgroundColor: 'white',
    backgroundColorStroke: '#f5f5f5',
    // pieHole:1,
    pieSliceText: 'value',

    enableInteractivity: true,
    legend: { position: 'right' },
    animation: {
      startup: true, // Enable animation when the chart loads initially
      easing: 'out', // Animation easing style
      duration: 5000, // Animation duration in milliseconds
    },
    tooltip: {
      isHtml: true, // Allows HTML content in the tooltip
      trigger: 'focus', // Specifies when to show the tooltip (other options: 'selection', 'none')
      showColorCode: true, // Displays the color code in the tooltip
      textStyle: {
        color: '#000', // Text color
        fontName: 'Arial', // Font family
        fontSize: 12, // Font size
      },
      ignoreBounds: false, // Ignore bounds to prevent the tooltip from being clipped by the chart container
    },
  };

  let finalValue = null;
  let antdPieChart = null;

  const allZeros =
    monthData &&
    monthData.every((entry) => entry.count === 0 || Number.isNaN(entry.count));

  if (allZeros) {
    antdPieChart = true;
    finalValue = [
      ['Task', 'Percent'],
      ['No Data', 0],
    ];
  } else if (chartType === 'PieChart' && monthData && monthData.length > 0) {
    const data = monthData.map(({ name, count }) => [name, count]);

    data.unshift(['Task', 'Percent']);
    finalValue = data;
  } else if (chartType === 'Bar' && monthData && monthData.length > 0) {
    // Extract keys and counts from monthData
    const keys = monthData.map(({ key }) => key);
    const counts = monthData.map(({ count }) => count);

    // Ensure we have valid keys and counts
    if (keys.length > 0 && counts.length > 0) {
      // Manually create the first row and the data rows
      const headerRow = ['', ...keys];
      const dataRow = ['Data', ...counts];

      // Combine the rows into the final value array
      finalValue = [headerRow, dataRow];
    }
  } else {
    antdPieChart = true;
    finalValue = [
      ['Task', 'Percent'],
      ['No Data', 0],
    ];
  }

  return (
    <>
      <div className="h-[209px] max-h-[380px] ">
        {loading ? (
          <div className='flex items-center justify-center'><Spin /></div>

        ) : antdPieChart ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <>
            <Chart
              chartType={chartType}
              data={finalValue}
              options={options}
              width={'100%'}
              height={
                chartType === 'Bar' ? modalOpen ? '100vh' : piModalOpen ? '100vh' : '' : chartType === 'PieChart' ? piModalOpen ? '100vh' : '' : '100%'}
            />
          </>
        )}
      </div>
    </>
  );
};

export default PiChart;
