import { createSlice } from '@reduxjs/toolkit';
import { createAdminLogApi, getAdminLogsApi, updateAdminLogApi } from './adminLogService';

const initialState = {
  adminLogList: [],
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
}

export const adminLogSlice = createSlice({
  name: 'adminLog',
  initialState,
  reducers: {
    // add your non-async reducers here
    logout: state => {
      state.adminLogList = [];
      state.loading = 'idle';
      state.currentRequestId = undefined;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    // extraReducers handles asynchronous requests, which is our main focus.
    // GET
    builder
      .addCase(getAdminLogsApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getAdminLogsApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.adminLogList.length = 0;
          state.adminLogList.push(...action.payload);
          state.currentRequestId = undefined;
        }
      })
      .addCase(getAdminLogsApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

    // POST
    builder
      .addCase(createAdminLogApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(createAdminLogApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.adminLogList.unshift(action.payload);
          state.currentRequestId = undefined;
        }
      })
      .addCase(createAdminLogApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

    // PUT
    builder
      .addCase(updateAdminLogApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(updateAdminLogApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          const updateItem = action.payload;
          const index = state.adminLogList.findIndex((item) => item.id === updateItem.id);
          if (index > -1) {
            state.adminLogList[index] = updateItem;
          }
          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })
      .addCase(updateAdminLogApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })
  }
})

// Action creators are generated for each case reducer function
export const { logout } = adminLogSlice.actions;
export default adminLogSlice.reducer;