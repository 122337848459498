import { createAsyncThunk } from '@reduxjs/toolkit';
import { addProvider, deleteProvider, getProviders, updateProvider } from '../../api/provider/provider';


export const getProvidersApi = createAsyncThunk(
  "provider/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getProviders();

      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createProviderApi = createAsyncThunk(
  "provider/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addProvider(data);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProviderApi = createAsyncThunk(
  "provider/update",
  async (data, { rejectWithValue }) => {
    const { updateId, items } = data;
    try {
      const response = await updateProvider(updateId, items);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteProviderApi = createAsyncThunk(
  "provider/delete",
  async (deleteId, { rejectWithValue }) => {
    try {
      const { status } = await deleteProvider(deleteId);
      if (status === 202) return deleteId;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);