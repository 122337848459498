import React from 'react';
import Selector from './Selector';
import { VFCategory, States } from '../../../assets/DispCodes';
import { Col, DatePicker, Row } from 'antd';

const { RangePicker } = DatePicker;

const MenuItem = ({
  uniqueEquipmentList,
  handleFilterChange,
  primaryInsuranceList,
  calledByList
}) => {

  const onRangeChange = (date, dateString) => {
    const selectedRangeArray = dateString ? dateString : []; // Convert dateString to an array, or an empty array if dateString is falsy
    handleFilterChange("Range", selectedRangeArray);
  };

  const callingCompletedChange = (date, dateString) => {
    const selectedDate = dateString ? dateString : ""; // Convert dateString to an array, or an empty array if dateString is falsy
    handleFilterChange("callingCompletedOn", selectedDate);
  }

  const internalFtpDateChange = (date, dateString) => {
    const selectedDate = dateString ? dateString : ""; // Convert dateString to an array, or an empty array if dateString is falsy
    handleFilterChange("internalFtpDateOn", selectedDate);
  }

  const subVfDateChange = (date, dateString) => {
    const selectedDate = dateString ? dateString : ""; // Convert dateString to an array, or an empty array if dateString is falsy
    handleFilterChange("subsequentScanDate", selectedDate);
  }

  const deliveredOnDateChange = (date, dateString) => {
    const selectedDate = dateString ? dateString : ""; // Convert dateString to an array, or an empty array if dateString is falsy
    handleFilterChange("deliveredOn", selectedDate);
  }

  return (
    <>
      <Row justify="space-around" gutter={0}>
        <Col span={24} style={{ marginTop: '5px' }}>
          <RangePicker
            size="small"
            className="w-full"
            onChange={onRangeChange}
            format={"MM/DD/YYYY"}
          />
        </Col>
        <Col span={24} style={{ marginTop: '5px' }}>
          <DatePicker
            size="small"
            className="w-full"
            placeholder={'Calling Completed On'}
            onChange={callingCompletedChange}
            format={"MM/DD/YYYY"}
          />
        </Col>
        <Col span={24} style={{ marginTop: '5px' }}>
          <Selector
            monthWidth={'100%'}
            placeholder={'Status'}
            setUserChange={handleFilterChange}
            options={VFCategory}
          />
        </Col>
        <Col span={24} style={{ marginTop: '5px' }}>
          <Selector
            monthWidth={'100%'}
            placeholder={'State'}
            setUserChange={handleFilterChange}
            options={States}
          />
        </Col>
        <Col span={24} style={{ marginTop: '5px' }}>
          <Selector
            monthWidth={'100%'}
            placeholder={'Product'}
            setUserChange={handleFilterChange}
            options={uniqueEquipmentList}
          />
        </Col>
        <Col span={24} style={{ marginTop: '5px' }}>
          <Selector
            monthWidth={'100%'}
            placeholder={'Insurance'}
            setUserChange={handleFilterChange}
            options={primaryInsuranceList}
          />
        </Col>
        <Col span={24} style={{ marginTop: '5px' }}>
          <Selector
            monthWidth={'100%'}
            placeholder={'Called By'}
            setUserChange={handleFilterChange}
            options={calledByList}
          />
        </Col>
        <Col span={24} style={{ marginTop: '5px' }}>
          <DatePicker
            size="small"
            className="w-full"
            placeholder={'Internal FTP Date'}
            onChange={internalFtpDateChange}
            format={"MM/DD/YYYY"}
          />
        </Col>
        <Col span={24} style={{ marginTop: '5px' }}>
          <DatePicker
            size="small"
            className="w-full"
            placeholder={'Subsequent VF Date'}
            onChange={subVfDateChange}
            format={"MM/DD/YYYY"}
          />
        </Col>
        <Col span={24} style={{ marginTop: '5px' }}>
          <DatePicker
            size="small"
            className="w-full"
            placeholder={'Delivered On'}
            onChange={deliveredOnDateChange}
            format={"MM/DD/YYYY"}
          />
        </Col>
      </Row>
    </>
  );
};

export default MenuItem;