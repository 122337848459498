/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useMemo, useCallback, } from "react";
import { Col, Row, Table, Button, Form, Modal, Dropdown, Tabs, Descriptions, Tooltip, message, Spin, Radio, Alert, Input, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import PiChart from "./PiChart";
import MenuItem from "./MenuItem";
import dayjs from "dayjs";
import CountUp from "react-countup";
import { PrinterTwoTone, FileTwoTone, CompassTwoTone, ScheduleOutlined, SendOutlined, SaveTwoTone } from "@ant-design/icons";
import {
  addNumberToObjectArray, convertObjectToArray, exportToExcel, generatePDF,
} from "../../../utils/utility";
import { useReactToPrint } from "react-to-print";
import SearchInput from "./SearchInput";
import TabPanel from "./ChartDetails/TabPanel"
import CreateScheduleSend from "./CreateScheduleSend";
import ScheduleReport from "./ScheduleReport"
import ModalBox from "../../../components/ModalBox";
import useFilteredCharts from "../../../pagination/useFilteredCharts";
import { InfoCircleOutlined } from '@ant-design/icons';
import ChartInfo from "../../../components/ChartInfo";
import { getExportFile } from "../../../api/exportFile/exportFile"

import {
  setChartDetailView,
  resetChartViewState,
} from '../../../redux/ChartViewManagement/ChartViewManagementSlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({
    Range: null,
    CallingCompletedOn: null,
    Status: null,
    State: null,
    Product: null,
    Insurance: null,
    calledBy: null,
    internalFtpDateOn: null,
    subsequentScanDate: null,
    deliveredOn: null
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [takeReport, setTakeReport] = useState(false);
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  // const [ChartDetailView, setChartDetailView] = useState({ selected: false, data: [] })
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [exportType, setExportType] = useState('excel');
  const [warningVisible, setWarningVisible] = useState(false);
  const role = useSelector((state) => state.userDetails.role);
  const ChartDetailView = useSelector((state) => state.chartViewManagement.chartDetailView);

  const [form] = Form.useForm();
  const componentRef = useRef(null);
  const userProject = useSelector((state) => state.userDetails.project);

  const [getUserProject, setGetUserProject] = useState([]);
  const { charts, setCharts, setFilters, setSearchTerm } = useFilteredCharts("", "", userProject);

  useEffect(() => {
    if (userProject !== null) {
      const getFilteredProject = charts.filter((items) => items?.project?.id === userProject);
      setGetUserProject(getFilteredProject);
    }

    if (userProject === undefined) {
      const getFilteredProject = charts;
      setGetUserProject(getFilteredProject);
    }

  }, [charts, userProject]);

  const filterChart = getUserProject;

  const handleFilterChange = useCallback((filterName, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
    setFilters({ [filterName]: value });
  }, [setFilters]);

  const extractUniqueValues = (filterChart, key) => {
    let uniqueValues = {};
    filterChart.forEach((item) => {
      const value = item[key];
      if (!uniqueValues[value]) {
        uniqueValues[value] = true;
      }
    });
    return Object.keys(uniqueValues)
      ?.filter((value) => value !== undefined)
      ?.map((value) => ({ value, label: value }));
  }; // Empty dependency array since this callback doesn't depend on any values outside its scope

  let filteredData = filterChart;

  let uniqueEquipmentNames = {};

  filterChart.forEach((item) => {
    const equipmentName = item.equipmentName;

    if (!uniqueEquipmentNames[equipmentName]) {
      uniqueEquipmentNames[equipmentName] = true;
    }
  });

  const primaryInsuranceList = useMemo(
    () => extractUniqueValues(filterChart, "primaryInsurance"),
    [filterChart]
  );

  const extractUniqueCallerValues = (filterChart) => {
    let uniqueValues = {};
    filterChart.forEach((item) => {
      const value = item.callingBy?.id;
      const label = item.callingBy?.name;
      if (value && !uniqueValues[value]) {
        uniqueValues[value] = label;
      }
    });

    return Object.keys(uniqueValues)
      .map((value) => ({ value, label: uniqueValues[value] }));
  };

  const calledByList = useMemo(
    () => extractUniqueCallerValues(filterChart),
    [filterChart]
  );

  const filteredDataArray = convertObjectToArray(getUserProject)
  addNumberToObjectArray(filteredDataArray);

  const lmnValidationFields = {
    /*LMN Validation*/
    prescriptionReceivedOn: "Prescription Received On",
    lmnOrderDate: "LMN Order Date",
    prescribingPhysician: "Prescribing Physician",
    npiNumber: "NPI Number",
    npiVerificationMethod: "NPI Verification Method",
    physicianSignature: "Physician Signature",
    physicianPhone: "Physician Phone",
    physicianFax: "Physician Fax",
    hcpcsCode: "HCPCS Code",
    itemEquipmentDescription: "Item/Equipment Description",
    quantity: "Quantity",
    diagnosisCode: "Diagnosis Code(s)",
    lmnStartDate: "Start Date",
    lmnEndDate: "End Date",
    orderType: "Order Type",
    faceToFaceNotes: "Face-to-Face Notes",
    progressNotesMedicalRecords: "Progress Notes/Medical Records",
    patientMedicalHistory: "Patient Medical History",
    sizing: "Sizing",
    customization: "Customization",
    accessoriesSupplies: "Accessories/Supplies",
    hipaaCompliant: "HIPAA Compliant",
    medicareMedicaidCompliant: "Medicare/Medicaid Compliant",
    stateRegulationsCompliant: "State Regulations Compliant",
    additionalClinicalInfoIncluded: "Additional Clinical Info Included",
    resolvable: "Resolvable",
    additionalNotes: "Additional Notes",
    validationStatus: "Validation Status",
    reasonForDenialPending: "Reason for Denial/Pending",
    validationDate: "Validation Date",
  }

  const otherDataFields = {
    /* Patient Details */
    no: "SL.No",
    project: "Facility Name",
    internalFtpDate: "Internal FTP Date",
    receivedOn: "Patient Received On",
    initialSubsequentDate: "Subsequent Scan Date",
    chartId: "UUID",
    patientName: "Patient Name",
    dob: "DOB",
    age: "Age",
    patientPrimaryInsurance: "Primary Insurance #",
    ssnNumber: "SSN #",
    gender: "Gender",
    relationship: "Relationship",
    subscriberName: "Subscriber Name",
    subscriberDob: "Subscriber DOB",
    maritalStatus: "Marital Status",
    receivedAddress: "Address Received in PRF/LMN",
    address: "Address",
    city: "City",
    state: "State",
    zipCode: "Zip",
    patientPhoneNumber: "Home/Phone #",
    patientMailId: "Mail ID",

    /* Insurance Information */

    portalAvailability: "Portal Availability",
    insuranceCardReceivedOn: "Insurance Card Received On",
    insuranceCard: "Insurance Card",
    multiplan: "Multiplan",
    icPayerID: "IC Payer ID",
    primaryInsurance: "Primary Insurance",
    primaryInsuranceState: "Primary Insurance State",
    primaryInsurancePlan: "Primary Insurance Plan",
    primaryInsuranceGroupNumber: "Primary Insurance Group #",
    secondaryInsurance: "Secondary Insurance",
    secondaryInsuranceState: "Secondary Insurance State",
    secondaryInsurancePlan: "Secondary Insurance Plan",
    secondaryInsuranceNumber: "Secondary Insurance #",
    secondaryInsuranceGroupNumber: "Secondary Insurance Group #",

    /* Order Summary */

    vfRequestedEquipmentModel: "VF Requested Equipment Model",
    hcpcsCodes: "HCPCS Codes",
    equipmentName: "Equipment Name",
    babyDueDate: "Baby Due Date",
    weeksDue: "Weeks Due",
    orderDate: "Order Date",
    prescriptionClassification: "Prescription Classification",
    DxCodesModelDetails: "DX Codes",
    orderingPhysician: "Ordering Physician",
    orderingPhysicianNPI: "Ordering Physician NPI",
    orderingPhysicianAddress: "Ordering Physician Address",
    orderingPhysicianPhoneNumber: "Ordering Physician Phn #",
    orderingPhysicianFaxNumber: "Ordering Physician Fax #",
    orderingPhysicianSpeciality: "Ordering Physician Speciality",
    pcp: "PCP",
    pcpNpiNumber: "PCP NPI #",
    pcpAddress: "PCP Address",
    pcpPhoneNumber: "PCP Phn #",
    pcpFaxNumber: "PCP Fax #",
    pcpSpeciality: "PCP Speciality",
    vfTAT: "VF TAT",
    prescriptionReceivedOn: "Prescription Received On",
    verifyCompletedOn: "VF Date",
    hcpcsFormPrescription: "HCPCS From Prescription",
    equipmentModelFromPrescription: "Equipment Model From Prescription",

    /* Coverage Summary  */
    calendarMonth: "Calendar Month",
    coverageStartDate: "Coverage Start Date",
    coverageEndDate: "Coverage End Date",
    networkStatus: "Network Status",
    innBenefits: "INN Benefit's For DME",
    innDedCalendar: "INN Ded Calendar/Actual Amt",
    innDedMetAmount: "INN Ded Met Amt",
    innDedBalanceAmount: "INN Ded Bal Amt",
    innCoins: "INN Coins",
    innOopCalendar: "INN OOP Calendar/Actual Amt",
    innOopMetAmount: "INN OOP Met Amt",
    onnBenefits: "OON Benefits For DME",
    oonDedCalendar: "OON Ded Calendar/Actual Amt",
    oonDedMetAmount: "OON Ded Met Amt",
    ooDedBalanceAmount: "OON Ded Bal Amt",
    oonCoins: "OON Coins",
    oonOopCalendar: "OON OOP Calendar/Actual Amt",
    oonOopMet: "OON OOP Met Amt",
    authRequired: "Auth Required",
    vfCategory: "E&B Status",
    ssReceived: "S&S Received Equipment In the Last 6 Months?",
    consultationStatus: "Prescription Availability",
    vfComments: "VF Comments",
    subsequentScanDate: 'Subsequent VF Date',
    subsequentQuarter: "Subsequent Quarter To Verify",
    transaction: "Transaction ID/SSA",
    callingAssigned: "Calling Assigned Reason",
    callingAdded: "Calling Added On",
    deliveryStatus: "Delivery Status",
    deliveredOn: "Delivered On",

    /* Calling Details  */
    insurancePhoneNumber: "Insurance Phn #",
    pcb: "PCB",
    callingComments: "Calling Comments",
    callRefNumber: "Call Ref #",
    callingPayerId: "Calling Payer ID",
    callMode: "Call Mode",
    operator: "Operator",
    startTime: "Start Time",
    endTime: "End Time",
    ach: "ACH",
    callDispositionCode: "Call Disposition Code",
    crDispositionCode: "CR Disposition Code"
  };

  const chartData = role === "CODING" ? {
    ...otherDataFields,
    ...lmnValidationFields
  } : otherDataFields;


  const onChangeFormat = (value) => {
    setSelectedFormat(value);
    setExportType(value) // Update the selectedFormat state
    if (value === 'pdf') {
      setWarningVisible(true);
    }
    if (value === 'excel') {
      setWarningVisible(false);
    }
  }

  const handleDownload = async (fileName, password, format) => {
    try {
      setLoadings(true);
      const response = await getExportFile(filteredDataArray, chartData, fileName, password, format);
      const fileExtension = format === 'pdf' ? 'pdf' : 'xlsx';
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}.${fileExtension}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      form.resetFields();
      setIsModalVisible(false);
      setLoadings(false);
    } catch (error) {
      setLoadings(false);
      console.error('Error downloading the file:', error);
      message.error('An error occurred while downloading the file.');
    }
  };

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      const { fileName, password } = values;
      if (filteredDataArray.length > 0) {
        handleDownload(fileName, password, exportType);
      }
      else {
        return notification.info({
          message: 'No data found',
          placement: "topLeft",
        });
      }
      // setIsModalVisible(false);
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const extractProductUniqueValues = (filterChart, key) => {
    let uniqueValues = new Set();
    let result = [];

    filterChart.forEach((item) => {
      const values = item[key];

      if (Array.isArray(values)) {
        values.forEach(value => {
          if (typeof value === 'string') {
            // Handle comma-separated lists within arrays
            value.split(',').map(v => v.trim()).forEach(v => {
              if (v && !uniqueValues.has(v)) {
                uniqueValues.add(v);
                result.push({ value: v, label: v });
              }
            });
          }
        });
      } else if (typeof values === 'string') {
        // Handle single comma-separated lists
        values.split(',').map(value => value.trim()).forEach(value => {
          if (value && !uniqueValues.has(value)) {
            uniqueValues.add(value);
            result.push({ value, label: value });
          }
        });
      }
    });

    return result;
  };
  const vfRequestedEquipmentModelDetails = filterChart
    .map((item) => item.vfRequestedEquipmentModelDetails)
    .flat();
  // Use the memoized version
  const uniqueEquipmentList = useMemo(
    () => extractProductUniqueValues(vfRequestedEquipmentModelDetails, "equipmentName"),
    [vfRequestedEquipmentModelDetails]
  );


  // Primary Insurance
  let primaryInsurance = {};
  filterChart.forEach((item) => {
    const primaryInsuranceName = item.primaryInsurance;

    // Check if the primaryInsuranceName is not undefined before adding it to the object
    if (primaryInsuranceName !== undefined) {
      primaryInsurance[primaryInsuranceName] = true;
    }
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Dashboard ${dayjs().format("YYYY-MM-DD")}`,
  });


  const handleDropDown = (e) => {
    if (e.key === "1") {
      setTakeReport(true);
    } else {
      if (charts.length === 0) {
        return message.warning("No Charts Found to Print!")
      }
      handlePrint();
    }
  }
  const getChartView = () => {
    return (
      <TabPanel
        ChartDetailView={ChartDetailView}
        charts={charts}
        setCharts={setCharts}
        setChartDetailView={setChartDetailView}
      />
    )
  }

  const handleExport = (key) => {
    if (key?.key === '1') {
      if (filteredDataArray.length > 0) {
        setIsModalVisible(true)
      }
      else {
        return notification.info({
          message: 'No data found',
          placement: "topLeft",
        });
      }
    }
    else {
      handlePrint();
    }

  }

  const selectedItems = [
    {
      key: '1',
      label: <span className="text-xs">Export As Excel/PDF</span>,
      icon: <FileTwoTone />,
      onClick: handleExport,
    },
    {
      key: '2',
      label: <span className="text-xs">Dashboard Report</span>,
      icon: <SaveTwoTone />,
      onClick: handleDropDown,

    },
  ]

  const columns = [
    {
      title: <div className="text-xs">Verification Status</div>,

      dataIndex: "name",
      key: "name",
      align: "left",
      width: "90%",
      render: (text, record, index) => <div className="text-xs">{text}</div>, // Set the width for the Verification Status column
    },
    {
      title: <div className="text-xs">Record Count</div>,
      dataIndex: "count",
      key: "count",
      align: "center",
      width: "30%",
      render: (text, record, index) => <div className="text-xs">{text}</div>, // Set the width for the Record Count column
    },
  ];

  let totalCount = 0;

  let count = {
    "Moved to PA": 0,
    Approved: 0,
    Approved_Closed: 0,
    "Approved_LMN_VF Closed": 0,
    "Approved_POD_VF Closed": 0,
    "In-Active": 0,
    "Missing Demo/Insurance Info": 0,
    "MR_VF Closed": 0,
    "PA Approved": 0,
    "PA Approved_Closed": 0,
    "PA Approved_POD _VF Closed": 0,
    "PA Denied_Closed": 0,
    "PA Held": 0,
    "PA LMN_VF Closed": 0,
    "PA Pending": 0,
    "Subsequent VF In-Process": 0,
    "VF Closed": 0,
    "VF Denied_Closed": 0,
    "VF Held": 0,
    "VF In-Process": 0,
    "VF Held Closed": 0,
  };

  filterChart.forEach((item) => {
    switch (item.vfCategory) {
      case "Moved to PA":
        count["Moved to PA"]++;
        totalCount++;
        break;
      case "Approved":
        count["Approved"]++;
        totalCount++;
        break;
      case "Approved_Closed":
        count["Approved_Closed"]++;
        totalCount++;
        break;
      case "Approved_LMN_VF Closed":
        count["Approved_LMN_VF Closed"]++;
        totalCount++;
        break;
      case "Approved_POD_VF Closed":
        count["Approved_POD_VF Closed"]++;
        totalCount++;
        break;
      case "In-Active":
        count["In-Active"]++;
        totalCount++;
        break;
      case "Missing Demo/Insurance Info":
        count["Missing Demo/Insurance Info"]++;
        totalCount++;
        break;
      case "MR_VF Closed":
        count["MR_VF Closed"]++;
        totalCount++;
        break;
      case "PA Approved":
        count["PA Approved"]++;
        totalCount++;
        break;
      case "PA Approved_Closed":
        count["PA Approved_Closed"]++;
        totalCount++;
        break;
      case "PA Approved_POD _VF Closed":
        count["PA Approved_POD _VF Closed"]++;
        totalCount++;
        break;
      case "PA Denied_Closed":
        count["PA Denied_Closed"]++;
        totalCount++;
        break;
      case "PA Held":
        count["PA Held"]++;
        totalCount++;
        break;
      case "PA LMN_VF Closed":
        count["PA LMN_VF Closed"]++;
        totalCount++;
        break;
      case "PA Pending":
        count["PA Pending"]++;
        totalCount++;
        break;
      case "Subsequent VF In-Process":
        count["Subsequent VF In-Process"]++;
        totalCount++;
        break;
      case "VF Closed":
        count["VF Closed"]++;
        totalCount++;
        break;
      case "VF Denied_Closed":
        count["VF Denied_Closed"]++;
        totalCount++;
        break;
      case "VF Held":
        count["VF Held"]++;
        totalCount++;
        break;
      case "VF In-Process":
        count["VF In-Process"]++;
        totalCount++;
        break;
      default:
        break;
    }
  });
  // Sending the chartDataList to PiChart
  let multiFilterValue = null;
  let filteredDataCopy = useMemo(() => [...filteredData], [filteredData]); // Make a copy of the original data

  // Initialize an object to store counts
  let counts = {};
  // Filter data based on selected filters
  if (selectedFilters.Year && selectedFilters.Year.length > 0) {
    filteredDataCopy = filteredDataCopy.filter((item) =>
      selectedFilters.Year.includes(dayjs(item.createdAt).year())
    );

  }

  if (selectedFilters.Month && selectedFilters.Month.length > 0) {
    filteredDataCopy = filteredDataCopy.filter((item) => {
      const month = dayjs(item.createdAt).format("MMMM").toUpperCase();
      return selectedFilters.Month.includes(month);
    });

  }

  if (selectedFilters.CallingCompletedOn && selectedFilters.CallingCompletedOn.length > 0) {
    filteredDataCopy = filteredDataCopy.filter((item) => {
      if (!item.callingCompletedOn) {
        return false;
      }
      const completedDate = dayjs(item.callingCompletedOn).format("MM/DD/YYYY");
      return selectedFilters.CallingCompletedOn.includes(completedDate);
    });
  }

  if (selectedFilters.Status && selectedFilters.Status.length > 0) {
    filteredDataCopy = filteredDataCopy.filter((item) =>
      selectedFilters.Status.includes(item.vfCategory)
    );

  }

  if (selectedFilters.State && selectedFilters.State.length > 0) {
    filteredDataCopy = filteredDataCopy.filter((item) =>
      selectedFilters.State.includes(item.state)
    );

  }
  // if (selectedFilters.Product && selectedFilters.Product.length > 0) {
  //   filteredDataCopy = filteredDataCopy.filter((item) =>
  //     selectedFilters.Product.includes(item.equipmentName)
  //   );
  // }

  if (selectedFilters.Insurance && selectedFilters.Insurance.length > 0) {
    filteredDataCopy = filteredDataCopy.filter((item) =>
      selectedFilters.Insurance.includes(item.primaryInsurance)
    );

  }

  // Count occurrences of primaryInsurance key in filtered data
  filteredDataCopy.forEach((item) => {
    const primaryInsurance = item.primaryInsurance; // Use primaryInsurance as the key
    counts[primaryInsurance] = (counts[primaryInsurance] || 0) + 1;
  });


  // Prepare data for multiFilterValue
  multiFilterValue = Object.entries(counts).map(([key, count]) => ({
    key: key,
    name: key,
    count: count,
  }));
  // Ensure uniqueness by removing duplicates
  multiFilterValue = multiFilterValue.filter(
    (value, index, self) => index === self.findIndex((v) => v.key === value.key)
  );

  // Check if filteredDataCopy is empty, then set multiFilterValue to an empty array
  if (filteredDataCopy.length === 0) {
    multiFilterValue = [];
  }
  // Data For passing as props for overall Chart
  const data = Object.entries(count).map(([category, count]) => ({
    key: category,
    name: category,
    // count: parseFloat(((count / totalCount) * 100).toFixed(1)),
    count: count,
  }));

  // Table Datas
  const datas = Object.entries(count).map(([category, count]) => ({
    key: category,
    name: category,
    count: count,
  }));

  // For Bar Chart

  let uniqueYearsMap = {};
  let filterValue = Object.entries(count)
    .filter(([key, val]) => val !== 0)
    .map(([key, val]) => ({ [key]: val }));
  filterValue.forEach((item) => {
    const year = dayjs(item.createdAt).year();
    if (!uniqueYearsMap.hasOwnProperty(year)) {
      uniqueYearsMap[year] = [item];
    } else {
      uniqueYearsMap[year].push(item);
    }
  });

  let allData = []; // Initialize allData array to store the final result

  // Define the header row with status categories

  let headerRow = ["Year"];
  filterValue.forEach((obj) => {
    headerRow.push(...Object.keys(obj));
  });

  allData.push(headerRow);
  // Push the header row into allData

  // Iterate through uniqueYearsMap
  Object.entries(uniqueYearsMap).forEach(([year, items]) => {
    let yearData = [year.toString()]; // Initialize yearData array with the year as the first element

    // Count occurrences of each status for the current year
    let statusCounts = {};
    filterValue.forEach((obj) => {
      // Initialize count for each status category
      Object.keys(obj).forEach((category) => {
        statusCounts[category] = 0;
      });
    });

    items.forEach((item) => {
      // Update count for each status category
      Object.keys(item).forEach((category) => {
        if (statusCounts.hasOwnProperty(category)) {
          statusCounts[category] += item[category];
        }
      });
    });

    // Push the counts of each status into yearData array
    filterValue.forEach((obj) => {
      Object.keys(obj).forEach((category) => {
        yearData.push(statusCounts[category]);
      });
    });

    // Push yearData array into allData array
    allData.push(yearData);
  });
  const SearchFilterData = useMemo(() => {
    if (!filterChart) return [];

    const lowerSearchQuery = searchQuery ? searchQuery.toLowerCase() : null;
    if (!lowerSearchQuery) return filterChart;

    const searchTerms = new Set(lowerSearchQuery.split(/\s+/));

    return filterChart.filter(item => {
      const name = item.patientName?.toLowerCase() || '';
      const modelPatientDetails = item.phoneMailEquipmentModels || [];
      const phone = modelPatientDetails.map(val => val.phoneNumber);
      const email = modelPatientDetails.map(val => val.mailId.toLowerCase());

      const modelDetails = item.vfRequestedEquipmentModelDetails || [];
      const hcpcsCodes = modelDetails.map(val => val.hcpcsCodes.toLowerCase());
      const equipmentNames = modelDetails.map(val => val.equipmentName.toLowerCase());

      const fields = [name, ...email, ...phone, ...hcpcsCodes, ...equipmentNames];

      return fields.some(field =>
        Array.from(searchTerms).some(term => field.includes(term))
      );
    });
  }, [filterChart, searchQuery]);


  return (
    <>
      <div className="grid grid-cols-3 grid-rows-1 gap-3">

        <div className="h-3/6">
          <SearchInput
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setSearchTerm={setSearchTerm}
            filterData={SearchFilterData && SearchFilterData.length > 0 ? SearchFilterData : null}
            searchField={"Search Name, Phone, HCPCS Codes and Email Chart"}
            setChartDetailView={setChartDetailView}
            ChartDetailView={ChartDetailView}
          />
          <>
            {ChartDetailView.selected &&
              <ModalBox
                open={ChartDetailView}
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Chart Overview
                    <Tooltip title="View Chart Info">
                      <InfoCircleOutlined
                        style={{ marginLeft: 8, cursor: 'pointer' }}
                        onClick={() => {
                          setIsInfoVisible(!isInfoVisible);
                        }}
                      />
                    </Tooltip>
                  </div>
                }
                width="xl"
                content={
                  <>
                    {getChartView()}
                  </>
                }
                onCancel={() => {
                  dispatch(resetChartViewState()); // Reset all states on close
                  // setChartDetailView({
                  //   selected: false,
                  //   data: [],
                  // });
                  setSearchQuery('')
                }}

                footer={null}

              >
              </ModalBox>}
          </>
        </div>
        <div>

        </div>
        <div className="flex justify-end items-center space-x-2" >
          {role !== "GUEST" && <Dropdown
            menu={{ items: selectedItems }}
            placement="bottom"
            size="small"
            className="w-[32%]"
          >
            <Button type="primary" size="small">   <span className="text-xs text-start">Export View</span></Button>
          </Dropdown>}
        </div>
      </div>
      <div ref={componentRef}>
        <Row gutter={8} className="max-w-[100vw] h-full mt-2">
          <Col
            className="border-slate-100 h-full  xs:w-full sm:w-full md:w-1/5 lg:w-1/5 xl:w-1/5"
            xs={24}
            sm={24}
            md={5}
            lg={5}
            xl={6}
          >
            <div className="text-center w-full h-full shadow bg-slate-200 rounded p-2">
              <h1 className="text-xs font-semibold">
                Total # of Patients in Verification Repository 2024{" "}
              </h1>
              <div className="font-black text-xl">
                <span className="font-black text-sm text-gray-800">
                  <CountUp end={filterChart.length} duration={2} />
                </span>
              </div>
            </div>
            <div className="max-w-[100%] h-[10%] mt-2 ">
              <Table
                columns={columns}
                dataSource={datas}
                pagination={false}
                scroll={{ y: 100 }}
                size="small"
                width="5"
                footer={() => (
                  <div className="text-right m-auto ">
                    <span className="text-xs font-semibold">Grand Total</span>:{" "}
                    <CountUp
                      className="text-xs"
                      end={filterChart.length}
                      duration={2}
                    />
                  </div>
                )}
                locale={{
                  emptyText: (
                    <div style={{ textAlign: "center" }}>
                      <div>Data Not Found</div>
                    </div>
                  ),
                }}
              />
            </div>
          </Col>
          <Col
            className="border-slate-10 mt-2 h-full xs:w-full sm:w-full md:w-4/5 lg:w-4/5 xl:w-4/5 "
            xs={24}
            sm={24}
            md={19}
            lg={19}
            xl={18}
          >
            <Row className="border  border-slate-100  rounded h-full">
              <Col className="flex justify-center " span={7}>
                {" "}
                <MenuItem
                  selectedFilters={selectedFilters}
                  handleFilterChange={handleFilterChange}
                  setSelectedFilters={setSelectedFilters}
                  uniqueEquipmentList={uniqueEquipmentList}
                  primaryInsuranceList={primaryInsuranceList}
                  calledByList={calledByList}
                />
              </Col>
              <Col span={17}>
                {" "}
                {modal2Open ? (
                  <>
                    <Modal
                      title="Full Screen Pie Chart"
                      style={{ top: 0 }}
                      bodyStyle={{ padding: 0, height: "100vh" }}
                      open={modal2Open}
                      onCancel={() => setModal2Open(false)}
                      width="100%"
                      footer={null} // Optional: Hide default footer
                      className="fixed top-0 left-0 w-full h-full overflow-auto"
                    >
                      {/* Your Chart or Content Here */}

                      <PiChart
                        piModalOpen={true}
                        chartType="PieChart"
                        monthData={multiFilterValue}
                        data={data}
                      />
                    </Modal>
                  </>
                ) : (
                  <>
                    <div className="text-end">

                      <CompassTwoTone onClick={() => setModal2Open(true)} />
                    </div>
                    <PiChart
                      chartType="PieChart"
                      monthData={multiFilterValue}
                      data={data}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="w-full h-full  ">
              {allData.length === 0 ? (
                <div className="text-center">
                  <div>
                    <h1 className="font-black text-1.5xl text-gray-800">
                      No Data
                    </h1>
                  </div>
                </div>
              ) : (
                <>
                  {modal1Open ? (
                    <Modal
                      title="Full Screen Bar Chart"
                      style={{ top: 0 }}
                      bodyStyle={{ padding: 0, height: "100vh" }}
                      open={modal1Open}
                      onCancel={() => setModal1Open(false)}
                      width="100%"
                      footer={null} // Optional: Hide default footer
                      className="fixed top-0 left-0 w-full h-full overflow-auto"
                    >
                      {/* Your Chart or Content Here */}

                      <PiChart
                        modalOpen={true}
                        chartType="Bar"
                        monthData={multiFilterValue}
                        data={data}
                      />
                    </Modal>
                  ) : (
                    <>
                      <div className="text-end">
                        <CompassTwoTone onClick={() => setModal1Open(true)} />
                      </div>
                      <PiChart
                        chartType="Bar"
                        monthData={multiFilterValue}
                        data={data}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
        {isInfoVisible && <ChartInfo
          isInfoVisible={isInfoVisible}
          setIsInfoVisible={setIsInfoVisible}
          chartData={ChartDetailView?.data}
        />}
        <Modal
          title="Export Options"
          visible={isModalVisible}
          onOk={handleModalOk}
          onCancel={() => {
            if (!loadings) {  // Prevent closing if loading
              setIsModalVisible(false);
              form.resetFields();
            }
          }}
          confirmLoading={loadings}
          maskClosable={!loadings} // Prevent closing by clicking outside
          closable={!loadings}     // Hide the close button on the top right
          keyboard={!loadings}     // Prevent closing with the ESC key
        >
          <Spin spinning={loadings} tip="Processing your download...">

            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
            >
              <input type="text" name="username" style={{ display: 'none' }} />
              <input type="password" name="password" style={{ display: 'none' }} />
              <Form.Item
                name="exportType"
                label="Select Export Format"
                initialValue="excel"
              >
                <Radio.Group
                  onChange={(e) => onChangeFormat(e.target.value)}
                  value={selectedFormat}
                >
                  <Radio value="excel">Excel</Radio>
                  <Radio value="pdf">PDF</Radio>
                </Radio.Group>
                {selectedFormat && (
                  <small className="mt-10">
                    Current selected format: <span className="font-bold">{selectedFormat === "excel" ? "Excel" : "PDF"}</span>
                  </small>
                )}
                {warningVisible && (
                  <Alert
                    size="small"
                    className="mt-1 text-xs"
                    message="Note: PDF format may not support all field data"
                    type="warning"
                    showIcon
                  />
                )}
              </Form.Item>
              <Form.Item
                name="fileName"
                label="File Name"
                rules={[{ required: true, message: 'Please enter a file name' }]}
              >
                <Input placeholder="Enter file name" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password (Optional)"
              >
                <Input.Password placeholder="Enter password (optional)" autoComplete="new-password" />
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </div>
    </>
  );
};

export default Dashboard;