import React, { lazy, Suspense } from 'react';
import {
  Routes,
  Route,
  BrowserRouter
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

import SignIn from './pages/SignIn/SignIn.js';
import SignUp from './pages/SignUp/SignUp.js';
import Loading from './components/Loading.js';
import BatchConfirmPage from './pages/BatchConfirmPage/BatchConfirmPage.js';
import NotificationCenter from './pages/Notification/NotificationCenter.js';
import ProviderIndex from './pages/Provider/Index.js';
import ProviderSignInPage from './pages/Provider/ProviderSignInPage.js';
import ProtectorRoute from './utils/ProtectedRoutes/ProtectorRoute.js';

const Chart = lazy(() => import('./pages/Chart/Index.js'));
const SuperAdmin = lazy(() => import('./pages/SuperAdmin/Index.js'));
const Admin = lazy(() => import('./pages/Admin/Index.js'));
const UsersIndex = lazy(() => import('./pages/Users/Index.js'));

const App = () => {
  const { isLoading } = useSelector((state) => state.loading);

  return (
    <>
      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar={false}
      />
      <BrowserRouter>
        <Suspense fallback={<Loading spinning={isLoading} />}>
          <Routes>
            <Route exact path="/" element={<SignIn />} />
            <Route exact path="/providerLogin" element={<ProviderSignInPage />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route exact path="/provider/*" element={<ProviderIndex />} />
            <Route path="/chart" element={<Chart />} />
            <Route path="/super-admin/*" element={<ProtectorRoute allowedRoles={['SUPER ADMIN', 'GUEST']}> <SuperAdmin /></ProtectorRoute>} />
            <Route path="/admin/*" element={<ProtectorRoute allowedRoles={['ADMIN', 'GUEST']}> <Admin /></ProtectorRoute>} />
            <Route path="/batchPage/*" element={<BatchConfirmPage />} />
            <Route path="/notification" element={<NotificationCenter />} />
            <Route path="/users/*" element={<ProtectorRoute allowedRoles={['VERIFICATION', 'AUDIT', 'VERIFICATION CALLER', 'AUDIT CALLER', 'CODING']}><UsersIndex /></ProtectorRoute>} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      <Loading spinning={isLoading} />
    </>
  );
}

export default App;
