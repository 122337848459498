import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSuperAdminLog, createSuperAdminLog } from '../../api/superAdminLog/superAdminLog';

export const getSuperAdminLogsApi = createAsyncThunk(
  "superAdminLog/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getSuperAdminLog();
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createSuperAdminLogApi = createAsyncThunk(
  "superAdminLog/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createSuperAdminLog(data);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);


