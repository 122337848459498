import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
}

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    // add your non-async reducers here
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { startLoading, stopLoading, setLoading } = loadingSlice.actions;

export default loadingSlice.reducer;