import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getRoles,
  addRole,
  updateRole,
  deleteRole
} from '../../api/role/role';

export const getRolesApi = createAsyncThunk(
  "role/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getRoles();
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createRoleApi = createAsyncThunk(
  "role/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addRole(data);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateRoleApi = createAsyncThunk(
  "role/update",
  async (data, { rejectWithValue }) => {
    const { updateId, items } = data;
    try {
      const response = await updateRole(updateId, items);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteRoleApi = createAsyncThunk(
  "role/delete",
  async (deleteId, { rejectWithValue }) => {
    try {
      const { status } = await deleteRole(deleteId);
      if (status === 202) return deleteId;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);