import { createAsyncThunk } from '@reduxjs/toolkit';
import {

  getExclusiveDocument,
  createExclusiveChart,
  updateExclusiveDocument,
  deleteExclusiveDocument
} from '../../api/Exclusion/exclusion.js';

export const getExclusiveDocumentApi = createAsyncThunk(
  "exclusionDocument/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getExclusiveDocument();
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);


export const createExclusionChartApi = createAsyncThunk(
  "exclusionDocument/createChart",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createExclusiveChart(data);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateExclusiveChartApi = createAsyncThunk(
  "exclusionDocument/updateChart",
  async (data, { rejectWithValue }) => {
    const { updateId, items } = data;
    try {
      const response = await updateExclusiveDocument(updateId, items);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
)
export const deleteExclusiveApi = createAsyncThunk(
  "exclusionDocument/DeleteChart",
  async (data, { rejectWithValue }) => {
    const { documentId, updateId } = data;

    try {
      const response = await deleteExclusiveDocument(updateId, documentId);
      // const { status } = await deleteExclusiveDocument(updateId, documentId);
      if (documentId === "deleteDocument") {

        return updateId
      }
      else {
        return response.data
      }

      // if (status === 202) return response.data;;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
)

