import React from 'react'
import ModalBox from '../../components/ModalBox';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setLoading } from '../../redux/loading/loadingSlice';
import { deleteProviderApi } from '../../redux/provider/providerService';
import { axiosErrorHandling } from '../../utils/utility';

const DeleteProviderUser = ({
  deleteStatus,
  setDeleteStatus,
  deleteData,
  setDeleteData
}) => {
  const dispatch = useDispatch();

  const getContent = () => {
    let content = '';

    if (deleteData) {
      content = <p>Are you sure to delete this user <strong>{deleteData.name}</strong> ?</p>
    } else {
      content = <p>Are you sure to delete this user?</p>
    }
    return (
      <div className="w-full text-center">
        {content}
      </div>
    )
  }

  const onSubmitDeleteUser = async () => {
    try {
      if (!deleteData.id) {
        return toast.warning('Invalid id!');
      }

      setDeleteStatus(false);
      dispatch(setLoading(true));

      await dispatch(deleteProviderApi(deleteData.id)).unwrap();

      dispatch(setLoading(false));
      toast.success('User deleted successfully!');
      setDeleteData(null);

    } catch (error) {
      dispatch(setLoading(false));
      return axiosErrorHandling(error);
    }
  }
  return (
    <>
      {
        deleteStatus && (
          <ModalBox
            open={deleteStatus}
            title='Confirmation'
            width='sm'
            content={getContent()}
            onSubmitTitle='Yes'
            onSubmit={onSubmitDeleteUser}
            onCancelTitle='No'
            onCancel={() => {
              setDeleteStatus(false);
              setDeleteData(null);
            }}
          />
        )
      }
    </>
  )
}

export default DeleteProviderUser;
