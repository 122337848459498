// src/redux/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState: {
    userId: null,
    role: null,
    project: null,
    userProject: null,
    batchNo: null,
    currentChartId: null,
    wholeBatchNo: null,
    userAccess: null,
    timeZone: null,
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.userId = action.payload.userId;
      state.role = action.payload.role;
      state.project = action.payload.project;
      state.userProject = action.payload.userProject;
      state.batchNo = action.payload.batchNo;
      state.currentChartId = action.payload.currentChartId;
      state.wholeBatchNo = action.payload.wholeBatchNo;
      state.userAccess = action.payload.userAccess;
      state.timeZone = action.payload.timeZone;
    },
    clearUserDetails: (state) => {
      state.userId = null;
      state.role = null;
      state.project = null;
      state.userProject = null;
      state.batchNo = null;
      state.currentChartId = null;
      state.wholeBatchNo = null;
      state.userAccess = null;
      state.timeZone = null;
    },
  },
});

export const { setUserDetails, clearUserDetails } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
