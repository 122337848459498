import api from "../../api";

const baseUrl = "/api/provider";

export const getProviders = () => {
  return api.get(baseUrl);
};

export const addProvider = (postData) => {
  return api.post(baseUrl, postData);
};

export const updateProvider = (updateId, putData) => {
  return api.put(`${baseUrl}/${updateId}`, putData);
};

export const deleteProvider = (deleteId) => {
  return api.delete(`${baseUrl}/${deleteId}`);
};