import api from "../../api";

const baseUrl = "/api/userLog";

export const getUserLogs = () => {
  return api.get(baseUrl);
};

export const addUserLog = (postData) => {
  return api.post(baseUrl, postData);
};

export const updateUserLog = (updateId, putData) => {
  return api.put(`${baseUrl}/${updateId}`, putData);
};

export const deleteUserLog = (deleteId) => {
  return api.delete(`${baseUrl}/${deleteId}`);
};

export const getByUserLogs = () => {
  return api.post(`${baseUrl}/userLogs`)
};

export const trackLogIn = (userId, role) => {
  return api.post(`${baseUrl}/trackLogin`, ({ userId, role }));
};

export const trackLogOut = (userId, role) => {
  return api.post(`${baseUrl}/trackLogOut`, ({ userId, role }));
};

export const getUserLog = (userId, dateRange) => {
  return api.post(`${baseUrl}/userLog`, ({ userId, dateRange }))
};

export const getAdminUserLog = (role) => {
  return api.post(`${baseUrl}/adminUserLog`, ({ role }))
};

export const getUserCompletedCount = (userId, role, batchNo) => {
  return api.post(`${baseUrl}/getCompletedCount`, ({ userId, role, batchNo }))
};

export const getSubsequentUserCompletedCount = (userId, role, batchNo) => {
  return api.post(`${baseUrl}/getSubsequentCompletedCount`, ({ userId, role, batchNo }))
};

export const trackUserAction = (userId, actionType, actionDetails, role) => {
  return api.post(`${baseUrl}/trackAction`, ({ userId, actionType, actionDetails, role }))
};
