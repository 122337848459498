import React, { memo, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Button, Form, Input, Select, DatePicker } from 'antd';
import dayjs from 'dayjs';
import {
  axiosSubmitErrorHandling,
  formattedDate,
  getIpAddress,
  isEmptyObject,
  isObject,
  trimObjValues,
} from '../../utils/utility';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/loading/loadingSlice';
import { updateChartApi } from '../../redux/chart/chartService';
import {
  BenefitsDME,
  CalendarMonth,
  CallingAssignedReason,
  ConsultationStatus,
  NetworkStatus,
  SsReceivedEquipment,
  VFCategory,
} from '../../assets/DispCodes';
import TextArea from 'antd/es/input/TextArea';
import _ from 'lodash';
const dateFormat = 'MM/DD/YYYY';



const CoverageDetails = ({
  chartDetail,
  setSubmitChartStatus,
  setSubmitChartData,
  setCoverageDetailsSaved,
  // isPatientDetailsSaved,
  // isInsuranceDetailsSaved,
  // isProductDetailsSaved,
  // isCoverageDetailsSaved,
  setActiveSubTab,
  callerSpaceToggle,
  setCallerSpaceToggle,
  isFromUpdate,
  defaultSize,
  formRef,
  charts,
  setCharts,
  setChartDetailView,
  documentViewer,
  refreshCharts
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [showCoverageStartField, setShowCoverageStartField] = useState(false);
  const [coverageStartDate, setCoverageStartDate] = useState(null);
  const [showCoverageEndField, setShowCoverageEndField] = useState(false);
  const [deliveredOnField, setDeliveredOnField] = useState(false);
  const [coverageEndDate, setCoverageEndDate] = useState(null);
  const role = useSelector((state) => state.userDetails.role);
  const userId = useSelector((state) => state.userDetails.userId);
  const EditAccess = (role === "PROVIDER" || role === "USER" || role === "CODING" || role === "GUEST") ? false : true;
  const isCoverageDetailsSaved = useSelector((state) => state.chartViewManagement.isCoverageDetailsSaved);
  const isPatientDetailsSaved = useSelector((state) => state.chartViewManagement.isPatientDetailsSaved);

  const isInsuranceDetailsSaved = useSelector((state) => state.chartViewManagement.isInsuranceDetailsSaved);

  const isProductDetailsSaved = useSelector((state) => state.chartViewManagement.isProductDetailsSaved);

  // const callerSpaceToggle = useSelector((state) => state.chartViewManagement.callerSpaceToggle);

  const colProps = documentViewer
    ? { xl: 24, lg: 12, md: 12, sm: 12, xs: 12 }
    : { xl: 6, lg: 6, md: 8, sm: 12, xs: 24 }; // Adjust based on your preferred layout

  useEffect(() => {
    if (!chartDetail) {
      return;
    }

    const coverageInitialValue = {
      /* Coverage Detail Initial Values */
      calendarMonth: chartDetail?.calendarMonth || null,
      networkStatus: chartDetail?.networkStatus || null,
      innBenefits: chartDetail?.innBenefits || null,
      innDedCalendar: chartDetail?.innDedCalendar || null,
      innDedMetAmount: chartDetail?.innDedMetAmount || null,
      innOopCalendar: chartDetail?.innOopCalendar || null,
      innDedBalanceAmount: chartDetail?.innDedBalanceAmount || null,
      innCoins: chartDetail?.innCoins || null,
      innOopMetAmount: chartDetail?.innOopMetAmount || null,
      onnBenefits: chartDetail?.onnBenefits || null,
      oonDedCalendar: chartDetail?.oonDedCalendar || null,
      oonDedMetAmount: chartDetail?.oonDedMetAmount || null,
      ooDedBalanceAmount: chartDetail?.ooDedBalanceAmount || null,
      oonCoins: chartDetail?.oonCoins || null,
      oonOopCalendar: chartDetail?.oonOopCalendar || null,
      oonOopMet: chartDetail?.oonOopMet || null,
      authRequired: chartDetail?.authRequired || null,
      vfCategory: chartDetail?.vfCategory || null,
      ssReceived: chartDetail?.ssReceived || null,
      consultationStatus: chartDetail?.consultationStatus || null,
      vfComments: chartDetail?.vfComments || '',
      subsequentScanDate: chartDetail?.subsequentScanDate ? chartDetail?.subsequentScanDate !== "N/A" ? formattedDate(chartDetail.subsequentScanDate) : null : null,
      subsequentQuarter: chartDetail?.subsequentQuarter || null,
      transaction: chartDetail?.transaction || null,
      callingAssigned: chartDetail?.callingAssigned || null,
      callingAdded: chartDetail?.callingAdded ? dayjs(chartDetail.callingAdded) : null,
      deliveryStatus: chartDetail?.deliveryStatus || null,
      deliveredOn: chartDetail?.deliveredOn ? formattedDate(chartDetail.deliveredOn) : null,
      internalNotes: chartDetail?.internalNotes ? chartDetail.internalNotes : ''
    };

    if (
      coverageInitialValue?.vfCategory?.toLowerCase() === "VF In-Process"?.toLowerCase() || coverageInitialValue?.hasOwnProperty('callingBy') ||
      coverageInitialValue?.vfCategory?.toLowerCase() === "Missing Demo/Insurance Info"?.toLowerCase() || coverageInitialValue?.vfCategory?.toLowerCase() === "In-Active"?.toLowerCase() ||
      coverageInitialValue?.vfCategory?.toLowerCase() === "Subsequent VF In-Process"?.toLowerCase()
    ) {
      dispatch(setCallerSpaceToggle(true));
      //  setCallerSpaceToggle(true);
    } else if (coverageInitialValue?.hasOwnProperty('callingBy')) {
      dispatch(setCallerSpaceToggle(true));
      //  setCallerSpaceToggle(true);
    }

    if (chartDetail?.coverageStartDate && chartDetail?.coverageStartDate !== "Invalid Date" && chartDetail?.coverageStartDate !== "U/A" && chartDetail?.coverageStartDate !== "N/A") {
      const putCoverageStartDate = chartDetail?.coverageStartDate ? dayjs(chartDetail.coverageStartDate) : ''
      setCoverageStartDate(putCoverageStartDate);
      setShowCoverageStartField(true);
    } else {
      setShowCoverageStartField(false);
      setCoverageStartDate(null);
    }

    if (chartDetail?.coverageEndDate && chartDetail?.coverageEndDate !== "Invalid Date" && chartDetail?.coverageEndDate !== "U/A" && chartDetail?.coverageEndDate !== "N/A") {
      const putCoverageEndDate = chartDetail?.coverageEndDate ? dayjs(chartDetail.coverageEndDate) : ''
      setCoverageEndDate(putCoverageEndDate);
      setShowCoverageEndField(true);
    } else {
      setShowCoverageEndField(false);
      setCoverageEndDate(null);
    }

    if (chartDetail?.deliveryStatus && chartDetail?.deliveryStatus === "YES") {
      const putDeliveredOn = chartDetail?.deliveredOn ? formattedDate(chartDetail.deliveredOn) : null
      form.setFieldsValue({
        deliveredOn: putDeliveredOn,
      });
      setDeliveredOnField(true);
    }
    else {
      form.setFieldsValue({
        deliveredOn: null,
      });
      setDeliveredOnField(false);
    }

    form.setFieldsValue(coverageInitialValue);
  }, [chartDetail, dispatch, form, setCallerSpaceToggle]);

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.innDedCalendar !== undefined) {
      if (allValues.innDedCalendar === "N/A") {
        form.setFieldsValue({
          innDedMetAmount: "N/A",
          innDedBalanceAmount: "N/A",
        });
      } else {
        const innDedCalendar = parseFloat(allValues.innDedCalendar) || 0;
        const innDedMetAmount = parseFloat(allValues.innDedMetAmount) || 0;
        const innDedBalanceAmount = innDedCalendar - innDedMetAmount;
        form.setFieldsValue({
          innDedBalanceAmount: innDedBalanceAmount,
        });
      }
    } else if (changedValues.innDedMetAmount !== undefined) {
      const innDedCalendar = parseFloat(allValues.innDedCalendar) || 0;
      const innDedMetAmount = parseFloat(allValues.innDedMetAmount) || 0;
      const innDedBalanceAmount = innDedCalendar - innDedMetAmount;
      form.setFieldsValue({
        innDedBalanceAmount: innDedBalanceAmount,
      });
    }


    // Handle oonDedCalendar logic
    if (changedValues.oonDedCalendar !== undefined) {
      if (allValues.oonDedCalendar === "N/A") {
        form.setFieldsValue({
          oonDedMetAmount: "N/A",
          ooDedBalanceAmount: "N/A",
        });
      } else {
        const oonDedCalendar = parseFloat(allValues.oonDedCalendar) || 0;
        const oonDedMetAmount = parseFloat(allValues.oonDedMetAmount) || 0;
        const oonDedBalanceAmount = oonDedCalendar - oonDedMetAmount;
        form.setFieldsValue({
          ooDedBalanceAmount: oonDedBalanceAmount,
        });
      }
    } else if (changedValues.oonDedMetAmount !== undefined) {
      const oonDedCalendar = parseFloat(allValues.oonDedCalendar) || 0;
      const oonDedMetAmount = parseFloat(allValues.oonDedMetAmount) || 0;
      const oonDedBalanceAmount = oonDedCalendar - oonDedMetAmount;
      form.setFieldsValue({
        ooDedBalanceAmount: oonDedBalanceAmount,
      });
    }

    // Handle Delivery Status logic
    if (changedValues.deliveryStatus !== undefined) {
      if (allValues.deliveryStatus === 'YES') {
        form.setFieldsValue({
          deliveredOn: chartDetail?.deliveredOn ? formattedDate(chartDetail.deliveredOn) : null, // Set to current date
        });
        setDeliveredOnField(true);
      } else if (allValues.deliveryStatus === 'NO') {
        form.setFieldsValue({
          deliveredOn: null, // Set to null
        });
        setDeliveredOnField(false);
      }
    }
  };

  //Save Coverage Summary
  const onFinish = async (values) => {
    try {

      const items = { ...values };

      // Ensure subsequentScanDate array is converted to a comma-separated string
      if (Array.isArray(items.subsequentScanDate)) {
        items.subsequentScanDate = items.subsequentScanDate
          ?.filter(date => date !== null) // Filter out null values
          ?.map(date => dayjs(date).format('MM/DD/YYYY')) // Format each date
          ?.join(','); // Join into a single string
      } else {
        if (items.subsequentScanDate && typeof items.subsequentScanDate === 'object' && !Array.isArray(items.subsequentScanDate)) {
          // Handle case where insuranceDate is an object
          items.subsequentScanDate = dayjs(items.subsequentScanDate).format('MM/DD/YYYY');
        }
      }
      if (items.subsequentScanDate) {
        items.subsequentCheck = true
        items.subsequentVerificationStatus = "Pending";
      }


      items.userRole = role;

      if (coverageStartDate === "Invalid Date") {
        items.coverageStartDate = "U/A"
      }

      if (!showCoverageStartField && !coverageStartDate) {
        items.coverageStartDate = "N/A"
      }

      if (coverageStartDate && coverageStartDate !== "Invalid Date") {
        items.coverageStartDate = coverageStartDate
      }

      if (coverageEndDate === "Invalid Date") {
        items.coverageEndDate = "U/A"
      }

      if (!showCoverageEndField && !coverageEndDate) {
        items.coverageEndDate = "N/A"
      }

      if (coverageEndDate && coverageEndDate !== "Invalid Date") {
        items.coverageEndDate = coverageEndDate
      }

      if (Array.isArray(items.deliveredOn)) {
        items.deliveredOn = items.deliveredOn
          .filter(date => date !== null) // Filter out null values
          .map(date => dayjs(date).format('MM/DD/YYYY')) // Format each date
          .join(','); // Join into a single string
      } else {
        items.deliveredOn = null
      }

      items.userId = userId;
      const ipAddress = await getIpAddress();
      items.ipAddress = ipAddress;
      if (coverageStartDate && typeof coverageStartDate === 'object' && !Array.isArray(coverageStartDate)) {
        // Handle case where insuranceDate is an object
        items.coverageStartDate = dayjs(coverageStartDate).format('MM/DD/YYYY');
      } else {
        items.coverageStartDate = coverageStartDate;
      }
      if (coverageEndDate && typeof coverageEndDate === 'object' && !Array.isArray(coverageEndDate)) {
        // Handle case where insuranceDate is an object
        items.coverageEndDate = dayjs(coverageEndDate).format('MM/DD/YYYY');
      } else {
        items.coverageEndDate = coverageEndDate;
      }

      if (!isObject(items)) {
        return toast.warning('This is not object data!');
      }

      if (isEmptyObject(items)) {
        return toast.warning('Empty object cannot accept!');
      }

      trimObjValues(items);
      dispatch(setLoading(true));

      const updateId = chartDetail.id;
      dispatch(setCoverageDetailsSaved(true));
      if (callerSpaceToggle) {
        setActiveSubTab('5');
        // setActiveSubTab('6');
      }
      const res = await dispatch(updateChartApi({ updateId, items })).unwrap();
      const rowIndex = _.findIndex(charts, { id: updateId });
      if (rowIndex > -1) {
        let updatedCharts = [...charts];
        updatedCharts[rowIndex] = { ...updatedCharts[rowIndex], ...res };
        setCharts(updatedCharts);
      }
      // form.resetFields();  // This will clear all fields in the form

      if (typeof setChartDetailView === 'function') {
        setChartDetailView((prevView) => ({

          ...prevView,  // Keep the rest of the view intact
          data: {
            ...prevView.data, // Merge the current data
            ...res,  // Override with updated response from the API
          },
          selected: true,  // Ensure the chart is still selected
        }));
      }

      dispatch(setLoading(false));
      toast.success('Coverage details updated successfully!');
    } catch (error) {
      dispatch(setLoading(false));
      return axiosSubmitErrorHandling(error);
    }
  };

  const disabledDate = (current) => {
    // Get the current date and year
    const currentDate = dayjs();
    const currentYear = currentDate.year();

    // Disable dates after the current year or after the current date
    return current && (dayjs(current).year() > currentYear || dayjs(current).isAfter(currentDate, 'day'));
  };

  //Submit whole chart
  const handleSubmitChart = async () => {

    if (
      isPatientDetailsSaved &&
      isInsuranceDetailsSaved &&
      isProductDetailsSaved &&
      isCoverageDetailsSaved
    ) {
      const updateId = chartDetail.id;
      setSubmitChartStatus(true);
      setSubmitChartData(updateId);

    } else if (!isPatientDetailsSaved) {
      toast.warning('Please save the Patient Details!');
    } else if (!isInsuranceDetailsSaved) {
      toast.warning('Please save the Insurance Information!');
    } else if (!isProductDetailsSaved) {
      toast.warning('Please save the Order Summary!');
    } else if (!isCoverageDetailsSaved) {
      toast.warning('Please save the Coverage Summary!');
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const onChange = (value) => {
    // console.log(`selected ${value}`);
  };

  const onChangeVFStatus = (value) => {
    if (
      value?.toLowerCase() === "VF In-Process"?.toLowerCase() ||
      role === "AUDIT CALLER" ||
      role === "VERIFICATION CALLER" ||
      chartDetail?.hasOwnProperty('callingBy') ||
      value?.toLowerCase() === "Missing Demo/Insurance Info"?.toLowerCase() ||
      value?.toLowerCase() === "In-Active"?.toLowerCase() ||
      value?.toLowerCase() === "Subsequent VF In-Process"?.toLowerCase()
    ) {
      dispatch(setCallerSpaceToggle(true));
      // setCallerSpaceToggle(true);
    }
    else {
      dispatch(setCallerSpaceToggle(false));
      // setCallerSpaceToggle(false);
    }
  };

  const onSearch = (value) => {
    // console.log('search:', value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase());

  const onChangeDatePicker = (date, dateString) => {
    if (date) {
      const month = dayjs(date).month() + 1; // month() is 0-indexed, so add 1
      let quarter = '';

      if (month >= 1 && month <= 3) {
        quarter = 'Q1';
      } else if (month >= 4 && month <= 6) {
        quarter = 'Q2';
      } else if (month >= 7 && month <= 9) {
        quarter = 'Q3';
      } else if (month >= 10 && month <= 12) {
        quarter = 'Q4';
      }

      form.setFieldsValue({ subsequentQuarter: quarter });
    } else {
      form.setFieldsValue({ subsequentQuarter: null });
    }
  };

  const onChangeCoverageStartDatePicker = (date, dateString) => {
    if (date) {
      const month = dayjs(date).month() + 1; // month() is 0-indexed, so add 1
      let calendarMonth = '';

      switch (month) {
        case 1:
          calendarMonth = 'JANUARY';
          break;
        case 2:
          calendarMonth = 'FEBRUARY';
          break;
        case 3:
          calendarMonth = 'MARCH';
          break;
        case 4:
          calendarMonth = 'APRIL';
          break;
        case 5:
          calendarMonth = 'MAY';
          break;
        case 6:
          calendarMonth = 'JUNE';
          break;
        case 7:
          calendarMonth = 'JULY';
          break;
        case 8:
          calendarMonth = 'AUGUST';
          break;
        case 9:
          calendarMonth = 'SEPTEMBER';
          break;
        case 10:
          calendarMonth = 'OCTOBER';
          break;
        case 11:
          calendarMonth = 'NOVEMBER';
          break;
        case 12:
          calendarMonth = 'DECEMBER';
          break;
        default:
          calendarMonth = '';
      }

      form.setFieldsValue({ calendarMonth });
    } else {
      form.setFieldsValue({ calendarMonth: null });
    }
    setCoverageStartDate(dayjs(date).format("MM/DD/YYYY"));
  };


  const onChangeCoverageEndDatePicker = (date, dateString) => {
    // console.log(date, dateString);
    setCoverageEndDate(dayjs(date).format("MM/DD/YYYY"));
  };

  const handleRemoveCoverageStart = () => {
    setShowCoverageStartField(false);
    setCoverageStartDate(null);
    form.setFieldsValue({ coverageStartDate: "" });
  };

  const handleAddCoverageStart = () => {
    setCoverageStartDate(null);
    setShowCoverageStartField(true);
  };

  const handleRemoveCoverageEnd = () => {
    setShowCoverageEndField(false);
    setCoverageEndDate(null);
    form.setFieldsValue({ coverageEndDate: "" });
  };

  const handleAddCoverageEnd = () => {
    setCoverageEndDate(null);
    setShowCoverageEndField(true);
  };

  const disableOutOfCurrentYear = (current) => {
    const currentYear = dayjs().year(); // Get the current year using Day.js
    const startOfYear = dayjs(`${currentYear}-01-01`);
    const endOfYear = dayjs(`${currentYear}-12-29`);

    // Disable dates outside of January 1 to December 29 of the current year
    return current && (current?.isBefore(startOfYear) || current?.isAfter(endOfYear));
  };

  return (
    <Form
      ref={formRef}
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      onValuesChange={onValuesChange}
      size={defaultSize === "dataCapture" ? "small" : ""}
    >
      <Row gutter={16}>
        <Col
          {...colProps}
        // xl={6} lg={6} md={8} sm={12} xs={24}
        >
          <Form.Item
            className="mb-2"
            label="Calendar Month"
            name="calendarMonth"
          >
            <Select
              showSearch
              allowClear
              placeholder="Select a calendar month"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={CalendarMonth}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
          </Form.Item>
          {showCoverageStartField ?
            (<Form.Item
              className="mb-2"
              label="Coverage Start Date"
              name="coverageStartDate"
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            >
              <DatePicker
                className="w-full"
                value={coverageStartDate ? dayjs(coverageStartDate) : null}
                onChange={onChangeCoverageStartDatePicker}
                format={dateFormat}
                disabledDate={disabledDate}
                style={!EditAccess ? { pointerEvents: "none" } : {}}
              />
              <Button
                type="link"
                onClick={handleRemoveCoverageStart}
                disabled={!EditAccess}
              >Remove Coverage Start Date</Button>
            </Form.Item>
            )
            :
            (<Button
              type="link"
              onClick={handleAddCoverageStart}
              disabled={!EditAccess}
            >Add Coverage Start Date</Button>)
          }
          {showCoverageEndField ? (<Form.Item
            className="mb-2"
            label="Coverage End Date"
            name="coverageEndDate"
          >
            <DatePicker
              className="w-full"
              value={coverageEndDate ? dayjs(coverageEndDate) : null}
              onChange={onChangeCoverageEndDatePicker}
              format={dateFormat}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
            <Button type="link" onClick={handleRemoveCoverageEnd}
              disabled={!EditAccess}>Remove Coverage End Date</Button>
          </Form.Item>) :
            (<Button type="link" onClick={handleAddCoverageEnd}
              disabled={!EditAccess}>Add Coverage End Date</Button>)
          }
          <Form.Item
            className="mb-2"
            label="Network Status"
            name="networkStatus"
          >
            <Select
              showSearch
              allowClear
              placeholder="Select a network status"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={NetworkStatus}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="INN Benefit's For DME"
            name="innBenefits"
          >
            <Select
              showSearch
              allowClear
              placeholder="Select a inn benefits for dme"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={BenefitsDME}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="INN Ded Calendar/Actual Amount"
            name="innDedCalendar"
          >
            <Input
              type="text"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, '').toUpperCase();
              }}
              prefix="$"
              readOnly={!EditAccess}
            />
          </Form.Item>

          <Form.Item
            className="mb-2"
            label="INN Ded Met Amount"
            name="innDedMetAmount"
          >
            <Input
              type="text"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, '').toUpperCase();
              }}
              prefix="$"
              readOnly={!EditAccess}
            />
          </Form.Item>

          <Form.Item
            className="mb-2"
            label="INN Ded Balance Amount"
            name="innDedBalanceAmount"
          >
            <Input
              type="text"
              readOnly
              prefix="$"
            />
          </Form.Item>

        </Col>
        <Col
          {...colProps}
        // xl={6} lg={6} md={8} sm={12} xs={24}
        >
          <Form.Item
            className="mb-2"
            label="INN Coins"
            name="innCoins"
          >
            <Input
              type="text"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, '').toUpperCase();
              }}
              readOnly={!EditAccess}
              suffix="%" />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="INN OOP Calendar/Actual Amount"
            name="innOopCalendar"

          >
            <Input
              type="text"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, '').toUpperCase();
              }}
              prefix="$"
              readOnly={!EditAccess}
            />
          </Form.Item>

          <Form.Item
            className="mb-2"
            label="INN OOP Met Amount"
            name="innOopMetAmount"

          >
            <Input
              type="text"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, '').toUpperCase();
              }}
              prefix="$"
              readOnly={!EditAccess}
            />
          </Form.Item>

          <Form.Item
            className="mb-2"
            label="OON Benefits For DME"
            name="onnBenefits"

          >
            <Select
              showSearch
              allowClear
              placeholder="Select a oon benefits for dme"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={BenefitsDME}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="OON Ded Calendar/Actual Amount"
            name="oonDedCalendar"
          >
            <Input
              type="text"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, '').toUpperCase();
              }}
              prefix="$"
              readOnly={!EditAccess}
            />
          </Form.Item>

          <Form.Item
            className="mb-2"
            label="OON Ded Met Amount"
            name="oonDedMetAmount"
          >
            <Input
              type="text"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, '').toUpperCase();
              }}
              prefix="$"
              readOnly={!EditAccess}
            />
          </Form.Item>

          <Form.Item
            className="mb-2"
            label="OON Ded Balance Amount"
            name="ooDedBalanceAmount"
          >
            <Input
              type="text"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, '').toUpperCase();
              }}
              prefix="$"
              readOnly
            />
          </Form.Item>

        </Col>
        <Col
          {...colProps}
        // xl={6} lg={6} md={8} sm={12} xs={24}
        >
          <Form.Item
            className="mb-2"
            label="OON Coins"
            name="oonCoins"

          >
            <Input
              type="text"
              suffix="%"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, '').toUpperCase();
              }}
              readOnly={!EditAccess}
            />
          </Form.Item>

          <Form.Item
            className="mb-2"
            label="OON OOP Calendar/Actual Amount"
            name="oonOopCalendar"

          >
            <Input
              type="text"
              prefix="$"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, '').toUpperCase();
              }}
              readOnly={!EditAccess}
            />
          </Form.Item>

          <Form.Item
            className="mb-2"
            label="OON OOP Met Amount"
            name="oonOopMet"
          >
            <Input
              type="text"
              prefix="$"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.UuNn/Aa]/g, '').toUpperCase();
              }}
              readOnly={!EditAccess}
            />
          </Form.Item>

          <Form.Item
            className="mb-2"
            label={<span className="text-red-500">Auth Required</span>}
            name="authRequired"
          >
            <Select
              showSearch
              allowClear
              placeholder="Select a auth required"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: 'YES',
                  label: 'YES',
                },
                {
                  value: 'NO',
                  label: 'NO',
                },
                {
                  value: 'VARIES',
                  label: 'VARIES',
                },
                {
                  value: 'N/A',
                  label: 'N/A',
                },
                {
                  value: 'U/A',
                  label: 'U/A',
                },
              ]}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label={<span className="text-red-500">E&B Status</span>}
            name="vfCategory"
            rules={[
              { required: true, message: 'Please select the E&B Status!' },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="Select a E&B Status"
              optionFilterProp="children"
              onChange={onChangeVFStatus}
              onSearch={onSearch}
              filterOption={filterOption}
              options={VFCategory}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="S&S Received Equipment In the Last 6 Months?"
            name="ssReceived"
          >
            <Select
              showSearch
              allowClear
              placeholder="Select a s and s received equipment in the last six months"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={SsReceivedEquipment}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="Prescription Availability"
            name="consultationStatus"
          >
            <Select
              showSearch
              allowClear
              placeholder="Select a Prescription Availability"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={ConsultationStatus}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="VF Comments"
            name="vfComments"
          >
            <TextArea maxLength={1000} readOnly={!EditAccess} />
          </Form.Item>

        </Col>
        <Col
          {...colProps}
        // xl={6} lg={6} md={8} sm={12} xs={24}
        >
          <Form.Item
            className="mb-2"
            label="Subsequent VF Date"
            name="subsequentScanDate"
            style={!EditAccess ? { pointerEvents: "none" } : {}}
          >
            <DatePicker
              className="w-full"
              onChange={onChangeDatePicker}
              format={dateFormat}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
              disabledDate={disableOutOfCurrentYear}
            />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="Subsequent Quarter To Verify"
            name="subsequentQuarter"
          >
            <Select
              showSearch
              allowClear
              placeholder="Select Subsequent Quarter To Verify"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: 'Q1',
                  label: 'Q1',
                },
                {
                  value: 'Q2',
                  label: 'Q2',
                },
                {
                  value: 'Q3',
                  label: 'Q3',
                },
                {
                  value: 'Q4',
                  label: 'Q4',
                },
              ]}
              style={{ pointerEvents: "none" }}
            />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="Transaction ID/SSA"
            name="transaction"
          >
            <Input
              readOnly={!EditAccess}
            />
          </Form.Item>

          <Form.Item
            className="mb-2"
            label="Calling Assigned Reason"
            name="callingAssigned"
          >
            <Select
              showSearch
              allowClear
              placeholder="Select a calling assigned reason"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={CallingAssignedReason}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="Calling Added On"
            name="callingAdded"
            style={!EditAccess ? { pointerEvents: "none" } : {}}
          >
            <DatePicker
              className="w-full"
              onChange={onChangeDatePicker}
              format={dateFormat}
              disabledDate={disabledDate}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="Delivery Status"
            name="deliveryStatus"
          >
            <Select
              showSearch
              placeholder="Select a delivery status"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={[
                {
                  value: 'YES',
                  label: 'YES',
                },
                {
                  value: 'NO',
                  label: 'NO',
                },
              ]}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
          </Form.Item>
          {deliveredOnField && <Form.Item
            className="mb-2"
            label="Delivered On"
            name="deliveredOn"
            style={!EditAccess ? { pointerEvents: "none" } : {}}
          >
            <DatePicker
              multiple
              className="w-full"
              format={dateFormat}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
          </Form.Item>}
          <Form.Item
            className="mb-2"
            label="Internal Notes Space Column"
            name="internalNotes"
          >
            <TextArea maxLength={1000} readOnly={!EditAccess} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col
          // {...colProps}
          xl={12} lg={12} md={12} sm={12} xs={12}
        >
          <Form.Item className="mb-2">
            {/* <Button className="w-fit mt-2 font-medium">Cancel</Button> */}

          </Form.Item>
        </Col>
        <Col
          // {...colProps}
          xl={12} lg={12} md={12} sm={12} xs={12}
        >
          <Form.Item className="mb-2">
            {(!isFromUpdate && !callerSpaceToggle) && (
              <Button
                className="w-fit bg-green-400 mt-2 font-medium float-right mr-2"
                type="primary"
                onClick={handleSubmitChart}
              >
                {role === "AUDIT" ? "Sign Off" : "Submit Chart"}
              </Button>
            )}
            <Button
              className="w-fit bg-blue-400 mt-2 font-medium float-right mr-2"
              type="primary"
              htmlType="submit"
              disabled={!EditAccess}
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

// export default memo(CoverageDetails, (prevProps, nextProps) => {
//   return prevProps.callerSpaceToggle === nextProps.callerSpaceToggle; // Avoid re-rendering if callerSpaceToggle is the same
// });

export default memo(CoverageDetails)

