import React from 'react';
import { Select } from 'antd';
import { useDispatch } from 'react-redux';



const SearchInput = ({ searchQuery, setSearchQuery, filterData = [], setChartDetailView, ChartDetailView, setSearchTerm }) => {
  const dispatch=useDispatch()
  const handleSearch = (newValue) => {
    setSearchQuery(newValue);
    setSearchTerm(newValue);

  };

  const handleChange = (newValue, option) => {
    setSearchQuery(newValue);
    const selectedData = option?.data; // Access the entire object d from option.data
    // Update ChartDetailView state with selected data and set selected to true
    if (option !== undefined) {


      dispatch(setChartDetailView({
        selected: true,
        data: selectedData
      })
      )
    }

  };
  return (
    <Select
      size="small"
      showSearch
      allowClear
      value={ChartDetailView?.data?.patientName || undefined}
      // placeholder={filterData && filterData.length > 0 ? null : "Search Name, Phone , HCPCS Code and Email Chart"}
      placeholder={"Search Name, Phone, Hcpcs, Equipment Name and Email Chart"}
      style={{
        width: "100%",
      }}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange} // Call handleChange when a selection is made
      notFoundContent={null}
      options={filterData && filterData.length > 0 ? filterData.map((d) => ({
        value: d.id,
        label: (

          <>
            <div className="grid grid-cols-2 grid-rows-1 gap-4">
              <div >
                <small>Name: {d.patientName}</small>
                <br />
                <small>Phone No: {d?.phoneMailEquipmentModels[0]?.phoneNumber}</small>
                <br />
                <small>Mail ID: {d?.phoneMailEquipmentModels[0]?.mailId}</small>
                <br />
                <small>HCPCS Codes: {d?.vfRequestedEquipmentModelDetails[0]?.hcpcsCodes}</small>
                <br />
                <small>Equipment Model: {d?.vfRequestedEquipmentModelDetails[0]?.equipmentName}</small>
              </div>
              {/* <div className='flex justify-center'><EyeTwoTone /></div> */}
            </div>

          </>
        ),
        data: d // Store the entire object d in the data property
      })) : []}
    />
  );
};

export default SearchInput;
