import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Switch, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/loading/loadingSlice';
import { getProvidersApi, updateProviderApi } from '../../redux/provider/providerService';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { axiosErrorHandling, isEmptyObject, isObject, trimObjValues } from '../../utils/utility';

const EditProviderProfile = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [getCalls, setGetCalls] = useState([1]);
  const [profile, setProfile] = useState({});
  const [passwordStrength, setPasswordStrength] = useState({
    lowercase: false,
    uppercase: false,
    specialChar: false,
    number: false,
    length: false,
  });
  const { providerList } = useSelector((state) => state.provider);
  const userId = useSelector((state) => state.userDetails.userId);

  useEffect(() => {
    const func = async () => {
      /* get providers */
      if (getCalls[1]) {
        let success = getCalls;
        success[1] = 0;
        const loadingResult = success.includes(1);
        try {
          dispatch(setLoading(true));
          await dispatch(getProvidersApi()).unwrap();
          setGetCalls(success);
          dispatch(setLoading(loadingResult))
        } catch (error) {
          setGetCalls(success)
          dispatch(setLoading(loadingResult));
        }
      }
    }

    func();
  }, [dispatch, getCalls]);

  useEffect(() => {
    if (userId) {
      const profileData = _.find(providerList, { id: userId });
      setProfile(profileData || {});
      form.setFieldsValue({
        twoFactor: profileData?.twoFactorEnabled || false,
      });
    }
  }, [providerList, userId, form]);

  const handlePasswordChange = (value) => {
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /[0-9]/;

    setPasswordStrength({
      lowercase: lowercaseRegex.test(value),
      uppercase: uppercaseRegex.test(value),
      specialChar: specialCharRegex.test(value),
      number: numberRegex.test(value),
      length: value.length >= 8,
    });
  };

  const validatePassword = (_, value) => {
    if (
      passwordStrength.lowercase &&
      passwordStrength.uppercase &&
      passwordStrength.specialChar &&
      passwordStrength.number &&
      passwordStrength.length
    ) {
      return Promise.resolve();
    }
    return Promise.reject(
      'Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one special character, and one number.'
    );
  };

  const validateCurrentPassword = async (_, value) => {
    if (!value || profile.password === value) {
      return Promise.resolve();
    }
    return Promise.reject('Current password is incorrect!');
  };

  const onFinish = async (values) => {
    try {
      const values = await form.validateFields();
      const currentItems = { ...values };
      const updateId = profile?.id;

      /* check update id */
      if (!updateId) {
        return toast.warning('Invalid update id');
      }

      /* check is object */
      if (!isObject(currentItems)) {
        return toast.warning('This is not object data!');
      }

      /* check is empty object */
      if (isEmptyObject(currentItems)) {
        return toast.warning('Empty object cannot accept!');
      }

      /* trim values */
      trimObjValues(currentItems);
      const items = {
        name: profile?.name,
        email: profile?.email,
        project: profile?.project?.id,
        access: profile?.access,
        status: profile?.status,
        password: currentItems?.newPassword,
        twoFactorEnabled: currentItems?.twoFactor
      }

      dispatch(setLoading(true));
      await dispatch(updateProviderApi({ updateId, items })).unwrap();

      dispatch(setLoading(false));
      message.success('Profile updated successfully!')
      form.resetFields(['currentPassword', 'newPassword', 'confirmPassword']);
    } catch (error) {
      dispatch(setLoading(false));
      return axiosErrorHandling(error)
    }
  };

  return (
    <div style={{ maxWidth: 600, margin: '0 auto' }}>
      <h2 className='text-gray-500 font-semibold'>Modify Profile</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label="Current Password"
          name="currentPassword"
          rules={[
            { required: true, message: 'Please enter your current password!' },
            { validator: validateCurrentPassword },
          ]}
        >
          <Input.Password placeholder="Enter your current password" />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            { required: true, message: 'Please enter a new password!' },
            { validator: validatePassword },
          ]}
        >
          <Input.Password
            placeholder="Enter a new password"
            onChange={(e) => handlePasswordChange(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label="Confirm New Password"
          name="confirmPassword"
          dependencies={['newPassword']}
          rules={[
            { required: true, message: 'Please confirm your new password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm your new password" />
        </Form.Item>

        <Form.Item
          label="Enable Two-Factor Authentication"
          name="twoFactor"
          valuePropName="checked"
        >
          <Switch checked={profile.twoFactorEnabled} onChange={(checked) => form.setFieldsValue({ twoFactor: checked })} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update Profile
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditProviderProfile;
