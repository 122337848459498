import { useCallback } from "react";
import PropTypes from "prop-types";

const ProviderSignInPage = ({ className = "" }) => {
  const onForgotPasswordTextClick = useCallback(() => {
    // Please sync "confirm password" to the project
  }, []);

  const onButtonsClick = useCallback(() => {
    // Please sync "Internal staff" to the project
  }, []);

  return (
    <div
      className={`w-full h-full bg-white max-w-full overflow-hidden flex flex-row items-end justify-start leading-[normal] tracking-[normal] [row-gap:20px] text-justify text-3xs text-royalblue-100 font-mulish mq1150:flex-wrap ${className}`}
    >
      <section className="w-[786px] flex flex-col items-start justify-end pt-0 px-0 pb-[17px] box-border min-w-[786px] max-w-full mq1050:min-w-full mq1150:flex-1">

        <header className="self-stretch flex flex-row items-start justify-between gap-[20px] max-w-full text-left text-38xl-6 text-gray font-dm-sans">
          <div className="w-[83.4px] flex flex-col items-start justify-start pt-[23px] px-0 pb-0 box-border">
            <div className="self-stretch flex flex-col items-end justify-start gap-[245px]">
              <div className="flex flex-row items-start justify-end py-0 pr-[23px] pl-[30px]">
                <img
                  className="h-[27px] w-[30px] relative object-cover"
                  loading="lazy"
                  alt=""
                  src={require("../../assets/wwsLogo.png")}
                />
              </div>
              <div className="self-stretch h-[202px] relative">
                <div className="absolute top-[191.34px] left-[35.5px] rounded-[4.5px] [background:linear-gradient(245.53deg,_#367aff,_#ec74e7)] w-[9px] h-[70px] [transform:_rotate(145deg)] [transform-origin:0_0]" />
                <div className="absolute top-[194.97px] left-[83.4px] rounded-md [background:linear-gradient(250.46deg,_rgba(54,_122,_255,_0.14),_rgba(236,_116,_231,_0.14))] w-3 h-[241px] [transform:_rotate(144deg)] [transform-origin:0_0] mix-blend-normal z-[1]" />
              </div>
            </div>
          </div>
          <div className="w-[597px] flex flex-col items-start justify-start gap-[118.1px] max-w-full mq750:gap-[59px] mq450:gap-[30px]">
            <div className="w-[274px] h-[100.9px] relative">
              <div className="absolute top-[83.66px] left-[254px] rounded-mini [background:linear-gradient(250.46deg,_rgba(54,_122,_255,_0.09),_rgba(54,_122,_255,_0.14))] w-[30px] h-[400px] [transform:_rotate(145deg)] [transform-origin:0_0] mix-blend-normal" />
              <div className="absolute top-[61.42px] left-[172.8px] rounded [background:linear-gradient(250.46deg,_#367aff,_#ec74e7)] w-2 h-[241px] [transform:_rotate(145deg)] [transform-origin:0_0] z-[1]" />
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[11px] box-border max-w-full">
              <div className="flex-1 flex flex-col items-end justify-start gap-[15px] max-w-full">
                <div className="self-stretch flex flex-col items-start justify-start max-w-full">
                  <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[23px] box-border max-w-full">
                    <div className="flex-1 flex flex-row items-start justify-start relative max-w-full ml-16">
                      <div className="h-[330px] flex-1 relative tracking-[-0.01em] leading-[130.31%] font-medium inline-block max-w-full">
                        <span className="whitespace-pre-wrap">{`Igniting  `}</span>
                        <span className="text-royalblue-200 whitespace-pre-wrap text-21xl">
                          <span>Wonder</span>
                        </span>
                        <span>
                          <span className="text-royalblue-200 whitespace-pre-wrap text-21xl">{` `}</span>
                          <span>Delivering</span>
                          <span className="text-royalblue-200 whitespace-pre-wrap text-21xl">{` `}</span>
                        </span>
                        <span className="text-royalblue-200 whitespace-pre-wrap text-21xl">
                          <span>Worth</span>
                        </span>
                      </div>
                      <img
                        className="h-[294.1px] w-[351.8px] absolute !m-[0] top-[-54.3px] left-[-52.7px] object-contain mix-blend-normal z-[1]"
                        loading="lazy"
                        alt=""
                        src={require("../../assets/Vector 918 (Stroke).png")}
                      />
                    </div>
                  </div>
                  <div className="w-[543px] flex flex-row items-start justify-start py-0 pr-5 pl-0 box-border max-w-full mt-[-46px] text-justify text-3xs text-royalblue-100 font-mulish ml-[90px]">
                    <div className="h-[191px] flex-1 relative max-w-full">
                      <div className="absolute top-[0px] left-[0px] rounded-[50%] w-full h-full" />
                      <div className="absolute top-[178px] left-[210px] tracking-[0.14em] font-semibold inline-block min-w-[54px] whitespace-nowrap z-[1]">
                        About us
                      </div>
                      <div className="absolute top-[178px] left-[303px] tracking-[0.14em] font-semibold inline-block min-w-[99px] whitespace-nowrap z-[1]">
                        Terms of service
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-start pt-[178px] px-0 pb-0">
                      <div className="relative tracking-[0.14em] font-semibold inline-block min-w-[83px] whitespace-nowrap">
                        Privacy policy
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-start justify-end py-0 px-[59px] text-justify text-3xs text-darkslategray font-mulish mq450:pl-5 mq450:pr-5 mq450:box-border">
                  <div className="flex flex-row items-start justify-start gap-[7px]">
                    <img
                      className="h-3.5 w-3.5 relative min-h-[14px]"
                      loading="lazy"
                      alt=""
                      src="/vuesaxlinearcopyright.svg"
                    />
                    <div className="relative tracking-[0.14em] font-semibold whitespace-nowrap pl-[500px]">
                      Wonder Worth Solutions LLC
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </section>

      <div className="w-[600px] mt-11 flex flex-col items-end justify-start gap-[40.3px] min-w-[444px] max-w-full ml-[-20px] mq750:min-w-full mq1150:flex-1 mq1150:ml-0 mq450:gap-[32px]">
        <div className="w-[425px] mt-10 flex flex-row items-start justify-end py-0 px-[18px] box-border max-w-full">
          <form className="-m-16 flex-1 flex flex-col items-end justify-start pt-0 px-0 pb-[147.6px] box-border gap-[26.4px] max-w-full mq750:pb-24 mq750:box-border mq1150:flex-1 mq450:h-auto">
            <h3 className="m-0 relative text-[14px] font-light font-mulish text-secondary-color text-center inline-block min-h-[36px]">
              <p className="m-9">
                We're glad you're here. Please sign in to access your account.
              </p>
            </h3>
            <div className="self-stretch flex flex-row items-start justify-end pt-0 pb-[6.6px] pr-[45px] pl-[46px] mq450:pl-5 mq450:pr-5 mq450:box-border">
              <div className="flex-1 flex flex-col items-start justify-start gap-[26.3px]">
                <div className="self-stretch flex flex-col items-start justify-start gap-[6.6px]">
                  <div className="relative text-xs-5 font-medium font-plus-jakarta-sans text-black text-left inline-block min-w-[30px] shrink-0">
                    Email
                  </div>
                  <div className="self-stretch rounded-[9.89px] flex flex-row items-start justify-start py-[13px] px-[19px] shrink-0 border-[0.7px] border-solid border-silver">
                    <input
                      className="w-[99px] [border:none] [outline:none] font-plus-jakarta-sans text-sm-2 bg-[transparent] h-[17px] relative text-darkgray text-left inline-block p-0"
                      placeholder="Enter your email"
                      type="text"
                    />
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[6.6px]">
                  <div className="relative text-xs-5 font-medium font-plus-jakarta-sans text-black text-left inline-block min-w-[55px] shrink-0">
                    Password
                  </div>
                  <div className="self-stretch rounded-[9.89px] flex flex-row items-start justify-between py-[13px] pr-[13px] pl-[19px] shrink-0 gap-[20px] border-[0.7px] border-solid border-silver">
                    <input
                      className="w-32 [border:none] [outline:none] font-plus-jakarta-sans text-sm-2 bg-[transparent] h-[17px] relative text-darkgray text-left inline-block p-0"
                      placeholder="Enter your password"
                      type="text"
                    />
                    <div className="flex flex-col items-start justify-start pt-[1.8px] px-0 pb-0">
                      <img
                        className="w-[14.4px] h-[14.4px] relative overflow-hidden shrink-0"
                        alt=""
                        src="/eyeoff.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-end py-0 pr-[42px] pl-[46px] mq450:pl-5 mq450:pr-5 mq450:box-border">
              <div className="flex-1 flex flex-col items-start justify-start gap-[53.9px] mq450:gap-[27px]">
                <div className="self-stretch flex flex-row items-start justify-between gap-[20px]">
                  <div className="flex flex-row items-start justify-start gap-[5.9px]">
                    <input
                      className="m-0 h-[13.1px] w-[11.2px]"
                      type="checkbox"
                    />
                    <div className="relative text-xs-5 font-plus-jakarta-sans text-black text-left inline-block min-w-[80px] shrink-0">
                      Remember Me
                    </div>
                  </div>
                  <div
                    className="relative text-xs-5 tracking-[-0.01em] font-plus-jakarta-sans text-royalblue-100 text-left inline-block min-w-[92px] cursor-pointer"
                    onClick={onForgotPasswordTextClick}
                  >
                    Forgot Password
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start gap-[33px] mq450:flex-wrap mq450:gap-[16px]">
                  <button
                    className="cursor-pointer [border:none] py-[15px] px-[45px] bg-dodgerblue-100 flex-1 rounded-[13.18px] flex flex-row items-start justify-start box-border min-w-[87px] hover:bg-dodgerblue-200"
                    onClick={onButtonsClick}
                  >
                    <b className="relative text-smi tracking-[0.04em] inline-block font-plus-jakarta-sans text-white text-left min-w-[33px]">
                      Next
                    </b>
                  </button>
                  <button
                    className="cursor-pointer [border:none] py-[15px] pr-[37px] pl-[45px] bg-white shadow-[0px_4px_14px_rgba(54,_122,_255,_0.1)] rounded-[13.18px] flex flex-row items-start justify-start whitespace-nowrap hover:bg-gainsboro"
                    onClick={onForgotPasswordTextClick}
                  >
                    <b className="relative text-smi tracking-[0.04em] inline-block font-plus-jakarta-sans text-dodgerblue-100 text-left min-w-[52px]">
                      Sign Up
                    </b>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap">
          <div className="flex flex-col items-start justify-start pt-[39px] px-0 pb-0">
            <div className="relative tracking-[0.14em] font-semibold inline-block min-w-[105px] ml-16">
              Contact support
            </div>
          </div>
          <div className="h-[98px] w-[254px] relative">
            <div className="absolute top-[327.66px] left-[254px] rounded-mini [background:linear-gradient(250.46deg,_rgba(54,_122,_255,_0.09),_rgba(54,_122,_255,_0.14))] w-[30px] h-[400px] [transform:_rotate(145deg)] [transform-origin:0_0] mix-blend-normal" />
            <div className="absolute top-[231.42px] left-[216.8px] rounded [background:linear-gradient(250.46deg,_#367aff,_#ec74e7)] w-2 h-[241px] [transform:_rotate(145deg)] [transform-origin:0_0] z-[1]" />
          </div>
        </div>
      </div>

    </div>
  );
};

ProviderSignInPage.propTypes = {
  className: PropTypes.string,
};

export default ProviderSignInPage;
