import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Popconfirm, Select, Space, Spin, Table, TimePicker, Typography, } from 'antd';
import { SaveTwoTone, DeleteTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import { deleteScheduleApi, getScheduleListApi, updateScheduleApi } from '../../../redux/scheduleSend/scheduleSendService';
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import { toast } from 'react-toastify';


dayjs.locale('fr');
const { RangePicker } = DatePicker;
const ScheduleReport = ({ filterChart }) => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [getCalls, setGetCalls] = useState([1]);
    const [selectedFrequency, setSelectedFrequency] = useState(null);
    const [frequencyOptions, setFrequencyOptions] = useState([]);
    const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);
    const [loadings, setLoadings] = useState(false)
    const { scheduleSendList } = useSelector((state) => state.scheduleSend)
    const isEditing = (record) => record.key === editingKey;
    const dispatch = useDispatch()
    const disabledDate = (current) => {
        // Disable dates before today
        return current && current < dayjs().startOf('day');
    };




    const disabledDatesTime = (current) => {
        const currentTime = dayjs();
        if (current && current.isSame(currentTime, 'day')) {
            return {
                disabledHours: () => Array.from({ length: 24 }, (_, i) => i).slice(0, currentTime.hour()),
                disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i).slice(0, currentTime.minute()),
                // disabledSeconds: () => Array.from({ length: 60 }, (_, i) => i).slice(0, currentTime.second()),
            };
        }
        return {};
    };
    const handleRangePickerChange = (dates) => {

        if (dates && dates.length === 2) {
            const [start, end] = dates;
            const diffDays = end.diff(start, 'day') + 1;
            form.resetFields(["frequency"])
            setSelectedFrequency(null);
            if (diffDays === 1) {
                setFrequencyOptions([{ value: 'once', label: 'Once' }]);
            } else if (diffDays < 7) {
                setFrequencyOptions([{ value: 'daily', label: 'Daily' }]);
            } else if (diffDays >= 7 && diffDays < 14) {
                setFrequencyOptions([
                    { value: 'daily', label: 'Daily' },
                    { value: 'weekly', label: 'Weekly' },
                ]);
            } else if (diffDays >= 14 && diffDays < 30) {
                setFrequencyOptions([
                    { value: 'daily', label: 'Daily' },
                    { value: 'weekly', label: 'Weekly' },
                    { value: 'biweekly', label: 'Biweekly' },
                ]);
            } else if (diffDays >= 30) {
                setFrequencyOptions([
                    { value: 'daily', label: 'Daily' },
                    { value: 'weekly', label: 'Weekly' },
                    { value: 'biweekly', label: 'Biweekly' },
                    { value: 'monthly', label: 'Monthly' },
                ]);
            } else {
                setFrequencyOptions([]);
            }


        }
    }
    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        let inputNode;

        switch (inputType) {
            case 'text':
                inputNode = <Input.TextArea rows={1} className="max-h-[60px]" />;
                // <Input />;
                break;
            case 'date':
                if (dataIndex === "dateRange") {
                    inputNode = <RangePicker
                        allowClear={false}
                        onChange={handleRangePickerChange}

                    />;
                }
                else if (dataIndex === "notifyRange") {
                    inputNode = <RangePicker
                        allowClear={false}
                        onChange={handleRangePickerChange}
                        disabledDate={disabledDate}
                        disabledTime={disabledDatesTime}

                    />;
                }


                break;
            case 'time':
                inputNode = (
                    <TimePicker
                        className="w-full"
                        format="h:mm A"
                        use12Hours
                        allowClear={false}
                        hideSecond
                        disabledTime={disabledDatesTime}
                    />
                );
                break;
            case 'select':
                inputNode = (
                    <Select
                        className="w-full"
                        value={selectedFrequency}
                        onChange={(value) => setSelectedFrequency(value)}
                        options={frequencyOptions}
                    />
                );
                break;
            case 'format':
                inputNode = (
                    <Select
                        placeholder="Select export as"
                        optionFilterProp="children"
                        options={[
                            { value: 'excel', label: 'Excel' },
                            { value: 'pdf', label: 'PDF' }
                        ]}
                    />
                );
                break;
            default: inputNode = null

        }


        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{ margin: 0 }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                            {
                                validator: (_, value) => {
                                    if (dataIndex === 'dateRange' || dataIndex === 'notifyRange') {
                                        if (!value || value.length !== 2) {
                                            return Promise.reject(new Error(`Please select both start and end ${title}!`));
                                        }
                                        const [startDate, endDate] = value;
                                        if (dayjs(startDate).isAfter(dayjs(endDate))) {
                                            return Promise.reject(new Error(`${title} end date cannot be before start date!`));
                                        }
                                    }
                                    // else if (dataIndex === 'notifyTime') {
                                    //     const selectedTime = value;
                                    //     const currentTime = dayjs();
                                    //     if (selectedTime && selectedTime.isBefore(currentTime)) {
                                    //         return Promise.reject(new Error('Selected time is in the past'));
                                    //     }
                                    // }
                                    return Promise.resolve();
                                }
                            }
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };
    useEffect(() => {
        const fetchData = async () => {
            if (getCalls[0]) {
                setLoadings(true)
                let success = getCalls;
                success[0] = 0;
                try {
                    await dispatch(getScheduleListApi()).unwrap();
                    setGetCalls(success);
                    setLoadings(false)

                } catch (error) {
                    setLoadings(false)
                    console.error('Error fetching schedule list:', error);
                    // Handle error
                }
            }
        };

        fetchData();
    }, [dispatch, getCalls]);
    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
    useEffect(() => {
        const scheduleSendListFilter = scheduleSendList.filter((item) => item.isActive && !item.isCompleted);
        setData(
            scheduleSendListFilter.map((item, index) => ({
                id: item.id,
                key: index.toString(),
                fileName: item.fileName,
                dateRange: `${dayjs(item.rangeStartDate).format('YYYY-MM-DD')} - ${dayjs(item.rangeEndDate).format('YYYY-MM-DD')}`,
                notifyRange: `${dayjs(item.notifyDateStart).format('YYYY-MM-DD')} - ${dayjs(item.notifyDateEnd).format('YYYY-MM-DD')}`,
                notifyTime: dayjs(item.notifyTime).format('hh:mm A'), // Ensure correct parsing format
                format: capitalizeFirstLetter(item.format),
                frequency: capitalizeFirstLetter(item.frequency),
                to: item.to,
            }))
        );
    }, [scheduleSendList]);


    const edit = async (record) => {
        const [startDateString, endDateString] = record.dateRange.split(' - ');
        const [startNotifyDateString, EndNotifyDateString] = record.notifyRange.split(' - ')

        form.setFieldsValue({
            fileName: record.fileName,
            format: record.format,
            dateRange: [
                dayjs(startDateString, 'YYYY-MM-DD'),
                dayjs(endDateString, 'YYYY-MM-DD'),
            ],
            notifyRange: [
                dayjs(startNotifyDateString, 'YYYY-MM-DD'),
                dayjs(EndNotifyDateString, 'YYYY-MM-DD'),
            ],

            notifyTime: dayjs(record.notifyTime, 'hh:mm A'), // Ensure correct parsing format
            frequency: record.frequency,
            to: record.to,
        });
        // await dispatch(getScheduleListApi()).unwrap();
        setEditingKey(record.key);
    };


    const cancel = () => {
        setEditingKey('');
    };
    const handleRowSelectionChange = (selectedRowKeys) => {
        setEditingKey('');
        setSelectedRowsKeys(selectedRowKeys);
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            const formattedRow = {
                rangeStartDate: row.dateRange ? dayjs(row.dateRange[0]).startOf('day').toISOString() : null,
                rangeEndDate: row.dateRange ? dayjs(row.dateRange[1]).endOf('day').toISOString() : null,
                to: row.to,
                format: row.format.toLowerCase(),
                fileName: row.fileName,
                notifyDateStart: row.notifyRange ? dayjs(row.notifyRange[0]).startOf('day').toISOString() : null,
                notifyDateEnd: row.notifyRange ? dayjs(row.notifyRange[1]).endOf('day').toISOString() : null,
                notifyTime: row.notifyTime ? dayjs(row.notifyTime).toISOString() : null,
                frequency: row.frequency.toLowerCase(),
            };
            if (index > -1) {
                const currentItem = newData[index];


                // Compare current item with formattedRow
                const isModified =
                    dayjs(currentItem.dateRange.split(' - ')[0]).startOf('day').toISOString() !== formattedRow.rangeStartDate ||
                    dayjs(currentItem.dateRange.split(' - ')[1]).endOf('day').toISOString() !== formattedRow.rangeEndDate ||
                    dayjs(currentItem.notifyRange.split(' - ')[0]).startOf('day').toISOString() !== formattedRow.notifyDateStart ||
                    dayjs(currentItem.notifyRange.split(' - ')[1]).endOf('day').toISOString() !== formattedRow.notifyDateEnd ||
                    currentItem.to !== formattedRow.to ||
                    currentItem.format.toLowerCase() !== formattedRow.format ||
                    currentItem.fileName !== formattedRow.fileName ||
                    dayjs(currentItem.notifyTime, 'hh:mm A').toISOString() !== formattedRow.notifyTime ||
                    currentItem.frequency.toLowerCase() !== formattedRow.frequency;


                if (!isModified) {
                    toast.warning("No Changes");
                    setEditingKey('');
                    return; // Exit early if no changes detected
                }
            }
            if (row.dateRange && row.dateRange.length === 2) {
                const [startDate, endDate] = row.dateRange;
                const rangeStartDate = dayjs(startDate).startOf("day");
                const rangeEndDate = dayjs(endDate).endOf("day");
                const filtered = filterChart.filter((item) => {
                    const itemDate = dayjs(item.createdAt).startOf("day");
                    return (
                        (itemDate.isAfter(rangeStartDate) &&
                            itemDate.isBefore(rangeEndDate)) ||
                        itemDate.isSame(rangeStartDate) ||
                        itemDate.isSame(rangeEndDate)
                    );
                });
                if (filtered.length === 0) {
                    toast.warning("No data found on selected date range!");

                    return; // Exit the function if no data is found
                }

            }


            const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

            // Prepare formatted row for comparison and saving




            // Perform the update API call
            const filterObject = newData.filter((item) => key === item.key);
            const updateId = filterObject[0].id;

            setLoadings(true);

            try {
                let result = await dispatch(updateScheduleApi({ updateId, items: formattedRow })).unwrap();
                if (result) {
                    // Update local state
                    const updatedRow = {
                        ...formattedRow,

                        notifyTime: row.notifyTime ? row.notifyTime.format('hh:mm A') : null, // Ensure correct display format
                        frequency: capitalizeFirstLetter(row.frequency),
                        format: capitalizeFirstLetter(row.format),
                    };
                    if (index > -1) {
                        const item = newData[index];
                        newData.splice(index, 1, {
                            ...item,
                            ...updatedRow,
                        });
                    } else {
                        newData.push(updatedRow);
                    }

                    setData(newData);
                    setEditingKey('');
                    setLoadings(false);
                }
            } catch (error) {
                console.error('Update failed:', error);
                setLoadings(false);
            } finally {
                setLoadings(false);
            }
        } catch (errInfo) {
            // console.log('Validate Failed:', errInfo);
            setLoadings(false); // Ensure loading state is stopped in case of validation failure
        }
    };

    const deleted = async (key) => {
        const newData = [...data];
        const filterObject = newData.find((item) => item.key === key); // Use find instead of filter to get the specific item
        if (!filterObject) {
            console.error(`Item with key ${key} not found in data.`);
            return;
        }

        try {
            setLoadings(true);
            const deleteId = filterObject.id
            const result = await dispatch(deleteScheduleApi(deleteId)).unwrap();
            if (result) {
                // Update local data state after successful deletion
                const updatedData = newData.filter((item) => item.key !== key);
                setData(updatedData);
                setEditingKey('');
                toast.success("Schedule deleted successfully");
            } else {
                toast.error("Failed to delete schedule. Please try again.");
            }
        } catch (error) {
            console.error('Delete failed:', error);
            toast.error("Failed to delete schedule. Please try again.");
        } finally {
            setLoadings(false);
            setEditingKey('');
        }
    };
    const handleBulkDelete = async () => {
        try {
            setEditingKey('');
            setLoadings(true);
            const deletePromises = selectedRowsKeys.map(key => {
                const filterObject = data.find(item => item.key === key);
                return dispatch(deleteScheduleApi(filterObject.id)).unwrap();
            });

            await Promise.all(deletePromises);
            const newData = data.filter(item => !selectedRowsKeys.includes(item.key));
            setData(newData);
            setSelectedRowsKeys([]);
            toast.success("Schedules deleted successfully");
        } catch (error) {
            console.error('Bulk delete failed:', error);
            toast.error("Failed to delete schedules. Please try again.");
        } finally {
            setLoadings(false);
        }
    };


    const columns = [
        {
            title: 'File Name',
            dataIndex: 'fileName',
            width: '10%',
            editable: true,
            inputType: 'text',
        },
        {
            title: 'Format',
            dataIndex: 'format',
            width: '2%',
            editable: true,
            inputType: 'format',
        },
        {
            title: 'Range Start And End Date',
            dataIndex: 'dateRange',
            width: '25%',
            editable: true,
            inputType: 'date',
        },
        {
            title: 'Notify Start And End Date',
            dataIndex: 'notifyRange',
            width: '25%',
            editable: true,
            inputType: 'date',
        },
        {
            title: 'Notify Time',
            dataIndex: 'notifyTime',
            width: '12%',
            editable: true,
            inputType: 'text', // If you have a specific time picker, use appropriate component
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            width: '5%',
            editable: true,
            inputType: 'select',
        },
        {
            title: 'To',
            dataIndex: 'to',
            width: '40%',
            editable: true,
            inputType: 'text',
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record) => {
                let editable = isEditing(record);
                return editable ? (
                    <span>

                        <div className="grid grid-cols-1 grid-rows-3 gap-2">
                            <div >  <Typography.Link
                                onClick={() => save(record.key)}

                                style={{
                                    marginRight: 8,
                                }}
                            >
                                <SaveTwoTone style={{ marginRight: 4 }} /> Save
                            </Typography.Link>
                            </div>
                            <div >
                                <Popconfirm
                                    title="Sure to Delete?"
                                    onConfirm={() => deleted(record.key)}
                                    okText="Yes"
                                    cancelText="No"
                                    icon={<DeleteTwoTone twoToneColor="red" />}
                                    okButtonProps={{ type: 'danger', style: { color: 'red' } }}
                                    cancelButtonProps={{ style: { color: 'black' } }}
                                >
                                    <Typography.Link type="danger">
                                        <DeleteTwoTone twoToneColor="red" style={{ marginRight: 2 }} /> Delete
                                    </Typography.Link>
                                </Popconfirm>
                            </div>

                            <div >

                                <Typography.Link onClick={cancel}>
                                    <CloseCircleTwoTone style={{ marginRight: 2 }} />  Cancel
                                </Typography.Link>


                            </div>
                        </div>



                    </span>
                ) : (
                    <Typography.Link
                        disabled={editingKey !== '' || selectedRowsKeys.length > 0}
                        onClick={() => edit(record)}
                    >
                        Edit
                    </Typography.Link>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: getInputType(col.dataIndex),
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const getInputType = (dataIndex) => {
        switch (dataIndex) {
            case 'fileName':
                return 'text';
            case 'format':
                return 'format';
            case 'dateRange':
                return "date"
            case 'notifyRange':
                return 'date';
            case 'notifyTime':
                return 'time';
            case 'frequency':
                return 'select';
            case 'to':
                return 'text';
            default:
                return null

        }
    };


    // ... your existing code

    // Bulk delete handler

    return (
        <>
            {selectedRowsKeys.length > 0 && (
                <Space style={{ marginBottom: 16 }}>
                    <Button
                        icon={<DeleteTwoTone twoToneColor={"white"} />}
                        type="primary" danger onClick={handleBulkDelete} disabled={selectedRowsKeys.length === 0}>
                        Delete Selected
                    </Button>
                </Space>
            )}
            <Form form={form} component={false}>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    bordered
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{
                        onChange: cancel,
                    }}
                    rowSelection={{
                        selectedRowKeys: selectedRowsKeys,
                        onChange: handleRowSelectionChange,
                    }}
                />
            </Form>
            <Spin spinning={loadings} fullscreen />
        </>

    );
};
export default ScheduleReport;