import { createAsyncThunk } from '@reduxjs/toolkit';
import { createAdminLog, getAdminLogs, updateAdminLog } from '../../api/adminLog/adminLog';

export const getAdminLogsApi = createAsyncThunk(
  "adminLog/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getAdminLogs();
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createAdminLogApi = createAsyncThunk(
  "adminLog/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await createAdminLog(data);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAdminLogApi = createAsyncThunk(
  "adminLog/update",
  async (data, { rejectWithValue }) => {
    const { updateId, items } = data;
    try {
      const response = await updateAdminLog(updateId, items);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);
