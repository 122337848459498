import React, { memo, useEffect, useRef, useState } from 'react';
import { Col, Row, Select, Space } from 'antd';
import dayjs from 'dayjs';
import { Button, Form, Input, DatePicker } from 'antd';
import _, { isEmpty } from 'lodash';
import {
  axiosErrorHandling,
  axiosSubmitErrorHandling,
  checkMatchingCodes,
  formattedDate,
  getIpAddress,
  isEmptyObject,
  isObject,
  trimObjValues,
} from '../../utils/utility';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/loading/loadingSlice';
import { updateChartApi } from '../../redux/chart/chartService';
import { getPhysicianNpi } from '../../api/physicianNpi/physicianNpi';
import { MdDone } from 'react-icons/md';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Carousel } from 'antd';
import { prescriptionClassifications } from '../../assets/DispCodes';

const { TextArea } = Input;
const dateFormat = 'MM/DD/YYYY';

const initialVfRequestEquipmentModel = [
  {
    'vfRequestedEquipmentModel': '',
    'hcpcsCodes': '',
    'equipmentName': '',
  }
]
const initialOrderingPhysicianNPI = [
  {
    'orderingPhysicianNPI': '',
    'orderingPhysician': '',
    'orderingPhysicianAddress': '',
    'orderingPhysicianPhoneNumber': '',
    'orderingPhysicianFaxNumber': '',
    'orderingPhysicianSpeciality': ''
  }
]
const initialPrescription = [
  {
    'hcpcsFormPrescription': '',
    'equipmentModelFormPrescription': '',
  }
]
const DxCodes = [
  {
    'dxCodes': '',
  }
]

const ProductDetails = ({
  chartDetail,
  setProductDetailsSaved,
  setActiveSubTab,
  defaultSize,
  formRef,
  charts,
  setCharts,
  setChartDetailView,
  documentViewer
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [pcpPhysicianNpiData, setPcpPhysicianNpiData] = useState([]);
  const [pcpNpiValue, setPcpNpiValue] = useState('');
  const carouselRef = useRef(null);
  const orderingPhysicianCarouselRef = useRef(null);
  const prescriptionRef = useRef(null);
  const DxCodesRef = useRef(null);
  const [vfRequestEquipmentModelForm, setVfRequestEquipmentModelForm] = useState([...initialVfRequestEquipmentModel]);
  const [orderingPhysicianNPI, setOrderingPhysicianNPI] = useState([...initialOrderingPhysicianNPI]);
  const [prescription, setPrescription] = useState([...initialPrescription])
  const [dxCodes, setDxCodes] = useState([...DxCodes])
  const [showBabyDueDateField, setShowBabyDueDateField] = useState(false);
  const [babyDueDate, setBabyDueDate] = useState(null);
  const [weeksDue, setWeeksDue] = useState('');
  const [lmnReceivedDate, setLmnReceivedDate] = useState(null);

  const [showLmnReceivedDateField, setShowLmnReceivedDateField] = useState(true);
  const role = useSelector((state) => state.userDetails.role);
  const userId = useSelector((state) => state.userDetails.userId);
  const EditAccess = (role === "PROVIDER" || role === "USER" || role === "CODING" || role === "GUEST") ? false : true;

  const colProps = documentViewer
    ? { xl: 24, lg: 12, md: 12, sm: 12, xs: 12 }
    : { xl: 8, lg: 8, md: 8, sm: 12, xs: 24 }; // Adjust based on your preferred layout

  useEffect(() => {
    const productInitialValue = {
      weeksDue: chartDetail?.weeksDue ? Math.round(chartDetail?.weeksDue) : '',
      orderDate: chartDetail?.orderDate ? formattedDate(chartDetail?.orderDate) : '',
      dxCodes: chartDetail?.dxCodes,
      pcp: chartDetail?.pcp,
      pcpNpiNumber: chartDetail?.pcpNpiNumber,
      pcpAddress: chartDetail?.pcpAddress,
      pcpPhoneNumber: chartDetail?.pcpPhoneNumber,
      pcpFaxNumber: chartDetail?.pcpFaxNumber,
      pcpSpeciality: chartDetail?.pcpSpeciality,
      vfDate: chartDetail?.vfDate ? dayjs(chartDetail.vfDate) : '',
      vfTAT: chartDetail?.vfTAT,
      prescriptionClassification: chartDetail?.prescriptionClassification
    };

    if (chartDetail?.babyDueDate && chartDetail?.babyDueDate !== "Invalid Date" && chartDetail?.babyDueDate !== "U/A" && chartDetail?.babyDueDate !== "N/A") {
      const putBabyDueDate = chartDetail?.babyDueDate ? formattedDate(chartDetail?.babyDueDate) : null
      setBabyDueDate(putBabyDueDate);
      setShowBabyDueDateField(true);
    }

    if (chartDetail?.babyDueDate === "Invalid Date" || chartDetail?.babyDueDate === "U/A" || chartDetail?.babyDueDate === "N/A") {
      setShowBabyDueDateField(false);
      setBabyDueDate(null);
    }


    if (chartDetail?.prescriptionReceivedOn && chartDetail?.prescriptionReceivedOn !== "Invalid Date" && chartDetail?.prescriptionReceivedOn !== "U/A" && chartDetail?.prescriptionReceivedOn !== "N/A") {
      setShowLmnReceivedDateField(false);
      setLmnReceivedDate(null);
    }

    if (chartDetail?.prescriptionReceivedOn && chartDetail?.prescriptionReceivedOn !== "Invalid Date" && chartDetail?.prescriptionReceivedOn !== "U/A" && chartDetail?.prescriptionReceivedOn !== "N/A") {
      // Ensure chartDetail.insuranceCardReceivedOn is split into an array and parsed correctly
      const putLmnDate = chartDetail?.prescriptionReceivedOn.split(',').map(date => dayjs(date, dateFormat));
      setLmnReceivedDate(putLmnDate);
      form.setFieldsValue({
        prescriptionReceivedOn: putLmnDate,
      });

      setShowLmnReceivedDateField(true);
    }

    setPcpNpiValue(productInitialValue.pcpNpiNumber);
    form.setFieldsValue(productInitialValue);
  }, [chartDetail, form])

  useEffect(() => {
    if (chartDetail && !isEmpty(chartDetail)) {
      if (Array.isArray(chartDetail?.vfRequestedEquipmentModelDetails) && chartDetail?.vfRequestedEquipmentModelDetails.length > 0) {
        setVfRequestEquipmentModelForm(chartDetail.vfRequestedEquipmentModelDetails);

      }
    }

    if (chartDetail && !isEmpty(chartDetail)) {
      if (Array.isArray(chartDetail?.orderingPhysicianModelDetails) && chartDetail?.orderingPhysicianModelDetails.length > 0) {
        setOrderingPhysicianNPI(chartDetail.orderingPhysicianModelDetails);

      }
    }

    if (chartDetail && !isEmpty(chartDetail)) {
      if (Array.isArray(chartDetail?.prescriptionModelDetails) && chartDetail?.prescriptionModelDetails.length > 0) {
        setPrescription(chartDetail.prescriptionModelDetails);

      }
    }

    if (chartDetail && !isEmpty(chartDetail)) {
      if (Array.isArray(chartDetail?.DxCodesModelDetails) && chartDetail?.DxCodesModelDetails.length > 0) {
        setDxCodes(chartDetail.DxCodesModelDetails);

      }
    }
  }, [chartDetail]);

  useEffect(() => {
    setWeeksDue(calculateWeeksDue(babyDueDate));
  }, [babyDueDate]);

  const calculateWeeksDue = (dueDate) => {
    if (dueDate) {
      const today = dayjs();
      const differenceInDays = today.diff(dueDate, 'day');
      const weeks = Math.floor(differenceInDays / 7);
      return weeks;
    }
    return '';
  };

  const handleOrderingPhysicianNpi = async (index, value) => {
    try {

      dispatch(setLoading(true));
      // Define a timeout promise
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error('Timeout error: The request took too long to complete.'));
        }, 10000); // 10 seconds timeout
      });
      // Call your API and race it against the timeout promise
      const apiCallPromise = getPhysicianNpi(value);
      const { data } = await Promise.race([apiCallPromise, timeoutPromise]);
      if (data?.results?.length >= 1) {
        // Extract and format the required data
        const findName = data?.results[0]?.basic;
        const findLocationAddress = data?.results[0]?.addresses;
        const findSpeciality = data?.results[0]?.taxonomies;

        const physicaianAddress = findLocationAddress?.filter(
          (item) => item.address_purpose === 'LOCATION'
        );
        const physicianSpeciality = findSpeciality?.filter(
          (item) => item.primary === true
        );
        let convertedName = '';
        if (findName.first_name) {
          convertedName = `${findName.name_prefix ? findName.name_prefix + ' ' : ''}${findName.first_name} ${findName.middle_name ? findName.middle_name + ' ' : ''}${findName.last_name} ${findName.credential ? findName.credential : ''}`;
        } else if (findName.authorized_official_name_prefix) {
          convertedName = `${findName.authorized_official_name_prefix} ${findName.authorized_official_first_name} ${findName.authorized_official_middle_name ? findName.authorized_official_middle_name : ""} ${findName.authorized_official_last_name}`;
        }
        const convertedAddress = `${physicaianAddress[0]?.address_1},${physicaianAddress[0]?.address_2 ? ' ' + physicaianAddress[0]?.address_2 + ',' : ''} ${physicaianAddress[0]?.city}, ${physicaianAddress[0]?.state}, ${physicaianAddress[0]?.postal_code}`;
        const convertedPhoneNumber = `(${physicaianAddress[0]?.telephone_number?.substring(0, 3)}) ${physicaianAddress[0]?.telephone_number?.substring(4)}`;
        const convertedFaxNumber = physicaianAddress[0].fax_number
          ? `(${physicaianAddress[0].fax_number?.substring(0, 3)}) ${physicaianAddress[0].fax_number?.substring(4)}`
          : "Unavailable";
        const convertedSpeciality = `${physicianSpeciality[0]?.desc}`;
        // Update the state with the fetched and formatted data
        let items = [...orderingPhysicianNPI];
        let item = { ...items[index] };
        item.orderingPhysicianNPI = value;
        item.orderingPhysician = convertedName;
        item.orderingPhysicianAddress = convertedAddress;
        item.orderingPhysicianPhoneNumber = convertedPhoneNumber;
        item.orderingPhysicianFaxNumber = convertedFaxNumber;
        item.orderingPhysicianSpeciality = convertedSpeciality;
        items[index] = item;
        setOrderingPhysicianNPI(items);

        dispatch(setLoading(false));
        toast.success('Ordering physician information was successfully updated!');
      } else {
        dispatch(setLoading(false));
        toast.warn('No ordering physician details were found for the NPI!');
      }
    } catch (error) {
      console.error("Error while fetching ordering physician data:", error);
      dispatch(setLoading(false));

      if (error.message === 'Timeout error: The request took too long to complete.') {
        toast.warning('Request timed out. Please try again later.');
      } else {
        axiosErrorHandling(error); // Handle other Axios errors as needed
      }
    }
  };

  const disabledDate = (current) => {
    // Get the current date and year
    const currentDate = dayjs();
    const currentYear = currentDate.year();

    // Disable dates after the current year or after the current date
    return current && (dayjs(current).year() > currentYear || dayjs(current).isAfter(currentDate, 'day'));
  };

  const handlePcpPhysicianNpi = async () => {
    try {
      const physicianNpi = form.getFieldValue('pcpNpiNumber');


      if (physicianNpi.length === 10) {
        dispatch(setLoading(true));

        const timeOutPromise = new Promise((resolve, reject) => {
          setTimeout(() => {
            reject(new Error('Timeout error: The request took too long to complete.'));
          }, 10000); // 10 seconds timeout
        })
        const apiCallPromise = getPhysicianNpi(physicianNpi)
        const { data } = await Promise.race([apiCallPromise, timeOutPromise]);

        if (data?.results?.length > 0) {
          const foundData = data?.results;
          setPcpPhysicianNpiData(foundData);
          dispatch(setLoading(false));
          toast.success('PCP physician information was successfully added!');
        } else {
          setPcpPhysicianNpiData([]);
          dispatch(setLoading(false));
          toast.warn('No PCP physician details were found for the NPI!');
        }
      }
    } catch (error) {
      dispatch(setLoading(false));
      if (error.message === 'Timeout error: The request took too long to complete.') {
        toast.warning('Request timed out. Please try again later.');
      } else {
        axiosErrorHandling(error); // Handle other Axios errors as needed
      }

    }
  };

  useEffect(() => {
    if (Array.isArray(pcpPhysicianNpiData) && pcpPhysicianNpiData.length > 0) {
      const findName = pcpPhysicianNpiData[0].basic;
      const findLocationAddress = pcpPhysicianNpiData[0].addresses;
      const findSpeciality = pcpPhysicianNpiData[0].taxonomies;
      const physicaianAddress = findLocationAddress.filter(
        (item) => item.address_purpose === 'LOCATION'
      );
      const physicianSpeciality = findSpeciality.filter(
        (item) => item.primary === true
      );
      let convertedName = '';
      if (findName.first_name) {
        convertedName = `${findName.name_prefix ? findName.name_prefix + ' ' : ''}${findName.first_name} ${findName.middle_name ? findName.middle_name + ' ' : ''}${findName.last_name} ${findName.credential ? findName.credential : ''}`;
      } else if (findName.authorized_official_name_prefix) {
        convertedName = `${findName.authorized_official_name_prefix} ${findName.authorized_official_first_name} ${findName.authorized_official_middle_name ? findName.authorized_official_middle_name : ""} ${findName.authorized_official_last_name}`;
      }
      const convertedAddress = `${physicaianAddress[0].address_1},${physicaianAddress[0].address_2
        ? ' ' + physicaianAddress[0].address_2 + ','
        : ''
        } ${physicaianAddress[0].city}, ${physicaianAddress[0].state}, ${physicaianAddress[0].postal_code
        }`;
      const convertedPhoneNumber = `(${physicaianAddress[0].telephone_number?.substring(
        0,
        3
      )}) ${physicaianAddress[0].telephone_number?.substring(4)}`;
      const convertedFaxNumber = physicaianAddress[0].fax_number
        ? `(${physicaianAddress[0].fax_number?.substring(0, 3)}) ${physicaianAddress[0].fax_number?.substring(4)}`
        : "Unavailable";
      const convertedSpeciality = `${physicianSpeciality[0].desc}`;

      form.setFieldsValue({
        pcpNpiNumber: pcpPhysicianNpiData[0]?.number,
        pcpAddress: convertedAddress,
        pcp: convertedName,
        pcpPhoneNumber: convertedPhoneNumber,
        pcpFaxNumber: convertedFaxNumber,
        pcpSpeciality: convertedSpeciality,
      });
    }
  }, [pcpPhysicianNpiData, form]);


  //Save Order Summary
  const onFinish = async (values) => {
    try {
      const validatedValues = await form.validateFields();

      // Extract arrays from validatedValues

      // Create vfRequestedEquipmentModel array

      // Prepare items to send to the API

      const items = {
        ...validatedValues,
        userRole: role,
        vfRequestedEquipmentModelDetails: vfRequestEquipmentModelForm,
        orderingPhysicianModelDetails: orderingPhysicianNPI,
        prescriptionModelDetails: prescription,
        DxCodesModelDetails: dxCodes,
      }
      if (babyDueDate === "Invalid Date") {
        items.babyDueDate = "U/A"
      }

      if (!showBabyDueDateField && !babyDueDate) {
        items.babyDueDate = "N/A"
      }

      if (babyDueDate && babyDueDate !== "Invalid Date") {
        items.babyDueDate = babyDueDate;
      }

      if (lmnReceivedDate === "Invalid Date") {
        items.prescriptionReceivedOn = "U/A"
      }

      if (!showLmnReceivedDateField && !lmnReceivedDate) {
        items.prescriptionReceivedOn = "N/A"
      }

      if (lmnReceivedDate && lmnReceivedDate !== "Invalid Date") {
        items.prescriptionReceivedOn = lmnReceivedDate
      }

      if (Array.isArray(items.orderDate)) {
        items.orderDate = items.orderDate
          .filter(date => date !== null) // Filter out null values
          .map(date => dayjs(date).format('MM/DD/YYYY')) // Format each date
          .join(','); // Join into a single string
      }
      else {
        items.orderDate = dayjs(items.orderDate).format("MM/DD/YYYY");
      }

      let babyDueDates = babyDueDate
      if (Array.isArray(babyDueDates)) {
        babyDueDates = babyDueDates
          ?.filter(date => date !== null) // Filter out null values
          ?.map(date => dayjs(date).format('MM/DD/YYYY')) // Format each date
          ?.join(','); // Join into a single string
      }
      if (babyDueDates && typeof babyDueDates === 'object' && !Array.isArray(babyDueDates)) {
        // Handle case where insuranceDate is an object
        items.babyDueDate = dayjs(babyDueDates).format('MM/DD/YYYY');
      } else {
        items.babyDueDate = babyDueDates;
      }

      let LMNdates = lmnReceivedDate
      if (Array.isArray(LMNdates)) {
        LMNdates = LMNdates
          .filter(date => date !== null) // Filter out null values
          .map(date => dayjs(date).format('MM/DD/YYYY')) // Format each date
          .join(','); // Join into a single string
      }

      items.prescriptionReceivedOn = LMNdates

      // Check if items is an object

      if (!isObject(items)) {
        return toast.warning('This is not object data!');
      }

      // Check if items is an empty object
      if (isEmptyObject(items)) {
        return toast.warning('Empty object cannot be accepted!');
      }

      // Trim values if needed (assuming trimObjValues trims in place)
      trimObjValues(items);
      items.userId = userId
      const ipAddress = await getIpAddress();
      items.ipAddress = ipAddress;
      // Dispatch loading state
      dispatch(setLoading(true));

      // Update API call
      const updateId = chartDetail.id;
      dispatch(setProductDetailsSaved(true));
      //  setProductDetailsSaved(true);
      setActiveSubTab('4');

      const res = await dispatch(updateChartApi({ updateId, items })).unwrap();
      const rowIndex = _.findIndex(charts, { id: updateId });

      if (rowIndex > -1) {
        let updatedCharts = [...charts];
        let updatedItem = { ...updatedCharts[rowIndex], ...res };
        updatedCharts[rowIndex] = updatedItem;
        setCharts(updatedCharts);
      }

      if (typeof setChartDetailView === 'function') {
        setChartDetailView((prevView) => ({
          ...prevView,  // Keep the rest of the view intact
          data: {
            ...prevView.data, // Merge the current data
            ...res,  // Override with updated response from the API
          },
          selected: true,  // Ensure the chart is still selected
        }));
      }
      // Clear loading state
      dispatch(setLoading(false));

      // Show success message
      toast.success('Product details updated successfully!');
    } catch (error) {
      // Handle errors
      dispatch(setLoading(false));
      axiosSubmitErrorHandling(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const onChangeDatePicker = (date, dateString) => {
    // console.log(date, dateString);
  };

  const onChangeBabyDueDatePicker = (date, dateString) => {
    // console.log(date, dateString);
    setBabyDueDate(date)
  };

  // const handleChangeVfRequestedEquipmentModel = (name, event, index) => {
  //   const targetValue = event.target.value.toUpperCase();
  //   let items = [...vfRequestEquipmentModelForm];
  //   let item = { ...items[index] };
  //   item[name] = targetValue;
  //   // If the value is "U/A" or "N/A", update all relevant fields
  //   if (targetValue === "U/A" || targetValue === "N/A") {
  //     item.vfRequestedEquipmentModel = targetValue;
  //     item.hcpcsCodes = targetValue;
  //     item.equipmentName = targetValue;
  //   }
  //   let value = checkMatchingCodes(targetValue)
  //   item.equipmentName = value;
  //   items[index] = item;
  //   setVfRequestEquipmentModelForm(items);
  // };

  const handleChangeVfRequestedEquipmentModel = (name, event, index) => {
    const targetValue = event.target.value.toUpperCase();
    let items = [...vfRequestEquipmentModelForm];
    let item = { ...items[index] };

    item[name] = targetValue;

    // Update only the hcpcsCodes and equipmentName fields when the value is "U/A" or "N/A"
    if ((targetValue === "U/A" || targetValue === "N/A") && name !== 'vfRequestedEquipmentModel') {
      item.hcpcsCodes = targetValue;
      if (name !== 'equipmentName') {
        item.equipmentName = targetValue;
      }
    } else if (name !== 'vfRequestedEquipmentModel' && name !== 'equipmentName') {
      // If the value is not "U/A" or "N/A", update equipmentName based on the checkMatchingCodes function only if it hasn't been manually typed
      const checkedValue = checkMatchingCodes(targetValue);
      if (Array.isArray(checkedValue)) {
        item.equipmentName = checkedValue.join(', ');
      } else {
        item.equipmentName = checkedValue;
      }

    }

    items[index] = item;


    setVfRequestEquipmentModelForm(items);
  };




  const addVfRequestedEquipmentModel = () => {
    let createObj = {};
    createObj.vfRequestedEquipmentModel = '';
    createObj.hcpcsCodes = '';
    createObj.equipmentName = '';
    setVfRequestEquipmentModelForm(preValue => {
      return [
        ...preValue,
        createObj
      ]
    })
    setTimeout(() => {
      carouselRef.current.goTo(vfRequestEquipmentModelForm.length);
    }, 0);
  }

  const removeVfRequestedEquipmentModel = (index) => {
    if (index > -1) {
      let items = [...vfRequestEquipmentModelForm];
      items.splice(index, 1);
      setVfRequestEquipmentModelForm(items);
      carouselRef.current?.prev();
    }
  }
  const handleChangeOrderingPhysicianModel = (name, event, index) => {
    const targetValue = event.target.value.toUpperCase();
    let items = [...orderingPhysicianNPI];
    let item = { ...items[index] };

    item[name] = targetValue;

    // If the value is "U/A" or "N/A", update all relevant fields
    if (targetValue === "U/A" || targetValue === "N/A") {
      item.orderingPhysician = targetValue;
      item.orderingPhysicianAddress = targetValue;
      item.orderingPhysicianPhoneNumber = targetValue;
      item.orderingPhysicianFaxNumber = targetValue;
      item.orderingPhysicianSpeciality = targetValue;
    }

    items[index] = item;
    setOrderingPhysicianNPI(items);
  };


  const addOrderingPhysicianModel = () => {
    let createObj = {};
    createObj.orderingPhysicianNPI = '';
    createObj.orderingPhysician = '';
    createObj.orderingPhysicianAddress = '';
    createObj.orderingPhysicianPhoneNumber = '';
    createObj.orderingPhysicianFaxNumber = '';
    createObj.orderingPhysicianSpeciality = '';
    setOrderingPhysicianNPI(preValue => {
      return [
        ...preValue,
        createObj
      ]
    })
    setTimeout(() => {
      orderingPhysicianCarouselRef.current.goTo(orderingPhysicianNPI.length);
    }, 0);
  }
  const removeOrderingPhysicianModel = (index) => {
    if (index > -1) {
      let items = [...orderingPhysicianNPI];
      items.splice(index, 1);
      setOrderingPhysicianNPI(items);
      orderingPhysicianCarouselRef.current?.prev();
    }
  }

  const addPrescriptionForm = () => {
    let createObj = {};
    createObj.hcpcsFormPrescription = '';
    createObj.equipmentModelFormPrescription = '';
    setPrescription(preValue => {
      return [
        ...preValue,
        createObj
      ]
    })
    setTimeout(() => {
      prescriptionRef.current.goTo(prescription.length);
    }, 0);
  }

  const removePrescriptionForm = (index) => {
    if (index > -1) {
      let items = [...prescription];
      items.splice(index, 1);
      setPrescription(items);
      prescriptionRef.current?.prev();
    }
  }
  const handleChangePrescription = (name, event, index) => {
    const targetValue = event.target.value.toUpperCase();
    let items = [...prescription];
    let item = { ...items[index] };

    item[name] = targetValue;

    // If the value is "U/A" or "N/A", update all relevant fields
    if (targetValue === "U/A" || targetValue === "N/A") {
      item.hcpcsFormPrescription = targetValue;
      item.equipmentModelFormPrescription = targetValue;
    }

    items[index] = item;
    setPrescription(items);
  };

  const addDxCodes = () => {
    let createObj = {};
    createObj.dxCodes = '';
    setDxCodes(preValue => {
      return [
        ...preValue,
        createObj
      ]
    })
    setTimeout(() => {
      DxCodesRef.current.goTo(dxCodes.length);
    }, 0);
  }
  const handleChangeDxCodes = (name, event, index) => {
    const targetValue = event.target.value.toUpperCase();
    let items = [...dxCodes];
    let item = { ...items[index] };

    item[name] = targetValue;

    // If the value is "U/A" or "N/A", update all relevant fields


    items[index] = item;
    setDxCodes(items);
  };
  const removeDxCodes = (index) => {
    if (index > -1) {
      let items = [...dxCodes];
      items.splice(index, 1);
      setDxCodes(items);
      DxCodesRef.current?.prev();
    }
  }

  // Adjust debounce delay as needed (e.g., 300ms)


  const setFormFieldsValue = (values) => {
    form.setFieldsValue(values);
  };

  const handleRemoveBabyDueDate = () => {
    setShowBabyDueDateField(false);
    setBabyDueDate(null);
    form.setFieldsValue({ babyDueDate: "" });
  };

  const handleAddBabyDueDate = () => {
    setBabyDueDate(null);
    setShowBabyDueDateField(true);
  };


  const onChange = (value) => {
    // console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onChangeLmnReceivedDate = (date, dateString) => {
    // console.log(date, dateString);
    // setLmnReceivedDate(dayjs(date).format("MM/DD/YYYY"));
    setLmnReceivedDate(date);
  };

  const handleRemoveLmnDate = () => {
    setShowLmnReceivedDateField(false);
    setLmnReceivedDate(null);
    form.setFieldsValue({ prescriptionReceivedOn: "" });
  };

  const handleAddLmnDate = () => {
    setLmnReceivedDate(null);
    setShowLmnReceivedDateField(true);
  };

  return (
    <Form
      ref={formRef}
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      size={defaultSize === "dataCapture" ? "small" : ""}
    >
      <Row gutter={16}>
        <Col
          {...colProps}
        // xl={8} lg={8} md={8} sm={12} xs={24}
        >
          <Form.Item className="mb-1">
            <Carousel arrows ref={carouselRef} className="bg-[#f5f5f5] rounded p-5">
              {
                Array.isArray(vfRequestEquipmentModelForm) && vfRequestEquipmentModelForm.length > 0 &&
                vfRequestEquipmentModelForm.map((ele, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Form.Item
                        label={`VF Requested Equipment Model ${index + 1}`}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter the VF requested equipment model!',
                          },
                          {
                            pattern: /^[A-Za-z0-9\s]{3,1000}$/,
                            message: 'VF Requested Equipment Model must be alphanumeric and between 3 to 1000 characters.',
                          },
                        ]}
                      >
                        <Input
                          defaultValue={ele.vfRequestedEquipmentModel}
                          tabIndex={-1}
                          name={`vfRequestedEquipmentModel${index}`}
                          value={ele.vfRequestedEquipmentModel}
                          onChange={(e) => handleChangeVfRequestedEquipmentModel('vfRequestedEquipmentModel', e, index)}
                          readOnly={!EditAccess}
                        />
                      </Form.Item>
                      <Form.Item
                        label={`HCPCS Code`}
                      >
                        <Input
                          tabIndex={-1}
                          name='hcpcsCodes'
                          value={ele.hcpcsCodes}
                          onChange={(e) => {
                            const { value } = e.target;
                            // Allow only alphanumeric characters, commas, and '/' character, and limit to 10 characters
                            const validValue = value
                              .replace(/[^a-zA-Z0-9,\\/]/g, '')
                              .replace(/([a-zA-Z0-9]{5})[a-zA-Z0-9]/g, '$1')
                              .slice(0, 11);

                            handleChangeVfRequestedEquipmentModel('hcpcsCodes', { target: { value: validValue } }, index);
                          }}
                          readOnly={!EditAccess}
                        />
                      </Form.Item>


                      <Form.Item
                        label='Equipment Name'
                      >
                        <Input
                          tabIndex={-1}
                          name='equipmentName'
                          value={ele.equipmentName}
                          onChange={(e) => {

                            handleChangeVfRequestedEquipmentModel('equipmentName', e, index)
                          }
                          }
                          readOnly={!EditAccess}
                        />
                      </Form.Item>
                      <div className="grid grid-cols-2 grid-rows-1 gap-0">
                        <Button
                          size="small"
                          type="dashed"
                          icon={<PlusOutlined />}
                          onClick={addVfRequestedEquipmentModel}
                          className="mt-2"
                          disabled={!EditAccess}
                        >
                          Add Equipment
                        </Button>
                        {vfRequestEquipmentModelForm.length > 1 ? <Button
                          size="small"
                          type="dashed"
                          danger
                          warning
                          icon={<MinusOutlined />}
                          onClick={() => removeVfRequestedEquipmentModel(index)}
                          className="mt-2"
                          disabled={!EditAccess}
                        >
                          Remove Equipment
                        </Button> : ""}


                      </div>
                    </React.Fragment>
                  )
                })
              }

            </Carousel>
          </Form.Item>
          {showBabyDueDateField ? (<Form.Item
            className="mb-2"
            label="Baby Due Date"
            name="babyDueDate"
          >
            <DatePicker
              className="w-full"
              value={babyDueDate}
              onChange={onChangeBabyDueDatePicker}
              format={dateFormat}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
            <Button
              type="link"
              onClick={handleRemoveBabyDueDate}
              disabled={!EditAccess}
            >Remove Baby Due Date</Button>
          </Form.Item>) :
            <Button
              type="link"
              onClick={handleAddBabyDueDate}
              disabled={!EditAccess}
            >Add Baby Due Date</Button>
          }
          <Form.Item
            className="mb-2"
            label="Weeks Due"
          >
            <Input
              value={weeksDue}
              type="text"
              maxLength={2}
              onInput={(e) => {
                // Replace non-numeric characters with an empty string
                e.target.value = e.target.value.replace(/\D/g, '');
              }}
              readOnly={!EditAccess}
            />
          </Form.Item>
          <Form.Item className="mb-1">
            <Carousel arrows ref={prescriptionRef} className="bg-[#f5f5f5] rounded p-5">
              {
                Array.isArray(prescription) && prescription.length > 0 &&
                prescription.map((ele, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Form.Item
                        className="mb-2"
                        label={`HCPCS From Prescription ${index + 1}`}
                      >
                        <Input
                          value={ele.hcpcsFormPrescription}
                          onChange={(e) => handleChangePrescription('hcpcsFormPrescription', e, index)}
                          disabled={!EditAccess}
                        />
                      </Form.Item>
                      <Form.Item
                        className="mb-2"
                        label="Equipment Model From Prescription"
                      >
                        <Input
                          value={ele.equipmentModelFormPrescription}
                          onChange={(e) => handleChangePrescription('equipmentModelFormPrescription', e, index)}
                          disabled={!EditAccess}
                        />
                      </Form.Item>
                      <div className="grid grid-cols-2 grid-rows-1 gap-0">
                        <Button
                          size="small"
                          type="dashed"
                          icon={<PlusOutlined />}
                          onClick={addPrescriptionForm}
                          className="mt-2"
                          disabled={!EditAccess}
                        >
                          Add Prescription
                        </Button>
                        {prescription.length > 1 ? <Button
                          size="small"
                          type="dashed"
                          danger
                          warning
                          icon={<MinusOutlined />}
                          onClick={() => removePrescriptionForm(index)}
                          className="mt-2"
                          disabled={!EditAccess}
                        >
                          Remove Prescription
                        </Button> : ""}
                      </div>
                    </React.Fragment>
                  )
                })
              }

            </Carousel>
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="Order Date"
            name="orderDate"
            style={!EditAccess ? { pointerEvents: "none" } : {}}
          >
            <DatePicker
              multiple
              className="w-full"
              onChange={onChangeDatePicker}
              format={dateFormat}
              disabledDate={disabledDate}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="Prescription Classification"
            name="prescriptionClassification"
            style={!EditAccess ? { pointerEvents: "none" } : {}}
          >
            <Select
              showSearch
              allowClear
              placeholder="Select a Prescription Classification"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={prescriptionClassifications}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
          </Form.Item>
        </Col>
        <Col
          {...colProps}
        // xl={8} lg={8} md={8} sm={12} xs={24}
        >
          <Carousel arrows ref={orderingPhysicianCarouselRef} className="bg-[#f5f5f5] rounded p-6">
            {
              Array.isArray(orderingPhysicianNPI) && orderingPhysicianNPI.length > 0 && orderingPhysicianNPI.map((ele, index) => {
                return <React.Fragment key={index}>
                  <Form.Item
                    className="mb-2"
                    label={`Ordering Physician NPI ${index + 1}`}
                    name="orderingPhysicianNpi"
                    rules={[
                      {
                        pattern: /^(^\d{10}$|^U\/A$|^N\/A$)$/i,
                        message: 'Ordering physician NPI must be exactly 10 digits, "U/A", or "N/A".',
                      },
                    ]}
                  >
                    <Space.Compact style={{ width: '100%' }} className="mb-2">
                      <Input
                        tabIndex={-1}
                        name="OrderingPhysicianNPI"
                        value={ele.orderingPhysicianNPI}
                        onChange={(e) => {
                          e.target.value.replace(/[^0-9UuNn/Aa]/g, '');
                          handleChangeOrderingPhysicianModel('orderingPhysicianNPI', e, index)
                        }
                        }
                        readOnly={!EditAccess}
                        maxLength={10}
                      />
                      {ele.orderingPhysicianNPI !== "U/A" && ele.orderingPhysicianNPI !== "N/A" && (
                        <Button
                          onClick={() => handleOrderingPhysicianNpi(index, ele.orderingPhysicianNPI)}
                          icon={<MdDone />}
                          disabled={!EditAccess}
                        />
                      )}
                    </Space.Compact>
                  </Form.Item>

                  <Form.Item
                    className="mb-2"
                    label={<span className="text-black">{`Ordering Physician ${index + 1}`}</span>}
                  >
                    <Input value={ele.orderingPhysician} onChange={(e) => handleChangeOrderingPhysicianModel('orderingPhysician', e, index)}
                      readOnly={!EditAccess} />
                  </Form.Item>
                  <Form.Item
                    className="mb-2"
                    label={<span className="text-black">{`Ordering Physician Address ${index + 1}`}</span>}
                  >
                    <TextArea rows={2} onChange={(e) => handleChangeOrderingPhysicianModel('orderingPhysicianAddress', e, index)} value={ele.orderingPhysicianAddress}
                      readOnly={!EditAccess} />
                  </Form.Item>
                  <Form.Item
                    className="mb-2"
                    label={<span className="text-black">{`Ordering Physician Phone Number ${index + 1}`}</span>}
                  >
                    <Input onChange={(e) => handleChangeOrderingPhysicianModel('orderingPhysicianPhoneNumber', e, index)} value={ele.orderingPhysicianPhoneNumber}
                      readOnly={!EditAccess} />
                  </Form.Item>
                  <Form.Item
                    className="mb-2"
                    label={<span className="text-black">{`Ordering Physician Fax Number ${index + 1}`}</span>}
                  >
                    <Input onChange={(e) => handleChangeOrderingPhysicianModel('orderingPhysicianFaxNumber', e, index)} value={ele.orderingPhysicianFaxNumber}
                      readOnly={!EditAccess} />
                  </Form.Item>
                  <Form.Item
                    className="mb-2"
                    label={<span className="text-black">{`Ordering Physician Speciality ${index + 1}`}</span>}
                  >
                    <Input onChange={(e) => handleChangeOrderingPhysicianModel('orderingPhysicianSpeciality', e, index)} value={ele.orderingPhysicianSpeciality}
                      readOnly={!EditAccess} />
                  </Form.Item>

                  <div className="grid grid-cols-2 grid-rows-1 gap-0">

                    <Button
                      size="small"
                      type="dashed"
                      icon={<PlusOutlined />}
                      onClick={addOrderingPhysicianModel}
                      className="mt-2"
                      disabled={!EditAccess}
                    >
                      Add Order NPI
                    </Button>
                    {orderingPhysicianNPI.length > 1 ? <Button
                      size="small"
                      type="dashed"
                      danger
                      icon={<MinusOutlined />}
                      onClick={() => removeOrderingPhysicianModel(index)}
                      className="mt-2"
                      disabled={!EditAccess}
                    >
                      Remove Order NPI
                    </Button> : ""}
                  </div>
                </React.Fragment>

              })
            }

          </Carousel>
          {showLmnReceivedDateField ? (<Form.Item
            className="mb-2"
            label="Prescription Received On"
            name="prescriptionReceivedOn"
          >
            <DatePicker
              multiple
              className="w-full"
              // defaultValue={lmnReceivedDate}
              value={
                Array.isArray(lmnReceivedDate) // Check if it's an array
                  ? lmnReceivedDate.map(date => dayjs(date, dateFormat))
                  : lmnReceivedDate // If not an array, pass it directly
                    ? dayjs(lmnReceivedDate, dateFormat)
                    : null
              }
              // value={lmnReceivedDate ? dayjs(lmnReceivedDate) : null}
              onChange={onChangeLmnReceivedDate}
              format={dateFormat}
              style={!EditAccess ? { pointerEvents: "none" } : {}}
            />
            <Button
              type="link"
              onClick={handleRemoveLmnDate}
              disabled={!EditAccess}
            >Remove Prescription Received On Date</Button>
          </Form.Item>) :
            <Button
              type="link"
              onClick={handleAddLmnDate}
              disabled={!EditAccess}
            >Add Prescription Received On Date</Button>
          }
          <Form.Item
            className="mb-2"
            name="dxCodes"
          >
            <Carousel arrows ref={DxCodesRef} className="bg-[#f5f5f5] rounded p-5">
              {
                Array.isArray(dxCodes) && dxCodes?.length > 0 &&
                dxCodes.map((ele, index) => {
                  return (

                    <React.Fragment key={index}>

                      <Form.Item
                        label='Dx Codes'
                      >
                        <Input
                          tabIndex={-1}
                          name='dxCodes'
                          value={ele.dxCodes}
                          onChange={(e) => {
                            handleChangeDxCodes('dxCodes', e, index)
                          }
                          }
                          readOnly={!EditAccess}
                        />
                      </Form.Item>
                      <div className="grid grid-cols-2 grid-rows-1 gap-0">
                        <Button
                          size="small"
                          type="dashed"
                          icon={<PlusOutlined />}
                          onClick={addDxCodes}
                          className="mt-2"
                          disabled={!EditAccess}
                        >
                          Add DX Code
                        </Button>
                        {dxCodes.length > 1 ? <Button
                          size="small"
                          type="dashed"
                          danger
                          warning
                          icon={<MinusOutlined />}
                          onClick={() => removeDxCodes(index)}
                          className="mt-2"
                          disabled={!EditAccess}
                        >
                          Remove DX Code
                        </Button> : ""}
                      </div>
                    </React.Fragment>
                  )
                })
              }
            </Carousel>
            {/* <Input /> */}
          </Form.Item>
        </Col>
        <Col
          {...colProps}
        // xl={8} lg={8} md={8} sm={12} xs={24}
        >
          <Form.Item
            className="mb-2"
            label="PCP NPI Number"
            name="pcpNpiNumber"
          >
            <Space.Compact style={{ width: '100%' }} className="mb-2">
              <Input
                value={pcpNpiValue}
                onInput={(e) => {
                  const value = e?.target?.value.replace(/[^0-9uNnU/Aa]/g, '').toUpperCase();
                  setPcpNpiValue(value);

                  const upperValue = value?.toUpperCase();
                  if (["U/A", "N/A"].includes(upperValue)) {
                    setFormFieldsValue({
                      pcp: upperValue,
                      pcpAddress: upperValue,
                      pcpPhoneNumber: upperValue,
                      pcpFaxNumber: upperValue,
                      pcpSpeciality: upperValue,
                    });
                  }
                }}
                readOnly={!EditAccess}
                maxLength={10}
              />
              {!["U/A", "N/A"].includes(pcpNpiValue?.toUpperCase()) && (
                <Button
                  onClick={handlePcpPhysicianNpi}
                  icon={<MdDone />}
                  disabled={!EditAccess}
                />
              )}
            </Space.Compact>

          </Form.Item>
          <Form.Item
            className="mb-2"
            label="PCP"
            name="pcp"
          >
            <Input readOnly={!EditAccess} />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="PCP Address"
            name="pcpAddress"
          >
            <TextArea rows={2} readOnly={!EditAccess} />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="PCP Phone Number"
            name="pcpPhoneNumber"
          >
            <Input readOnly={!EditAccess} />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="PCP Fax Number"
            name="pcpFaxNumber"
          >
            <Input readOnly={!EditAccess} />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="PCP Speciality"
            name="pcpSpeciality"
          >
            <Input readOnly={!EditAccess} />
          </Form.Item>
          <Form.Item
            className="mb-2"
            label="VF TAT"
            name="vfTAT"
          >
            <Input
              type="text"
              maxLength={2} // Limit input to 2 characters visually
              onInput={(e) => {
                // Replace non-numeric characters with an empty string
                e.target.value = e.target.value.replace(/\D/g, '');
              }}
              readOnly={!EditAccess}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col
          // {...colProps}
          xl={12} lg={12} md={12} sm={12} xs={12}
        >
          <Form.Item className="mb-2">
            {/* <Button className="w-fit mt-2 font-medium">Cancel</Button> */}

          </Form.Item>
        </Col>
        <Col
          // {...colProps}
          xl={12} lg={12} md={12} sm={12} xs={12}
        >
          <Form.Item className="mb-2">
            <Button
              className="w-fit bg-blue-400 mt-2 font-medium float-right"
              type="primary"
              htmlType="submit"
              disabled={!EditAccess}
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form >
  );
};

export default memo(ProductDetails);
