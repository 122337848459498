import { Button, Dropdown } from 'antd';
import React, { useEffect, useState } from 'react'
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/loading/loadingSlice';
import { getProvidersApi } from '../../redux/provider/providerService';
import _ from 'lodash';
import { getProjectsApi } from '../../redux/project/projectService';
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { clearUserDetails } from '../../redux/userDetails/userDetailsSlice';
import storage from 'redux-persist/lib/storage';
import { IoIosContact } from "react-icons/io";

const ProviderHeader = ({
  collapsed,
  setCollapsed
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [getCalls, setGetCalls] = useState([1, 1]);
  const [userName, setUserName] = useState('');
  const [projectName, setProjectName] = useState('');

  const { providerList } = useSelector((state) => state.provider);
  const { projectList } = useSelector((state) => state.project);

  const userId = useSelector((state) => state.userDetails.userId);
  const project = useSelector((state) => state.userDetails.project);

  useEffect(() => {
    const func = async () => {
      /* get admin logs */
      if (getCalls[0]) {
        let success = getCalls;
        success[0] = 0;
        const loadingResult = success.includes(1);
        try {
          dispatch(setLoading(loadingResult));
          await dispatch(getProvidersApi()).unwrap();
          setGetCalls(success);
          dispatch(setLoading(loadingResult))
        } catch (error) {
          setGetCalls(success)
          dispatch(setLoading(loadingResult));
        }
      }
      /* get admin logs */
      if (getCalls[1]) {
        let success = getCalls;
        success[1] = 0;
        const loadingResult = success.includes(1);
        try {
          dispatch(setLoading(loadingResult));
          await dispatch(getProjectsApi()).unwrap();
          setGetCalls(success);
          dispatch(setLoading(loadingResult))
        } catch (error) {
          setGetCalls(success)
          dispatch(setLoading(loadingResult));
        }
      }
    }
    func();
  }, [dispatch, getCalls]);
  useEffect(() => {
    const findUser = _.find(providerList, { id: userId });
    setUserName(findUser?.name ? findUser.name : 'Loading...');
    if (project) {
      const findProject = _.find(projectList, { id: project });
      setProjectName(findProject?.name);
    }
  }, [project, projectList, providerList, userId]);


  const getPersistKey = () => {
    return sessionStorage.getItem('persistKey');
  };

  const handleLogOut = async () => {

    dispatch(clearUserDetails());
    const persistKey = getPersistKey();
    if (persistKey) {
      storage.removeItem(`persist:${persistKey}`);
      localStorage.removeItem(`persist:${persistKey}`);
      // sessionStorage.removeItem('persistKey');
    }
    navigate("/providerLogin");
  };

  const items = [
    {
      label: 'Log Out',
      key: '1',
      button: (
        <Button icon={<LogoutOutlined />} onClick={handleLogOut}>
          Log Out
        </Button>
      ),
    }
  ];



  const menuProps = {
    items,
    onClick: handleLogOut,
  };


  return (
    <header className='w-full flex items-center bg-blue-300 px-4 sticky top-0 z-10 mb-2'>
      <Button
        type="text"
        icon={collapsed ? <IoIosArrowForward /> : <IoIosArrowBack />}
        onClick={() => setCollapsed(!collapsed)}
        style={{
          fontSize: 20,
          width: 80,
          height: 80,
          color: 'white'
        }}
      />
      <div className='flex justify-center items-center flex-grow'>
        <h1 className='text-white font-medium text-2xl'>
          MYWWS Business Manager
        </h1>
      </div>
      <div className="flex flex-col items-start mr-4">
        <p className="text-white font-medium text-base mb-1">
          <span className="text-white-200 font-semibold">Name :</span> {userName ? `${userName}` : "Loading..."}

        </p>
        <div className="text-white font-medium text-md">
          <span className="text-white-200">Facility : {projectName ? `${projectName}` : "Loading..."}</span>
        </div>
      </div>
      <div className='flex justify-end items-center w-20'>
        <Dropdown className='text-white rounded cursor-pointer' menu={menuProps} placement="bottomRight" arrow>
          <button>
            <IoIosContact style={{ fontSize: '35px' }} />
          </button>
        </Dropdown>
      </div>
    </header>
  )
}

export default ProviderHeader;