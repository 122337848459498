import { Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import ModalBox from '../../components/ModalBox';
import { runes } from 'runes2';
import { toast } from 'react-toastify';
import { axiosErrorHandling, compareTwoObjects, isEmptyObject, isObject, trimObjValues } from '../../utils/utility';
import _ from 'lodash';
import { setLoading } from '../../redux/loading/loadingSlice';
import { updateProviderApi } from '../../redux/provider/providerService';

const EditProviderUser = ({
  editStatus,
  setEditStatus,
  editData,
  setEditData,
  providerList,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);

  const handlePasswordChange = (value) => {
    // Password validation checks
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /[0-9]/;

    // Check if all criteria are met
    const isValid =
      lowercaseRegex.test(value) &&
      uppercaseRegex.test(value) &&
      specialCharRegex.test(value) &&
      numberRegex.test(value) &&
      value.length >= 8;

    // Update password validity state
    setIsPasswordValid(isValid);
  };

  const validatePassword = (_, value) => {
    if (isPasswordValid) {
      return Promise.resolve();
    }
    return Promise.reject(
      'Password must be 8 characters or more, include at least one lowercase character, one uppercase character, one special character, and one number.'
    );
  };

  const handleNameChange = (value) => {
    // Name validation checks
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>_+=/;`~[\]\\']/;


    // Check if all criteria are met
    const isValid =
      !specialCharRegex.test(value)

    // Update Name validity state
    setIsNameValid(isValid);
  };

  const validateName = (_, value) => {
    if (isPasswordValid) {
      return Promise.resolve();
    }
    return Promise.reject(
      'Special characters are not allowed in user names.'
    );
  };

  const getStatusOptions = (status) => {
    if (status === 'ACTIVE') {
      return [{ value: 'SUSPEND', label: 'SUSPEND' }];
    } else if (status === 'SUSPEND') {
      return [{ value: 'ACTIVE', label: 'ACTIVE' }];
    }
    return [];
  };

  const getContent = () => {
    const filterOption = (input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
      <Row gutter={16}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onSubmitEditUser}
            initialValues={editData}
            autoComplete="off"
          >
            <Form.Item
              className="mb-2"
              label="Name"
              name="name"
              validateStatus={!isNameValid ? 'error' : ''}
              help={
                !isNameValid && 'Special characters are not allowed in user names.'
              }
              rules={[
                { required: true, message: 'Please enter the name!' },
                { validator: validateName },
              ]}
            >
              <Input
                count={{
                  show: true,
                  max: 25,
                  strategy: (txt) => runes(txt).length,
                  exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(''),
                }}
                onChange={(e) => handleNameChange(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              className="mb-2"
              label="Email"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'This is not a valid email!',
                },
                { required: true, message: 'Please enter the email!' },
                { whitespace: true },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="mb-2"
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please enter the password!' },
                { validator: validatePassword },
              ]}
            >
              <Input.Password
                onChange={(e) => handlePasswordChange(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              className="mb-2"
              label="Access"
              name="access"
              rules={[
                { required: true, message: 'Please select the user access!' },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select user access"
                optionFilterProp="children"
                filterOption={filterOption}
                options={[
                  { value: 'VIEW', label: 'VIEW' },
                  { value: 'DOWNLOAD', label: 'DOWNLOAD' },

                ]}
              />
            </Form.Item>
            <Form.Item
              className="mb-2"
              label="Status"
              name="status"
              rules={[
                { required: true, message: 'Please select the user status!' },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select user status"
                optionFilterProp="children"
                filterOption={filterOption}
                options={getStatusOptions(editData.status)}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  };

  const onSubmitEditUser = async () => {
    try {
      const values = await form.validateFields();
      const items = { ...values };
      const updateId = editData.id;

      /* check update id */
      if (!updateId) {
        return toast.warning('Invalid update id');
      }

      /* check is object */
      if (!isObject(items)) {
        return toast.warning('This is not object data!');
      }

      /* check is empty object */
      if (isEmptyObject(items)) {
        return toast.warning('Empty object cannot accept!');
      }

      /* trim values */
      trimObjValues(items);

      /* get previous object from database */
      const findPreviousObject = _.find(providerList, { id: updateId });

      const previousObject = {
        name: findPreviousObject.name,
        email: findPreviousObject.email,
        password: findPreviousObject.password,
        project: findPreviousObject?.project?.id,
        access: findPreviousObject.access,
        status: findPreviousObject.status
      };

      /* selected data */
      const currentObject = {
        name: items.name,
        email: items.email,
        password: items.password,
        project: items?.project?.id,
        access: items.access,
        status: items.status
      };

      /* compare two objects for same or not returns boolean value */
      const compareResult = compareTwoObjects(previousObject, currentObject);
      const nameResult = providerList.filter((item) => {
        return (
          item['name'].toString().toLowerCase() ===
          items.name.toString().toLowerCase()
        );
      });

      const emailResult = providerList.filter((item) => {
        return (
          item['email'].toString().toLowerCase() ===
          items.email.toString().toLowerCase()
        );
      });

      if (compareResult) {
        setEditStatus(false);
        setEditData({});
        return;
      }

      const previousName = findPreviousObject.name;
      const updateName = currentObject.name;
      const previousEmail = findPreviousObject.email;
      const updateEmail = currentObject.email;

      if (previousName.toLowerCase() !== updateName.toLowerCase()) {
        if (nameResult.length > 0) {
          return toast.warning('The name already exists!');
        }
      }

      if (previousEmail.toLowerCase() !== updateEmail.toLowerCase()) {
        if (emailResult.length > 0) {
          return toast.warning('The email already exists!');
        }
      }

      setEditStatus(false);
      dispatch(setLoading(true));
      items.project = editData?.project?.id;
      await dispatch(updateProviderApi({ updateId, items })).unwrap();

      dispatch(setLoading(false));
      toast.success('User updated successfully!');
      setEditData({});
    } catch (error) {
      dispatch(setLoading(false));
      return axiosErrorHandling(error);
    }
  };
  return (
    <>
      {editStatus && (
        <ModalBox
          open={editStatus}
          title="Edit User"
          width="xs"
          content={getContent()}
          onSubmitTitle="Update"
          onSubmit={onSubmitEditUser}
          onCancelTitle="Cancel"
          onCancel={() => {
            setEditStatus(false);
          }}
        />
      )}
    </>
  )
}

export default EditProviderUser;
