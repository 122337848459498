import { createSlice } from '@reduxjs/toolkit';
import {
  getProjectsApi,
  createProjectApi,
  updateProjectApi,
  deleteProjectApi
} from './projectService';

const initialState = {
  projectList: [],
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // add your non-async reducers here
    logout: state => {
      state.projectList = [];
      state.loading = 'idle';
      state.currentRequestId = undefined;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    // extraReducers handles asynchronous requests, which is our main focus.
    // GET
    builder
      .addCase(getProjectsApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getProjectsApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.projectList.length = 0;
          state.projectList.push(...action.payload);
          state.currentRequestId = undefined;
        }
      })
      .addCase(getProjectsApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

    // POST
    builder
      .addCase(createProjectApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(createProjectApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.projectList.unshift(action.payload);
          state.currentRequestId = undefined;
        }
      })
      .addCase(createProjectApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

    // PUT
    builder
      .addCase(updateProjectApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(updateProjectApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          const updateItem = action.payload;
          const index = state.projectList.findIndex((item) => item.id === updateItem.id);
          if (index > -1) {
            state.projectList[index] = updateItem;
          }
          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })
      .addCase(updateProjectApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })

    // DELETE
    builder
      .addCase(deleteProjectApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteProjectApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          const index = state.projectList.findIndex((item) => item.id === action.payload);
          if (index > -1) {
            state.projectList.splice(index, 1);
          }
          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteProjectApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      })
  }
})

// Action creators are generated for each case reducer function
export const { logout } = projectSlice.actions;

export default projectSlice.reducer;