import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import loadingReducer from './loading/loadingSlice';
import projectReducer from './project/projectSlice';
import roleReducer from './role/roleSlice';
import userReducer from './user/userSlice';
import documentReducer from './document/documentSlice';
import chartReducer from "./chart/chartSlice";
import userLogReducer from "./userLog/userLogSlice";
import notificationReducer from "./notification/notificationSlice";
import adminLogReducer from "./adminLog/adminLogSlice";
import chartDocumentReducer from "./chartDocument/chartDocumentSlice";
import scheduleSliceReducer from './scheduleSend/scheduleSendSlice';
import superAdminLogReducer from './superAdminLog/superAdminLogSlice';
import userDetailsReducer from './userDetails/userDetailsSlice';
import providerReducer from './provider/providerSlice';
import exclusionReducer from "./Exclusion/exclusionSlice";
import timeZoneReducer from "./timeZone/timeZoneSlice";
import chartViewManagementReducer from './ChartViewManagement/ChartViewManagementSlice';


// Function to generate a unique key and store it in sessionStorage
const getPersistKey = () => {
  const persistKey = sessionStorage.getItem('persistKey');
  if (!persistKey) {
    const newPersistKey = `persistedState-${new Date().getTime()}`;
    sessionStorage.setItem('persistKey', newPersistKey);
    return newPersistKey;
  }
  return persistKey;
};

const persistConfig = {
  key: getPersistKey(),
  version: 1,
  storage,
  blacklist: ['loading', 'userLog', 'notification', 'adminLog', 'chartDocument', 'scheduleSend', 'superAdminLog', 'chart', 'document', 'project', 'role', 'user', 'provider', 'exclusion', 'timeZone', 'chartViewManagement'], // Add slices that don’t need persistence
  whitelist: ['userDetails'],
};

const rootReducer = combineReducers({
  loading: loadingReducer,
  project: projectReducer,
  role: roleReducer,
  user: userReducer,
  document: documentReducer,
  chart: chartReducer,
  userLog: userLogReducer,
  notification: notificationReducer,
  adminLog: adminLogReducer,
  chartDocument: chartDocumentReducer,
  scheduleSend: scheduleSliceReducer,
  superAdminLog: superAdminLogReducer,
  userDetails: userDetailsReducer,
  provider: providerReducer,
  exclusion: exclusionReducer,
  timeZone: timeZoneReducer,
  chartViewManagement: chartViewManagementReducer, 
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,

    }),
});

export const persistor = persistStore(store);
