import React from 'react';
import { List } from 'antd';
import dayjs from 'dayjs';

const NotificationList = ({ notificationList, searchQuery }) => {

  const filteredNotifications = notificationList.filter(notify =>
    ((notify.title.toLowerCase().includes(searchQuery.toLowerCase())) || notify.message.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <List
      itemLayout="horizontal"
      dataSource={filteredNotifications}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            title={<span className='text-sm font-semibold text-blue-600 mb-2'>{item.title}</span>}
            description={<span className='text-sm font-normal text-gray-800'>{item.message}</span>}
          />
          <div className='text-xs font-semibold text-slate-600'>{dayjs(item.createdAt).format("MM-DD-YY hh:mm:ss A")}</div>
        </List.Item>
      )}
    />
  );
};

export default NotificationList;
