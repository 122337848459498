import api from "../../api";

const baseUrl = "/api/user";

export const getUsers = () => {
  return api.get(baseUrl);
};

export const addUser = (postData) => {
  return api.post(baseUrl, postData);
};

export const updateUser = (updateId, putData) => {
  return api.put(`${baseUrl}/${updateId}`, putData);
};

export const deleteUser = (deleteId) => {
  return api.delete(`${baseUrl}/${deleteId}`);
};
