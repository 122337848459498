import { createSlice } from '@reduxjs/toolkit';
import {
  createUserLogApi,
  deleteUserLogApi,
  getUserLogsApi,
  updateUserLogApi,
} from './userLogService';

const initialState = {
  userLogList: [],
  loading: 'idle',
  currentRequestId: undefined,
  error: null,
};

export const userLogSlice = createSlice({
  name: 'userLog',
  initialState,
  reducers: {
    // add your non-async reducers here
    logout: (state) => {
      state.userLogList = [];
      state.loading = 'idle';
      state.currentRequestId = undefined;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // extraReducers handles asynchronous requests, which is our main focus.
    // GET
    builder
      .addCase(getUserLogsApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getUserLogsApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.userLogList.length = 0;
          state.userLogList.push(...action.payload);
          state.currentRequestId = undefined;
        }
      })
      .addCase(getUserLogsApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      });

    // POST
    builder
      .addCase(createUserLogApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(createUserLogApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          state.userLogList.unshift(action.payload);
          state.currentRequestId = undefined;
        }
      })
      .addCase(createUserLogApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      });

    // PUT
    builder
      .addCase(updateUserLogApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(updateUserLogApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          const updateItem = action.payload;
          const index = state.userLogList.findIndex(
            (item) => item.id === updateItem.id
          );
          if (index > -1) {
            state.userLogList[index] = updateItem;
          }
          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })
      .addCase(updateUserLogApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      });

    // DELETE
    builder
      .addCase(deleteUserLogApi.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteUserLogApi.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          const index = state.userLogList.findIndex(
            (item) => item.id === action.payload
          );
          if (index > -1) {
            state.userLogList.splice(index, 1);
          }
          state.loading = 'idle';
          state.currentRequestId = undefined;
        }
      })
      .addCase(deleteUserLogApi.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loading === 'pending' &&
          state.currentRequestId === requestId
        ) {
          state.loading = 'idle';
          // state.error = action.error;
          state.currentRequestId = undefined;
          if (action.payload) {
            state.error = action.payload.errorMessage;
          } else {
            state.error = action.error.message;
          }
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const { logout } = userLogSlice.actions;

export default userLogSlice.reducer;
