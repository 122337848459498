import { Table, Tooltip } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaEdit } from 'react-icons/fa';
import { IoMdTrash } from 'react-icons/io';
import { SmileOutlined } from '@ant-design/icons';

const ProviderUserTable = ({
  providerList,
  searchQuery,
  setEditStatus,
  setEditData,
  setDeleteStatus,
  setDeleteData,
  setUserCount
}) => {
  const [getUserProject, setGetUserProject] = useState([]);

  const userProject = useSelector((state) => state.userDetails.project);
  const userId = useSelector((state) => state.userDetails.userId);


  useEffect(() => {
    if (userProject !== null) {
      const getFilteredProject = providerList.filter((items) => items?.project?.id === userProject && !items?.isProvider && items?.createdBy === userId);
      setUserCount(getFilteredProject.length);
      setGetUserProject(getFilteredProject);
    }

    if (userProject === undefined) {
      setGetUserProject(providerList);
    }

  }, [providerList, setUserCount, userId, userProject]);


  const editPress = (id) => {
    if (!id) return toast.warning('Invalid id!');
    const findRole = _.find(providerList, { 'id': id });
    if (!findRole) return toast.warning('Invalid selected data!');
    setEditStatus(true);
    setEditData(findRole);
  };

  const deletePress = (id) => {
    if (!id) return toast.warning('Invalid id!');
    const findRole = _.find(providerList, { 'id': id });
    if (!findRole) return toast.warning('Invalid selected data!');
    setDeleteStatus(true);
    setDeleteData(findRole);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
      render: (_, record) => <span>{'*'.repeat(record.password.length)}</span>
    },
    {
      title: 'Access',
      dataIndex: 'access',
      key: 'access'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Actions Menu',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <div className='w-full flex justify-center items-center'>
          <Tooltip title="Modify User Information">
            <button type='button' onClick={() => editPress(record.id)}>
              <FaEdit className='text-blue-500 text-lg' />
            </button>
          </Tooltip>
          <Tooltip title="Delete">
            <button type='button' className='ml-2' onClick={() => deletePress(record.id)}>
              <IoMdTrash className='text-red-500 text-xl' />
            </button>
          </Tooltip>
        </div>
      )
    }
  ];

  const data = getUserProject.filter(item =>
    (item.name.toString().toLowerCase().indexOf(searchQuery.toString().toLowerCase()) !== -1) ||
    (item.email.toString().toLowerCase().indexOf(searchQuery.toString().toLowerCase()) !== -1)
  ).map(({ id, name, email, password, access, status }) => ({ "key": id, "id": id, "name": name, "email": email, "password": password, "access": access, "status": status }));

  return (
    <div className="flex flex-col flex-wrap">
      <div className="-m-1.5">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="border shadow-md rounded-lg max-h-[65vh] overflow-y-auto">
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              size='small'
              locale={{
                emptyText: (
                  <div style={{ textAlign: 'center' }}>
                    <SmileOutlined style={{ fontSize: 20 }} />
                    <p>Data Not Found</p>
                  </div>
                )
              }}
              scroll={{ y: 400 }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProviderUserTable;