import api from "../../api";

const baseUrl = "/api/scheduleSend";

export const scheduleSend = (postData) => {
    return api.post(baseUrl, postData);
};

export const getScheduleList = () => {
    return api.get(baseUrl);
};

export const updateScheduleList = (updateId, putData) => {
    return api.put(`${baseUrl}/${updateId}`, putData);
}

export const deleteScheduleList = (deleteId) => {
    return api.delete(`${baseUrl}/${deleteId}`);
};