import React, { useEffect, useState } from 'react'
import { Link, Routes, Route, useLocation } from "react-router-dom";
import { Layout, Menu } from 'antd';
import { PieChartOutlined } from '@ant-design/icons';
import { FaUsersCog } from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import { HiOutlineUserCircle } from "react-icons/hi";
import ProviderSignInPage from './ProviderSignInPage';
import ProviderUser from '../ProviderUser/ProviderUser';
import AntFooter from '../../components/AntFooter';
import ProviderHeader from './ProviderHeader';
import Dashboard from '../SuperAdmin/Dashboard/Dashboard';
import { useSelector } from 'react-redux';
import EditProviderProfile from '../ProviderUser/EditProviderProfile';

const { Content, Sider } = Layout;

const navigation = [
  { key: '1', icon: <PieChartOutlined />, text: "Dashboard", to: "/provider/dashBoard" },
  {
    key: 'settings',
    icon: <AiFillSetting />,
    text: "Settings",
    children: [
      { key: '2', icon: <FaUsersCog />, text: "Users", to: "/provider/providerUser" },
      { key: '3', icon: <HiOutlineUserCircle />, text: "Edit Profile", to: "/provider/providerProfile" }
    ]
  },
];

const Index = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKey, setSelectedKey] = useState(
    navigation.find(item => location.pathname.startsWith(item.to))?.key || ''
  );
  const role = useSelector((state) => state.userDetails.role);

  useEffect(() => {
    setSelectedKey(navigation.find(item => location.pathname.startsWith(item.to))?.key || '')
  }, [location])

  useEffect(() => {
    const findSelectedKey = (items) => {
      for (const item of items) {
        if (item.children) {
          const childItem = findSelectedKey(item.children);
          if (childItem) return childItem;
        }
        if (location.pathname.startsWith(item.to)) {
          return item.key;
        }
      }
      return '';
    };

    const selected = findSelectedKey(navigation);
    setSelectedKey(selected);
  }, [location]);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };


  const renderMenuItems = (items) => {
    return items.map(item => {
      if (item.children) {
        return (
          <Menu.SubMenu key={item.key} icon={item.icon} title={item.text}>
            {renderMenuItems(item.children)}
          </Menu.SubMenu>
        );
      }
      if (item.key === '2' && role !== 'PROVIDER') {
        return null;
      }
      return (
        <Menu.Item key={item.key} icon={item.icon} onClick={() => {
          setSelectedKey(item.key);
        }}>
          <Link to={item.to}>{item.text}</Link>
        </Menu.Item>
      );
    });
  };


  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
          style={{ backgroundColor: '#93c5fd', position: 'fixed', left: 0, top: 0, bottom: 0, zIndex: 100, width: collapsed ? '80px' : '200px' }}
        >
          <div className="demo-logo-vertical m-2">
            {collapsed ? <img src={require("../../assets/WWS.jpg")} style={{ backgroundColor: 'white', borderRadius: 10 }} alt='logo' /> : <img src={require("../../assets/Wonder-Worth-Solutions-Logo.png")} style={{ backgroundColor: 'white', margin: 1, borderRadius: 10, padding: 15, paddingTop: 15, paddingBottom: 15 }} alt='logo' />}
          </div>
          <Menu
            className='bg-blue-300 font-semibold text-white'
            selectedKeys={[selectedKey]}
            mode="inline"
          >
            {renderMenuItems(navigation)}
          </Menu>
        </Sider>
        <Layout className="site-layout" style={{ marginLeft: collapsed ? '80px' : '200px', transition: 'margin-left 0.2s' }}>
          <ProviderHeader collapsed={collapsed} setCollapsed={setCollapsed} />
          <Content style={{ margin: '10px 10px 10px 10px', overflow: 'auto', zIndex: 0 }}>
            <div style={{ padding: 20, minHeight: 'calc(-160px + 100vh)', background: '#fff', boxShadow: '0 2px 8px rgba(0,0,0,0.09)', borderRadius: '8px' }}>
              <Routes>
                <Route path="/" element={<ProviderSignInPage />} />
                <Route path="/dashBoard/*" element={<Dashboard />} />
                <Route path="/providerUser/*" element={<ProviderUser />} />
                <Route path="/providerProfile" element={<EditProviderProfile />} />
              </Routes>
            </div>
          </Content>
          <AntFooter />
        </Layout>
      </Layout>
    </>
  )
}

export default Index;