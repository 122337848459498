import api from "../../api";

const baseUrl = "/api/notification";

export const getNotifications = () => {
  return api.get(baseUrl);
};

export const addNotification = (postData) => {
  return api.post(baseUrl, postData);
};

export const updateNotification = (updateId, putData) => {
  return api.put(`${baseUrl}/${updateId}`, putData);
};

export const deleteNotification = (deleteId, items) => {
  return api.delete(`${baseUrl}/${deleteId}`, { data: { items } });
};
