import { Col, Descriptions, Row, Timeline, Typography } from 'antd';
import React from 'react';
import ModalBox from './ModalBox';
import dayjs from 'dayjs';

const { Title, Text } = Typography;

const ChartInfo = ({ isInfoVisible, setIsInfoVisible, chartData }) => {
  const getStatusTimeline = () => {
    return (
      <Timeline>
        {chartData?.statusHistory?.map((status, index) => (
          <Timeline.Item key={status._id}>
            <Text strong>
              {status.fromStatus ? `From: ${status.fromStatus}` : 'Initial Status'}{' '}
              <Text type="danger">→</Text> To: {status.toStatus ? status.toStatus : 'Unknown'}
            </Text>
            <br />
            <Text type="secondary">{dayjs(status.changedAt).format('MM/DD/YYYY HH:mm')}</Text>
            {status.changedBy && (
              <>
                <br />
                <Text italic>Changed By: {status.changedBy.name}</Text>
              </>
            )}
          </Timeline.Item>
        ))}
      </Timeline>
    );
  };

  const getContent = () => {
    return (
      <div>
        <Row gutter={16}>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Descriptions column={1} bordered size='small'>
              <Descriptions.Item label="Created At">
                {chartData?.createdAt ? dayjs(chartData?.createdAt).format('MM/DD/YYYY') : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Verification Assigned On">
                {chartData?.verifyAssignedOn
                  ? dayjs(chartData?.verifyAssignedOn).format('MM/DD/YYYY')
                  : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Verification Completed On">
                {chartData?.verifyCompletedOn
                  ? dayjs(chartData?.verifyCompletedOn).format('MM/DD/YYYY')
                  : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Audit Assigned On">
                {chartData?.auditAssignedOn
                  ? dayjs(chartData?.auditAssignedOn).format('MM/DD/YYYY')
                  : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Audit Completed On/Signed Off On">
                {chartData?.signedOffOn
                  ? dayjs(chartData?.signedOffOn).format('MM/DD/YYYY')
                  : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="LMN Completed On">
                {chartData?.lmnValidationCompletedOn
                  ? dayjs(chartData?.lmnValidationCompletedOn).format('MM/DD/YYYY')
                  : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Calling Assigned On">
                {chartData?.callVerifyAssignedOn
                  ? dayjs(chartData?.callVerifyAssignedOn).format('MM/DD/YYYY')
                  : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Calling Completed On">
                {chartData?.callVerifyCompletedOn
                  ? dayjs(chartData?.callVerifyCompletedOn).format('MM/DD/YYYY')
                  : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Calling Audit Assigned On">
                {chartData?.callAuditAssignedOn
                  ? dayjs(chartData?.callAuditAssignedOn).format('MM/DD/YYYY')
                  : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Calling Audit Completed On">
                {chartData?.callAuditCompletedOn
                  ? dayjs(chartData?.callAuditCompletedOn).format('MM/DD/YYYY')
                  : 'U/A'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Descriptions column={1} bordered size='small'>
              <Descriptions.Item label="Chart No">
                {chartData?.verifyChartNo ? chartData?.verifyChartNo : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Verification By">
                {chartData?.verifyBy?.name ? chartData?.verifyBy?.name : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Chart No">
                {chartData?.auditChartNo ? chartData?.auditChartNo : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Audit By">
                {chartData?.auditBy?.name ? chartData?.auditBy?.name : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="LMN Validation By">
                {chartData?.lmnValidatedBy?.name ? chartData?.lmnValidatedBy?.name : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Chart No">
                {chartData?.callerChartNo ? chartData?.callerChartNo : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Calling By">
                {chartData?.callingBy?.name ? chartData?.callingBy?.name : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Chart No">
                {chartData?.callerAuditChartNo ? chartData?.callerAuditChartNo : 'U/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Calling Audit By">
                {chartData?.callingAuditBy?.name ? chartData?.callingAuditBy?.name : 'U/A'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: '20px' }}>
          <Col span={24}>
            <Title level={5}>Status Change History</Title>
            {getStatusTimeline()}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      {isInfoVisible && (
        <ModalBox
          open={isInfoVisible}
          width="lg"
          content={getContent()}
          title={`Chart Info : ${chartData?.patientName}`}
          onCancel={() => {
            setIsInfoVisible(false);
          }}
        />
      )}
    </>
  );
};

export default ChartInfo;
