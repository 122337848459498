import React from 'react';
import { Input } from 'antd';

const DisabledInputField = ({ value }) => {
  return (
    <div className='w-full'>
      <Input
        value={value || ""}
        disabled
        style={{ color: "black", cursor: "text" }}
      />
    </div>
  )
}

export default DisabledInputField;