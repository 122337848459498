import React from 'react';
import SearchBar from '../../components/SearchBar';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const NotificationHeader = ({
  searchQuery,
  setSearchQuery
}) => {
  const navigate = useNavigate();
  return (
    <div className='w-full h-[15%] flex justify-between items-center text-gray-500 px-40 py-2'>
      <ArrowLeftOutlined style={{ fontSize: '20px', marginRight: '10px' }} onClick={() => navigate(-1)} />
      <SearchBar
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
    </div>
  );
};

export default NotificationHeader;
