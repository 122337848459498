import api from "../../api";

const baseUrl = "/api/adminLog";

export const getAdminLogs = () => {
  return api.get(baseUrl);
};

export const createAdminLog = (postData) => {
  return api.post(baseUrl, postData);
};

export const updateAdminLog = (updateId, putData) => {
  return api.put(`${baseUrl}/${updateId}`, putData);
};

export const deleteAdminLog = (deleteId) => {
  return api.delete(`${baseUrl}/${deleteId}`);
};
