import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProviderUserTable from './ProviderUserTable';
import { setLoading } from '../../redux/loading/loadingSlice';
import { getProvidersApi } from '../../redux/provider/providerService';
import SearchBar from '../../components/SearchBar';
import { Button, notification } from 'antd';
import CreateProviderUser from './CreateProviderUser';
import EditProviderUser from './EditProviderUser';
import DeleteProviderUser from './DeleteProviderUser';

const ProviderUser = () => {
  const dispatch = useDispatch();

  const [getCalls, setGetCalls] = useState([1]);
  const [searchQuery, setSearchQuery] = useState('');
  const [userCount, setUserCount] = useState(0);
  const [createStatus, setCreateStatus] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const { providerList } = useSelector((state) => state.provider);

  useEffect(() => {
    const func = async () => {
      /* get providers */
      if (getCalls[0]) {
        let success = getCalls;
        success[0] = 0;
        const loadingResult = success.includes(1);
        try {
          dispatch(setLoading(true));
          await dispatch(getProvidersApi()).unwrap();
          setGetCalls(success);
          dispatch(setLoading(loadingResult));
        } catch (error) {
          setGetCalls(success);
          dispatch(setLoading(loadingResult));
        }
      }
    };

    func();
  }, [dispatch, getCalls])

  return (
    <div className='w-full h-full'>
      <div className='w-full h-[15%] flex justify-between items-center text-gray-500 px-40 py-2'>
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <Button
          className='bg-blue-500 font-medium float-right mr-2'
          type="primary"
          onClick={(e) => {
            e.preventDefault();
            if (userCount === 3) {
              return notification.warning({
                message: 'Max User Limit Reached',
                description: 'If you need additional users, kindly reach the admin of WWS Facility.',
              });
            }
            setCreateStatus(true);
          }}
        >
          Create User
        </Button>
      </div>
      <div className='my-2 px-4'>
        <ProviderUserTable
          providerList={providerList}
          searchQuery={searchQuery}
          setEditStatus={setEditStatus}
          setEditData={setEditData}
          setDeleteStatus={setDeleteStatus}
          setDeleteData={setDeleteData}
          setUserCount={setUserCount}
        />
        {createStatus && (
          <CreateProviderUser
            createStatus={createStatus}
            setCreateStatus={setCreateStatus}
            providerList={providerList}
          />
        )}
        {
          editStatus && (
            <EditProviderUser
              editStatus={editStatus}
              setEditStatus={setEditStatus}
              editData={editData}
              setEditData={setEditData}
              providerList={providerList}
            />
          )
        }
        {
          deleteStatus && (
            <DeleteProviderUser
              deleteStatus={deleteStatus}
              setDeleteStatus={setDeleteStatus}
              deleteData={deleteData}
              setDeleteData={setDeleteData}
            />
          )
        }
      </div>
    </div>
  );
};

export default ProviderUser;
