import api from "../../api";

const baseUrl = "/api/superAdminLog";

export const getSuperAdminLog = () => {
  return api.get(baseUrl);
};

export const createSuperAdminLog = (postData) => {
  return api.post(baseUrl, postData);
};