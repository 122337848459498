import Axios from "axios";
import api from "../../api";

const baseUrl = "/api/document";

export const getDocuments = () => {
  return api.get(baseUrl);
};

export const getPaginatedDocuments = (page, limit, searchTerm, project, userId) => {
  return api.get(`${baseUrl}/documents`, {
    params: {
      page,
      limit,
      search: searchTerm,
      project,
      userId
    },
  });
};

export const viewDocument = (id) => {
  return api.get(`${baseUrl}/${id}`);
};

export const addDocument = (postData) => {
  return api.post(baseUrl, postData);
};

export const updateDocument = (updateId, putData) => {
  return api.put(`${baseUrl}/${updateId}`, putData);
};

export const deleteDocument = (deleteId) => {
  return api.delete(`${baseUrl}/${deleteId}`);
};

export const getPresignedUrl = (id, type) => {
  return api.post(`${baseUrl}/presignedUrl`, { id, type });
};

export const uploadFile = (url, file, cb) => {
  const controller = new AbortController();
  return Axios.put(url, file.fileObject, {
    headers: {
      "Content-Type": file.type,
    },
    onUploadProgress: (e) => {
      cb(file, controller, {
        uploaded: e.loaded,
        total: e.total,
        progress: Math.floor((e.loaded / e.total) * 100),
      });
    },
    signal: controller.signal,
  });
};