import { createAsyncThunk } from '@reduxjs/toolkit';
import { addUserLog, deleteUserLog, getUserLogs, updateUserLog } from '../../api/userLog/userLog';

export const getUserLogsApi = createAsyncThunk(
  "userLog/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getUserLogs();
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createUserLogApi = createAsyncThunk(
  "userLog/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addUserLog(data);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserLogApi = createAsyncThunk(
  "userLog/update",
  async (data, { rejectWithValue }) => {
    const { updateId, items } = data;
    try {
      const response = await updateUserLog(updateId, items);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserLogApi = createAsyncThunk(
  "userLog/delete",
  async (deleteId, { rejectWithValue }) => {
    try {
      const { status } = await deleteUserLog(deleteId);
      if (status === 202) return deleteId;
    } catch (error) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.data);
    }
  }
);