import api from "../../api";

const baseUrl = "/api/project";

export const getProjects = () => {
  return api.get(baseUrl);
};

export const addProject = (postData) => {
  return api.post(baseUrl, postData);
};

export const updateProject = (updateId, putData) => {
  return api.put(`${baseUrl}/${updateId}`, putData);
};

export const deleteProject = (deleteId) => {
  return api.delete(`${baseUrl}/${deleteId}`);
};
