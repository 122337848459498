import React, { useEffect, useState } from 'react';
import NotificationHeader from './NotificationHeader';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/loading/loadingSlice';
import { getNotificationsApi } from '../../redux/notification/notificationService';
import NotificationList from './NotificationList';

const NotificationCenter = () => {
  const dispatch = useDispatch();
  const [getCalls, setGetCalls] = useState([1]);
  const [searchQuery, setSearchQuery] = useState('');
  const { notificationList } = useSelector((state) => state.notification);

  useEffect(() => {
    const func = async () => {
      if (getCalls[0]) {
        let success = getCalls;
        success[0] = 0;
        const loadingResult = success.includes(1);
        try {
          dispatch(setLoading(true));
          await dispatch(getNotificationsApi()).unwrap();
          setGetCalls(success);
          dispatch(setLoading(loadingResult));
        } catch (error) {
          setGetCalls(success);
          dispatch(setLoading(loadingResult));
        }
      }
    };
    func();
  }, [dispatch, getCalls]);

  return (
    <div className='w-full h-full flex flex-col'>
      <NotificationHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <div className='flex-grow overflow-y-auto'>
        <div className='m-10 mt-4 my-2 px-4'>
          <NotificationList
            notificationList={notificationList}
            searchQuery={searchQuery}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationCenter;
