/* eslint-disable no-unused-vars */
import React, { useState, memo, useEffect } from 'react';
import { unChangedObj, axiosErrorHandling, customFindByAdmin } from '../../utils/utility';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { signInApi } from '../../api/signIn/signIn';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/loading/loadingSlice';
import { Button, Form, Input, Select } from 'antd';
import { getProjects } from '../../api/project/project';
import { getRoles } from '../../api/role/role';
import dayjs from 'dayjs';
import { createAdminLogApi, updateAdminLogApi } from '../../redux/adminLog/adminLogService';
import { getAdminLogs } from '../../api/adminLog/adminLog';
import { createSuperAdminLogApi } from '../../redux/superAdminLog/superAdminLogService';
import { setUserDetails } from '../../redux/userDetails/userDetailsSlice';
import { trackLogIn } from '../../api/userLog/userLog';

const initialState = {
  email: '',
  password: '',
  project: '',
  role: '',
};

const roleMapping = {
  'SUPER ADMIN': 'System Administrator',
  'VERIFICATION': 'E&B Data Integrity Specialist',
  'AUDIT': 'E&B Compliance Auditor',
  'ADMIN': 'E&B Administrator',
  'VERIFICATION CALLER': 'E&B Caller & Data Integrity Specialist',
  'AUDIT CALLER': 'E&B Caller - Compliance Auditor',
  'CODING': 'Coding and Reimbursement Specialist',
  'GUEST': 'Visitor Viewer',
};

const getMappedRoleName = (name) => roleMapping[name] || name;

const SignIn = () => {
  const [form] = Form.useForm();
  const [addSignIn, setAddSignIn] = useState(() => unChangedObj(initialState));
  const [projectList, setProjectList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [adminLogList, setAdminLogList] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        // Fetch projects
        const { data: projectsData, status: projectsStatus } = await getProjects();
        if (projectsStatus === 200) {
          setProjectList(projectsData);
        } else {
          setProjectList([]);
        }

        // Fetch roles
        const { data: rolesData, status: rolesStatus } = await getRoles();
        if (rolesStatus === 200) {
          setRoleList(rolesData);
        } else {
          setRoleList([]);
        }

        // Fetch admin logs
        const { data: adminLogsData, status: adminLogStatus } = await getAdminLogs();
        if (adminLogStatus === 200) {
          setAdminLogList(adminLogsData);
        } else {
          setAdminLogList([]);
        }

        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        return axiosErrorHandling(error);
      }
    };
    fetchData();
  }, [dispatch]);

  const onChange = (value) => {
    // console.log(`selected ${value}`);
  };

  const onSearch = (value) => {
    // console.log('search:', value);
  };

  useEffect(() => {
    form.setFields([
      {
        name: 'project',
        rules: isSuperAdmin ? [] : [{ required: true, message: 'Please select the project!' }],
      },
    ]);
  }, [isSuperAdmin, form]);

  const handleRoleChange = (value) => {
    const selectedRoleIds = value || [];
    const selectedRoles = roleList.filter(role => selectedRoleIds.includes(role.id));
    const superAdminRole = selectedRoles.some(role => role.name === 'SUPER ADMIN');

    setIsSuperAdmin(superAdminRole);
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onFinish = async (values) => {
    try {
      dispatch(setLoading(true));
      const { status, data } = await signInApi(values);
      if (status === 201 && data.user === "ADMIN") {
        dispatch(setUserDetails({ userId: data.userId, role: data.user, project: data.project, userProject: data.project, timeZone: data.timeZone }));
        const userId = data.userId;
        const role = data.user;
        await trackLogIn(userId, role)
        navigate('/admin/dashboard');
        dispatch(setLoading(false));
      } else if (status === 201 && data.user === "GUEST") {
        dispatch(setUserDetails({ userId: data.userId, role: data.user, project: data.project, userProject: data.project, timeZone: data.timeZone }));
        const userId = data.userId;
        const role = data.user;
        await trackLogIn(userId, role)
        navigate('/admin/dashboard');
        dispatch(setLoading(false));
      }
      else if (status === 201 && data.user === "SUPER ADMIN") {
        dispatch(setUserDetails({ userId: data.userId, role: data.user, project: data.project, timeZone: data.timeZone }));
        const userId = data.userId;
        const role = data.user;
        await trackLogIn(userId, role)
        navigate('/super-admin/dashboard');
        dispatch(setLoading(false));
      } else if (status === 201) {
        dispatch(setUserDetails({ userId: data.userId, role: data.user, project: data.project, timeZone: data.timeZone }));
        navigate('/users/');
        dispatch(setLoading(false));
      } else if (status === 202) {
        toast.warning('Invalid credentials, please verify your email and password');
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      return axiosErrorHandling(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <div className="sign-in-page">
      <div className="w-full h-[100vh] flex justify-center items-center">
        <div className="w-[90vw] flex items-center bg-white rounded-xl shadow-xl">
          <div className="xl:w-3/5 lg:w-3/5 md:w-3/5 sm:w-3/5 xs:w-0 xl:flex lg:flex md:flex sm:flex xs:hidden">
            <div className="w-full h-full flex justify-center items-center">
              <img
                src={require('../../assets/Wonder-Worth-Solutions-Logo.png')}
                alt="logo"
              />
            </div>
          </div>
          <div className="flex xl:w-2/5 lg:w-2/5 md:w-2/5 sm:w-2/5 xs:w-full p-5 bg-gray-100 xs:bg-white rounded-xl">
            <div className="flex justify-center items-center xl:hidden lg:hidden md:hidden sm:hidden xs:flex">
              <img
                src={require('../../assets/Wonder-Worth-Solutions-Logo.png')}
                alt="logo"
              />
            </div>
            <Form
              className="w-full"
              name="basic"
              layout="vertical"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              onFieldsChange={(changedFields, allFields) => {
                const roleField = allFields.find(field => field.name[0] === 'role');
                if (roleField) {
                  handleRoleChange(roleField.value);
                }
              }}
            >
              <Form.Item
                className="mb-2"
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please enter the email!' }]}
              >
                <Input
                  value={addSignIn.email}
                  onChange={(e) =>
                    setAddSignIn((preValue) => ({
                      ...preValue,
                      email: e.target.value,
                    }))
                  }
                  placeholder='Enter Email'
                />
              </Form.Item>

              <Form.Item
                className="mb-2"
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please enter the password!' }]}
              >
                <Input.Password placeholder='Enter Password' />
              </Form.Item>

              <Form.Item
                className="mb-2"
                label="Facility"
                name="project"
                rules={isSuperAdmin ? [] : [{ required: true, message: 'Please select the project!' }]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select Facility"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={projectList.map(({ name, id }) => ({
                    value: id,
                    label: name,
                  }))}
                />
              </Form.Item>

              <Form.Item
                className="mb-2"
                label="Role"
                name="role"
                rules={[{ required: true, message: 'Please select the role!' }]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select User Role"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={roleList.map(({ name, id }) => ({
                    value: id,
                    label: getMappedRoleName(name),
                  }))}
                />
              </Form.Item>

              <Form.Item className="mb-2">
                <Button
                  className="w-full bg-blue-400 mt-2 font-medium"
                  type="primary"
                  htmlType="submit"
                >
                  Sign In
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SignIn);